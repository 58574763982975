import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const journalTypeModule = {
  state: {
    journalType: [],
  },
  mutations: {
    setJournalType(state, payload) {
      state.journalType = payload;
    },
  },
  actions: {
    async getJournalTypeData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/journalType");
        let data = res.data.data;
        commit("setJournalType", data);
      } catch (error) {
        console.log(error);
      }
    },
    async addJournalTypeData({ dispatch, commit }, payload) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + "/journalType", {
          code: payload.code,
          name: payload.name,
        });
        dispatch("getJournalTypeData");
        commit("showSnackbarAdd", true);
      } catch (error) {
        console.log(error);
      }
    },
    async editJournalTypeData({ dispatch, commit }, payload) {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "/journalType/" + payload.id,
          {
            code: payload.code,
            name: payload.name,
          }
        );
        dispatch("getJournalTypeData");
        commit("showSnackbarEdit", true);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteJournalType({ dispatch, commit }, id) {
      try {
        await axios.delete(process.env.VUE_APP_API_URL + "/journalType/" + id);
        dispatch("getJournalTypeData");
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getJournalType(state) {
      return state.journalType;
    },
  },
};

export default journalTypeModule;
