<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="mini"
    :expand-on-hover="mini"
    :disable-route-watcher="mini"
    @mouseover.native="showAllItemsMenu"
    @mouseleave.native="hideAllMenu"
    permanent
    class="sidebar"
    color="#49A6E1"
    app
  >
    <v-list-item class="list-item-title" to="/main/beranda">
      <v-list-item-content>
        <v-list-item-title class="title text-center">
          {{ getCompanyName }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list class="mt-4" dense nav>
      <v-list-item-group active-class="list-active">
        <v-list-item class="list-item" :to="home.route">
          <v-list-item-content>
            <v-list-item-title @click="hideAllItemList">
              <div class="d-flex flex-row align-center">
                <v-icon size="20" class="white--text mr-4">
                  {{ home.icon }}
                </v-icon>
                <span class="item-title">{{ home.title }}</span>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="list-item" v-if="isShowMenuSetup === true">
          <v-list-item-content style="padding: 0 !important">
            <v-list-item-title
              style="padding: 8px 0 !important"
              @click="showItemList"
            >
              <div class="d-flex flex-row align-center">
                <v-icon size="20" class="white--text mr-4">
                  mdi-cog-outline
                </v-icon>
                <span class="item-title">Pengaturan</span>
                <v-spacer></v-spacer>
                <v-icon
                  v-if="isShowItem === true && showAllMenu === true"
                  size="18"
                  class="white--text"
                >
                  mdi-chevron-down
                </v-icon>

                <v-icon v-else size="18" class="white--text">
                  mdi-chevron-right
                </v-icon>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-expand-transition>
          <div v-if="isShowItem === true && showAllMenu === true">
            <v-list-item
              v-for="(item, index) in setup"
              :key="index"
              :to="item.route"
              class="list-item ml-9 mb-1"
            >
              <!-- <v-list-item-title
                v-if="item.title === 'Laporan'"
                @click="showReportSetupList"
                class="white--text"
              >
                <div class="d-flex flex-row align-center">
                  <span class="item-title">{{ item.title }}</span>
                  <v-spacer></v-spacer>
                  <v-icon
                    v-if="isShowReportSetup === true && showAllMenu === true"
                    size="18"
                    class="white--text"
                  >
                    mdi-chevron-down
                  </v-icon>

                  <v-icon v-else size="18" class="white--text">
                    mdi-chevron-right
                  </v-icon>
                </div>
              </v-list-item-title> -->
              <v-list-item-title class="white--text">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item-group active-class="list-active">
              <v-list-item
                class="list-item ml-9 mb-1"
                v-if="isShowSettingSetup === true"
              >
                <v-list-item-content style="padding: 0 !important">
                  <v-list-item-title
                    style="padding: 8px 0 !important"
                    @click="showSettingReportList"
                  >
                    <div class="d-flex flex-row align-center">
                      <span class="item-title">Laporan</span>
                      <v-spacer></v-spacer>
                      <v-icon
                        v-if="
                          isShowItemSettingSetup === true &&
                          showAllMenu === true
                        "
                        size="18"
                        class="white--text"
                      >
                        mdi-chevron-down
                      </v-icon>

                      <v-icon v-else size="18" class="white--text">
                        mdi-chevron-right
                      </v-icon>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-expand-transition>
                <div
                  v-if="isShowItemSettingSetup === true && showAllMenu === true"
                >
                  <v-list-item
                    v-for="(item, index) in settingSetup"
                    :key="index"
                    :to="item.route"
                    class="list-item ml-16 mb-1"
                  >
                    <v-list-item-title class="white--text">{{
                      item.title
                    }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-expand-transition>
            </v-list-item-group>
          </div>
        </v-expand-transition>

        <!-- <v-expand-transition>
          <div v-if="isShowReportSetup === true && showAllMenu === true">
            <v-list-item
              v-for="(item, index) in reportSetup"
              :key="index"
              :to="item.route"
              class="list-item ml-14 mb-1"
            >
              <v-list-item-title class="white--text">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-expand-transition> -->

        <v-list-item class="list-item" v-if="isShowMenuTransaction === true">
          <v-list-item-content style="padding: 0 !important">
            <v-list-item-title
              style="padding: 8px 0 !important"
              @click="showSettingsList"
            >
              <div class="d-flex flex-row align-center">
                <v-icon size="20" class="white--text mr-4"> mdi-cash </v-icon>
                <span class="item-title">Transaksi</span>
                <v-spacer></v-spacer>
                <v-icon
                  v-if="isShowSettings === true && showAllMenu === true"
                  size="18"
                  class="white--text"
                >
                  mdi-chevron-down
                </v-icon>

                <v-icon v-else size="18" class="white--text">
                  mdi-chevron-right
                </v-icon>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-expand-transition>
          <div v-if="isShowSettings === true && showAllMenu === true">
            <v-list-item
              v-for="(setting, index) in transactions"
              :key="index"
              :to="setting.route"
              class="list-item ml-9 mb-1"
            >
              <v-list-item-title class="white--text">{{
                setting.title
              }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-expand-transition>

        <v-list-item class="list-item" v-if="isShowMenuReport === true">
          <v-list-item-content style="padding: 0 !important">
            <v-list-item-title
              style="padding: 8px 0 !important"
              @click="showReportList"
            >
              <div class="d-flex flex-row align-center">
                <v-icon size="20" class="white--text mr-4">
                  mdi-file-document-outline
                </v-icon>
                <span class="item-title">Laporan</span>
                <v-spacer></v-spacer>
                <v-icon
                  v-if="isShowReport === true && showAllMenu === true"
                  size="18"
                  class="white--text"
                >
                  mdi-chevron-down
                </v-icon>

                <v-icon v-else size="18" class="white--text">
                  mdi-chevron-right
                </v-icon>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-expand-transition>
          <div v-if="isShowReport === true && showAllMenu === true">
            <v-list-item
              v-for="(report, index) in reports"
              :key="index"
              :to="report.route"
              class="list-item ml-9"
            >
              <v-list-item-title class="white--text">{{
                report.title
              }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-expand-transition>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TheSidebar",
  props: ["mini", "showAllMenu"],
  data: () => ({
    drawer: true,
    listSetup: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    listReport: [false, false, false, false],
    listTransaction: [false, false],
    listSettingSetup: [false],
    home: {
      title: "Beranda",
      route: "/main/beranda",
      icon: "mdi-home-variant-outline",
    },
    setup: [
      { title: "Pengguna", route: "/main/pengaturan/pengguna/list" },
      { title: "Peran", route: "/main/pengaturan/peran" },
      { title: "Manajemen Peran", route: "/main/pengaturan/manajemen-peran" },
      {
        title: "Informasi Perusahaan",
        route: "/main/pengaturan/informasi-perusahaan",
      },
      {
        title: "Tipe Akun",
        route: "/main/pengaturan/tipe-akun",
      },
      {
        title: "Kategori Akun",
        route: "/main/pengaturan/kategori-akun",
      },
      {
        title: "Tipe Arus Kas",
        route: "/main/pengaturan/tipe-arus-kas",
      },
      {
        title: "Kategori Arus Kas",
        route: "/main/pengaturan/kategori-arus-kas",
      },
      {
        title: "Akun (COA)",
        route: "/main/pengaturan/kode-akun",
      },
      {
        title: "Tipe Transaksi",
        route: "/main/pengaturan/tipe-transaksi",
      },
      {
        title: "Tipe Jurnal",
        route: "/main/pengaturan/tipe-jurnal",
      },
      {
        title: "Kode T",
        route: "/main/pengaturan/kode-t",
      },
      {
        title: "Periode",
        route: "/main/pengaturan/periode",
      },
      {
        title: "Tipe Laporan",
        route: "/main/pengaturan/tipe-laporan",
      },
    ],
    reportSetup: [
      { title: "NA", route: "/main/pengaturan/laporan/statements-net-asset" },
      { title: "BS", route: "/main/pengaturan/laporan/balance-sheets" },
      {
        title: "CNA",
        route: "/main/pengaturan/laporan/statements-of-changes-in-net-asset",
      },
      { title: "IS", route: "/main/pengaturan/laporan/statements-of-income" },
      {
        title: "CF",
        route: "/main/pengaturan/laporan/statements-of-cash-flow",
      },
    ],
    settingSetup: [
      {
        title: "Perubahan Asset Neto",
        route: "/main/pengaturan/informasi/perubahan-asset-neto",
      },
    ],
    transactions: [
      {
        title: "Manajemen Periode",
        route: "/main/transaksi/manajemen-periode",
      },
      {
        title: "Jurnal",
        route: "/main/transaksi/jurnal/list",
      },
    ],
    reports: [
      {
        title: "Account Listing",
        route: "/main/laporan/account-listing",
      },
      {
        title: "Journal Listing",
        route: "/main/laporan/journal-listing",
      },
      {
        title: "Trial Balance",
        route: "/main/laporan/trial-balance",
      },
      {
        title: "Laporan",
        route: "/main/laporan/keuangan-dana-pensiun",
      },
    ],
    isShowItem: false,
    isShowSettings: false,
    isShowReport: false,
    isShowReportSetup: false,
    isShowMenuSetup: false,
    isShowMenuTransaction: false,
    isShowMenuReport: false,
    isShowSettingSetup: false,
    isShowItemSettingSetup: false,
  }),
  computed: {
    ...mapGetters(["getCompanyName", "getListPermissionById"]),
  },
  methods: {
    ...mapActions(["getCompanyNameData"]),
    hideAllItemList() {
      this.isShowItem = false;
      this.isShowSettings = false;
      this.isShowReport = false;
      this.isShowItemSettingSetup = false;
      this.isShowReportSetup = false;
    },
    showReportList() {
      this.isShowItem = false;
      this.isShowSettings = false;
      this.isShowReportSetup = false;
      this.isShowItemSettingSetup = false;
      this.isShowReport = !this.isShowReport;
    },
    showItemList() {
      this.isShowSettings = false;
      this.isShowReport = false;
      this.isShowReportSetup = false;
      this.isShowItemSettingSetup = false;
      this.isShowItem = !this.isShowItem;
    },
    showSettingsList() {
      this.isShowItem = false;
      this.isShowReport = false;
      this.isShowReportSetup = false;
      this.isShowItemSettingSetup = false;
      this.isShowSettings = !this.isShowSettings;
    },
    showSettingReportList() {
      this.isShowSettings = false;
      this.isShowReport = false;
      this.isShowReportSetup = false;
      this.isShowItem = true;
      this.isShowItemSettingSetup = !this.isShowItemSettingSetup;
    },
    showReportSetupList() {
      this.isShowReportSetup = !this.isShowReportSetup;
    },
    showAllItemsMenu() {
      if (this.$props.mini) {
        this.$emit("showAllItemsMenu");
      }
    },
    hideAllMenu() {
      if (this.$props.mini) {
        this.$emit("hideAllMenu");
      }
    },
    setListPermissionSetup() {
      var permissions = this.getListPermissionById;

      this.listSetup[11] = permissions.some((item) => {
        if (item === 38) return true;
        if (item === 39) return true;
        if (item === 40) return true;
        if (item === 41) return true;
        return false;
      });
      if (this.listSetup[11] === false) this.setup.splice(11, 1);

      this.listSetup[10] = permissions.some((item) => {
        if (item === 36) return true;
        if (item === 37) return true;
        return false;
      });
      if (this.listSetup[10] === false) this.setup.splice(10, 1);

      this.listSetup[9] = permissions.some((item) => {
        if (item === 32) return true;
        if (item === 33) return true;
        if (item === 34) return true;
        if (item === 35) return true;
        return false;
      });
      if (this.listSetup[9] === false) this.setup.splice(9, 1);

      this.listSetup[8] = permissions.some((item) => {
        if (item === 28) return true;
        if (item === 29) return true;
        if (item === 30) return true;
        if (item === 31) return true;
        return false;
      });
      if (this.listSetup[8] === false) this.setup.splice(8, 1);

      this.listSetup[7] = permissions.some((item) => {
        if (item === 24) return true;
        if (item === 25) return true;
        if (item === 26) return true;
        if (item === 27) return true;
        return false;
      });
      if (this.listSetup[7] === false) this.setup.splice(7, 1);

      this.listSetup[6] = permissions.some((item) => {
        if (item === 20) return true;
        if (item === 21) return true;
        if (item === 22) return true;
        if (item === 23) return true;
        return false;
      });
      if (this.listSetup[6] === false) this.setup.splice(6, 1);

      this.listSetup[5] = permissions.some((item) => {
        if (item === 16) return true;
        if (item === 17) return true;
        if (item === 18) return true;
        if (item === 19) return true;
        return false;
      });
      if (this.listSetup[5] === false) this.setup.splice(5, 1);

      this.listSetup[4] = permissions.some((item) => {
        if (item === 12) return true;
        if (item === 13) return true;
        if (item === 14) return true;
        if (item === 14) return true;
        return false;
      });
      if (this.listSetup[4] === false) this.setup.splice(4, 1);

      this.listSetup[3] = permissions.some((item) => {
        return item === 11;
      });
      if (this.listSetup[3] === false) this.setup.splice(3, 1);

      this.listSetup[2] = permissions.some((item) => {
        if (item === 9) return true;
        if (item === 10) return true;
        return false;
      });
      if (this.listSetup[2] === false) this.setup.splice(2, 1);

      this.listSetup[1] = permissions.some((item) => {
        if (item === 5) return true;
        if (item === 6) return true;
        if (item === 7) return true;
        if (item === 8) return true;
        return false;
      });
      if (this.listSetup[1] === false) this.setup.splice(1, 1);

      this.listSetup[0] = permissions.some((item) => {
        if (item === 1) return true;
        if (item === 2) return true;
        if (item === 3) return true;
        if (item === 4) return true;
        return false;
      });
      if (this.listSetup[0] === false) this.setup.splice(0, 1);
    },
    setSetupByPermission() {
      this.isShowMenuSetup = this.listSetup.some((item) => {
        return item === true;
      });
    },
    setListPermissionTransaction() {
      var permissions = this.getListPermissionById;

      this.listTransaction[1] = permissions.some((item) => {
        if (item === 44) return true;
        if (item === 45) return true;
        if (item === 46) return true;
        if (item === 47) return true;
        if (item === 48) return true;
        return false;
      });
      if (this.listTransaction[1] === false) this.transactions.splice(1, 1);

      this.listTransaction[0] = permissions.some((item) => {
        if (item === 42) return true;
        if (item === 43) return true;
        return false;
      });
      if (this.listTransaction[0] === false) this.transactions.splice(0, 1);
    },
    setTransactionByPermission() {
      this.isShowMenuTransaction = this.listTransaction.some((item) => {
        return item === true;
      });
    },
    setListPermissionReport() {
      var permissions = this.getListPermissionById;

      this.listReport[3] = permissions.some((item) => {
        if (item === 50) return true;
        if (item === 51) return true;
        if (item === 52) return true;
        if (item === 53) return true;
        if (item === 54) return true;
        return false;
      });
      if (this.listReport[3] === false) this.reports.splice(3, 1);

      this.listReport[2] = permissions.some((item) => {
        return item === 49;
      });
      if (this.listReport[2] === false) this.reports.splice(2, 1);

      this.listReport[1] = permissions.some((item) => {
        return item === 56;
      });
      if (this.listReport[1] === false) this.reports.splice(1, 1);

      this.listReport[0] = permissions.some((item) => {
        return item === 55;
      });
      if (this.listReport[0] === false) this.reports.splice(0, 1);
    },
    setReportByPermission() {
      this.isShowMenuReport = this.listReport.some((item) => {
        return item === true;
      });
    },
    setListSettingSetup() {
      var permissions = this.getListPermissionById;

      this.listSettingSetup[0] = permissions.some((item) => {
        if (item === 57) return true;
        return false;
      });
      if (this.listSettingSetup[0] === false) this.settingSetup.splice(0, 1);
    },
    setSettingSetupByPermission() {
      this.isShowSettingSetup = this.listSettingSetup.some((item) => {
        return item === true;
      });
    },
  },
  async created() {
    await this.getCompanyNameData();
    setTimeout(() => {
      this.setListPermissionSetup();
      this.setListPermissionReport();
      this.setListPermissionTransaction();
      this.setListSettingSetup();
      this.setSetupByPermission();
      this.setReportByPermission();
      this.setTransactionByPermission();
      this.setSettingSetupByPermission();
    }, 300);
  },
};
</script>

<style src="../../styles/sidebar.scss" lang="scss" />
