import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const profileModule = {
  state: {
    userById: [],
  },
  mutations: {
    setUserById(state, payload) {
      state.userById = payload;
    },
  },
  actions: {
    async getProfileData({ commit }) {
      try {
        let id = localStorage.getItem("userId");
        let res = await axios.get(process.env.VUE_APP_API_URL + "/user/" + id);
        let data = res.data.data;
        commit("setUserById", data);
      } catch (error) {
        console.log(error);
      }
    },
    async editProfileData({ commit }, payload) {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "/user/profile/" + payload.id,
          {
            name: payload.name,
            address: payload.address,
            email: payload.email,
            phone: payload.phone,
            password: payload.password,
          }
        );
        commit("showSnackbarEdit", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getProfile(state) {
      return state.userById;
    },
  },
};

export default profileModule;
