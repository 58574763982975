import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const arusKasTypeModule = {
  state: {
    arusKasType: [],
  },
  mutations: {
    setArusKasType(state, payload) {
      state.arusKasType = payload;
    },
  },
  actions: {
    async getArusKasTypeData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/arusKasType");
        let data = res.data.data;
        commit("setArusKasType", data);
      } catch (error) {
        console.log(error);
      }
    },
    async addArusKasTypeData({ dispatch, commit }, payload) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + "/arusKasType", {
          code: payload.code,
          name: payload.name,
        });
        dispatch("getArusKasTypeData");
        commit("showSnackbarAdd", true);
      } catch (error) {
        console.log(error);
      }
    },
    async editAruskasTypeData({ dispatch, commit }, payload) {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "/arusKasType/" + payload.id,
          {
            code: payload.code,
            name: payload.name,
          }
        );
        dispatch("getArusKasTypeData");
        commit("showSnackbarEdit", true);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteArusKasType({ dispatch, commit }, id) {
      try {
        await axios.delete(process.env.VUE_APP_API_URL + "/arusKasType/" + id);
        dispatch("getArusKasTypeData");
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getArusKasType(state) {
      return state.arusKasType;
    },
  },
};

export default arusKasTypeModule;
