<template>
  <v-expand-transition>
    <v-card
      v-show="getNotification"
      class="notification-card mt-3"
      id="navbar-notification"
    >
      <v-card-title class="toolbar">
        <span class="card-title">Notifikasi</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeNotification">
          <v-icon color="black" size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center align-center mt-16">
          <v-img max-width="100" src="@/assets/images/notification.svg"></v-img>
        </div>
        <div class="d-flex justify-center align-center mt-8">
          <span class="notification-text">Belum ada notifikasi</span>
        </div>
      </v-card-text>
    </v-card>
  </v-expand-transition>
</template>

<script>
export default {
  name: "TheNotification",
  computed: {
    getNotification: {
      get() {
        return this.$store.state.notification;
      },
      set(v) {
        this.$store.commit("showNotification", v);
      },
    },
  },
  methods: {
    closeNotification() {
      this.$store.commit("showNotification", false);
    },
  },
};
</script>

<style src="../../styles/notification.scss" lang="scss" scoped />
