const setNumberFormat = (value) => {
  const splitNumber = value.toString().split(".");
  const beforeDot = splitNumber[0];
  const afterDot = splitNumber[1];

  if (splitNumber[1]) {
    return (
      beforeDot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      "," +
      afterDot
    );
  } else {
    return beforeDot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ",00";
  }
};

export { setNumberFormat };
