import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const arusKasCategoryModule = {
  state: {
    arusKasCategory: [],
  },
  mutations: {
    setArusKasCategory(state, payload) {
      state.arusKasCategory = payload;
    },
  },
  actions: {
    async getDataCategoryArusKas({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/arusKasCategory"
        );
        let data = res.data.data;
        commit("setArusKasCategory", data);
      } catch (error) {
        console.log(error);
      }
    },
    async addArusKasCategoryData({ dispatch, commit }, payload) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + "/arusKasCategory", {
          arusKasTypeId: payload.arusKasTypeId,
          type: payload.type,
          code: payload.code,
          name: payload.name,
        });
        dispatch("getDataCategoryArusKas");
        commit("showSnackbarAdd", true);
      } catch (error) {
        console.log(error);
      }
    },
    async editArusKasCategoryData({ dispatch, commit }, payload) {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "/arusKasCategory/" + payload.id,
          {
            code: payload.code,
            type: payload.type,
            name: payload.name,
            arusKasTypeId: payload.arusKasTypeId,
          }
        );
        dispatch("getDataCategoryArusKas");
        commit("showSnackbarEdit", true);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteArusKasCategory({ dispatch, commit }, id) {
      try {
        await axios.delete(
          process.env.VUE_APP_API_URL + "/arusKasCategory/" + id
        );
        dispatch("getDataCategoryArusKas");
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getCategoryArusKas(state) {
      return state.arusKasCategory;
    },
  },
};

export default arusKasCategoryModule;
