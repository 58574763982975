import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const accountTypeModule = {
  state: {
    accountType: [],
  },
  mutations: {
    setAccountType(state, payload) {
      state.accountType = payload;
    },
  },
  actions: {
    async getAccountTypeData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/accountType");
        let data = res.data.data;
        commit("setAccountType", data);
      } catch (error) {
        console.log(error);
      }
    },
    async addAccountTypeData({ dispatch, commit }, payload) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + "/accountType", {
          code: payload.code,
          name: payload.name,
        });
        dispatch("getAccountTypeData");
        commit("showSnackbarAdd", true);
      } catch (error) {
        console.log(error);
      }
    },
    async editAccountTypeData({ dispatch, commit }, payload) {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "/accountType/" + payload.id,
          {
            code: payload.code,
            name: payload.name,
          }
        );
        dispatch("getAccountTypeData");
        commit("showSnackbarEdit", true);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteAccountType({ dispatch, commit }, id) {
      try {
        await axios.delete(process.env.VUE_APP_API_URL + "/accountType/" + id);
        dispatch("getAccountTypeData");
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getAccountType(state) {
      return state.accountType;
    },
  },
};

export default accountTypeModule;
