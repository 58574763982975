import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const unitModule = {
  state: {
    unit: [],
  },
  mutations: {
    setUnit(state, payload) {
      state.unit = payload;
    },
  },
  actions: {
    async getUnitData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/unit");
        let data = res.data.data;
        commit("setUnit", data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getUnit(state) {
      return state.unit;
    },
  },
};

export default unitModule;
