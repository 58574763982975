<template>
  <v-container>
    <v-card class="arus-kas-category-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="arus-kas-card-title">Kategori Arus Kas</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <base-button
              :disabled="listPermission[0] === false"
              @add="showAddDialog"
            >
              <template v-slot:btnText>
                <span>Tambah Kategori Arus Kas</span>
              </template></base-button
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7 mt-n2">
        <v-row justify="space-between">
          <v-col md="3">
            <v-select
              v-model="arusKasTypeId"
              :items="arusKasTypeItems"
              item-value="id"
              item-text="name"
              :loading="arusKasTypeLoading"
              placeholder="Tipe Arus Kas"
              append-icon=""
              outlined
              dense
            >
              <template v-slot:prepend-inner>
                <v-icon color="#CCCCCC">mdi-filter</v-icon>
              </template>
              <template v-slot:selection="{ item }">
                <span class="d-flex justify-center" style="width: 100%">
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col md="4" class="mr-3">
            <v-text-field
              v-model="search"
              placeholder="Cari"
              prepend-inner-icon="mdi-magnify"
              background-color="#FAFAFA"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-7 pr-7 pb-7 mt-n8">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="
              listPermission[3] === false
                ? arusKasCategoryItems
                : filteredArusKasCategory
            "
            :search="search"
            class="arus-kas-category-table"
            :loading="isLoading"
            loading-text="Sedang memuat data..."
            no-results-text="Data tidak ditemukan"
            :no-data-text="noDataText"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.arusKasTypeId`]="{ item }">
              <span> {{ arusKasType(item.arusKasTypeId) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#E89F3C"
                    x-small
                    height="24"
                    min-width="24"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleEditItem(item)"
                    :disabled="listPermission[1] === false"
                  >
                    <v-icon color="white" size="14">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    color="#CE5542"
                    x-small
                    height="24"
                    min-width="24"
                    class="ml-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleDeleteItem(item.id)"
                    :disabled="listPermission[2] === false"
                  >
                    <v-icon color="white" size="14">mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- Add Dialog -->
    <v-dialog
      v-model="addArusKasCategoryDialog"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Tambah Kategori Arus Kas</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeArusKasCategoryAddDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="addForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleAddArusKasCategory"
          >
            <v-row class="mt-6">
              <v-col md="4" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Arus Kas</span>
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="input.arusKasTypeId"
                  :items="getArusKasType"
                  item-value="id"
                  item-text="name"
                  :rules="arusKasCategoryRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="input.code"
                  :rules="arusKasCategoryCodeRules.concat(checkAddCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Saldo Normal</span>
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="input.type"
                  :items="akunCategoryType"
                  placeholder="Pilih Saldo Normal"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="input.name"
                  :rules="arusKasCategoryNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeArusKasCategoryAddDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Add Dialog -->
    <!-- Add Dialog -->
    <v-dialog
      v-model="editArusKasCategoryDialog"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Ubah Kategori Arus Kas</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeArusKasCategoryEditDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="editForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleEditArusKasCategory"
          >
            <v-row class="mt-6">
              <v-col md="4" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Arus Kas</span>
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="arusKasCategory.arusKasTypeId"
                  :items="getArusKasType"
                  item-value="id"
                  item-text="name"
                  :rules="arusKasCategoryRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="arusKasCategory.code"
                  :rules="arusKasCategoryCodeRules.concat(checkEditCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Saldo Normal</span>
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="arusKasCategory.type"
                  :items="akunCategoryType"
                  placeholder="Pilih Saldo Normal"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="arusKasCategory.name"
                  :rules="arusKasCategoryNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeArusKasCategoryEditDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Add Dialog -->
    <!-- Delete Dialog -->
    <v-dialog
      v-model="dialogDelete"
      transition="dialog-top-transition"
      max-width="350"
    >
      <v-card color="pt-8">
        <BodyCardDelete />
        <v-card-actions class="justify-center">
          <v-btn
            class="btn-close-dialog mr-4"
            tile
            depressed
            @click="dialogDelete = false"
            >Batal</v-btn
          >
          <v-btn class="btn-confirm" tile depressed @click="confirmDeleteItem"
            >Hapus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Delete Dialog -->
    <v-snackbar
      v-model="getSnackbarAdd"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAddText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAdd = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarEditText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarDelete"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarDeleteText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarDelete = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "./base/BaseButton.vue";
import BodyCardDelete from "./AppCardBodyDelete.vue";

export default {
  name: "ArusKasCategory",
  components: { BaseButton, BodyCardDelete },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    akunCategoryType: ["D", "C"],
    listPermission: [false, false, false, false],
    arusKasCategoryItems: [],
    noDataText: "Tidak ada data yang tersedia",
    arusKasTypeItems: [
      {
        id: 0,
        name: "Semua Tipe Arus Kas",
      },
    ],
    arusKasTypeLoading: true,
    isLoading: true,
    search: "",
    headers: [
      {
        text: "Kode",
        align: "start",
        sortable: true,
        value: "code",
        divider: true,
        width: "15%",
      },
      { text: "Saldo Normal", value: "type", sortable: true, divider: true },
      { text: "Nama", value: "name", sortable: true, divider: true },
      {
        text: "Tipe Arus Kas",
        value: "arusKasTypeId",
        sortable: true,
        divider: true,
      },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "20%",
      },
    ],
    valid: true,
    addArusKasCategoryDialog: false,
    editArusKasCategoryDialog: false,
    input: {
      arusKasTypeId: null,
      code: "",
      type: "",
      name: "",
    },
    arusKasCategory: {
      arusKasTypeId: null,
      id: null,
      code: "",
      type: "",
      name: "",
    },
    arusKasCategoryCode: "",
    arusKasTypeId: 0,
    // Validation
    arusKasCategoryRules: [(v) => !!v || "Tipe Arus Kas harus dipilih"],
    arusKasCategoryCodeRules: [
      (v) => !!v || "Kode Kategori Arus Kas harus diisi",
      (v) =>
        (v && v.length >= 3) ||
        "Kode Kategori Arus Kas minimal harus terdiri dari 3 karakter",
      (v) =>
        (v && v.length <= 10) ||
        "Kode Kategori Arus Kas maksimal terdiri dari 10 karakter",
    ],
    arusKasCategoryNameRules: [
      (v) => !!v || "Nama Kategori Arus Kas harus diisi",
    ],
    dialogDelete: false,
    // Snackbar
    snackbarAddText: "Data berhasil ditambahkan!",
    snackbarEditText: "Data berhasil diubah!",
    snackbarDeleteText: "Data berhasil dihapus!",
    timeout: 2500,
  }),
  computed: {
    ...mapGetters([
      "getArusKasType",
      "getCategoryArusKas",
      "getListPermissionById",
    ]),
    filteredArusKasCategory() {
      return this.getCategoryArusKas.filter((items) => {
        if (!this.arusKasTypeId) {
          return items;
        } else if (this.arusKasTypeId === 0) {
          return items;
        } else {
          return items.arusKasTypeId === this.arusKasTypeId;
        }
      });
    },
    getArusKasCategoryCodes() {
      return this.getCategoryArusKas.map((item) => item.code);
    },
    getSomeArusKasCategoryCodes() {
      let codes = this.getArusKasCategoryCodes;
      let index = codes.indexOf(this.arusKasCategoryCode);
      if (index !== -1) {
        codes.splice(index, 1);
      }
      return codes;
    },
    checkAddCodeRules() {
      return () =>
        this.getArusKasCategoryCodes.includes(this.input.code) === false ||
        "Kode kategori arus kas sudah ada";
    },
    checkEditCodeRules() {
      return () =>
        this.getSomeArusKasCategoryCodes.includes(this.arusKasCategory.code) ===
          false || "Kode kategori arus kas sudah ada";
    },
    getSnackbarAdd: {
      get() {
        return this.$store.state.snackbarAdd;
      },
      set(v) {
        this.$store.commit("showSnackbarAdd", v);
      },
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
    getSnackbarDelete: {
      get() {
        return this.$store.state.snackbarDelete;
      },
      set(v) {
        this.$store.commit("showSnackbarDelete", v);
      },
    },
  },
  watch: {
    getCategoryArusKas() {
      this.isLoading = false;
    },
    getArusKasType() {
      this.arusKasTypeLoading = false;
    },
  },
  methods: {
    ...mapActions(["getArusKasTypeData", "getDataCategoryArusKas"]),
    selectItem(value) {
      this.itemPerPage = value;
    },
    closeArusKasCategoryEditDialog() {
      this.editArusKasCategoryDialog = false;
    },
    closeArusKasCategoryAddDialog() {
      this.addArusKasCategoryDialog = false;
      this.$refs.addForm.reset();
    },
    showAddDialog() {
      this.addArusKasCategoryDialog = true;
    },
    handleAddArusKasCategory() {
      if (this.$refs.addForm.validate()) {
        let data = {
          arusKasTypeId: this.input.arusKasTypeId,
          type: this.input.type,
          code: this.input.code,
          name: this.input.name,
        };
        this.$store.dispatch("addArusKasCategoryData", data);
        this.addArusKasCategoryDialog = false;
        this.$refs.addForm.reset();
      }
    },
    handleEditItem(item) {
      this.arusKasCategory.arusKasTypeId = item.arusKasTypeId;
      this.arusKasCategory.id = item.id;
      this.arusKasCategory.type = item.type;
      this.arusKasCategory.code = item.code;
      this.arusKasCategory.name = item.name;
      this.arusKasCategoryCode = item.code;
      this.editArusKasCategoryDialog = true;
    },
    handleEditArusKasCategory() {
      if (this.$refs.editForm.validate()) {
        let data = {
          id: this.arusKasCategory.id,
          arusKasTypeId: this.arusKasCategory.arusKasTypeId,
          type: this.arusKasCategory.type,
          code: this.arusKasCategory.code,
          name: this.arusKasCategory.name,
        };
        this.$store.dispatch("editArusKasCategoryData", data);
        this.editArusKasCategoryDialog = false;
      }
    },
    handleDeleteItem(id) {
      this.arusKasCategory.id = id;
      this.dialogDelete = true;
    },
    confirmDeleteItem() {
      this.$store.dispatch("deleteArusKasCategory", this.arusKasCategory.id);
      this.dialogDelete = false;
    },
    arusKasType(id) {
      return this.getArusKasType
        .filter((e) => e.id === id)
        .map((item) => item.name)
        .toString();
    },
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 16;
      });

      this.listPermission[1] = permissions.some((item) => {
        return item === 17;
      });

      this.listPermission[2] = permissions.some((item) => {
        return item === 18;
      });

      this.listPermission[3] = permissions.some((item) => {
        return item === 19;
      });
    },
  },
  async created() {
    await this.setListPermission();
    if (this.listPermission[3] === false) {
      this.isLoading = false;
      this.arusKasTypeLoading = false;
      this.noDataText = "Anda tidak memiliki izin untuk mengakses data";
    } else {
      await this.getDataCategoryArusKas();
      await this.getArusKasTypeData();
      this.arusKasTypeItems.push(...this.getArusKasType);
    }
  },
};
</script>

<style src="../styles/arus-kas-category.scss" lang="scss" />
