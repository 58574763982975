import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const companyInfoModule = {
  state: {
    companyInfo: [],
    companyName: "",
  },
  mutations: {
    setCompanyInformation(state, payload) {
      state.companyInfo = payload;
    },
    setCompanyName(state, payload) {
      state.companyName = payload;
    },
  },
  actions: {
    async getCompanyInfoData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/companyInfo");
        commit("setCompanyInformation", res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getCompanyNameData({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/companyInfo/detail/1"
        );
        commit("setCompanyName", res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async editCompanyInfoData({ commit, dispatch }, payload) {
      try {
        await axios.put(process.env.VUE_APP_API_URL + "/companyInfo", payload);
        commit("showSnackbarEdit", true);
        dispatch("getCompanyInfoData");
        dispatch("getCompanyNameData");
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getCompanyInfo(state) {
      return state.companyInfo;
    },
    getCompanyName(state) {
      return state.companyName.value;
    },
  },
};

export default companyInfoModule;
