<template>
  <v-container>
    <v-card class="role-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <span class="role-card-title">Manajemen Peran</span>
      </v-card-title>
      <v-card-text class="pt-1 pl-7">
        <v-row>
          <v-col md="5">
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-row>
                <v-col md="8">
                  <label>
                    <b>Pilih Peran</b>
                    <v-select
                      v-model="role"
                      :items="roleItems"
                      item-text="name"
                      :return-object="true"
                      :loading="isLoading"
                      :rules="roleRules"
                      no-data-text="Tidak ada data yang tersedia"
                      class="mt-1 text-capitalize"
                      placeholder="Pilih"
                      append-icon="mdi-chevron-down"
                      outlined
                      dense
                    ></v-select>
                  </label>
                </v-col>
              </v-row>
              <v-card-actions class="ml-n2 mt-n6">
                <v-btn
                  type="submit"
                  @click.prevent="handleChooseRole"
                  :disabled="!valid"
                  class="btn-choose"
                  tile
                  depressed
                >
                  Pilih
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-col>
          <v-col md="7">
            <table class="role-table" v-show="getShowListPermission">
              <thead>
                <th style="width: 10%"></th>
                <th>List Otoritas {{ roleName }}</th>
              </thead>
              <tbody v-if="listPermission.length > 0 && permission[1] === true">
                <tr v-for="item in permissions" :key="item.id">
                  <td class="text-center">
                    <input
                      v-model="item.permissionId"
                      :value="item.permissionId"
                      type="checkbox"
                      disabled
                    />
                  </td>
                  <td>
                    {{ permissionType(item.permissionId) }}
                    {{ permissionAction(item.permissionId) }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="permission[1] === false">
                <tr>
                  <td></td>
                  <td>Anda tidak memiliki izin untuk mengakses list</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td></td>
                  <td>Tidak ada list</td>
                </tr>
              </tbody>
            </table>

            <v-row v-show="getShowListPermission">
              <v-col md="12">
                <v-btn
                  @click="showEditDialog"
                  :disabled="permission[0] === false"
                  class="ml-4 mt-2 btn-save"
                  tile
                  depressed
                >
                  Ubah
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="getEditDialog"
      transition="dialog-top-transition"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title class="pl-7">
          <span class="edit-card-title">Ubah List Otoritas {{ roleName }}</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeEditDialog">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pl-7">
          <v-row justify="center">
            <v-col md="8">
              <v-form
                v-model="valid"
                ref="editForm"
                lazy-validation
                @submit.prevent="handleEditRole"
              >
                <table class="role-table" v-show="getShowListPermission">
                  <thead>
                    <th style="width: 10%"></th>
                    <th>List Otoritas Baru</th>
                  </thead>
                  <tbody v-if="listPermission.length > 0">
                    <tr>
                      <td class="text-center">
                        <input
                          v-model="allSelected"
                          @click="selectAll"
                          type="checkbox"
                        />
                      </td>
                      <td class="font-weight-bold">Pilih Semua</td>
                    </tr>
                    <tr v-for="item in listPermission" :key="item.id">
                      <td class="text-center">
                        <input
                          v-model="selectedPermission"
                          :value="item.id"
                          :name="
                            permissionType(item.id) +
                            ' ' +
                            permissionAction(item.id)
                          "
                          @click="selectList"
                          type="checkbox"
                        />
                      </td>
                      <td>
                        {{ permissionType(item.id) }}
                        {{ permissionAction(item.id) }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td></td>
                      <td>Tidak ada list</td>
                    </tr>
                  </tbody>
                </table>
                <v-card-actions class="justify-center">
                  <v-btn
                    @click="closeEditDialog"
                    class="btn-close"
                    tile
                    depressed
                  >
                    Batal
                  </v-btn>
                  <v-btn
                    type="submit"
                    :disabled="!valid"
                    class="btn-save ml-4"
                    tile
                    depressed
                  >
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Edit Dialog -->
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarEditText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RoleManagement",
  data: () => ({
    valid: true,
    role: {},
    roleName: "",
    roleItems: [],
    isLoading: true,
    // Validation rules
    roleRules: [(v) => !!v || "Role harus dipilih"],
    // Table
    listPermission: [],
    selectedPermission: "",
    permissions: [],
    allSelected: false,
    permission: [false, false],
    // Snackbar
    snackbarEditText: "Data berhasil diubah!",
    timeout: 2500,
  }),
  computed: {
    ...mapGetters(["getRole", "getListPermission", "getListPermissionById"]),
    getEditDialog: {
      get() {
        return this.$store.state.editDialog;
      },
      set(v) {
        this.$store.commit("showEditDialog", v);
      },
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
    getShowListPermission: {
      get() {
        return this.$store.state.listPermission;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
  },
  watch: {
    getRole() {
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions(["getRoleData", "getListPermissionData"]),
    async getRoleDetail(id) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/permissionLink/detail/" + id
        );
        let data = res.data.data;
        this.permissions = data;
        let permissionId = data.map((item) => item.permissionId);
        this.selectedPermission = permissionId;
        this.$store.commit("showListPermission", true);
      } catch (error) {
        console.log(error);
      }
    },
    handleChooseRole() {
      if (this.$refs.form.validate()) {
        this.roleName = this.role.name;
        this.getRoleDetail(this.role.id);
      }
    },
    permissionType(id) {
      return this.getListPermission
        .filter((e) => e.id === id)
        .map((item) => item.type)
        .toString();
    },
    permissionAction(id) {
      return this.getListPermission
        .filter((e) => e.id === id)
        .map((item) => item.action)
        .toString();
    },
    showEditDialog() {
      this.$store.commit("showEditDialog", true);
    },
    closeEditDialog() {
      this.$store.commit("showEditDialog", false);
    },
    selectAll() {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        let listPermission = this.listPermission.map((item) => item.id);
        this.selectedPermission = listPermission;
      } else {
        this.selectedPermission = [];
      }
    },
    selectList() {
      this.allSelected = false;
    },
    handleEditRole() {
      let data = {
        id: this.role.id,
        permission: this.selectedPermission.toString(),
      };
      this.$store.dispatch("editListPermissionData", data);
    },
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.permission[0] = permissions.some((item) => {
        return item === 9;
      });

      this.permission[1] = permissions.some((item) => {
        return item === 10;
      });
    },
  },
  async created() {
    await this.setListPermission();
    await this.getRoleData();
    await this.getListPermissionData();
    this.roleItems = await this.getRole;
    this.listPermission = await this.getListPermission;
  },
};
</script>

<style src="../styles/set-role.scss" lang="scss" scoped />
