<template>
  <v-container>
    <!-- Snackbar -->
    <v-snackbar
      v-model="getSnackbarAlert"
      :timeout="timeout"
      color="warning"
      min-width="100"
      top
      right
      transition="slide-y-transition"
    >
      {{ snackbarAlertText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          x-small
          v-bind="attrs"
          @click="getSnackbarAlert = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End Snackbar -->
    <v-row class="pl-6 mt-4">
      <v-col>
        <span class="user-title">Ubah Pengguna</span>
      </v-col>
    </v-row>
    <v-form
      v-model="valid"
      ref="editForm"
      lazy-validation
      @submit.prevent="handleEditUser"
    >
      <v-row class="pl-6 mt-7">
        <v-col>
          <span class="user-card-title">Peran dan Departemen</span>
        </v-col>
      </v-row>
      <v-card class="edit-user-card mt-2 ml-6 mr-6 pb-4">
        <v-row class="pa-7">
          <v-col>
            <!-- <v-row>
              <v-col md="2" class="mt-2 text-right">
                <span>Unit</span>
              </v-col>
              <v-col md="10">
                <v-select
                  v-model="user.unitId"
                  :items="unitItems"
                  item-value="id"
                  item-text="name"
                  :rules="[unitRequired]"
                  small-chips
                  hide-selected
                  placeholder="Pilih"
                  persistent-placeholder
                  background-color="#F5F5F5"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      v-if="item === Object(item)"
                      v-bind="attrs"
                      color="white"
                      :input-value="selected"
                      small
                    >
                      <span class="pr-2">
                        {{ item.name }}
                      </span>
                      <v-icon
                        color="#F4A8A8"
                        small
                        @click="parent.selectItem(item)"
                      >
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row> -->
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <span>Peran</span>
              </v-col>
              <v-col md="10">
                <v-select
                  v-model="user.roleId"
                  :items="roleItems"
                  item-value="id"
                  item-text="name"
                  :rules="roleRules"
                  placeholder="Pilih"
                  background-color="#F5F5F5"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span class="text-capitalize">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <span>Departemen</span>
              </v-col>
              <v-col md="10">
                <v-select
                  v-model="user.departmentId"
                  :items="departmentItems"
                  item-value="id"
                  item-text="name"
                  :rules="departmentRules"
                  placeholder="Pilih"
                  background-color="#F5F5F5"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span class="text-capitalize">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="pl-6 mt-7">
        <v-col>
          <span class="user-card-title">Pribadi</span>
        </v-col>
      </v-row>
      <v-card class="edit-user-card mt-2 ml-6 mr-6 pb-4">
        <v-row class="pa-7">
          <v-col>
            <v-row>
              <v-col md="2" class="mt-2 text-right">
                <span>Kode</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="user.code"
                  :rules="codeRules.concat(checkCodeRules)"
                  type="text"
                  placeholder="Masukkan Kode"
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <span>Nama</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="user.name"
                  :rules="nameRules"
                  type="text"
                  placeholder="Masukkan Nama"
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <span>Email</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="user.email"
                  :rules="emailRules"
                  type="email"
                  placeholder="Masukkan Email"
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <span>Telepon</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="user.phone"
                  :rules="phoneRules"
                  type="text"
                  @keypress="validateOnlyNumber($event)"
                  placeholder="Masukkan Nomor Telepon"
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <span>Alamat</span>
              </v-col>
              <v-col md="10">
                <v-textarea
                  v-model="user.address"
                  type="text"
                  placeholder="Masukkan Alamat"
                  background-color="#F5F5F5"
                  height="100"
                  no-resize
                  outlined
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="pl-6 mt-7">
        <v-col>
          <span class="user-card-title">Keamanan</span>
        </v-col>
      </v-row>
      <v-card class="edit-user-card mt-2 ml-6 mr-6">
        <v-row class="pa-7">
          <v-col>
            <v-row>
              <v-col md="2" class="mt-2 text-right">
                <span>Kata Sandi</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="user.password"
                  type="password"
                  @input="onChangePassword"
                  placeholder="Masukkan Kata Sandi"
                  hint="*Kosongkan password jika tidak ada perubahan password"
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
                <v-alert
                  v-show="passwordAlert"
                  transition="slide-y-transition"
                  class="password-alert"
                  >{{ passwordAlertText }}</v-alert
                >
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <span>Ulangi Kata Sandi</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="user.repeatPassword"
                  type="password"
                  @input="onChangeRepeatPassword"
                  placeholder="Ulangi Kata Sandi"
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
                <v-alert
                  v-show="repeatPassAlert"
                  transition="slide-y-transition"
                  class="password-alert"
                  >{{ repeatPassAlertText }}</v-alert
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="justify-end mt-8 mr-6">
        <v-btn @click="$router.go(-1)" class="btn-close" tile depressed>
          Batal
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!valid"
          class="btn-save ml-4"
          tile
          depressed
        >
          Simpan
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserEdit",
  data: () => ({
    valid: true,
    unitItems: [] || null,
    roleItems: [] || null,
    departmentItems: [] || null,
    user: {
      id: null,
      unitId: null,
      roleId: null,
      departmentId: null,
      code: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      password: "",
      repeatPassword: "",
    },
    userCode: "",
    // Validation Rules
    // unitRules: [(v) => !!v || "Unit harus dipilih"],
    roleRules: [(v) => !!v || "Peran harus dipilih"],
    departmentRules: [(v) => !!v || "Departemen harus dipilih"],
    codeRules: [(v) => !!v || "Kode harus diisi"],
    nameRules: [(v) => !!v || "Nama harus diisi"],
    emailRules: [
      (v) => !!v || "Email harus diisi",
      (v) => /.+@.+\..+/.test(v) || "Email harus valid",
    ],
    phoneRules: [
      (v) => !!v || "Nomor Telepon harus diisi",
      (v) =>
        (v && v.length >= 10) ||
        "Nomor Telepon harus terdiri dari 10 karakter atau lebih",
    ],
    repeatPassAlert: false,
    repeatPassAlertText: "",
    passwordAlert: false,
    passwordAlertText: "",
    snackbarAlertText: "Data sudah ada!",
    timeout: 2000,
  }),
  computed: {
    ...mapGetters(["getUsers", "getRole", "getDepartment"]),
    getUserCodes() {
      let codes = this.getUsers.map((item) => item.code);
      var index = codes.indexOf(this.userCode);
      if (index !== -1) {
        codes.splice(index, 1);
      }
      return codes;
    },
    checkCodeRules() {
      return () =>
        this.getUserCodes.includes(this.user.code) === false ||
        "Kode sudah ada";
    },
    getSnackbarAlert: {
      get() {
        return this.$store.state.snackbarAlert;
      },
      set(v) {
        this.$store.commit("showSnackbarAlert", v);
      },
    },
  },
  methods: {
    ...mapActions(["getUserData", "getRoleData", "getDepartmentData"]),
    validateOnlyNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onChangePassword(password) {
      if (password.length !== 0 && password.length < 8) {
        this.passwordAlert = true;
        this.passwordAlertText = "Kata sandi harus lebih dari 8 karakter";
      } else {
        this.passwordAlert = false;
      }
    },
    onChangeRepeatPassword(password) {
      if (this.user.password !== password) {
        this.repeatPassAlert = true;
        this.repeatPassAlertText = "Kata sandi harus cocok";
      } else {
        this.repeatPassAlert = false;
      }
    },
    async getUserDataById() {
      try {
        let id = this.$route.params.id;
        let res = await axios.get(process.env.VUE_APP_API_URL + "/user/" + id);
        let data = res.data.data;
        this.user.id = data.id;
        this.user.roleId = data.roleId;
        this.user.departmentId = data.departmentId;
        this.user.code = data.code;
        this.user.name = data.name;
        this.user.email = data.email;
        this.user.phone = data.phone;
        this.user.address = data.address;
        this.userCode = data.code;
      } catch (error) {
        console.log(error);
      }
    },
    handleEditUser() {
      if (this.$refs.editForm.validate()) {
        let data = {
          id: this.user.id,
          unitId: this.user.unitId,
          roleId: this.user.roleId,
          departmentId: this.user.departmentId,
          code: this.user.code,
          name: this.user.name,
          email: this.user.email,
          phone: this.user.phone,
          address: this.user.address,
          password: this.user.password,
        };
        this.$store.dispatch("editUserData", data);
      }
    },
    // unitRequired(value) {
    //   if (value instanceof Array && value.length == 0) {
    //     return "Unit harus dipilih";
    //   }
    //   return !!value || "Unit harus dipilih";
    // },
  },
  async created() {
    await this.getUserData();
    await this.getRoleData();
    await this.getDepartmentData();
    await this.getUserDataById();
    this.roleItems = this.getRole;
    this.departmentItems = this.getDepartment;
  },
};
</script>

<style src="../styles/user-edit.scss" lang="scss" />
