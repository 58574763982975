import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const accountCategoryModule = {
  state: {
    accountCategory: [],
  },
  mutations: {
    setAccountCategory(state, payload) {
      state.accountCategory = payload;
    },
  },
  actions: {
    async getAccountCategoryData({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/accountCategory"
        );
        let data = res.data.data;
        commit("setAccountCategory", data);
      } catch (error) {
        console.log(error);
      }
    },
    async addAccountCategoryData({ dispatch, commit }, payload) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + "/accountCategory", {
          accountTypeId: payload.accountTypeId,
          code: payload.code,
          name: payload.name,
        });
        dispatch("getAccountCategoryData");
        commit("showSnackbarAdd", true);
      } catch (error) {
        console.log(error);
      }
    },
    async editAccountCategoryData({ dispatch, commit }, payload) {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "/accountCategory/" + payload.id,
          {
            accountTypeId: payload.accountTypeId,
            code: payload.code,
            name: payload.name,
          }
        );
        dispatch("getAccountCategoryData");
        commit("showSnackbarEdit", true);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteAccountCategory({ dispatch, commit }, id) {
      try {
        await axios.delete(
          process.env.VUE_APP_API_URL + "/accountCategory/" + id
        );
        dispatch("getAccountCategoryData");
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getAccountCategory(state) {
      return state.accountCategory;
    },
  },
};

export default accountCategoryModule;
