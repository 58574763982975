import Vue from "vue";
import Vuex from "vuex";
import authModule from "./modules/auth";
import userModule from "./modules/user";
import profileModule from "./modules/profile";
import unitModule from "./modules/unit";
import roleModule from "./modules/role";
import roleManagementModule from "./modules/roleManagement";
import companyInfoModule from "./modules/companyInformation";
import departmentModule from "./modules/department";
import accountCodeModule from "./modules/accountCode";
import accountTypeModule from "./modules/accountType";
import accountCategoryModule from "./modules/accountCategory";
import transactionTypeModule from "./modules/transactionType";
import journalTypeModule from "./modules/journalType";
import periodModule from "./modules/period";
import tCodeModule from "./modules/tCode";
import transactionModule from "./modules/transaction";
import reportTypeModule from "./modules/reportType";
import reportModule from "./modules/report";
import configurationReportModule from "./modules/configurationReport";
import arusKasTypeModule from "./modules/arusKasType";
import arusKasCategoryModule from "./modules/arusKasCategory";
import netoAssetChangeModule from "./modules/netoAssetChange";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authModule,
    userModule,
    profileModule,
    unitModule,
    roleModule,
    roleManagementModule,
    companyInfoModule,
    departmentModule,
    accountCodeModule,
    accountTypeModule,
    accountCategoryModule,
    transactionTypeModule,
    journalTypeModule,
    periodModule,
    tCodeModule,
    transactionModule,
    reportTypeModule,
    reportModule,
    configurationReportModule,
    arusKasTypeModule,
    arusKasCategoryModule,
    netoAssetChangeModule,
  },
  state: {
    snackbarAdd: false,
    snackbarEdit: false,
    snackbarDelete: false,
    snackbarPost: false,
    snackbarWarning: false,
    snackbarAlert: false,
    snackbarAddAlert: false,
    snackbarEditAlert: false,
    notification: false,
    editDialog: false,
    detailDialog: false,
    deleteDialog: false,
    listPermission: false,
    btnLoading: false,
  },
  mutations: {
    showSnackbarAdd(state, payload) {
      state.snackbarAdd = payload;
    },
    showSnackbarEdit(state, payload) {
      state.snackbarEdit = payload;
    },
    showSnackbarDelete(state, payload) {
      state.snackbarDelete = payload;
    },
    showSnackbarPost(state, payload) {
      state.snackbarPost = payload;
    },
    showSnackbarWarning(state, payload) {
      state.snackbarWarning = payload;
    },
    showSnackbarAlert(state, payload) {
      state.snackbarAlert = payload;
    },
    showSnackbarAddAlert(state, payload) {
      state.snackbarAddAlert = payload;
    },
    showSnackbarEditAlert(state, payload) {
      state.snackbarEditAlert = payload;
    },
    showEditDialog(state, payload) {
      state.editDialog = payload;
    },
    showDetailDialog(state, payload) {
      state.detailDialog = payload;
    },
    showDeleteDialog(state, payload) {
      state.deleteDialog = payload;
    },
    showNotification(state, payload) {
      state.notification = payload;
    },
    showListPermission(state, payload) {
      state.listPermission = payload;
    },
    setBtnLoading(state, payload) {
      state.btnLoading = payload;
    },
  },
});
