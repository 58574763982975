<template>
  <v-container>
    <!-- Snackbar -->
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
      transition="slide-y-transition"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          x-small
          v-bind="attrs"
          @click="getSnackbarEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End Snackbar -->
    <v-card class="company-card mt-6 ml-6 mr-6">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="company-card-title">Halaman Informasi Perusahaan</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="handleSave"
        class="pa-7"
      >
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <label>
                  Nama Perusahaan
                  <v-text-field
                    v-model="company.name"
                    :rules="nameRules"
                    :readonly="listPermission[0] === false"
                    :loading="isLoading"
                    type="text"
                    class="mt-2"
                    outlined
                    dense
                  >
                  </v-text-field>
                </label>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col>
                <label>
                  PIC
                  <v-text-field
                    v-model="company.pic"
                    :rules="picRules"
                    :readonly="listPermission[0] === false"
                    :loading="isLoading"
                    type="text"
                    class="mt-2"
                    outlined
                    dense
                  >
                  </v-text-field>
                </label>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col>
                <label>
                  Website
                  <v-text-field
                    v-model="company.website"
                    :readonly="listPermission[0] === false"
                    :loading="isLoading"
                    :error-messages="websiteErrMessageText"
                    @input="handleInputWebsite"
                    type="text"
                    :hint="hintText"
                    class="mt-2"
                    outlined
                    dense
                  >
                  </v-text-field>
                </label>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col>
                <label>
                  Email
                  <v-text-field
                    v-model="company.email"
                    @input="handleInputEmail"
                    :readonly="listPermission[0] === false"
                    :error-messages="emailErrMessageText"
                    :loading="isLoading"
                    :hint="hintText"
                    type="email"
                    class="mt-2"
                    outlined
                    dense
                  >
                  </v-text-field>
                </label>
              </v-col>
            </v-row>
            <v-row class="mb-4 mt-n4">
              <v-col md="6">
                <label
                  >Logo Perusahaan
                  <v-img :src="company.img" width="180" class="mt-2 rounded">
                  </v-img>
                </label>
              </v-col>
              <v-col md="6" class="mt-11 ml-n8">
                <v-row>
                  <label class="mb-12">
                    <v-btn class="btn-upload" @click="selectFile" tile depressed
                      >Pilih Foto</v-btn
                    >
                    <input
                      ref="file"
                      type="file"
                      @change="onFileChange"
                      style="visibility: hidden"
                    />
                  </label>
                  <span class="label mt-4">* Ukuran maksimal 2MB</span>
                  <span class="label mt-2"
                    >Logo perusahaan akan ditampilkan pada laporan
                    keuangan</span
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <label>
                  Nomor Telpon
                  <v-text-field
                    v-model="company.phone"
                    :rules="phoneRules"
                    :readonly="listPermission[0] === false"
                    @keypress="validateOnlyNumber($event)"
                    :loading="isLoading"
                    type="text"
                    class="mt-2"
                    outlined
                    dense
                  >
                  </v-text-field>
                </label>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col>
                <label>
                  Fax
                  <v-text-field
                    v-model="company.fax"
                    @input="handleInputFax"
                    :readonly="listPermission[0] === false"
                    :error-messages="faxErrMessageText"
                    :loading="isLoading"
                    type="text"
                    :hint="hintText"
                    class="mt-2"
                    outlined
                    dense
                  >
                  </v-text-field>
                </label>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col>
                <label>
                  Kode POS
                  <v-text-field
                    v-model="company.postalCode"
                    @input="handleInputPostalCode"
                    :readonly="listPermission[0] === false"
                    :error-messages="postalCodeErrMessageText"
                    :loading="isLoading"
                    type="text"
                    :hint="hintText"
                    class="mt-2"
                    outlined
                    dense
                  >
                  </v-text-field>
                </label>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col>
                <label>
                  Alamat 1
                  <v-textarea
                    v-model="company.addressOne"
                    :rules="addressOneRules"
                    :readonly="listPermission[0] === false"
                    :loading="isLoading"
                    type="text"
                    class="mt-2"
                    height="85"
                    no-resize
                    outlined
                  >
                  </v-textarea>
                </label>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col>
                <label>
                  Alamat 2
                  <v-textarea
                    v-model="company.addressTwo"
                    @input="handleInputAddressTwo"
                    :readonly="listPermission[0] === false"
                    :loading="isLoading"
                    :error-messages="addressTwoErrMessageText"
                    type="text"
                    :hint="hintText"
                    class="mt-2"
                    height="85"
                    no-resize
                    outlined
                  >
                  </v-textarea>
                </label>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col>
                <label>
                  Alamat 3
                  <v-textarea
                    v-model="company.addressThree"
                    @input="handleInputAddressThree"
                    :readonly="listPermission[0] === false"
                    :loading="isLoading"
                    :error-messages="addressThreeErrMessageText"
                    type="text"
                    :hint="hintText"
                    class="mt-2"
                    height="85"
                    no-resize
                    outlined
                  >
                  </v-textarea>
                </label>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mb-7"></v-divider>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <label>
                  License Key
                  <v-text-field
                    v-model="company.licenseKey"
                    :readonly="listPermission[0] === false"
                    :loading="isLoading"
                    :hint="hintText"
                    type="text"
                    class="mt-2 mb-0"
                    outlined
                    dense
                  >
                  </v-text-field>
                </label>
              </v-col>
            </v-row>
            <v-row class="mt-7">
              <v-col cols="2">
                <label>
                  Reminder
                  <v-text-field
                    v-model="company.reminderLicenseKey"
                    :readonly="listPermission[0] === false"
                    :loading="isLoading"
                    :hint="hintText"
                    type="text"
                    class="mt-2 mb-0"
                    outlined
                    dense
                  >
                    <template v-slot:append-outer>
                      <label>Days</label>
                    </template>
                  </v-text-field>
                </label>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mb-7"></v-divider>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <label>
                  Default Nomor Referensi
                  <v-text-field
                    v-model="company.defaultReferensi"
                    :readonly="listPermission[0] === false"
                    :loading="isLoading"
                    :hint="hintText"
                    type="text"
                    class="mt-2 mb-0"
                    outlined
                    dense
                  >
                  </v-text-field>
                  <div style="font-size: 10px; font-style: italic">
                    * Nomor referensi yang akan di munculkan pada jurnal ketika
                    awal data akan diinputkan (belum ada data pada sistem).
                    Contoh <b>001/JU/1/2022</b>
                    <br />
                    * Atau ketika pada saat penginputan jurnal memasukan nomor
                    referensi tidak sesuai format
                  </div>
                </label>
              </v-col>
            </v-row>
            <v-row class="mt-7">
              <v-col>
                <label>
                  Format Nomor Referensi Jurnal
                  <v-text-field
                    v-model="company.formatReferensi"
                    :readonly="listPermission[0] === false"
                    :loading="isLoading"
                    :hint="hintText"
                    type="text"
                    class="mt-2 mb-0"
                    outlined
                    dense
                  >
                  </v-text-field>
                  <div style="font-size: 10px; font-style: italic">
                    * Contoh format nomor referensi
                    <b>{urut}/{statis}/{bulan}/{tahun}</b>
                    <br />
                    * Artinya format diatas akan digunakan untuk nomor referensi
                    <b>001/JU/1/2022</b>
                    <br />
                    * <b>{statis}</b> menunjukan bahwa pada bagian tersebut
                    tidak akan diotomasi oleh sistem
                    <br />
                    * <b>{bulan}</b> menunjukan bahwa pada bagian tersebut akan
                    diotomasi oleh sistem by bulan saat jurnal diinputkan
                    <br />
                    * <b>{tahun}</b> menunjukan bahwa pada bagian tersebut akan
                    diotomasi oleh sistem by tahun saat jurnal diinputkan
                    <br />
                    * <b>{urut}</b> menunjukan bahwa pada bagian tersebut akan
                    diotomasi oleh sistem by nomor urut selanjutnya
                  </div>
                </label>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-actions class="mt-n8">
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            :disabled="!valid || listPermission[0] === false"
            class="mr-n2 mt-4 btn-save"
            tile
            depressed
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CompanyInformation",
  data: () => ({
    valid: true,
    company: {
      name: "",
      pic: "",
      addressOne: "",
      addressTwo: "",
      addressThree: "",
      website: "",
      email: "",
      phone: "",
      fax: "",
      postalCode: "",
      defaultReferensi: "",
      formatReferensi: "",
      licenseKey: "",
      reminderLicenseKey: "",
      img: require("@/assets/images/default.png"),
    },
    // Validation rules
    nameRules: [(v) => !!v || "Nama perusahaan harus diisi"],
    picRules: [(v) => !!v || "PIC harus diisi"],
    phoneRules: [
      (v) => !!v || "Nomor Telepon harus diisi",
      (v) => /^[0-9]*$/.test(v) || "Nomor Telepon harus terdiri dari angka",
      (v) =>
        (v && v.length >= 10) ||
        "Nomor Telepon harus terdiri dari 10 karakter atau lebih",
    ],
    addressOneRules: [(v) => !!v || "Alamat 1 harus diisi"],
    snackbarText: "Data berhasil disimpan",
    timeout: 2000,
    hintText: "Jika tidak ada yang diinputkan isikan tanda strip (-)",
    emailErrMessageText: "",
    faxErrMessageText: "",
    postalCodeErrMessageText: "",
    websiteErrMessageText: "",
    addressTwoErrMessageText: "",
    addressThreeErrMessageText: "",
    isLoading: true,
    listPermission: [false],
  }),
  computed: {
    ...mapGetters(["getCompanyInfo", "getListPermissionById"]),
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
  },
  watch: {
    getCompanyInfo() {
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions(["getCompanyInfoData"]),
    selectFile() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
      // Do something with chosen file
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.company.img = URL.createObjectURL(file);
    },
    handleInputWebsite(val) {
      if (!val) {
        this.websiteErrMessageText = "Website belum diisi";
      } else {
        this.websiteErrMessageText = "";
      }
    },
    handleInputEmail(val) {
      if (val.length > 0 && val !== "-" && /.+@.+\..+/.test(val) === false) {
        this.emailErrMessageText = "Email harus valid";
      } else if (!val) {
        this.emailErrMessageText = "Email belum diisi";
      } else {
        this.emailErrMessageText = "";
      }
    },
    handleInputFax(val) {
      if (val.length > 0 && val !== "-" && /^[0-9]*$/.test(val) === false) {
        this.faxErrMessageText = "Fax harus terdiri dari angka";
      } else if (!val) {
        this.faxErrMessageText = "Fax belum diisi";
      } else {
        this.faxErrMessageText = "";
      }
    },
    handleInputPostalCode(val) {
      if (val.length > 0 && val !== "-" && /^[0-9]*$/.test(val) === false) {
        this.postalCodeErrMessageText = "Kode POS harus terdiri dari angka";
      } else if (!val) {
        this.postalCodeErrMessageText = "Kode POS belum diisi";
      } else {
        this.postalCodeErrMessageText = "";
      }
    },
    handleInputAddressTwo(val) {
      if (!val) {
        this.addressTwoErrMessageText = "Alamat 2 belum diisi";
      } else {
        this.addressTwoErrMessageText = "";
      }
    },
    handleInputAddressThree(val) {
      if (!val) {
        this.addressThreeErrMessageText = "Alamat 3 belum diisi";
      } else {
        this.addressThreeErrMessageText = "";
      }
    },
    handleSave() {
      if (this.$refs.form.validate()) {
        let data = {
          data: [
            { id: 1, value: this.company.name },
            { id: 2, value: this.company.addressOne },
            { id: 3, value: this.company.addressTwo },
            { id: 4, value: this.company.addressThree },
            { id: 5, value: this.company.phone },
            { id: 6, value: this.company.pic },
            { id: 7, value: this.company.website },
            { id: 8, value: this.company.email },
            { id: 9, value: "-" },
            { id: 10, value: this.company.fax },
            { id: 11, value: this.company.postalCode },
            { id: 12, value: this.company.defaultReferensi },
            { id: 13, value: this.company.formatReferensi },
            { id: 14, value: this.company.licenseKey },
            { id: 15, value: this.company.reminderLicenseKey },
          ],
        };
        this.$store.dispatch("editCompanyInfoData", data);
      }
    },
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 11;
      });
    },
  },
  async created() {
    await this.setListPermission();
    await this.getCompanyInfoData();
    this.company.name = await this.getCompanyInfo[0].value;
    this.company.addressOne = await this.getCompanyInfo[1].value;
    this.company.addressTwo = await this.getCompanyInfo[2].value;
    this.company.addressThree = await this.getCompanyInfo[3].value;
    this.company.phone = await this.getCompanyInfo[4].value;
    this.company.pic = await this.getCompanyInfo[5].value;
    this.company.website = await this.getCompanyInfo[6].value;
    this.company.email = await this.getCompanyInfo[7].value;
    this.company.fax = await this.getCompanyInfo[9].value;
    this.company.postalCode = await this.getCompanyInfo[10].value;
    this.company.defaultReferensi = await this.getCompanyInfo[11].value;
    this.company.formatReferensi = await this.getCompanyInfo[12].value;
    this.company.licenseKey = await this.getCompanyInfo[13].value;
    this.company.reminderLicenseKey = await this.getCompanyInfo[14].value;
  },
};
</script>

<style src="../styles/company-information.scss" lang="scss" scoped />
