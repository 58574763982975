import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const roleManagementModule = {
  state: {
    roleDetail: [],
    listPermission: [],
  },
  mutations: {
    setRoleDetail(state, payload) {
      state.roleDetail = payload;
    },
    setListPermission(state, payload) {
      state.listPermission = payload;
    },
  },
  actions: {
    async getRoleDetail({ commit }, id) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/permissionLink/detail/" + id
        );
        let data = res.data.data;
        commit("setRoleDetail", data);
        commit("showListPermission", true);
      } catch (error) {
        console.log(error);
      }
    },
    async getListPermissionData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/permission");
        let data = res.data.data;
        commit("setListPermission", data);
      } catch (error) {
        console.log(error);
      }
    },
    async editListPermissionData({ dispatch, commit }, payload) {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "/permission/" + payload.id,
          {
            permission: payload.permission,
          }
        );
        commit("showSnackbarEdit", true);
        commit("showEditDialog", false);
        dispatch("getRoleDetail", payload.id);
        dispatch("getListPermissionDataById", localStorage.getItem("roleId"));
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getRoleDetail(state) {
      let permissionId = state.roleDetail.map((item) => ({
        id: item.permissionId,
      }));
      return permissionId;
    },
    getListPermission(state) {
      return state.listPermission;
    },
  },
};

export default roleManagementModule;
