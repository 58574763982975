<template>
  <v-dialog
    v-model="isShow"
    transition="dialog-top-transition"
    persistent
    :max-width="width"
  >
    <v-card>
      <div class="dialog-content">
        <v-icon color="red">mdi-close-circle</v-icon>
        <h2>Error</h2>
        <span>{{ text }}</span>
        <div class="list">
          <ol>
            <li v-for="(item, index) in textArray" :key="index">
              {{ item }}
            </li>
          </ol>
        </div>
        <v-card-actions class="action">
          <v-btn
            @click="closePopUp"
            class="action__button mr-2"
            tile
            depressed
            rounded
          >
            Ok
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PopUpError",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    textArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => ({
    width: 350,
  }),
  watch: {
    textArray(val) {
      if (val.length !== 0) {
        this.width = 400;
      } else {
        this.width = 350;
      }
    },
  },
  emits: ["close-modal"],
  methods: {
    closePopUp() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-content {
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .v-icon {
    font-size: 100px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  span {
    font-size: 16px;
    color: #333333;
    max-width: 300px;
    margin: 0 auto;
  }

  .list {
    text-align: center;
    ol {
      text-align: justify;
      // li {
      //   line-height: 1.3em;
      // }
    }
  }

  h2 {
    margin: 10px 0 5px 0;
  }

  .action {
    margin-top: 30px;
    width: 100%;

    &__button {
      width: 100%;
      background-color: #f44336;
      border-radius: 5px;
    }
    ::v-deep &__button {
      span {
        color: #ffffff;
      }
    }
  }
}
</style>
