<template>
  <v-container>
    <v-snackbar
      v-model="snackbarAlert"
      :timeout="timeout"
      :color="alertColor"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAlertText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbarAlert = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="net-asset-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="net-asset-card-title"
              >Konfigurasi Laporan Statements of Changes in Net Asset</span
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="7">
            <base-button @add="handleAddAccountParent">
              <template v-slot:btnText>
                <span>Tambah Akun</span>
              </template></base-button
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="4">
            <v-row>
              <v-col md="4" class="mr-n6">
                <span class="body-1">Tampilkan</span>
              </v-col>
              <v-col md="4">
                <v-select
                  v-model="itemPerPage"
                  :items="itemsPerPage"
                  @change="selectItem"
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="4" class="ml-n2">
                <span class="body-1">Entri</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" class="mr-3">
            <v-text-field
              v-model="search"
              placeholder="Cari akun"
              prepend-inner-icon="mdi-magnify"
              background-color="#FAFAFA"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-7 pr-7 pb-7">
        <v-col>
          <v-card class="account-table pb-4" elevation="0">
            <v-data-table
              :headers="headers"
              :items="accountItemsWithIndex"
              item-key="id"
              :options="{
                itemsPerPage: itemPerPage,
              }"
              :page.sync="page"
              @page-count="pageCount = $event"
              :search="search"
              :no-results-text="noResultsText"
              :no-data-text="noDataText"
              :single-expand="true"
              :expanded.sync="expanded"
              show-expand
              :footer-props="{
                'items-per-page-text': '',
                'disable-items-per-page': true,
                'items-per-page-options': [],
                'prev-icon': null,
                'next-icon': null,
              }"
            >
              <template v-slot:[`item.visible`]="{ item }">
                <v-simple-checkbox
                  v-model="item.visible"
                  :ripple="false"
                  color="primary"
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#6BC282"
                      x-small
                      height="24"
                      min-width="24"
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleAddAccountChild(item)"
                    >
                      <v-icon color="white" size="14">mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pl-14 pr-0">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="(account, index) in accountChild"
                          :key="index"
                        >
                          <template
                            v-if="
                              item.code.charAt(0) === account.code.charAt(0)
                            "
                          >
                            <td style="width: 8.2%"></td>
                            <td style="width: 15%">
                              {{ account.code }}
                            </td>
                            <td style="width: 36.2%">{{ account.name }}</td>
                            <td class="text-center" style="width: 15%">
                              <v-simple-checkbox
                                v-model="account.visible"
                                :ripple="false"
                                color="primary"
                              ></v-simple-checkbox>
                            </td>
                            <td class="text-center" style="width: 20%">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="#E89F3C"
                                    x-small
                                    height="24"
                                    min-width="24"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="handleEditAccountChild(account)"
                                  >
                                    <v-icon color="white" size="14"
                                      >mdi-pencil</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    color="#CE5542"
                                    x-small
                                    height="24"
                                    min-width="24"
                                    class="ml-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="handleDeleteAccountChild(account)"
                                  >
                                    <v-icon color="white" size="14"
                                      >mdi-delete</v-icon
                                    >
                                  </v-btn>
                                </template>
                              </v-menu>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </template>
              <template
                v-slot:[`footer.page-text`]="{
                  pageStart,
                  pageStop,
                  itemsLength,
                }"
              >
                Showing {{ pageStart }} to {{ pageStop }} of {{ itemsLength }}
              </template>
            </v-data-table>
            <v-row class="mt-n10">
              <v-spacer></v-spacer>
              <v-col md="auto" class="mr-10">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="7"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="subtitle-1">{{ accountDialogTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn small text @click="closeDialog">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="mb-n8">
          <v-row justify="end">
            <v-col md="4">
              <v-text-field
                v-model="searchAccount"
                append-icon="mdi-magnify"
                placeholder="search"
                outlined
                dense
                >Search</v-text-field
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-card class="mt-2" elevation="0">
            <v-data-table
              :headers="accountHeaders"
              :items="
                addAccountParentDialog === true
                  ? getAccountParent
                  : getAccountChild
              "
              :search="searchAccount"
              :loading="accountLoading"
              :loading-text="loadingText"
              :no-results-text="noResultsText"
              :no-data-text="noDataText"
              class="account-table"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="#E46D47"
                  @click="addAccount(item)"
                  x-small
                  height="24"
                  min-width="20"
                >
                  <v-icon color="white" size="16"> mdi-plus-circle </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog -->
    <v-dialog
      v-model="dialogDelete"
      transition="dialog-top-transition"
      max-width="350"
    >
      <v-card color="pt-8">
        <BodyCardDelete />
        <v-card-actions class="justify-center">
          <v-btn
            class="btn-close-dialog mr-4"
            tile
            depressed
            @click="dialogDelete = false"
            >Batal</v-btn
          >
          <v-btn class="btn-confirm" tile depressed @click="confirmDeleteItem"
            >Hapus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Delete Dialog -->
  </v-container>
</template>

<script>
import BaseButton from "./base/BaseButton.vue";
import BodyCardDelete from "./AppCardBodyDelete.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ReportStatementsOfNetAsset",
  components: { BaseButton, BodyCardDelete },
  data: () => ({
    headers: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "index",
        divider: true,
        width: "8%",
      },
      {
        text: "Kode",
        align: "start",
        sortable: true,
        value: "code",
        divider: true,
        width: "15%",
      },
      { text: "Nama", value: "name", sortable: true, divider: true },
      {
        text: "Visible",
        value: "visible",
        align: "center",
        sortable: false,
        divider: true,
        width: "15%",
      },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "20%",
      },
    ],
    accountHeaders: [
      {
        text: "Kode",
        align: "start",
        sortable: true,
        value: "code",
        divider: true,
        width: "15%",
      },
      { text: "Nama", value: "name", sortable: true, divider: true },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "20%",
      },
    ],
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    accountId: "",
    expanded: [],
    account: [],
    accountChild: [],
    oldAccountChild: {},
    accountDialog: [],
    accountCodeFirstChar: "",
    search: "",
    searchAccount: "",
    dialog: false,
    dialogDelete: false,
    addAccountParentDialog: false,
    addAccountChildDialog: false,
    editAccountChildDialog: false,
    accountLoading: true,
    accountDialogTitle: "",
    loadingText: "Sedang memuat data...",
    noResultsText: "Data tidak ditemukan",
    noDataText: "Tidak ada data yang tersedia",
    // Snackbar
    alertColor: "",
    snackbarAlert: false,
    snackbarAlertText: "",
    timeout: 2000,
  }),
  computed: {
    ...mapGetters(["getAccount"]),
    getAccountParent() {
      return this.getAccount.filter((item) => item.parentId === 0);
    },
    getAccountChild() {
      return this.getAccount.filter(
        (item) =>
          item.parentId !== 0 &&
          item.code.charAt(0) === this.accountCodeFirstChar
      );
    },
    accountItemsWithIndex() {
      return this.account.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  watch: {
    getAccountParent() {
      this.accountLoading = false;
    },
  },
  methods: {
    ...mapActions(["getAccountData"]),
    selectItem(value) {
      this.itemPerPage = value;
    },
    handleAddAccountParent() {
      this.accountDialogTitle = "Tambah Akun";
      this.addAccountParentDialog = true;
      this.accountDialog = this.getAccountParent;
      this.dialog = true;
    },
    handleAddAccountChild(item) {
      this.accountDialogTitle = "Tambah Anak Akun";
      this.addAccountChildDialog = true;
      this.accountCodeFirstChar = item.code.charAt(0);
      this.accountDialog = this.getAccountChild;
      this.dialog = true;
    },
    snackbarWarning() {
      this.alertColor = "warning";
      this.snackbarAlertText = "Akun sudah ditambahkan!";
      this.snackbarAlert = true;
    },
    snackbarSuccess(text) {
      this.alertColor = "success";
      this.snackbarAlertText = text;
      this.snackbarAlert = true;
    },
    addAccount(item) {
      let addSuccessText = "Akun berhasil ditambahkan!";
      if (this.addAccountParentDialog) {
        if (this.account.includes(item)) {
          this.snackbarWarning();
        } else {
          this.account.push(item);
          this.snackbarSuccess(addSuccessText);
        }
      } else if (this.addAccountChildDialog) {
        if (this.accountChild.includes(item)) {
          this.snackbarWarning();
        } else {
          this.accountChild.push(item);
          this.snackbarSuccess(addSuccessText);
        }
      } else {
        if (this.accountChild.includes(item)) {
          this.snackbarWarning();
        } else {
          this.accountChild.splice(
            this.accountChild.indexOf(this.oldAccountChild),
            1,
            item
          );
          let text = "Akun berhasil diubah!";
          this.snackbarSuccess(text);
          this.dialog = false;
        }
      }
    },
    handleEditAccountChild(account) {
      this.accountDialogTitle = "Edit Anak Akun";
      this.oldAccountChild = account;
      this.editAccountChildDialog = true;
      this.accountCodeFirstChar = account.code.charAt(0);
      this.accountDialog = this.getAccountChild;
      this.dialog = true;
    },
    handleDeleteAccountChild(account) {
      this.accountId = account.id;
      this.dialogDelete = true;
    },
    confirmDeleteItem() {
      this.accountChild.splice(
        this.accountChild.findIndex((item) => item.id === this.accountId),
        1
      );
      let text = "Akun berhasil dihapus!";
      this.dialogDelete = false;
      this.snackbarSuccess(text);
    },
    closeDialog() {
      this.searchAccount = "";
      this.dialog = false;
      this.addAccountParentDialog = false;
      this.addAccountChildDialog = false;
    },
  },
  mounted() {
    this.getAccountData();
  },
};
</script>

<style src="../styles/statements-net-asset.scss" lang="scss" />
