<template>
  <v-container>
    <v-card class="account-category-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="account-card-title">Kategori Akun</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <base-button
              :disabled="listPermission[0] === false"
              @add="showAddDialog"
            >
              <template v-slot:btnText>
                <span>Tambah Kategori Akun</span>
              </template></base-button
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7 mt-n2">
        <v-row justify="space-between">
          <v-col md="3">
            <v-select
              v-model="accountTypeId"
              :items="accountTypeItems"
              item-value="id"
              item-text="name"
              :loading="accountTypeLoading"
              placeholder="Tipe Akun"
              append-icon=""
              outlined
              dense
            >
              <template v-slot:prepend-inner>
                <v-icon color="#CCCCCC">mdi-filter</v-icon>
              </template>
              <template v-slot:selection="{ item }">
                <span class="d-flex justify-center" style="width: 100%">
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col md="4" class="mr-3">
            <v-text-field
              v-model="search"
              placeholder="Cari"
              prepend-inner-icon="mdi-magnify"
              background-color="#FAFAFA"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-7 pr-7 pb-7 mt-n8">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="
              listPermission[3] === false
                ? accountCategoryItems
                : filteredAccountCategory
            "
            :search="search"
            class="account-category-table"
            :loading="isLoading"
            loading-text="Sedang memuat data..."
            no-results-text="Data tidak ditemukan"
            :no-data-text="noDataText"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.accountTypeId`]="{ item }">
              <span> {{ accountType(item.accountTypeId) }} </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#E89F3C"
                    x-small
                    height="24"
                    min-width="24"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleEditItem(item)"
                    :disabled="listPermission[1] === false"
                  >
                    <v-icon color="white" size="14">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    color="#CE5542"
                    x-small
                    height="24"
                    min-width="24"
                    class="ml-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleDeleteItem(item.id)"
                    :disabled="listPermission[2] === false"
                  >
                    <v-icon color="white" size="14">mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- Add Dialog -->
    <v-dialog
      v-model="addAccountCategoryDialog"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Tambah Kategori Akun</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeAccountCategoryAddDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="addForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleAddAccountCategory"
          >
            <v-row class="mt-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="input.accountTypeId"
                  :items="getAccountType"
                  item-value="id"
                  item-text="name"
                  :rules="accountCategoryRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="input.code"
                  :rules="accountCategoryCodeRules.concat(checkAddCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="input.name"
                  :rules="accountCategoryNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeAccountCategoryAddDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Add Dialog -->
    <!-- Add Dialog -->
    <v-dialog
      v-model="editAccountCategoryDialog"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Ubah Kategori Akun</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeAccountCategoryEditDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="editForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleEditAccountCategory"
          >
            <v-row class="mt-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="accountCategory.accountTypeId"
                  :items="getAccountType"
                  item-value="id"
                  item-text="name"
                  :rules="accountCategoryRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="accountCategory.code"
                  :rules="accountCategoryCodeRules.concat(checkEditCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="accountCategory.name"
                  :rules="accountCategoryNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeAccountCategoryEditDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Add Dialog -->
    <!-- Delete Dialog -->
    <v-dialog
      v-model="dialogDelete"
      transition="dialog-top-transition"
      max-width="350"
    >
      <v-card color="pt-8">
        <BodyCardDelete />
        <v-card-actions class="justify-center">
          <v-btn
            class="btn-close-dialog mr-4"
            tile
            depressed
            @click="dialogDelete = false"
            >Batal</v-btn
          >
          <v-btn class="btn-confirm" tile depressed @click="confirmDeleteItem"
            >Hapus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Delete Dialog -->
    <v-snackbar
      v-model="getSnackbarAdd"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAddText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAdd = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarEditText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarDelete"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarDeleteText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarDelete = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "./base/BaseButton.vue";
import BodyCardDelete from "./AppCardBodyDelete.vue";

export default {
  name: "AccountCategory",
  components: { BaseButton, BodyCardDelete },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    listPermission: [false, false, false, false],
    accountCategoryItems: [],
    noDataText: "Tidak ada data yang tersedia",
    accountTypeItems: [
      {
        id: 0,
        name: "Semua Tipe Akun",
      },
    ],
    accountTypeLoading: true,
    isLoading: true,
    search: "",
    headers: [
      {
        text: "Kode",
        align: "start",
        sortable: true,
        value: "code",
        divider: true,
        width: "15%",
      },
      { text: "Nama", value: "name", sortable: true, divider: true },
      {
        text: "Tipe Akun",
        value: "accountTypeId",
        sortable: true,
        divider: true,
      },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "20%",
      },
    ],
    valid: true,
    addAccountCategoryDialog: false,
    editAccountCategoryDialog: false,
    input: {
      accountTypeId: null,
      code: "",
      name: "",
    },
    accountCategory: {
      accountTypeId: null,
      id: null,
      code: "",
      name: "",
    },
    accountCategoryCode: "",
    accountTypeId: 0,
    // Validation
    accountCategoryRules: [(v) => !!v || "Tipe Akun harus dipilih"],
    accountCategoryCodeRules: [
      (v) => !!v || "Kode Kategori Akun harus diisi",
      (v) =>
        (v && v.length >= 3) ||
        "Kode Kategori Akun minimal harus terdiri dari 3 karakter",
      (v) =>
        (v && v.length <= 10) ||
        "Kode Kategori Akun maksimal terdiri dari 10 karakter",
    ],
    accountCategoryNameRules: [(v) => !!v || "Nama Kategori Akun harus diisi"],
    dialogDelete: false,
    // Snackbar
    snackbarAddText: "Data berhasil ditambahkan!",
    snackbarEditText: "Data berhasil diubah!",
    snackbarDeleteText: "Data berhasil dihapus!",
    timeout: 2500,
  }),
  computed: {
    ...mapGetters([
      "getAccountType",
      "getAccountCategory",
      "getListPermissionById",
    ]),
    filteredAccountCategory() {
      return this.getAccountCategory.filter((items) => {
        if (!this.accountTypeId) {
          return items;
        } else if (this.accountTypeId === 0) {
          return items;
        } else {
          return items.accountTypeId === this.accountTypeId;
        }
      });
    },
    getAccountCategoryCodes() {
      return this.getAccountCategory.map((item) => item.code);
    },
    getSomeAccountCategoryCodes() {
      let codes = this.getAccountCategoryCodes;
      let index = codes.indexOf(this.accountCategoryCode);
      if (index !== -1) {
        codes.splice(index, 1);
      }
      return codes;
    },
    checkAddCodeRules() {
      return () =>
        this.getAccountCategoryCodes.includes(this.input.code) === false ||
        "Kode kategori akun sudah ada";
    },
    checkEditCodeRules() {
      return () =>
        this.getSomeAccountCategoryCodes.includes(this.accountCategory.code) ===
          false || "Kode kategori akun sudah ada";
    },
    getSnackbarAdd: {
      get() {
        return this.$store.state.snackbarAdd;
      },
      set(v) {
        this.$store.commit("showSnackbarAdd", v);
      },
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
    getSnackbarDelete: {
      get() {
        return this.$store.state.snackbarDelete;
      },
      set(v) {
        this.$store.commit("showSnackbarDelete", v);
      },
    },
  },
  watch: {
    getAccountCategory() {
      this.isLoading = false;
    },
    getAccountType() {
      this.accountTypeLoading = false;
    },
  },
  methods: {
    ...mapActions(["getAccountTypeData", "getAccountCategoryData"]),
    selectItem(value) {
      this.itemPerPage = value;
    },
    closeAccountCategoryEditDialog() {
      this.editAccountCategoryDialog = false;
    },
    closeAccountCategoryAddDialog() {
      this.addAccountCategoryDialog = false;
      this.$refs.addForm.reset();
    },
    showAddDialog() {
      this.addAccountCategoryDialog = true;
    },
    handleAddAccountCategory() {
      if (this.$refs.addForm.validate()) {
        let data = {
          accountTypeId: this.input.accountTypeId,
          code: this.input.code,
          name: this.input.name,
        };
        this.$store.dispatch("addAccountCategoryData", data);
        this.addAccountCategoryDialog = false;
        this.$refs.addForm.reset();
      }
    },
    handleEditItem(item) {
      this.accountCategory.accountTypeId = item.accountTypeId;
      this.accountCategory.id = item.id;
      this.accountCategory.code = item.code;
      this.accountCategory.name = item.name;
      this.accountCategoryCode = item.code;
      this.editAccountCategoryDialog = true;
    },
    handleEditAccountCategory() {
      if (this.$refs.editForm.validate()) {
        let data = {
          id: this.accountCategory.id,
          accountTypeId: this.accountCategory.accountTypeId,
          code: this.accountCategory.code,
          name: this.accountCategory.name,
        };
        this.$store.dispatch("editAccountCategoryData", data);
        this.editAccountCategoryDialog = false;
      }
    },
    handleDeleteItem(id) {
      this.accountCategory.id = id;
      this.dialogDelete = true;
    },
    confirmDeleteItem() {
      this.$store.dispatch("deleteAccountCategory", this.accountCategory.id);
      this.dialogDelete = false;
    },
    accountType(id) {
      return this.getAccountType
        .filter((e) => e.id === id)
        .map((item) => item.name)
        .toString();
    },
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 16;
      });

      this.listPermission[1] = permissions.some((item) => {
        return item === 17;
      });

      this.listPermission[2] = permissions.some((item) => {
        return item === 18;
      });

      this.listPermission[3] = permissions.some((item) => {
        return item === 19;
      });
    },
  },
  async created() {
    await this.setListPermission();
    this.accountTypeItems.push(...this.getAccountType);
    if (this.listPermission[3] === false) {
      this.isLoading = false;
      this.accountTypeLoading = false;
      this.noDataText = "Anda tidak memiliki izin untuk mengakses data";
    } else {
      await this.getAccountCategoryData();
      await this.getAccountTypeData();
    }
  },
};
</script>

<style src="../styles/account-category.scss" lang="scss" />
