<template>
  <v-card-text>
    <div class="d-flex flex-row justify-center">
      <img src="../assets/images/delete.png" class="delete-icon" />
    </div>
    <div class="d-flex flex-row justify-center mt-4">
      <span class="title">Apakah anda yakin?</span>
    </div>
    <div class="d-flex flex-row justify-center">
      <span class="text-body">Anda tidak bisa memulihkannya jika dihapus</span>
    </div>
  </v-card-text>
</template>

<script>
export default {
  name: "AppCardBodyDelete",
};
</script>
