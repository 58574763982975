<template>
  <v-container>
    <v-card class="journal-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="card-title">Detail Transaksi</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-form>
        <v-row class="ml-n16 pr-8">
          <v-col md="6">
            <v-row class="mt-4">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Referensi</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="journal.code"
                  :readonly="true"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n7">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Periode</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="journal.period"
                  :readonly="true"
                  append-icon=""
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n7">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Tanggal</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="journal.date"
                  :readonly="true"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n7">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Penginput</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="journal.user"
                  :readonly="true"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6">
            <v-row class="mt-4">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Tipe Transaksi</span>
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="journal.transactionType"
                  :items="getTransactionType"
                  item-value="id"
                  item-text="name"
                  :readonly="true"
                  multiple
                  append-icon=""
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n7">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Tipe Jurnal</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="journal.journalType"
                  :readonly="true"
                  append-icon=""
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-n7">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Uraian</span>
              </v-col>
              <v-col md="8">
                <v-textarea
                  v-model="journal.note"
                  :readonly="true"
                  background-color="#FAFAFA"
                  height="110"
                  no-resize
                  outlined
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row> -->
          </v-col>
        </v-row>
        <v-card-title class="mt-n4 pl-7">
          <span class="card-title">Detail Transaksi</span>
        </v-card-title>
        <v-card-text>
          <v-row class="transaction-details-table-outer">
            <v-col>
              <table class="transaction-details-table">
                <thead>
                  <th class="text-left" style="width: 128px">Kode Akun</th>
                  <th class="text-left" style="width: 210px">Nama</th>
                  <th class="text-left" style="width: 220px">Debit</th>
                  <th class="text-left" style="width: 220px">Kredit</th>
                  <th class="text-left" style="width: 230px">Deskripsi</th>
                  <th
                    v-for="(item, index) in TCode"
                    :key="index"
                    class="text-left"
                    style="width: 300px"
                  >
                    T Code {{ index + 1 }}
                  </th>
                </thead>
                <tbody>
                  <tr v-for="data in journal.transactionDetail" :key="data.id">
                    <td class="text-center">
                      <input
                        :value="data.account.code"
                        readonly
                        class="code-account-input"
                      />
                    </td>
                    <td class="text-center">
                      <input
                        :value="data.account.name"
                        readonly
                        class="name-input"
                        outlined
                        dense
                      />
                    </td>
                    <td class="text-center">
                      <input readonly class="rupiah-input" placeholder="Rp" />
                      <input
                        :value="formatRupiah(data.debet)"
                        readonly
                        class="debit-input"
                      />
                    </td>
                    <td class="text-center">
                      <input readonly class="rupiah-input" placeholder="Rp" />
                      <input
                        :value="formatRupiah(data.kredit)"
                        readonly
                        class="debit-input"
                      />
                    </td>
                    <td class="text-center">
                      <input :value="data.memo" readonly class="memo-input" />
                    </td>
                    <template v-if="data.tblTCode !== null">
                      <td v-for="(item, i) in data.tblTCode" :key="item.id">
                        <!-- <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs"> -->
                        <v-select
                          v-model="item.value"
                          :items="{ ...getTCode[i] }.detail"
                          item-value="id"
                          item-text="name"
                          class="mt-6"
                          append-icon=""
                          style="font-size: 14px"
                          :placeholder="item.value"
                          readonly
                          outlined
                          dense
                        ></v-select>
                        <!-- </div>
                          </template>
                          <span>{{ getTcodeDetailName(item.value) }}</span>
                        </v-tooltip> -->
                      </td>
                    </template>
                    <template v-else>
                      <td v-for="item in getTCode" :key="item.id">
                        <input readonly class="tcode-input" />
                      </td>
                    </template>
                  </tr>
                  <tr>
                    <td></td>
                    <td class="body-2 text-right font-weight-black">Total</td>
                    <td class="text-right">
                      Rp. {{ formatRupiah(getDebetTotal) }}
                    </td>
                    <td class="text-right">
                      Rp. {{ formatRupiah(getKreditTotal) }}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td class="body-2 text-right font-weight-black">
                      Different
                    </td>
                    <td class="text-right">
                      Rp. {{ formatRupiah(getOutOfBalance) }}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
          <!-- <v-row justify="start">
            <v-col md="3" class="ml-4">
              <span class="row-title-img">Gambar</span>
            </v-col>
          </v-row>
          <v-row justify="center">
            <div class="box-img" justify="center">
              <v-col md="4">
                <v-img
                  :src="journal.img"
                  width="246"
                  height="138"
                  class="mt-2 rounded"
                ></v-img>
              </v-col>
            </div>
          </v-row> -->
        </v-card-text>
        <v-card-actions class="justify-end mt-4 mr-4">
          <v-btn @click="$router.go(-1)" class="btn-cancel mr-2" tile depressed>
            Kembali
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "JournalDetail",
  data: () => ({
    valid: true,
    id: "",
    journal: {
      code: "",
      date: null,
      period: "",
      user: "",
      unit: "",
      transactionType: [],
      journalType: "",
      // note: "",
      transactionDetail: [],
    },
    TCode: [],
  }),
  computed: {
    ...mapGetters(["getPeriodItems", "getTCode", "getTransactionType"]),
    getDebetTotal() {
      let total;
      let accountDebet = [];
      const num = 0;
      let accountDebetTotal;
      for (let i = 0; i < this.journal.transactionDetail.length; i++) {
        if (this.journal.transactionDetail[i].debet !== "") {
          accountDebet.push(this.journal.transactionDetail[i].debet);
        } else {
          accountDebet.push(num);
        }
      }
      accountDebetTotal = accountDebet.reduce((acc, val) => {
        return acc + val;
      }, 0);
      total = accountDebetTotal;
      // if (
      //   this.journal.transactionDetail[0].debet === "" &&
      //   this.journal.transactionDetail[1].debet === "" &&
      //   this.journal.transactionDetail.length === 2
      // ) {
      //   return 0;
      // } else {
      //   return total;
      // }
      return total;
    },
    getKreditTotal() {
      let total;
      let accountKredit = [];
      const num = 0;
      let accountKreditTotal;
      for (let i = 0; i < this.journal.transactionDetail.length; i++) {
        if (this.journal.transactionDetail[i].kredit !== "") {
          accountKredit.push(this.journal.transactionDetail[i].kredit);
        } else {
          accountKredit.push(num);
        }
      }
      accountKreditTotal = accountKredit.reduce((acc, val) => {
        return acc + val;
      }, 0);
      total = accountKreditTotal;
      // if (
      //   this.journal.transactionDetail[0].kredit === "" &&
      //   this.journal.transactionDetail[1].kredit === "" &&
      //   this.journal.transactionDetail.length === 2
      // ) {
      //   return 0;
      // } else {
      //   return total;
      // }
      return total;
    },
    getOutOfBalance() {
      var total = this.getDebetTotal - this.getKreditTotal;

      var check = total.toFixed(2) === "-0.00" ? true : false;

      return check ? total * -1 : total;
    },
  },
  methods: {
    ...mapActions(["getTCodeData", "getTransactionTypeData"]),
    getMonth(value) {
      return this.getPeriodItems
        .filter((e) => e.value === value)
        .map((item) => item.name)
        .toString();
    },
    formatRupiah(value) {
      return Intl.NumberFormat("id-ID", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    // getTcodeDetailName(id) {
    //   let name = [];
    //   this.getTCode.map((el) => {
    //     if (el.detail.length > 0) {
    //       for (let i = 0; i < el.detail.length; i++) {
    //         if (el.detail[i].id === id) {
    //           name.push(el.detail[i].name);
    //         }
    //       }
    //     }
    //   });
    //   return name[0];
    // },
    async getTransactionDetailData() {
      try {
        let id = this.$route.params.id;
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/transaction/detail/" + id
        );
        let data = res.data.data;
        this.journal.code = data.code;
        this.journal.date = moment(data.date).locale("id").format("YYYYMMDD");
        this.journal.period = data.periode.year + data.periode.month;
        this.journal.user = data.user.name;
        this.journal.transactionType.push(data.transactionType);
        this.journal.transactionType.push(data.transactionType2);
        this.journal.journalType = data.journalType.name;
        // this.journal.note = data.note;
        this.journal.transactionDetail = data.transactionDetail;
        this.TCode = data.transactionDetail[0].tblTCode;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    await this.getTransactionDetailData();
    await this.getTCodeData();
    await this.getTransactionTypeData();
  },
};
</script>

<style src="../styles/journal-detail.scss" lang="scss" />
