import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router/index.js";

Vue.use(Vuex);

const authModule = {
  state: {
    userId: localStorage.getItem("userId") || null,
    userById: {},
    listPermissionById:
      JSON.parse(localStorage.getItem("permissions")) !== null
        ? JSON.parse(localStorage.getItem("permissions"))
        : [],
    token: localStorage.getItem("token") || null,
    isLogin: JSON.parse(localStorage.getItem("isLogin")) || false,
    alertLogin: false,
    alertLogout: false,
    expiredDate: "",
    isReminder: false,
    loginErrorMessage: "",
  },
  mutations: {
    setUserId(state, payload) {
      state.userId = payload;
    },
    setUserById(state, payload) {
      state.userById = payload;
    },
    setListPermissionById(state, payload) {
      state.listPermissionById = payload;
    },
    setToken(state, payload) {
      state.token = payload;
      state.isLogin = true;
    },
    setLogout(state, payload) {
      state.isLogin = payload;
      state.userId = "";
      state.token = "";
    },
    setAlertLogin(state, payload) {
      state.alertLogin = payload;
    },
    setAlertLogout(state, payload) {
      state.alertLogout = payload;
    },
    setExpiredDate(state, payload) {
      state.expiredDate = payload;
    },
    setReminder(state, payload) {
      if (payload == "true") {
        state.isReminder = true;
      } else {
        state.isReminder = false;
      }
    },
    setLoginErrorMessage(state, payload) {
      state.loginErrorMessage = payload;
    },
  },
  actions: {
    async loginUser({ dispatch, commit }, payload) {
      try {
        const res = await axios.post(process.env.VUE_APP_API_URL + "/login", {
          email: payload.email,
          password: payload.password,
        });
        if (res.status === 207) {
          commit("setLoginErrorMessage", "Email atau Password salah!");
          commit("setAlertLogin", true);
        } else {
          const token = res.data.data.token;
          const id = res.data.data.userId;
          const licenseKey = res.data.data.linceseKey;
          let expiredDate = "";
          let isReminder = "";
          if (Object.keys(licenseKey).length !== 0) {
            expiredDate = licenseKey.expiredDate;
            isReminder = licenseKey.isReminder;
          } else {
            expiredDate = "";
            isReminder = "";
          }
          commit("setToken", token);
          commit("setUserId", id);
          commit("setExpiredDate", expiredDate);
          commit("setReminder", isReminder);
          localStorage.setItem("userId", id);
          localStorage.setItem("token", token);
          localStorage.setItem("isLogin", true);
          dispatch("getRoleById");
          router.push("/main/beranda");
        }
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status === 403) {
            console.log(error.response);
            if (error.response.data.message === "Login denide") {
              commit("setLoginErrorMessage", "Email atau Password salah!");
              commit("setAlertLogin", true);
            } else {
              commit("setLoginErrorMessage", "License key is expired!");
              commit("setAlertLogin", true);
            }
          } else {
            console.log(error);
          }
        } else {
          console.log(error);
        }
      }
    },
    async getRoleById({ state, dispatch }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/user/" + state.userId
        );
        let roleId = res.data.data.roleId;
        localStorage.setItem("roleId", roleId);
        dispatch("getListPermissionDataById", localStorage.getItem("roleId"));
      } catch (error) {
        console.log(error);
      }
    },
    async getListPermissionDataById({ commit }, id) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/permissionLink/detail/" + id
        );
        let data = res.data.data;
        let permissions = data.map((item) => item.permissionId);
        let sortedPermissions = permissions.sort((a, b) => {
          return a - b;
        });
        commit("setListPermissionById", sortedPermissions);
        localStorage.setItem("permissions", JSON.stringify(sortedPermissions));
      } catch (error) {
        console.log(error);
      }
    },
    async logout({ state, commit }) {
      try {
        await axios.get(process.env.VUE_APP_API_URL + "/logOut");
        commit("setLogout", false);
        localStorage.removeItem("userId");
        localStorage.removeItem("roleId");
        localStorage.removeItem("token");
        localStorage.removeItem("isLogin");
        localStorage.removeItem("permissions");
        state.listPermissionById = [];
        router.push("/login");
        commit("setAlertLogout", true);
      } catch (error) {
        console.log(error);
        localStorage.removeItem("userId");
        localStorage.removeItem("roleId");
        localStorage.removeItem("token");
        localStorage.removeItem("isLogin");
        localStorage.removeItem("permissions");
        state.listPermissionById = [];
        router.push("/login");
        commit("setAlertLogout", true);
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.isLogin;
    },
    getListPermissionById(state) {
      var response =
        state.listPermissionById === []
          ? JSON.parse(localStorage.getItem("permissions"))
          : state.listPermissionById;
      return response;
    },
    getIsReminder(state) {
      return state.isReminder;
    },
    getExpiredDate(state) {
      return state.expiredDate;
    },
    getLoginErrorMessage(state) {
      return state.loginErrorMessage;
    },
  },
};

export default authModule;
