import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const tCodeModule = {
  state: {
    tCode: [],
  },
  mutations: {
    setTCode(state, payload) {
      state.tCode = payload;
    },
  },
  actions: {
    async getTCodeData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/tCode");
        let data = res.data.data;
        commit("setTCode", data);
      } catch (error) {
        console.log(error);
      }
    },
    async addTCodeData({ dispatch, commit }, payload) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + "/tCode", {
          code: payload.code,
          name: payload.name,
          description: payload.description,
          detail: payload.detail,
        });
        dispatch("getTCodeData");
        commit("showSnackbarAdd", true);
      } catch (error) {
        console.log(error);
      }
    },
    async editTCodeData({ dispatch, commit }, payload) {
      try {
        await axios.put(process.env.VUE_APP_API_URL + "/tCode/" + payload.id, {
          code: payload.code,
          name: payload.name,
          description: payload.description,
          detail: payload.detail,
        });
        dispatch("getTCodeData");
        commit("showSnackbarEdit", true);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteTCode({ dispatch, commit }, id) {
      try {
        await axios.delete(process.env.VUE_APP_API_URL + "/tCode/" + id);
        dispatch("getTCodeData");
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getTCode(state) {
      return state.tCode;
    },
  },
};

export default tCodeModule;
