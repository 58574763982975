<template>
  <v-container>
    <v-card class="period-management-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="period-card-title">Manajemen Periode</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-form @submit.prevent="handleCheck">
        <v-card-text class="pt-7 pr-13">
          <v-row>
            <v-col md="2" class="mt-2 text-right">
              <span class="input-title">Periode</span>
            </v-col>
            <v-col md="10">
              <date-picker
                v-model="date"
                type="month"
                format="YYYYMM"
                placeholder="Pilih periode"
                class="datepicker"
                popup-class="periode-management"
                :popup-style="{
                  top: '220px',
                  left: '475px',
                  right: '630px',
                }"
                @open="onOpenCalendar"
                @change="handleOnChangeMonth"
                @clear="clearDate"
                @calendar-change="changeCalendar"
                @panel-change="onPanelChange"
              >
                <template #input>
                  <v-text-field
                    v-model="formattedDate"
                    placeholder="Pilih periode"
                    background-color="#FAFAFA"
                    outlined
                    dense
                    readonly
                    @click="showPopup"
                  />
                </template>
                <template v-if="calendarType === 'month'" #footer>
                  <div style="display: flex; justify-content: flex-start">
                    <v-btn
                      :class="auditSelected ? 'selected' : ''"
                      text
                      elevation="0"
                      @click="handleOnChangeMonth"
                    >
                      Aud
                    </v-btn>
                  </div>
                </template>
              </date-picker>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col md="2" class="mt-2 text-right">
              <span class="input-title">Periode</span>
            </v-col>
            <v-col md="10">
              <v-select
                v-model="period"
                :items="periodFilterItems"
                return-object
                :item-text="(item) => getMonth(item.month)"
                @change="handleOnChangePeriod"
                placeholder="Pilih"
                no-data-text="Tidak ada data yang tersedia"
                background-color="#FAFAFA"
                append-icon="mdi-chevron-down"
                clearable
                outlined
                dense
              >
              </v-select>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions class="justify-end pr-13">
          <v-btn type="submit" class="btn-check" tile depressed>Cek</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <v-row v-if="getShowPeriodTable === true" class="pa-7">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="getPeriods"
          :no-data-text="noDataText"
          class="period-management-table"
        >
          <template v-slot:[`item.month`]="{ item }">
            {{ getMonth(item.month) || "Audit" }}
          </template>
          <template v-slot:[`item.isClosed`]="{ item }">
            <span v-if="item.isClosed === false">Buka</span>
            <span v-else>Tutup</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="handleChangeDescription(item)"
                  :disabled="listPermission[1] === false"
                  :class="item.isClosed === true ? 'btn-open' : 'btn-close'"
                  tile
                  depressed
                >
                  <span v-if="item.isClosed === true" class="text-capitalize"
                    >Buka</span
                  >
                  <span v-else class="text-capitalize">Tutup</span>
                </v-btn>
              </template>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-else></v-row>
    <v-snackbar
      v-model="getSnackbarOpen"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarOpenText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarOpen = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarClose"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarCloseText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarClose = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PeriodManagement",
  components: { DatePicker },
  data: () => ({
    date: null,
    formattedDate: "",
    selectedYear: "",
    year: "",
    period: "",
    headers: [
      {
        text: "Tahun",
        sortable: false,
        value: "year",
        width: "15%",
      },
      { text: "Bulan", value: "month", sortable: false },
      {
        text: "Keterangan",
        value: "isClosed",
        align: "center",
        sortable: false,
        width: "5%",
      },
      {
        value: "actions",
        sortable: false,
        align: "start",
        width: "5%",
      },
    ],
    noDataText: "Tidak ada data yang tersedia",
    listPermission: [false, false],
    snackbarOpenText: "Periode berhasil dibuka!",
    snackbarCloseText: "Periode berhasil ditutup!",
    timeout: 2500,
    auditSelected: false,
    calendarType: "month",
  }),
  computed: {
    ...mapGetters([
      "getPeriodItems",
      "getPeriod",
      "getShowPeriodTable",
      "getListPermissionById",
    ]),
    getPeriods() {
      return this.getPeriod.filter((item) => {
        const year = this.formattedDate.substring(0, 4);
        // const month = this.formattedDate.substring(4, 6);
        if (this.formattedDate && !this.period) {
          return item.year == year;
        } else if (this.period && !this.formattedDate) {
          return item.month == this.period;
        } else if (this.formattedDate && this.period) {
          return item.year == year && item.month == this.period;
        } else {
          return item;
        }
      });
    },
    // periodFilterItems() {
    //   return this.getPeriod.filter((item) => {
    //     if (this.date) {
    //       return item.year == this.year;
    //     } else {
    //       return item;
    //     }
    //   });
    // },
    getSnackbarOpen: {
      get() {
        return this.$store.state.periodModule.snackbarOpen;
      },
      set(v) {
        this.$store.commit("showSnackbarOpen", v);
      },
    },
    getSnackbarClose: {
      get() {
        return this.$store.state.periodModule.snackbarClose;
      },
      set(v) {
        this.$store.commit("showSnackbarClose", v);
      },
    },
  },
  methods: {
    ...mapActions(["getPeriodData"]),
    async onPanelChange(type) {
      this.calendarType = type;
    },
    async changeCalendar() {
      await this.$nextTick();
      const year = Number(
        document.querySelector("span.mx-calendar-header-label > .mx-btn-text")
          .textContent
      );
      if (this.formattedDate && this.formattedDate.substring(4, 6) === "13") {
        document
          .querySelector(
            ".mx-datepicker-main.mx-datepicker-popup.periode-management td[data-month='0'].cell"
          )
          .classList.remove("active");
      }
      this.auditSelected =
        this.formattedDate === `${this.selectedYear}13` &&
        year === this.selectedYear;
    },
    async onOpenCalendar() {
      this.calendarType = "month";
      if (this.formattedDate && this.formattedDate.substring(4, 6) === "13") {
        this.date = new Date(this.formattedDate.substring(0, 4));
        await this.$nextTick();
        document
          .querySelector(
            ".mx-datepicker-main.mx-datepicker-popup.periode-management td[data-month='0'].cell"
          )
          .classList.remove("active");
      }
    },
    async showPopup() {
      await this.$nextTick();
      if (
        document.querySelector(
          ".mx-datepicker-main.mx-datepicker-popup.periode-management"
        )
      ) {
        document.querySelector(
          ".mx-datepicker-main.mx-datepicker-popup.periode-management"
        ).style.display = "block";
      }
    },
    getMonth(value) {
      if (value.length === 2) {
        return this.getPeriodItems
          .filter((e) => e.value === value)
          .map((item) => item.name)
          .toString();
      } else {
        return this.getPeriodItems
          .filter((e) => e.value === "0" + value)
          .map((item) => item.name)
          .toString();
      }
    },
    handleCheck() {
      if (!this.formattedDate) {
        this.$store.commit("setPeriodTable", true);
      } else {
        this.year = Number(this.formattedDate.substring(0, 4));
        this.period = this.formattedDate.substring(4, 6);
        this.$store.commit("setPeriodTable", true);
      }
    },
    handleOnChangeMonth(e) {
      if (e && !(e instanceof Date)) {
        this.date = null;
        const year = Number(
          document.querySelector("span.mx-calendar-header-label > .mx-btn-text")
            .textContent
        );
        this.selectedYear = year;
        const month = "13";
        this.formattedDate = `${year}${month}`;
        document.querySelector(
          ".mx-datepicker-main.mx-datepicker-popup.periode-management"
        ).style.display = "none";
      }
      if (e && e instanceof Date) {
        const year = this.date.getFullYear();
        this.selectedYear = year;
        const month =
          this.date.getMonth() + 1 < 10
            ? "0" + (this.date.getMonth() + 1)
            : this.date.getMonth() + 1;
        this.formattedDate = `${year}${month}`;
      }
      if (e) {
        this.periodAlert = false;
        this.$store.commit("setPeriodTable", false);
      } else {
        this.period = "";
        this.$store.commit("setPeriodTable", false);
      }
    },
    // handleOnChangeYear(e) {
    //   if (!e) {
    //     this.$store.commit("setPeriodTable", false);
    //   } else {
    //     this.year = e.getFullYear();
    //     this.$store.commit("setPeriodTable", false);
    //   }
    // },
    // handleOnChangePeriod(e) {
    //   if (!e) {
    //     this.$store.commit("setPeriodTable", false);
    //   } else {
    //     this.$store.commit("setPeriodTable", false);
    //   }
    // },
    handleChangeDescription(item) {
      if (item.isClosed === false) {
        this.$store.dispatch("closePeriod", item.periodeId);
      } else {
        this.$store.dispatch("openPeriod", item.periodeId);
      }
    },
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 42;
      });

      this.listPermission[1] = permissions.some((item) => {
        return item === 43;
      });
    },
    clearDate() {
      this.formattedDate = "";
    },
  },
  async created() {
    await this.setListPermission();
    if (this.listPermission[0] === false) {
      this.isLoading = false;
      this.noDataText = "Anda tidak memiliki izin untuk mengakses data";
    } else {
      await this.getPeriodData();
    }
    this.$store.commit("setPeriodTable", false);
  },
  watch: {
    formattedDate: {
      handler() {
        this.auditSelected = this.formattedDate === `${this.selectedYear}13`;
      },
    },
    selectedYear: {
      handler() {
        this.auditSelected = this.formattedDate === `${this.selectedYear}13`;
      },
    },
  },
};
</script>

<style src="../styles/period-management.scss" lang="scss" />
