import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const accountCodeModule = {
  state: {
    account: [],
    accountActive: [],
    arusKasCategory: [],
  },
  mutations: {
    setAccount(state, payload) {
      state.account = payload;
    },
    setAccountActive(state, payload) {
      state.accountActive = payload;
    },
    setArusKasCategory(state, payload) {
      state.arusKasCategory = payload;
    },
  },
  actions: {
    async getAccountData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/account");
        let data = res.data.data;
        commit("setAccount", data);
      } catch (error) {
        console.log(error);
      }
    },
    async getArusKasCategoryData({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/arusKasCategory"
        );
        let data = res.data.data;
        commit("setArusKasCategory", data);
      } catch (error) {
        console.log(error);
      }
    },
    async getAccountListActive({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/account/list/active"
        );
        let data = res.data.data;
        commit("setAccountActive", data);
      } catch (error) {
        console.log(error);
      }
    },
    async addAccountData({ dispatch, commit }, payload) {
      try {
        let res = await axios.post(process.env.VUE_APP_API_URL + "/account", {
          parentCode: payload.parentCode,
          parentId: payload.parentId,
          isHeader: payload.isHeader,
          code: payload.code,
          name: payload.name,
          accountCategoryId: payload.accountCategoryId,
          accountTypeId: payload.accountTypeId,
          arusKasCategoryId: payload.arusKasCategoryId,
          reportType: payload.reportType,
          linkAccount: payload.linkAccount,
          // normalBalance: payload.normalBalance,
          description: payload.description,
          isActive: payload.isActive,
        });
        if (res.data.status === 207) {
          commit("showSnackbarAddAlert", true);
        } else {
          commit("showSnackbarAdd", true);
          dispatch("getAccountData");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async editAccountData({ dispatch, commit }, payload) {
      try {
        let res = await axios.put(
          process.env.VUE_APP_API_URL + "/account/" + payload.id,
          {
            parentCode: payload.parentCode,
            parentId: payload.parentId,
            isHeader: payload.isHeader,
            code: payload.code,
            name: payload.name,
            accountCategoryId: payload.accountCategoryId,
            arusKasCategoryId: payload.arusKasCategoryId,
            accountTypeId: payload.accountTypeId,
            reportType: payload.reportType,
            linkAccount: payload.linkAccount,
            // normalBalance: payload.normalBalance,
            description: payload.description,
            isActive: payload.isActive,
          }
        );
        if (res.data.status === 207) {
          commit("showSnackbarEditAlert", true);
        } else {
          commit("showSnackbarEdit", true);
          dispatch("getAccountData");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async searchAccount({ commit }, payload) {
      try {
        let res = await axios.post(
          process.env.VUE_APP_API_URL + "/account/search",
          {
            search: payload,
          }
        );
        let data = res.data.data;
        commit("setAccount", data);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteAccount({ dispatch, commit }, id) {
      try {
        let res = await axios.delete(
          process.env.VUE_APP_API_URL + "/account/" + id
        );
        if (res.status === 207) {
          commit("showSnackbarWarning", true);
        } else {
          commit("showSnackbarDelete", true);
          dispatch("getAccountData");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getAccount(state) {
      let account = state.account.sort((a, b) => {
        return `${a.parentCode}${a.code}` - `${b.parentCode}${b.code}`;
      });
      return account;
    },
    getArusKasCategory(state) {
      return state.arusKasCategory;
    },
    getAccountActive(state) {
      let accountActive = state.accountActive.sort((a, b) => {
        return `${a.parentCode}${a.code}` - `${b.parentCode}${b.code}`;
      });
      return accountActive;
    },
  },
};

export default accountCodeModule;
