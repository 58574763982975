<template>
  <v-container>
    <v-card class="report-card mt-6 ml-6 mr-6 pb-14">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="report-card-title">Journal Listing</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-7 mt-7">
        <v-col>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-row>
              <v-col md="3" class="mt-4">
                <label>
                  <span class="input-title">Nomor Posting Awal</span>
                </label>
              </v-col>
              <v-col md="9" class="ml-n12">
                <v-select
                  v-model="report.startPostingNum"
                  :items="getPostingNum"
                  :rules="startPostingNumRules"
                  :loading="startPostingNumLoading"
                  :no-data-text="noDataText"
                  placeholder="Pilih"
                  clearable
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  class="mt-2"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col md="3" class="mt-4">
                <label>
                  <span class="input-title">Nomor Posting Akhir</span>
                </label>
              </v-col>
              <v-col md="9" class="ml-n12">
                <v-select
                  v-model="report.endPostingNum"
                  :items="getPostingNum"
                  :rules="endPostingNumRules"
                  :loading="endPostingNumLoading"
                  :no-data-text="noDataText"
                  placeholder="Pilih"
                  clearable
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  class="mt-2"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-n8">
              <v-col md="3" class="mt-4">
                <label>
                  <span class="input-title">Tahun</span>
                </label>
              </v-col>
              <v-col md="9" class="ml-n12">
                <date-picker
                  v-model="report.year"
                  type="year"
                  format="YYYY"
                  value-type="format"
                  @change="onChangeYear"
                  @close="onClosePanelYear"
                  class="datepicker mt-2"
                  placeholder="Pilih"
                  :popup-style="{
                    top: '380px',
                    left: '460px',
                  }"
                >
                </date-picker>
                <v-alert
                  v-show="yearAlert"
                  transition="slide-y-transition"
                  class="yearpicker-alert"
                  >Tahun harus dipilih</v-alert
                >
              </v-col>
            </v-row> -->
            <!-- <v-row class="mt-3">
              <v-col md="3" class="mt-4">
                <label>
                  <span class="input-title">Periode</span>
                </label>
              </v-col>
              <v-col md="9" class="ml-n12">
                <v-select
                  v-model="report.period"
                  :items="periodFilter"
                  :item-text="(item) => getMonth(item.month)"
                  return-object
                  :rules="periodRules"
                  :loading="periodLoading"
                  :no-data-text="noDataText"
                  clearable
                  placeholder="Pilih"
                  class="mt-2"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                  <template v-slot:item="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ getMonth(item.month) }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row> -->
            <!-- <v-row class="mt-n8">
              <v-col md="3" class="mt-4">
                <label>
                  <span class="input-title">Tanggal</span>
                </label>
              </v-col>
              <v-col md="9" class="ml-n12">
                <date-picker
                  v-model="report.day"
                  type="date"
                  format="DD/MM/YYYY"
                  value-type="YYYYMMDD"
                  @change="onChangeDay"
                  @close="onClosePanelDay"
                  class="datepicker mt-2"
                  range
                  placeholder="Pilih"
                  :popup-style="{
                    top: '380px',
                    left: '460px',
                  }"
                >
                </date-picker>
                <v-alert
                  v-show="dayAlert"
                  transition="slide-y-transition"
                  class="yearpicker-alert"
                  >Tanggal harus dipilih</v-alert
                >
              </v-col>
            </v-row> -->
            <v-row class="mt-12" justify="center">
              <!-- <v-btn
                @click="handlePreview"
                class="btn-preview mr-4"
                small
                tile
                depressed
                >Pratinjau</v-btn
              > -->
              <!-- <v-btn class="btn-print-pdf" small tile depressed
                >Cetak PDF</v-btn
              > -->
              <base-button-export
                @export="previewAction"
                :color="'#3E9FF9'"
                :disabled="!valid"
                class="mr-2"
              >
                <template v-slot:btnText>
                  <span>Preview</span>
                </template>
              </base-button-export>
              <base-button-export
                @export="exportToExcel"
                :loading="getBtnLoading"
                :disabled="!valid || getBtnLoading"
                class="ml-2"
              >
                <template v-slot:btnText>
                  <span>Cetak Excel</span>
                </template>
              </base-button-export>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-show="previewTable" class="report-card mt-6 ml-6 mr-6 pb-14">
      <div v-if="loadingTable" class="d-flex justify-center align-center pt-14">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <div
          v-if="errorState"
          class="d-flex justify-center align-center pt-14 text-center"
        >
          <h3>Oops, something went wrong. Please try again later.</h3>
        </div>
        <div v-else>
          <div class="title-preview">
            <h5>DANA PENSIUN SAMUDERA INDONESIA UTAMA</h5>
            <h5>JOURNAL LISTING</h5>
            <h5>
              JOURNAL POSTING NO : {{ startPost }} TO
              {{ endPost }}
            </h5>
          </div>
          <div class="preview-table">
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <th rowspan="2">Line No</th>
                    <th rowspan="2">Trans Date</th>
                    <th rowspan="2">Periode Trans</th>
                    <th rowspan="2">Reff</th>
                    <th rowspan="2">Trans Type</th>
                    <th rowspan="2">JE Type</th>
                    <th rowspan="2">User ID</th>
                    <th rowspan="2">Posting No</th>
                    <th rowspan="2">Account Code</th>
                    <th rowspan="2">Account Name</th>
                    <th rowspan="2">Debit/Credit</th>
                    <th rowspan="2">Amount</th>
                    <th rowspan="2">Description</th>
                    <th :colspan="getTCode.length">Optional</th>
                  </tr>
                  <tr>
                    <th v-for="(data, i) in getTCode" :key="i">
                      {{ data.code }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in dataTable" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ data.transDate }}</td>
                    <td>{{ data.periodeTrans }}</td>
                    <td>{{ data.reff }}</td>
                    <td>{{ data.transType }}</td>
                    <td>{{ data.jeType }}</td>
                    <td>{{ data.userId }}</td>
                    <td>{{ data.postingNo }}</td>
                    <td>{{ data.accountCode }}</td>
                    <td>{{ data.accountName }}</td>
                    <td>{{ data.debitCredit }}</td>
                    <td :class="{ 'text-red': parseFloat(data.amount) < 0 }">
                      {{ data.amount }}
                    </td>
                    <td>{{ data.description }}</td>
                    <td v-for="(item, i) in data.tc" :key="i">
                      {{ item.value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import BaseButtonExport from "./base/BaseButtonExport.vue";
import { setNumberFormat } from "../functions/NumberFormat.ts";

export default {
  name: "ListingJournal",
  components: { BaseButtonExport },
  data: () => ({
    valid: true,
    // yearValid: true,
    // dayValid: true,
    report: {
      startPostingNum: null,
      endPostingNum: null,
      // day: "",
      // year: "",
      // period: "",
    },
    // periodItems: [],
    // periodLoading: true,
    startPostingNumLoading: true,
    endPostingNumLoading: true,
    noDataText: "Tidak ada data yang tersedia",
    // yearAlert: false,
    // dayAlert: false,
    // Validation Rules
    startPostingNumRules: [(v) => !!v || "Nomor posting awal harus dipilih"],
    endPostingNumRules: [(v) => !!v || "Nomor posting akhir harus dipilih"],
    // periodRules: [(v) => !!v || "Periode harus dipilih"],
    // months: [
    //   { value: "01", name: "Januari" },
    //   { value: "02", name: "Februari" },
    //   { value: "03", name: "Maret" },
    //   { value: "04", name: "April" },
    //   { value: "05", name: "Mei" },
    //   { value: "06", name: "Juni" },
    //   { value: "07", name: "Juli" },
    //   { value: "08", name: "Agustus" },
    //   { value: "09", name: "September" },
    //   { value: "10", name: "Oktober" },
    //   { value: "11", name: "November" },
    //   { value: "12", name: "Desember" },
    // ],
    previewTable: false,
    dataTable: [],
    statusPreviewButton: false,
    startPost: "",
    endPost: "",
    loadingTable: true,
    errorState: false,
  }),
  computed: {
    ...mapGetters(["getPostingNum", "getTCode"]),
    // periodFilter() {
    //   return this.periodItems.filter((item) => item.year == this.report.year);
    // },
    getBtnLoading: {
      get() {
        return this.$store.state.btnLoading;
      },
      set(v) {
        this.$store.commit("setBtnLoading", v);
      },
    },
  },
  watch: {
    getPostingNum() {
      this.startPostingNumLoading = false;
      this.endPostingNumLoading = false;
    },
    statusPreviewButton(val) {
      if (val == true) {
        this.startPost = this.report.startPostingNum;
        this.endPost = this.report.endPostingNum;
      } else {
        let start = this.startPost;
        this.startPost = start;
        let end = this.endPost;
        this.endPost = end;
      }
    },
    // getOpenPeriod() {
    //   this.periodLoading = false;
    // },
  },
  methods: {
    ...mapActions(["getPostingNumData", "getTCodeData"]),
    // getMonth(value) {
    //   return this.months
    //     .filter((e) => e.value === value)
    //     .map((item) => item.name)
    //     .toString();
    // },
    // onChangeDay(value) {
    //   if (!value) {
    //     this.dayAlert = true;
    //     this.dayValid = false;
    //   } else {
    //     this.dayAlert = false;
    //     this.dayValid = true;
    //   }
    // },
    // onClosePanelDay() {
    //   if (!this.report.day) {
    //     this.dayAlert = true;
    //     this.dayValid = false;
    //   } else {
    //     this.dayAlert = false;
    //   }
    // },
    // onChangeYear(value) {
    //   if (!value) {
    //     this.yearAlert = true;
    //     this.yearValid = false;
    //   } else {
    //     this.yearAlert = false;
    //     this.yearValid = true;
    //   }
    // },
    // onClosePanelYear() {
    //   if (!this.report.year) {
    //     this.yearAlert = true;
    //     this.yearValid = false;
    //   } else {
    //     this.yearAlert = false;
    //   }
    // },
    exportToExcel() {
      if (this.$refs.form.validate()) {
        this.$store.commit("setBtnLoading", true);
        this.$store.dispatch("exportJLExcel", this.report);
      }
    },
    previewAction() {
      this.loadingTable = true;
      this.statusPreviewButton = true;
      if (this.$refs.form.validate()) {
        this.previewTable = true;
        this.dataTable.splice(0, this.dataTable.length);
        this.$store
          .dispatch("getListPreviewJL", this.report)
          .then((response) => {
            if (response.length !== 0) {
              response.map((res) => {
                let tc = [];
                if (res.tcodeList === null) {
                  for (let i = 0; i < this.getTCode.length; i++) {
                    let data = {
                      value: "",
                    };
                    tc.push(data);
                  }
                } else {
                  if (res.tcodeList.length < this.getTCode.length) {
                    tc = res.tcodeList;
                    let sum = this.getTCode.length - res.tcodeList.length;
                    for (let e = 0; e < sum; e++) {
                      let data = {
                        value: "",
                      };
                      tc.push(data);
                    }
                  } else {
                    tc = res.tcodeList;
                  }
                }
                let data = {
                  transDate: res.transDate,
                  periodeTrans: res.transPeriod,
                  reff: res.reff,
                  transType: res.transType1,
                  jeType: res.jeType,
                  userId: res.userId,
                  postingNo: res.postingNo,
                  accountCode: res.accountCode,
                  accountName: res.accountName,
                  debitCredit: res.balance,
                  amount: setNumberFormat(res.amount),
                  description: res.description,
                  tc: tc,
                };
                this.dataTable.push(data);
                this.statusPreviewButton = false;
                this.errorState = false;
                this.loadingTable = false;
              });
            } else {
              this.statusPreviewButton = false;
              this.errorState = false;
              this.loadingTable = false;
            }
          })
          .catch(() => {
            this.loadingTable = false;
            this.errorState = true;
          });
      }
    },
  },
  created() {
    this.getPostingNumData();
    this.getTCodeData();
    // await this.getPeriodData();
    // this.periodItems = await this.getOpenPeriod;
  },
};
</script>

<style src="../styles/report.scss" lang="scss" />
