import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const roleModule = {
  state: {
    role: [],
  },
  mutations: {
    setRole(state, payload) {
      state.role = payload;
    },
  },
  actions: {
    async getRoleData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/role");
        let data = res.data.data;
        commit("setRole", data);
      } catch (error) {
        console.log(error);
      }
    },
    async addRoleData({ commit, dispatch }, payload) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + "/role", {
          code: payload.code,
          name: payload.name,
        });
        dispatch("getRoleData");
        commit("showSnackbarAdd", true);
      } catch (error) {
        console.log(error);
      }
    },
    async editRoleData({ commit, dispatch }, payload) {
      try {
        await axios.put(process.env.VUE_APP_API_URL + "/role/" + payload.id, {
          code: payload.code,
          name: payload.name,
        });
        dispatch("getRoleData");
        commit("showSnackbarEdit", true);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteRole({ commit, dispatch }, id) {
      try {
        await axios.delete(process.env.VUE_APP_API_URL + "/role/" + id);
        dispatch("getRoleData");
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getRole(state) {
      return state.role;
    },
  },
};

export default roleModule;
