<template>
  <v-container>
    <v-card class="report-type-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="report-card-title">Tipe Laporan</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="7">
            <base-button
              :disabled="listPermission[0] === false"
              @add="showAddDialog"
            >
              <template v-slot:btnText>
                <span>Tambah Tipe Laporan</span>
              </template></base-button
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pa-7">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="getReportType"
            :loading="isLoading"
            loading-text="Sedang memuat data..."
            :search="search"
            class="report-type-table"
            no-results-text="Data tidak ditemukan"
            :no-data-text="noDataText"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#E89F3C"
                    x-small
                    height="24"
                    min-width="24"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleEditItem(item)"
                    :disabled="listPermission[1] === false"
                  >
                    <v-icon color="white" size="14">mdi-pencil</v-icon>
                  </v-btn>
                  <!-- <v-btn
                    color="#CE5542"
                    x-small
                    height="24"
                    min-width="24"
                    class="ml-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleDeleteItem(item)"
                    :disabled="listPermission[2] === false"
                  >
                    <v-icon color="white" size="14">mdi-delete</v-icon>
                  </v-btn> -->
                </template>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- Add Dialog -->
    <v-dialog
      v-model="addReportTypeDialog"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Tambah Tipe Laporan</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeReportTypeAddDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="addForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleAddReportType"
          >
            <v-row class="mt-6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="input.code"
                  :rules="reportTypeCodeRules.concat(checkAddCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="input.name"
                  :rules="reportTypeNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeReportTypeAddDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Add Dialog -->
    <!-- Add Dialog -->
    <v-dialog
      v-model="editReportTypeDialog"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Ubah Tipe Laporan</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeReportTypeEditDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="editForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleEditReportType"
          >
            <v-row class="mt-6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="reportType.code"
                  :rules="reportTypeCodeRules.concat(checkEditCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="reportType.name"
                  :rules="reportTypeNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeReportTypeEditDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Add Dialog -->
    <!-- Delete Dialog -->
    <!-- <v-dialog
      v-model="dialogDelete"
      transition="dialog-top-transition"
      max-width="350"
    >
      <v-card color="pt-8">
        <BodyCardDelete />
        <v-card-actions class="justify-center">
          <v-btn
            class="mr-2 btn-close-dialog"
            tile
            depressed
            @click="dialogDelete = false"
            >Batal</v-btn
          >
          <v-btn class="btn-confirm" tile depressed @click="confirmDeleteItem"
            >Hapus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- End Delete Dialog -->
    <v-snackbar
      v-model="getSnackbarAdd"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAddText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAdd = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarEditText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarDelete"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarDeleteText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarDelete = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import BaseButton from "./base/BaseButton.vue";
// import BodyCardDelete from "./AppCardBodyDelete.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ReportType",
  components: { BaseButton },
  data: () => ({
    search: "",
    isLoading: true,
    headers: [
      {
        text: "Kode",
        align: "start",
        sortable: true,
        value: "code",
        divider: true,
        width: "15%",
      },
      { text: "Nama", value: "name", sortable: true, divider: true },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "20%",
      },
    ],
    noDataText: "Tidak ada data tidak tersedia",
    listPermission: [false, false, false, false],
    valid: true,
    addReportTypeDialog: false,
    editReportTypeDialog: false,
    input: {
      code: "",
      name: "",
    },
    reportType: {
      id: null,
      code: "",
      name: "",
    },
    // Validation
    reportTypeCodeRules: [
      (v) => !!v || "Kode Tipe Laporan harus diisi",
      (v) =>
        (v && v.length <= 5) ||
        "Kode Tipe Laporan maksimal terdiri dari 5 karakter",
    ],
    reportTypeNameRules: [(v) => !!v || "Nama Tipe Laporan harus diisi"],
    dialogDelete: false,
    // Snackbar
    snackbarAddText: "Data berhasil ditambahkan!",
    snackbarEditText: "Data berhasil diubah!",
    snackbarDeleteText: "Data berhasil dihapus!",
    timeout: 2500,
  }),
  computed: {
    ...mapGetters(["getReportType", "getListPermissionById"]),
    getSnackbarAdd: {
      get() {
        return this.$store.state.snackbarAdd;
      },
      set(v) {
        this.$store.commit("showSnackbarAdd", v);
      },
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
    getSnackbarDelete: {
      get() {
        return this.$store.state.snackbarDelete;
      },
      set(v) {
        this.$store.commit("showSnackbarDelete", v);
      },
    },
    getAllCode() {
      return this.getReportType.map((item) => item.code);
    },
    checkAddCodeRules() {
      return () =>
        this.getAllCode.includes(this.input.code) === false || "Kode sudah ada";
    },
    checkEditCodeRules() {
      return () =>
        this.getAllCode.includes(this.reportType.code) === false ||
        "Kode sudah ada";
    },
  },
  watch: {
    getReportType() {
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions(["getReportTypeData"]),
    handleEditItem(item) {
      this.reportType.id = item.id;
      this.reportType.code = item.code;
      this.reportType.name = item.name;
      this.editReportTypeDialog = true;
    },
    closeReportTypeEditDialog() {
      this.editReportTypeDialog = false;
    },
    closeReportTypeAddDialog() {
      this.addReportTypeDialog = false;
      this.$refs.addForm.reset();
    },
    showAddDialog() {
      this.addReportTypeDialog = true;
    },
    handleAddReportType() {
      if (this.$refs.addForm.validate()) {
        let data = {
          code: this.input.code,
          name: this.input.name,
        };
        this.$store.dispatch("addReportTypeData", data);
        this.addReportTypeDialog = false;
        this.$refs.addForm.reset();
      }
    },
    handleEditReportType() {
      if (this.$refs.editForm.validate()) {
        let data = {
          id: this.reportType.id,
          code: this.reportType.code,
          name: this.reportType.name,
        };
        this.$store.dispatch("editReportTypeData", data);
        this.editReportTypeDialog = false;
      }
    },
    // handleDeleteItem(item) {
    //   this.reportType.id = item.id;
    //   this.dialogDelete = true;
    // },
    // confirmDeleteItem() {
    //   this.$store.dispatch("deleteReportTypeData", this.reportType.id);
    //   this.dialogDelete = false;
    // },
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 38;
      });

      this.listPermission[1] = permissions.some((item) => {
        return item === 39;
      });

      this.listPermission[2] = permissions.some((item) => {
        return item === 40;
      });

      this.listPermission[3] = permissions.some((item) => {
        return item === 41;
      });
    },
  },
  async created() {
    await this.setListPermission();
    if (this.listPermission[3] === false) {
      this.isLoading = false;
      this.noDataText = "Anda tidak memiliki izin untuk mengakses data";
    } else {
      await this.getReportTypeData();
    }
  },
};
</script>

<style src="../styles/report-type.scss" lang="scss" />
