<template>
  <v-container>
    <v-snackbar
      v-model="getSnackbarAdd"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAddText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAdd = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarEditText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarDelete"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarDeleteText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarDelete = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="role-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="role-card-title">Peran</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <base-button
              :disabled="listPermission[0] === false"
              @add="showAddDialog"
            >
              <template v-slot:btnText>
                <span>Tambah Peran</span>
              </template></base-button
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pa-7">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="listPermission[3] === false ? roleItems : getRole"
            :search="search"
            :loading="isLoading"
            loading-text="Sedang memuat data..."
            no-results-text="Data tidak ditemukan"
            :no-data-text="noDataText"
            hide-default-footer
            disable-pagination
            class="role-table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#E89F3C"
                    x-small
                    height="24"
                    min-width="24"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleEditItem(item)"
                    :disabled="listPermission[1] === false"
                  >
                    <v-icon color="white" size="14">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    color="#CE5542"
                    x-small
                    height="24"
                    min-width="24"
                    class="ml-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleDeleteItem(item)"
                    :disabled="listPermission[2] === false"
                  >
                    <v-icon color="white" size="14">mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- Add Dialog -->
    <v-dialog
      v-model="addRoleDialog"
      transition="dialog-top-transition"
      persistent
      width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Tambah Peran</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeAddDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-1 pl-7">
          <v-form
            v-model="valid"
            ref="addForm"
            lazy-validation
            @submit.prevent="handleAddRole"
          >
            <v-row class="mt-6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="input.code"
                  :rules="roleCodeRules.concat(checkAddCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="input.name"
                  :rules="roleNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn @click="closeAddDialog" class="btn-close" tile depressed>
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save ml-4"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Add Dialog -->
    <!-- Edit Dialog -->
    <v-dialog
      v-model="editRoleDialog"
      transition="dialog-top-transition"
      persistent
      width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Ubah Peran</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="editRoleDialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-1 pl-7">
          <v-form
            v-model="valid"
            ref="editForm"
            lazy-validation
            @submit.prevent="handleEditRole"
          >
            <v-row class="mt-6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="role.code"
                  :rules="roleCodeRules.concat(checkEditCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="role.name"
                  :rules="roleNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="editRoleDialog = false"
                class="btn-close"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save ml-4"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Edit Dialog -->
    <!-- Delete Dialog -->
    <v-dialog
      v-model="dialogDelete"
      transition="dialog-top-transition"
      max-width="350"
    >
      <v-card color="pt-8">
        <BodyCardDelete />
        <v-card-actions class="justify-center">
          <v-btn
            class="btn-close-dialog mr-4"
            @click="dialogDelete = false"
            tile
            depressed
            >Batal</v-btn
          >
          <v-btn class="btn-confirm" @click="confirmDeleteItem" tile depressed
            >Hapus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Delete Dialog -->
  </v-container>
</template>

<script>
import BaseButton from "./base/BaseButton.vue";
import BodyCardDelete from "./AppCardBodyDelete.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Role",
  components: { BaseButton, BodyCardDelete },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    isLoading: true,
    search: "",
    listPermission: [false, false, false, false],
    roleItems: [],
    noDataText: "Tidak ada data yang tersedia",
    headers: [
      {
        text: "Kode",
        align: "start",
        sortable: true,
        value: "code",
        divider: true,
        width: "15%",
      },
      { text: "Nama", value: "name", sortable: true, divider: true },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "20%",
      },
    ],
    valid: true,
    input: {
      code: "",
      name: "",
    },
    role: {
      id: null,
      code: "",
      name: "",
    },
    roleCode: "",
    addRoleDialog: false,
    editRoleDialog: false,
    dialogDelete: false,
    // Validation rules
    roleCodeRules: [
      (v) => !!v || "Kode role harus diisi",
      (v) => (v && v.length === 3) || "Kode role harus terdiri dari 3 karakter",
    ],
    roleNameRules: [(v) => !!v || "Nama role harus diisi"],
    // Snackbar
    snackbarAddText: "Data berhasil ditambahkan!",
    snackbarEditText: "Data berhasil diubah!",
    snackbarDeleteText: "Data berhasil dihapus!",
    timeout: 2500,
  }),
  computed: {
    ...mapGetters(["getRole", "getListPermissionById"]),
    getAllRoleCodes() {
      return this.getRole.map((item) => item.code);
    },
    getSomeRoleCodes() {
      let codes = this.getAllRoleCodes;
      let index = codes.indexOf(this.roleCode);
      if (index !== -1) {
        codes.splice(index, 1);
      }
      return codes;
    },
    checkAddCodeRules() {
      return () =>
        this.getAllRoleCodes.includes(this.input.code) === false ||
        "Kode role sudah ada";
    },
    checkEditCodeRules() {
      return () =>
        this.getSomeRoleCodes.includes(this.role.code) === false ||
        "Kode role sudah ada";
    },
    getSnackbarAdd: {
      get() {
        return this.$store.state.snackbarAdd;
      },
      set(v) {
        this.$store.commit("showSnackbarAdd", v);
      },
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
    getSnackbarDelete: {
      get() {
        return this.$store.state.snackbarDelete;
      },
      set(v) {
        this.$store.commit("showSnackbarDelete", v);
      },
    },
  },
  watch: {
    getRole() {
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions(["getRoleData"]),
    selectItem(value) {
      this.itemPerPage = value;
    },
    showAddDialog() {
      this.addRoleDialog = true;
    },
    handleAddRole() {
      if (this.$refs.addForm.validate()) {
        let data = {
          code: this.input.code,
          name: this.input.name,
        };
        this.$store.dispatch("addRoleData", data);
        this.addRoleDialog = false;
        this.$refs.addForm.reset();
      }
    },
    closeAddDialog() {
      this.addRoleDialog = false;
      this.$refs.addForm.reset();
    },
    handleEditItem(item) {
      this.role.id = item.id;
      this.role.code = item.code;
      this.role.name = item.name;
      this.roleCode = item.code;
      this.editRoleDialog = true;
    },
    handleEditRole() {
      if (this.$refs.editForm.validate()) {
        let data = {
          id: this.role.id,
          code: this.role.code,
          name: this.role.name,
        };
        this.$store.dispatch("editRoleData", data);
        this.editRoleDialog = false;
      }
    },
    handleDeleteItem(item) {
      this.role.id = item.id;
      this.dialogDelete = true;
    },
    confirmDeleteItem() {
      this.$store.dispatch("deleteRole", this.role.id);
      this.dialogDelete = false;
    },
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 5;
      });

      this.listPermission[1] = permissions.some((item) => {
        return item === 6;
      });

      this.listPermission[2] = permissions.some((item) => {
        return item === 7;
      });

      this.listPermission[3] = permissions.some((item) => {
        return item === 8;
      });
    },
  },
  async created() {
    await this.setListPermission();
    if (this.listPermission[3] === false) {
      this.isLoading = false;
      this.noDataText = "Anda tidak memliki izin untuk mengakses data";
    } else {
      await this.getRoleData();
    }
  },
};
</script>

<style src="../styles/role.scss" lang="scss" />
