<template>
  <v-app>
    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  methods: {
    interceptorRequest() {
      axios.interceptors.request.use(
        (config) => {
          let token = localStorage.getItem("token");
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  created() {
    this.interceptorRequest();
  },
};
</script>

<style lang="scss" scoped>
.main {
  background-color: #f9f9f9 !important;
}
</style>
