<template>
  <v-container>
    <!-- <v-snackbar
      v-model="getSnackbarAlert"
      :timeout="timeout"
      color="warning"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAlertText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAlert = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->
    <pop-up-warning
      :isShow="isShowWarning"
      :text="textWarning"
      @close-modal="closeModalWarning"
    >
    </pop-up-warning>
    <v-card class="journal-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="card-title">Tambah Transaksi Baru</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-form
        @submit.prevent="handleAddAccount"
        ref="addForm"
        v-model="valid"
        lazy-validation
      >
        <v-row class="ml-n16 pr-8">
          <v-col md="6">
            <v-row class="mt-4">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Referensi</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="journal.code"
                  :rules="transactionCodeRules"
                  placeholder="Masukkan nomor referensi"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Periode</span>
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="journal.period"
                  :items="periodItems"
                  :item-text="(item) => item.year + item.month"
                  return-object
                  :rules="periodRules"
                  placeholder="Pilih"
                  :no-data-text="noDataText"
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                  @change="onChangeDate(journal.date)"
                >
                  <template v-slot:item="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ getMonth(item.month) || "Audit" }} {{ item.year }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n7">
              <v-col md="4" class="mt-3 text-right">
                <span class="input-title">Tanggal</span>
              </v-col>
              <v-col md="8">
                <date-picker
                  v-model="journal.date"
                  type="date"
                  format="YYYYMMDD"
                  @change="onChangeDate"
                  @clear="onClearDate"
                  :disabled="journal.period == '' ? true : false"
                  :popup-style="{
                    top: '365px',
                    left: '420px',
                  }"
                  placeholder="Masukkan tanggal"
                  class="journal-datepicker mt-2"
                >
                </date-picker>
                <v-alert
                  v-show="dateAlert"
                  transition="slide-y-transition"
                  class="date-alert"
                  >{{ dateAlertText }}</v-alert
                >
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Penginput</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="journal.userInput"
                  :readonly="true"
                  :loading="userInputLoading"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6">
            <v-row class="mt-4">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Tipe Transaksi</span>
              </v-col>
              <v-col md="8">
                <v-combobox
                  v-model="journal.transactionType"
                  :items="transactionTypeItems"
                  item-value="id"
                  item-text="name"
                  :rules="transactionTypeRules"
                  :no-data-text="noDataText"
                  @input="limitSelectTransType"
                  :error-messages="transTypeErrMessage"
                  placeholder="Pilih"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Tipe Jurnal</span>
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="journal.journalType"
                  :items="journalTypeItems"
                  item-value="id"
                  item-text="name"
                  :rules="journalTypeRules"
                  placeholder="Pilih"
                  :no-data-text="noDataText"
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Uraian</span>
              </v-col>
              <v-col md="8">
                <v-textarea
                  v-model="journal.description"
                  :rules="descriptionRules"
                  placeholder="Masukkan Uraian"
                  background-color="#FAFAFA"
                  height="115"
                  no-resize
                  outlined
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row> -->
          </v-col>
        </v-row>
        <v-card-title class="mt-n4 pl-7">
          <span class="card-title">Detail Transaksi</span>
        </v-card-title>
        <v-card-text>
          <v-row class="transaction-details-table-outer">
            <v-col>
              <table class="transaction-details-table">
                <thead>
                  <th class="text-left" style="width: 160px">Kode Akun</th>
                  <th class="text-left" style="width: 210px">Nama</th>
                  <th class="text-left" style="width: 220px">Debit</th>
                  <th class="text-left" style="width: 220px">Kredit</th>
                  <th class="text-left" style="width: 230px">Deskripsi</th>
                  <th
                    v-for="item in getTCode"
                    :key="item.id"
                    class="text-left"
                    style="width: 300px"
                  >
                    {{ item.name }}
                  </th>
                  <th class="text-center" style="width: 85px">
                    <v-btn @click="addNewAccountRow" icon>
                      <v-icon color="#3E9FF9">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <v-row no-gutters>
                        <input v-model="accountOne.id" type="text" hidden />
                        <v-col md="6">
                          <input
                            v-model="accountOneCode"
                            :readonly="true"
                            class="code-account-input"
                          />
                        </v-col>
                        <v-col md="4">
                          <v-btn
                            @click="showDialogOne"
                            class="btn-add-account ml-7"
                            tile
                            depressed
                          >
                            <v-icon color="white" size="25"
                              >mdi-plus-circle</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </td>
                    <td>
                      <input
                        v-model="accountOneName"
                        :readonly="true"
                        class="name-input"
                      />
                    </td>
                    <td class="text-center">
                      <input class="rupiah-input" placeholder="Rp" disabled />
                      <vue-numeric
                        v-model="accountOne.debet"
                        :empty-value="0"
                        separator="."
                        v-bind:precision="2"
                        class="debit-input"
                      >
                      </vue-numeric>
                    </td>
                    <td class="text-center">
                      <input class="rupiah-input" placeholder="Rp" disabled />
                      <vue-numeric
                        v-model="accountOne.kredit"
                        :empty-value="0"
                        separator="."
                        v-bind:precision="2"
                        class="debit-input"
                      >
                      </vue-numeric>
                    </td>
                    <td class="text-center">
                      <input v-model="accountOne.memo" class="memo-input" />
                    </td>
                    <td v-for="(item, i) in accountOne.tbITCode" :key="item.id">
                      <input v-model="item.id" hidden />
                      <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs"> -->
                      <v-select
                        v-model="item.value"
                        :items="{ ...dataTCode[i] }.detail"
                        item-value="value"
                        item-text="name"
                        class="mt-6"
                        style="font-size: 14px"
                        append-icon="mdi-chevron-down"
                        outlined
                        dense
                        attach
                      >
                        <template v-slot:prepend-item>
                          <v-list-item class="mt-n5 mb-n7">
                            <v-list-item-content>
                              <v-text-field
                                v-model="dataSearch[i].searchTcode"
                                placeholder="Search"
                                @input="actionSearchTcode(i)"
                              ></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                      <!-- </div>
                        </template>
                        <span>{{ getTcodeDetailName(item.value) }}</span>
                      </v-tooltip> -->
                      <!-- <input v-model="item.value" class="tcode-input" /> -->
                    </td>
                  </tr>
                  <tr v-for="(input, index) in account" :key="index">
                    <td class="text-center">
                      <v-row no-gutters>
                        <input v-model="input.id" type="text" hidden />
                        <v-col md="6">
                          <input
                            :value="accountTwo[index].code"
                            :readonly="true"
                            class="code-account-input"
                          />
                        </v-col>
                        <v-col md="4">
                          <v-btn
                            @click="showDialogTwo(index)"
                            class="btn-add-account ml-7"
                            tile
                            depressed
                          >
                            <v-icon color="white" size="25"
                              >mdi-plus-circle</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </td>
                    <td>
                      <input
                        :value="accountTwo[index].name"
                        :readonly="true"
                        class="name-input"
                      />
                    </td>
                    <td class="text-center">
                      <input class="rupiah-input" placeholder="Rp" disabled />
                      <vue-numeric
                        v-model="input.debet"
                        :empty-value="0"
                        separator="."
                        v-bind:precision="2"
                        class="debit-input"
                      >
                      </vue-numeric>
                    </td>
                    <td class="text-center">
                      <input class="rupiah-input" placeholder="Rp" disabled />
                      <vue-numeric
                        v-model="input.kredit"
                        :empty-value="0"
                        separator="."
                        v-bind:precision="2"
                        class="debit-input"
                      >
                      </vue-numeric>
                    </td>
                    <td class="text-center">
                      <input v-model="input.memo" class="memo-input" />
                    </td>
                    <template v-for="(item, i) in input.tbITCode">
                      <td :key="i">
                        <input v-model="item.id" hidden />
                        <!-- <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs"> -->
                        <v-select
                          v-model="item.value"
                          :items="{ ...dataTCodeTwo[index][i] }.detail"
                          item-value="value"
                          item-text="name"
                          class="mt-6"
                          style="font-size: 14px"
                          append-icon="mdi-chevron-down"
                          outlined
                          dense
                          @click="clickTcodeTwo"
                        >
                          <template v-slot:prepend-item>
                            <v-list-item class="mt-n5 mb-n5">
                              <v-list-item-content>
                                <v-text-field
                                  v-model="dataSearchTwo[index][i].searchTcode"
                                  placeholder="Search"
                                  @input="actionSearchTcodeTwo(i, index)"
                                ></v-text-field>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                        <!-- </div>
                          </template>
                          <span>{{ getTcodeDetailName(item.value) }}</span>
                        </v-tooltip> -->
                        <!-- <input v-model="item.value" class="tcode-input" /> -->
                      </td>
                    </template>
                    <td v-if="index > 0" class="text-center">
                      <v-btn @click="removeAccountRow" icon>
                        <v-icon color="#EB7765"
                          >mdi-minus-circle-outline</v-icon
                        >
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td class="body-2 text-right font-weight-black">Total</td>
                    <td class="text-right">
                      Rp. {{ formatRupiah(getDebetTotal) }}
                    </td>
                    <td class="text-right">
                      Rp. {{ formatRupiah(getKreditTotal) }}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td class="body-2 text-right font-weight-black">
                      Different
                    </td>
                    <td class="text-right">
                      Rp. {{ formatRupiah(getOutOfBalance) }}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
          <!-- <v-row justify="space-between" class="mr-n4">
            <v-col md="3" class="ml-4 mt-3">
              <span class="row-title-img">Gambar</span>
            </v-col>
            <v-col md="3" class="mr-1">
              <label>
                <v-btn
                  @click="selectFile"
                  class="btn-upload"
                  color="#F1FFEF"
                  tile
                  depressed
                >
                  <v-icon size="20" color="#6BC282">mdi-cloud-upload</v-icon>
                  <span class="text-capitalize ml-2">Lampirkan Gambar</span>
                </v-btn>
                <input
                  ref="file"
                  type="file"
                  multiple
                  @change="onFileChange"
                  style="visibility: hidden"
                />
              </label>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-n6">
            <div
              v-if="isOnFileChange === false"
              class="box-img-default"
              justify="center"
            >
              <v-col md="2">
                <v-img
                  :src="imgDefault"
                  width="60"
                  height="50"
                  class="mt-2 rounded"
                ></v-img>
              </v-col>
            </div>
            <v-row v-else class="box-img ml-7 mr-7 mt-1" justify="center">
              <v-col v-for="(img, index) in journal.img" :key="index" md="3">
                <div class="container-img">
                  <v-img
                    :src="img"
                    width="246"
                    height="138"
                    class="rounded"
                  ></v-img>
                  <v-btn class="btn-download" icon>
                    <v-icon color="white">mdi-download</v-icon>
                  </v-btn>
                  <v-btn @click="zoomImage(img.src)" class="btn-view" icon>
                    <v-icon color="white">mdi-eye</v-icon>
                  </v-btn>
                  <v-btn @click="deleteImage(img.id)" class="btn-delete" icon>
                    <v-icon color="white">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-row> -->
        </v-card-text>
        <v-card-actions class="justify-end mt-4 mr-6">
          <v-btn @click="$router.go(-1)" class="btn-cancel mr-2" tile depressed>
            Batal
          </v-btn>
          <v-btn
            type="submit"
            :disabled="!valid"
            class="btn-save-account"
            tile
            depressed
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <!-- <v-dialog v-model="dialogZoomImage" max-width="850">
      <v-row>
        <v-col md="2">
          <v-btn
            @click="dialogZoomImage = false"
            color="white"
            min-width="20"
            width="20"
            height="30"
            class="rounded-xl"
          >
            <v-icon size="16" color="black" dark>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <img :src="imgZoom" width="800" height="auto" />
    </v-dialog> -->
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="subtitle-1">Browse Kode Akun</span>
          <v-spacer></v-spacer>
          <v-btn small text @click="dialog = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="mb-n8">
          <v-row justify="end">
            <v-col md="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                placeholder="search"
                outlined
                dense
                >Search</v-text-field
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-card class="mt-2" elevation="1">
            <v-data-table
              :headers="headers"
              :items="getAccountActive"
              :search="search"
              class="account-table"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="#E46D47" x-small height="24" min-width="20">
                  <v-icon color="white" size="16" @click="addAccountCode(item)">
                    mdi-plus-circle
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import VueNumeric from "vue-numeric";
import { mapActions, mapGetters } from "vuex";
import PopUpWarning from "./base/popup/PopUpWarning.vue";

export default {
  name: "JournalAdd",
  components: { DatePicker, VueNumeric, PopUpWarning },
  data: () => ({
    valid: true,
    months: [
      { value: "01", name: "Januari" },
      { value: "02", name: "Februari" },
      { value: "03", name: "Maret" },
      { value: "04", name: "April" },
      { value: "05", name: "Mei" },
      { value: "06", name: "Juni" },
      { value: "07", name: "Juli" },
      { value: "08", name: "Agustus" },
      { value: "09", name: "September" },
      { value: "10", name: "Oktober" },
      { value: "11", name: "November" },
      { value: "12", name: "Desember" },
    ],
    periodItems: [],
    journalTypeItems: [],
    transactionTypeItems: [],
    journal: {
      code: "",
      date: "",
      period: "",
      userInput: "",
      transactionType: [],
      journalType: "",
      // description: "",
      // img: [],
    },
    // img: "",
    // imgDefault: require("@/assets/images/default-2.png"),
    // imgZoom: null,
    dialog: false,
    dialogZoomImage: false,
    dialogAccountOne: false,
    dialogAccountTwo: false,
    showNewAccountRow: false,
    // isOnFileChange: false,
    dateAlert: false,
    dateAlertText: "",
    userInputLoading: true,
    // Datatable
    headers: [
      {
        text: "Kode",
        align: "start",
        sortable: false,
        value: "code",
        divider: true,
        width: "10%",
      },
      { text: "Nama", value: "name", divider: true, sortable: false },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    search: "",
    accountOne: {
      id: "",
      debet: "",
      kredit: "",
      memo: "",
      tbITCode: [],
    },
    accountOneCode: "",
    accountOneName: "",
    account: [
      {
        id: "",
        debet: "",
        kredit: "",
        memo: "",
        tbITCode: [],
      },
    ],
    accountTwo: [
      {
        code: "",
        name: "",
      },
    ],
    index: "",
    // Validation
    transTypeErrMessage: "",
    transactionCodeRules: [(v) => !!v || "Nomor Referensi harus diisi"],
    periodRules: [(v) => !!v || "Periode harus dipilih"],
    transactionTypeRules: [
      (v) => (v && v.length > 0) || "Tipe transaksi harus dipilih",
    ],
    journalTypeRules: [(v) => !!v || "Tipe jurnal harus dipilih"],
    tCodeRules: [(v) => !!v || "Kode T harus dipilih"],
    // descriptionRules: [(v) => !!v || "Uraian harus diisi"],
    noDataText: "Tidak ada data yang tersedia",
    // Snackbar
    timeout: 2500,
    snackbarAlertText: "Nomor Referensi sudah ada!",
    isShowWarning: false,
    textWarning: "",
    timer: null,
    isSubmit: false,
    // searchTcode: "",
    dataTCode: [],
    dataTCodeTwo: [[{}]],
    dataSearch: [],
    dataSearchTwo: [],
    checkClick: false,
  }),
  watch: {
    getProfile() {
      this.userInputLoading = false;
    },
    getSnackbarAlert(val) {
      if (val === true) {
        this.isShowWarning = val;
        this.textWarning = this.snackbarAlertText;
        this.timer = setTimeout(() => {
          this.isShowWarning = false;
          this.getSnackbarAlert = false;
        }, 2500);
      }
    },
  },
  computed: {
    ...mapGetters([
      "getAccount",
      "getAccountActive",
      "getProfile",
      "getPeriod",
      // "getOpenPeriod",
      "getJournalType",
      "getTransactionType",
      "getTCode",
      "getNomorReferensi",
    ]),
    getSnackbarAlert: {
      get() {
        return this.$store.state.snackbarAlert;
      },
      set(v) {
        this.$store.commit("showSnackbarAlert", v);
      },
    },
    getDebetTotal() {
      let total;
      let accountOneDebet;
      let accountTwoDebet = [];
      const num = 0;
      if (this.accountOne.debet !== "") {
        accountOneDebet = this.accountOne.debet;
      } else {
        accountOneDebet = 0;
      }
      if (this.account.length === 1) {
        if (this.account[0].debet !== "") {
          accountTwoDebet.push(this.account[0].debet);
        } else {
          accountTwoDebet.push(num);
        }
        total = accountOneDebet + accountTwoDebet[0];
      } else {
        let accountTwoDebetTotal;
        for (let i = 0; i < this.account.length; i++) {
          if (this.account[i].debet !== "") {
            accountTwoDebet.push(this.account[i].debet);
          } else {
            accountTwoDebet.push(num);
          }
        }
        accountTwoDebetTotal = accountTwoDebet.reduce((acc, val) => {
          return acc + val;
        }, 0);
        total = accountOneDebet + accountTwoDebetTotal;
      }
      if (
        this.accountOne.debet === "" &&
        this.account[0].debet === "" &&
        this.account.length === 1
      ) {
        return 0;
      } else {
        return total;
      }
    },
    getKreditTotal() {
      let total;
      let accountOneKredit;
      let accountTwoKredit = [];
      const num = 0;
      if (this.accountOne.kredit !== "") {
        accountOneKredit = this.accountOne.kredit;
      } else {
        accountOneKredit = 0;
      }
      if (this.account.length === 1) {
        if (this.account[0].kredit !== "") {
          accountTwoKredit.push(this.account[0].kredit);
        } else {
          accountTwoKredit.push(num);
        }
        total = accountOneKredit + accountTwoKredit[0];
      } else {
        let accountTwoKreditTotal;
        for (let i = 0; i < this.account.length; i++) {
          if (this.account[i].kredit !== "") {
            accountTwoKredit.push(this.account[i].kredit);
          } else {
            accountTwoKredit.push(num);
          }
        }
        accountTwoKreditTotal = accountTwoKredit.reduce((acc, val) => {
          return acc + val;
        }, 0);
        total = accountOneKredit + accountTwoKreditTotal;
      }
      if (
        this.accountOne.kredit === "" &&
        this.account[0].kredit === "" &&
        this.account.length === 1
      ) {
        return 0;
      } else {
        return total;
      }
    },
    getOutOfBalance() {
      this.checkValidDebetKredit();
      return this.getDebetTotal - this.getKreditTotal;
    },
  },
  methods: {
    ...mapActions([
      "getAccountData",
      "getAccountListActive",
      "getProfileData",
      "getPeriodData",
      "getJournalTypeData",
      "getTransactionTypeData",
      "getTCodeData",
      "getNomorReferensiData",
    ]),
    actionSearchTcode(i) {
      if (this.dataSearch[i].searchTcode == "") {
        this.dataTCode[i].detail = this.getTCode[i].detail;
      } else {
        this.getTCodeData();
        this.dataTCode[i].detail = this.getTCode[i].detail.filter((item) => {
          return (
            item.name
              .toLowerCase()
              .indexOf(this.dataSearch[i].searchTcode.toLowerCase()) > -1
          );
        });
      }
    },
    actionSearchTcodeTwo(i, index) {
      if (this.dataSearchTwo[index][i].searchTcode == "") {
        this.dataTCodeTwo[index][i].detail = this.getTCode[i].detail;
      } else {
        this.getTCodeData();
        this.dataTCodeTwo[index][i].detail = this.getTCode[i].detail.filter(
          (item) => {
            return (
              item.name
                .toLowerCase()
                .indexOf(
                  this.dataSearchTwo[index][i].searchTcode.toLowerCase()
                ) > -1
            );
          }
        );
      }
    },
    clickTcodeTwo() {
      if (this.checkClick == false) {
        this.dataTCodeTwo.splice(0, 1);
        this.getTCodeData();
        this.dataTCodeTwo.push(this.getTCode);
        this.checkClick = true;
      }
    },
    changeSearchTcode(i) {
      this.dataTCode[i].detail = this.getTCode[i].detail;
    },
    limitSelectTransType(e) {
      if (e.length > 2) {
        this.transTypeErrMessage = "Maksimal pilih 2 tipe transaksi";
      } else {
        this.transTypeErrMessage = "";
      }
    },
    getMonth(value) {
      return this.months
        .filter((e) => e.value === value)
        .map((item) => item.name)
        .toString();
    },
    // getTcodeDetailName(id) {
    //   let name = [];
    //   this.getTCode.map((el) => {
    //     if (el.detail.length > 0) {
    //       for (let i = 0; i < el.detail.length; i++) {
    //         if (el.detail[i].id === id) {
    //           name.push(el.detail[i].name);
    //         }
    //       }
    //     }
    //   });
    //   return name[0];
    // },
    onChangeDate(date) {
      if (!date) {
        this.dateAlert = true;
        this.dateAlertText = "Tanggal harus dipilih";
        this.valid = false;
        return;
      }
      let year = date.getFullYear();
      let month = moment(date).locale("id").format("MMMM");
      let yearPeriod = this.journal.period.year;
      let monthPeriod = this.getMonth(this.journal.period.month) || "Desember";
      if (year != yearPeriod && month == monthPeriod) {
        this.dateAlert = true;
        this.dateAlertText = "Tahun harus sesuai dengan periode";
        this.valid = false;
      } else if (month != monthPeriod && year == yearPeriod) {
        this.dateAlert = true;
        this.dateAlertText = "Bulan harus sesuai dengan periode";
        this.valid = false;
      } else if (year != yearPeriod && month != monthPeriod) {
        this.dateAlert = true;
        this.dateAlertText = "Bulan dan tahun harus sesuai dengan periode";
        this.valid = false;
      } else {
        this.dateAlert = false;
        this.valid = true;
      }
    },
    onClearDate(date) {
      if (!date) {
        this.dateAlert = true;
        this.dateAlertText = "Tanggal harus dipilih";
        this.valid = false;
      } else {
        this.dateAlert = false;
        this.valid = true;
      }
    },
    checkValidDebetKredit() {
      for (let i = 0; i < this.account.length; i++) {
        if (
          (this.account[i].debet === 0 && this.account[i].kredit === 0) ||
          (this.account[i].debet === 0 && this.account[i].kredit === "") ||
          (this.account[i].debet === "" && this.account[i].kredit === 0) ||
          (this.account[i].debet === "" && this.account[i].kredit === "")
        ) {
          this.valid = false;
          break;
        } else {
          this.valid = true;
        }
      }
    },
    showDialogOne() {
      this.dialog = true;
      this.dialogAccountOne = true;
    },
    showDialogTwo(index) {
      this.index = index;
      this.dialog = true;
      this.dialogAccountTwo = true;
    },
    addNewAccountRow() {
      let tbITCode = [];
      for (let i = 0; i < this.getTCode.length; i++) {
        tbITCode.push({ id: i + 1, value: "" });
      }
      this.accountTwo.push({
        code: "",
        name: "",
      });
      this.account.push({
        id: "",
        debet: "",
        kredit: "",
        memo: "",
        tbITCode: tbITCode,
      });
      let search = [];
      this.getTCode.map(() => {
        let data = {
          searchTcode: "",
        };
        search.push(data);
      });
      this.dataSearchTwo.push(search);
      this.getTCodeData();
      this.dataTCodeTwo.push(this.getTCode);
    },
    removeAccountRow() {
      this.account.pop();
      this.dataSearchTwo.pop();
      this.dataTCodeTwo.pop();
    },
    formatRupiah(value) {
      var rupiah = Intl.NumberFormat("id-ID", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);

      rupiah = rupiah === "-0,00" || rupiah === "0,00" ? "0" : rupiah;

      return rupiah;
    },
    // selectFile() {
    //   let fileInputElement = this.$refs.file;
    //   fileInputElement.click();
    // },
    // onFileChange(e) {
    //   let images = e.target.files;
    //   for (const i of Object.keys(images)) {
    //     this.journal.img.push({
    //       id: i,
    //       src: URL.createObjectURL(images[i]),
    //     });
    //   }
    //   this.img = e.target.files[0];
    //   this.isOnFileChange = true;
    // },
    // deleteImage(index) {
    //   let images = this.journal.img;
    //   images.splice(
    //     images.findIndex((item) => item.id === index),
    //     1
    //   );
    //   if (images.length === 0) {
    //     this.isOnFileChange = false;
    //   }
    // },
    // zoomImage(img) {
    //   this.imgZoom = img;
    //   this.dialogZoomImage = true;
    // },
    addAccountCode(item) {
      if (this.dialogAccountOne === true) {
        this.accountOne.id = item.id;
        this.accountOneCode = item.code;
        this.accountOneName = item.name;
        this.dialogAccountOne = false;
        this.dialog = false;
      } else if (this.dialogAccountTwo === true) {
        let index = this.index;
        this.accountTwo[index].code = item.code;
        this.accountTwo[index].name = item.name;
        for (let i = index; i < this.account.length; i++) {
          this.account[i].id = item.id;
          this.dialogAccountTwo = false;
          this.dialog = false;
        }
      }
    },
    handleAddAccount() {
      if (this.$refs.addForm.validate()) {
        let date = moment(this.journal.date).format("YYYY-MM-DD HH:mm:ss");
        let transactionDetail = [];
        let accountOneDebet;
        let accountOneKredit;
        if (this.accountOne.debet !== "") {
          accountOneDebet = this.accountOne.debet;
        } else {
          accountOneDebet = 0;
        }
        if (this.accountOne.kredit !== "") {
          accountOneKredit = this.accountOne.kredit;
        } else {
          accountOneKredit = 0;
        }
        let accountOne = {
          account: { id: this.accountOne.id },
          debet: accountOneDebet,
          kredit: accountOneKredit,
          memo: this.accountOne.memo,
          tblTCode: JSON.stringify(this.accountOne.tbITCode),
        };
        transactionDetail.push(accountOne);
        let accountTwo = {};
        for (let i = 0; i < this.account.length; i++) {
          let accountTwoDebet;
          let accountTwoKredit;
          if (this.account[i].debet !== "") {
            accountTwoDebet = this.account[i].debet;
          } else {
            accountTwoDebet = 0;
          }
          if (this.account[i].kredit !== "") {
            accountTwoKredit = this.account[i].kredit;
          } else {
            accountTwoKredit = 0;
          }
          accountTwo = {
            account: { id: this.account[i].id },
            debet: accountTwoDebet,
            kredit: accountTwoKredit,
            memo: this.account[i].memo,
            tblTCode: JSON.stringify(this.account[i].tbITCode),
          };
          transactionDetail.push(accountTwo);
        }
        let transactionType2;
        if (this.journal.transactionType.length > 1) {
          transactionType2 = { id: this.journal.transactionType[1].id };
        } else {
          transactionType2 = null;
        }
        let data = {
          user: { id: this.getProfile.id },
          journalType: { id: this.journal.journalType },
          transactionType: { id: this.journal.transactionType[0].id },
          transactionType2: transactionType2,
          periode: { id: this.journal.period.periodeId },
          code: this.journal.code,
          date: date,
          // note: this.journal.description,
          // attachment: this.img,
          transactionDetail: transactionDetail,
        };
        this.$store.dispatch("addTransaction", data);
        this.isSubmit = true;
      }
    },
    closeModalWarning() {
      this.isShowWarning = false;
      clearTimeout(this.timer);
      this.getSnackbarAlert = false;
    },
    confirmLeave() {
      return window.confirm(
        "Do you really want to leave? Changes you made may not be saved."
      );
    },
    confirmStay() {
      return !this.confirmLeave();
    },
    beforeWindowUnload(e) {
      e.preventDefault();
      e.returnValue = "";
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSubmit == false) {
      if (this.confirmStay()) {
        next(false);
      } else {
        next();
      }
    } else {
      next();
    }
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  async created() {
    await this.getAccountData();
    await this.getProfileData();
    await this.getPeriodData();
    await this.getJournalTypeData();
    await this.getTransactionTypeData();
    await this.getTCodeData();
    await this.getNomorReferensiData();
    await this.getAccountListActive();
    // this.periodItems = await this.getOpenPeriod;
    this.journal.code = await this.getNomorReferensi;
    this.periodItems = await this.getPeriod;
    this.journalTypeItems = await this.getJournalType;
    this.transactionTypeItems = await this.getTransactionType;
    this.journal.userInput = await this.getProfile.name;
    this.dataTCode = await this.getTCode;
    // this.dataTCodeTwo.push(this.getTCode);
    this.getTCode.map(() => {
      let data = {
        searchTcode: "",
      };
      this.dataSearch.push(data);
    });
    let search = [];
    this.getTCode.map(() => {
      let data = {
        searchTcode: "",
      };
      search.push(data);
    });
    this.dataSearchTwo.push(search);
    for (let i = 0; i < this.getTCode.length; i++) {
      this.accountOne.tbITCode.push({ id: i + 1, value: "" });
    }
    for (let i = 0; i < this.getTCode.length; i++) {
      this.account[0].tbITCode.push({ id: i + 1, value: "" });
    }

    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
};
</script>

<style src="../styles/journal-add.scss" lang="scss" />
