import Main from "@/views/Main.vue";
import Login from "@/views/auth/Login.vue";
import Dashboard from "@/components/Dashboard.vue";
import Setup from "@/views/Setup.vue";
import Transaction from "@/views/Transaction.vue";
import Report from "@/views/Report.vue";
import Information from "@/views/Information.vue";
// Setup
import User from "@/views/User.vue";
import UserList from "@/components/UserList.vue";
import UserAdd from "@/components/UserAdd.vue";
import UserEdit from "@/components/UserEdit.vue";
import Role from "@/components/Role.vue";
import RoleManagement from "@/components/RoleManagement.vue";
import CompanyInformation from "@/components/CompanyInformation.vue";
import AccountType from "@/components/AccountType.vue";
import AccountCategory from "@/components/AccountCategory.vue";
import AccountCode from "@/components/AccountCode.vue";
import ArusKasCategory from "@/components/ArusKasCategory.vue";
import ArusKasType from "@/components/ArusKasType.vue";
import TransactionType from "@/components/TransactionType.vue";
import JournalType from "@/components/JournalType.vue";
import TCode from "@/components/TCode.vue";
import Period from "@/components/Period.vue";
import ReportType from "@/components/ReportType.vue";
import ReportStatementsNetAssetList from "@/components/ReportStatementsNetAssetList.vue";
import ReportStatementsNetAssetEdit from "@/components/ReportStatementsNetAssetEdit.vue";
import ReportBalanceSheetsList from "@/components/ReportBalanceSheetsList.vue";
import ReportBalanceSheetsEdit from "@/components/ReportBalanceSheetsEdit.vue";
import ReportStatementsChangesNetAssetList from "@/components/ReportStatementsChangesNetAssetList.vue";
import ReportStatementsChangesNetAssetEdit from "@/components/ReportStatementsChangesNetAssetEdit.vue";
import ReportStatementsIncomeList from "@/components/ReportStatementsIncomeList.vue";
import ReportStatementsIncomeEdit from "@/components/ReportStatementsIncomeEdit.vue";
import ReportStatementsCashFlowList from "@/components/ReportStatementsCashFlowList";
import ReportStatementsCashFlowEdit from "@/components/ReportStatementsCashFlowEdit";
// import Configuration from "@/components/Configuration.vue";
// Transactions
import PeriodManagement from "@/components/PeriodManagement.vue";
import Journal from "@/views/Journal.vue";
import JournalList from "@/components/JournalList.vue";
import JournalDetail from "@/components/JournalDetail.vue";
import JournalAdd from "@/components/JournalAdd.vue";
import JournalEdit from "@/components/JournalEdit.vue";
// Reports
import ListingAccount from "@/components/ListingAccount.vue";
import ListingJournal from "@/components/ListingJournal.vue";
import ReportTrialBalance from "@/components/ReportTrialBalance.vue";
import ReportPensionFundFinancial from "@/components/ReportPensionFundFinancial.vue";
import ProfileEdit from "@/components/ProfileEdit.vue";
import NetoAssetChange from "@/components/NetoAssetChange.vue";

const routes = [
  {
    path: "/",
    redirect: "/main/beranda",
  },
  {
    path: "/login",
    name: "Login",
    meta: { requiresAuth: true },
    component: Login,
  },
  {
    path: "/main",
    name: "Main",
    meta: { requiresAuth: true },
    component: Main,
    redirect: "/main/dashboard",
    children: [
      {
        path: "beranda",
        name: "Beranda",
        component: Dashboard,
      },
      {
        path: "pengaturan",
        name: "Pengaturan",
        component: Setup,
        children: [
          {
            path: "pengguna",
            name: "Pengguna",
            component: User,
            children: [
              {
                path: "list",
                name: "List Pengguna",
                component: UserList,
              },
              {
                path: "tambah",
                name: "Tambah Pengguna",
                component: UserAdd,
              },
              {
                path: ":id/ubah",
                name: "Ubah Pengguna",
                component: UserEdit,
              },
            ],
          },
          {
            path: "peran",
            name: "Peran",
            component: Role,
          },
          {
            path: "manajemen-peran",
            name: "Manajemen Peran",
            component: RoleManagement,
          },
          {
            path: "informasi-perusahaan",
            name: "Informasi Perusahaan",
            component: CompanyInformation,
          },
          {
            path: "tipe-akun",
            name: "Tipe Akun",
            component: AccountType,
          },
          {
            path: "kategori-akun",
            name: "Kategori Akun",
            component: AccountCategory,
          },
          {
            path: "tipe-arus-kas",
            name: "Tipe Arus Kas",
            component: ArusKasType,
          },
          {
            path: "kategori-arus-kas",
            name: "Kategori Arus Kas",
            component: ArusKasCategory,
          },
          {
            path: "kode-akun",
            name: "Kode Akun",
            component: AccountCode,
          },
          {
            path: "tipe-transaksi",
            name: "Tipe Transaksi",
            component: TransactionType,
          },
          {
            path: "tipe-jurnal",
            name: "Tipe Jurnal",
            component: JournalType,
          },
          {
            path: "kode-t",
            name: "Kode T",
            component: TCode,
          },
          {
            path: "periode",
            name: "Periode",
            component: Period,
          },
          {
            path: "tipe-laporan",
            name: "Tipe Laporan",
            component: ReportType,
          },
          {
            path: "laporan",
            name: "Konfigurasi Laporan",
            component: Report,
            children: [
              {
                path: "statements-net-asset",
                name: "Statements Net Asset",
                component: ReportStatementsNetAssetList,
              },
              {
                path: "statements-net-asset/edit",
                name: "Statements Net Asset Edit",
                component: ReportStatementsNetAssetEdit,
              },
              {
                path: "balance-sheets",
                name: "Balance Sheets",
                component: ReportBalanceSheetsList,
              },
              {
                path: "balance-sheets/edit",
                name: "Balance Sheets Edit",
                component: ReportBalanceSheetsEdit,
              },
              {
                path: "statements-of-changes-in-net-asset",
                name: "Statements of Changes in Net Asset",
                component: ReportStatementsChangesNetAssetList,
              },
              {
                path: "statements-of-changes-in-net-asset/edit",
                name: "Statements of Changes in Net Asset Edit",
                component: ReportStatementsChangesNetAssetEdit,
              },
              {
                path: "statements-of-income",
                name: "Statements of Income",
                component: ReportStatementsIncomeList,
              },
              {
                path: "statements-of-income/edit",
                name: "Statements of Income Edit",
                component: ReportStatementsIncomeEdit,
              },
              {
                path: "statements-of-cash-flow",
                name: "Statements of Cash Flow",
                component: ReportStatementsCashFlowList,
              },
              {
                path: "statements-of-cash-flow/edit",
                name: "Statements of Cash Flow Edit",
                component: ReportStatementsCashFlowEdit,
              },
            ],
          },
          {
            path: "informasi",
            name: "informasi",
            component: Information,
            children: [
              {
                path: "perubahan-asset-neto",
                name: "Perubahan Asset Neto",
                component: NetoAssetChange,
              },
            ],
          },
          // {
          //   path: "laporan/statements-of-changes-in-net-asset",
          //   name: "Statements of Changes in Net Asset",
          //   component: ReportStatementsChangesNetAsset,
          // },
          // {
          //   path: "laporan/statements-of-income",
          //   name: "Statements of Income",
          //   component: ReportStatementsIncome,
          // },
          // {
          //   path: "laporan/statements-of-cash-flow",
          //   name: "Statements of Cash Flow",
          //   component: ReportStatementsCashFlow,
          // },
          // {
          //   path: "konfigurasi",
          //   name: "Konfigurasi",
          //   component: Configuration,
          // },
        ],
      },
      {
        path: "transaksi",
        name: "Transaksi",
        component: Transaction,
        children: [
          {
            path: "manajemen-periode",
            name: "Manajemen Periode",
            component: PeriodManagement,
          },
          {
            path: "jurnal",
            name: "Jurnal",
            component: Journal,
            children: [
              {
                path: "list",
                name: "List Jurnal",
                component: JournalList,
              },
              {
                path: ":id/detail",
                name: "Detail Jurnal",
                component: JournalDetail,
              },
              {
                path: "tambah",
                name: "Tambah Jurnal",
                component: JournalAdd,
              },
              {
                path: ":id/ubah",
                name: "Ubah Jurnal",
                component: JournalEdit,
              },
            ],
          },
        ],
      },
      {
        path: "laporan",
        name: "Laporan",
        component: Report,
        children: [
          {
            path: "account-listing",
            name: "Account Listing",
            component: ListingAccount,
          },
          {
            path: "journal-listing",
            name: "Journal Listing",
            component: ListingJournal,
          },
          {
            path: "trial-balance",
            name: "Trial Balance",
            component: ReportTrialBalance,
          },
          {
            path: "keuangan-dana-pensiun",
            name: "Laporan Keuangan Dana Pensiun",
            component: ReportPensionFundFinancial,
          },
        ],
      },
      {
        path: "profil/:id/ubah",
        name: "Ubah Profil",
        component: ProfileEdit,
      },
    ],
  },
];

export default routes;
