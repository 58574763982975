<template>
  <v-card color="#F4F7F9" class="pb-7" tile>
    <v-toolbar elevation="0">
      <v-toolbar-title class="dialog-title">Detail Pengguna</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn @click="closeDetailDialog" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card-title class="pl-6 mt-7">
        <v-row>
          <v-col>
            <span class="detail-card-title">Peran dan Departemen</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="detail-card mt-2 ml-6 mr-6 pb-4">
        <v-row class="pa-7">
          <v-col>
            <v-row>
              <v-col md="2" class="text-right">
                <span>Peran:</span>
              </v-col>
              <v-col md="10">
                <span>{{ role(getUserDetail.roleId) }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col md="2" class="text-right">
                <span>Departemen:</span>
              </v-col>
              <v-col md="10">
                <span>{{ department(getUserDetail.departmentId) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-card-title class="pl-6 mt-8">
        <v-row>
          <v-col>
            <span class="detail-card-title">Pribadi</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="detail-card mt-2 ml-6 mr-6 pb-4">
        <v-row class="pa-7">
          <v-col>
            <v-row>
              <v-col md="2" class="text-right">
                <span>Kode:</span>
              </v-col>
              <v-col md="10">
                <span>{{ getUserDetail.code }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col md="2" class="text-right">
                <span>Nama:</span>
              </v-col>
              <v-col md="10">
                <span>{{ getUserDetail.name }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col md="2" class="text-right">
                <span>Email:</span>
              </v-col>
              <v-col md="10">
                <span>{{ getUserDetail.email }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col md="2" class="text-right">
                <span>Telepon:</span>
              </v-col>
              <v-col md="10">
                <span>{{ getUserDetail.phone }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col md="2" class="text-right">
                <span>Alamat:</span>
              </v-col>
              <v-col md="10">
                <span>{{ getUserDetail.address }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-card-title class="pl-6 mt-8">
        <v-row>
          <v-col>
            <span class="detail-card-title">Keamanan</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="detail-card mt-2 ml-6 mr-6 pb-4">
        <v-row class="pa-7">
          <v-col>
            <v-row>
              <v-col md="2" class="text-right">
                <span>Kata Sandi:</span>
              </v-col>
              <v-col md="10">
                <span>{{ hidePassword }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserDetail",
  data: () => ({
    hidePassword: "",
  }),
  computed: {
    ...mapGetters(["getUserDetail", "getRole", "getDepartment"]),
  },
  watch: {
    getUserDetail() {
      let password = this.getUserDetail.password;
      this.hidePassword = password.replace(/./g, "*");
    },
  },
  methods: {
    ...mapActions(["getRoleData", "getDepartmentData"]),
    role(id) {
      return this.getRole
        .filter((e) => e.id === id)
        .map((item) => item.name)
        .toString();
    },
    department(id) {
      return this.getDepartment
        .filter((e) => e.id === id)
        .map((item) => item.name)
        .toString();
    },
    closeDetailDialog() {
      this.$store.commit("showDetailDialog", false);
    },
  },
  async created() {
    await this.getRoleData();
    await this.getDepartmentData();
  },
};
</script>

<style src="../styles/user-detail.scss" lang="scss" />
