import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const netoAssetChangeModule = {
  state: {
    assets: [],
  },
  mutations: {
    setNetoAssetChange(state, payload) {
      state.assets = payload;
    },
  },
  actions: {
    async getDataNetoAssetChange({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/perubahanAssetNeto"
        );
        let data = res.data.data;
        console.log(res);
        commit("setNetoAssetChange", data);
      } catch (error) {
        console.log(error.response);
      }
    },
    async addNetoAssetChange({ dispatch, commit }, payload) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + "/perubahanAssetNeto", {
          type: payload.type,
          name: payload.name,
          noUrut: payload.noUrut,
          isHeader: payload.isHeader,
          isCalculate: payload.isCalculate,
          account: payload.account,
        });
        dispatch("getDataNetoAssetChange");
        commit("showSnackbarAdd", true);
      } catch (error) {
        console.log(error);
      }
    },
    async editNetoAssetChange({ dispatch, commit }, payload) {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "/perubahanAssetNeto/" + payload.id,
          {
            type: payload.type,
            name: payload.name,
            noUrut: payload.noUrut,
            isHeader: payload.isHeader,
            isCalculate: payload.isCalculate,
            account: payload.account,
          }
        );
        dispatch("getDataNetoAssetChange");
        commit("showSnackbarEdit", true);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteNetoAssetChange({ dispatch, commit }, id) {
      try {
        await axios.delete(
          process.env.VUE_APP_API_URL + "/perubahanAssetNeto/" + id
        );
        dispatch("getDataNetoAssetChange");
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getNetoAssetChange(state) {
      return state.assets;
    },
  },
};

export default netoAssetChangeModule;
