import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../../router";

Vue.use(Vuex);

const userModule = {
  state: {
    user: [],
    userDetail: [],
    userById: [],
  },
  mutations: {
    setUsers(state, payload) {
      state.user = payload;
    },
    setUserDetail(state, payload) {
      state.userDetail = payload;
    },
    setUserById(state, payload) {
      state.userById = payload;
    },
  },
  actions: {
    async getUserData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/user");
        let data = res.data.data;
        commit("setUsers", data);
      } catch (error) {
        console.log(error);
      }
    },
    async getUserDetail({ commit }, id) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/user/" + id);
        let data = res.data.data;
        commit("setUserDetail", data);
        commit("showDetailDialog", true);
      } catch (error) {
        console.log(error);
      }
    },
    async getUserById({ commit }, id) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/user/" + id);
        let data = res.data.data;
        commit("setUserById", data);
      } catch (error) {
        console.log(error);
      }
    },
    async addUserData({ dispatch, commit }, payload) {
      try {
        let res = await axios.post(process.env.VUE_APP_API_URL + "/user", {
          unitId: payload.unitId,
          roleId: payload.roleId,
          departmentId: payload.departmentId,
          code: payload.code,
          name: payload.name,
          email: payload.email,
          phone: payload.phone,
          address: payload.address,
          password: payload.password,
        });
        if (res.status === 207) {
          commit("showSnackbarAlert", true);
        } else {
          router.push({ name: "List Pengguna" });
          commit("showSnackbarAdd", true);
          dispatch("getUserData");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async editUserData({ dispatch, commit }, payload) {
      try {
        let res = await axios.put(
          process.env.VUE_APP_API_URL + "/user/" + payload.id,
          {
            unitId: payload.unitId,
            roleId: payload.roleId,
            departmentId: payload.departmentId,
            code: payload.code,
            name: payload.name,
            email: payload.email,
            phone: payload.phone,
            address: payload.address,
            password: payload.password,
          }
        );
        if (res.status === 207) {
          commit("showSnackbarAlert", true);
        } else {
          router.push({ name: "List Pengguna" });
          commit("showSnackbarEdit", true);
          dispatch("getUserData");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteUser({ dispatch, commit }, id) {
      try {
        await axios.delete(process.env.VUE_APP_API_URL + "/user/" + id);
        dispatch("getUserData");
        commit("showDeleteDialog", false);
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getUsers(state) {
      let user = state.user.sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 0;
      });
      return user;
    },
    getUserDetail(state) {
      return state.userDetail;
    },
    getUserById(state) {
      return state.userById;
    },
  },
};

export default userModule;
