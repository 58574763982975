<template>
  <v-container>
    <v-card class="code-account-card mt-6 ml-6 mr-6">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="account-card-title">Akun (COA)</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7 pr-7">
        <v-row>
          <v-col md="8">
            <!-- <v-btn
              @click="addAccountParentDialog = true"
              :disabled="listPermission[0] === false"
              class="btn-add"
              tile
              depressed
            >
              <v-icon class="mr-1" size="20">mdi-plus</v-icon>
              Tambah Akun
            </v-btn> -->
            <base-button
              :disabled="listPermission[0] === false"
              @add="showAddDialog"
            >
              <template v-slot:btnText>
                <span>Tambah Akun</span>
              </template></base-button
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="mt-n2 pl-7 pr-7">
        <v-row>
          <v-col md="10">
            <v-row>
              <v-col md="4">
                <v-select
                  v-model="accountTypeId"
                  :items="accountTypeItems"
                  item-value="id"
                  item-text="name"
                  :loading="accountTypeLoading"
                  placeholder="Tipe Akun"
                  append-icon=""
                  outlined
                  dense
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#CCCCCC">mdi-filter</v-icon>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="4">
                <v-select
                  v-model="accountCategoryId"
                  :items="accountCategoryItems"
                  item-value="id"
                  item-text="name"
                  :loading="accountCategoryLoading"
                  placeholder="Kategori Akun"
                  append-icon=""
                  outlined
                  dense
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#CCCCCC">mdi-filter</v-icon>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="4">
                <v-select
                  v-model="accountStatusId"
                  :items="accountStatusItems"
                  item-value="id"
                  item-text="name"
                  placeholder="Status Akun"
                  append-icon=""
                  outlined
                  dense
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#CCCCCC">mdi-filter</v-icon>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="mt-n8 pl-7 pr-7">
        <v-row justify="space-between">
          <v-col md="4">
            <v-row>
              <v-col md="4" class="mr-n6">
                <span class="body-1">Tampilkan</span>
              </v-col>
              <v-col md="4">
                <v-select
                  :value="itemPerPage"
                  item-value="itemPerPage"
                  :items="itemsPerPage"
                  @change="selectItem"
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="4" class="ml-n2">
                <span class="body-1">Entri</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="search"
              @input="searchAccount"
              prepend-inner-icon="mdi-magnify"
              placeholder="Cari"
              class="rounded"
              background-color="#FAFAFA"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="mt-n7 pl-7 pr-7 pb-7">
        <v-col>
          <v-card class="coa-table-card pb-4" elevation="0">
            <v-data-table
              :headers="computedHeaders"
              :items="filteredAccounts"
              :options="{
                itemsPerPage: itemPerPage,
              }"
              :page.sync="page"
              @page-count="pageCount = $event"
              @current-items="currentItems"
              :loading="isLoading"
              :loading-text="loadingText"
              :no-data-text="noDataText"
              :footer-props="{
                'items-per-page-text': '',
                'disable-items-per-page': true,
                'items-per-page-options': [],
                'prev-icon': null,
                'next-icon': null,
              }"
              class="coa-table"
            >
              <template v-slot:[`item.code`]="{ item }">
                <span v-if="item.isHeader !== 0" class="font-weight-bold">{{
                  item.code
                }}</span>
                <span v-else>{{ item.code }}</span>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <span v-if="item.isHeader !== 0" class="font-weight-bold">{{
                  item.name
                }}</span>
                <span v-else>{{ item.name }}</span>
              </template>
              <template v-slot:[`item.accountTypeId`]="{ item }">
                {{ accountType(item.accountTypeId) }}
              </template>
              <template
                v-for="id in computedHeaders.length - 7"
                v-slot:[`item.accountCategoryId-`+id]="{ item }"
              >
                {{ accountCategory(item.accountCategoryId, id - 1) }}
              </template>
              <template v-slot:[`item.description`]="{ item }">
                <span v-if="item.description.length > 15">
                  {{ item.description.slice(0, 15) + "..." }}
                </span>
                <span v-else>
                  {{ item.description }}
                </span>
              </template>
              <template v-slot:[`item.reportType`]="{ item }">
                {{ item.reportType }}
              </template>
              <template v-slot:[`item.arusKasCategoryId`]="{ item }">
                {{ item.arusKasCategoryId }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#3E9FF9"
                      x-small
                      height="24"
                      min-width="24"
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="showDetailItem(item)"
                    >
                      <v-icon color="white" size="14">mdi-eye</v-icon>
                    </v-btn>
                    <!-- <v-btn
                      :class="
                        item.isActive === true ? 'btn-active' : 'btn-nonActive'
                      "
                      x-small
                      height="24"
                      min-width="24"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        handleChangeActiveAccount(item) ===
                          !handleChangeActiveAccount(item)
                      "
                    >
                      <v-icon color="white" size="14">mdi-power</v-icon>
                    </v-btn> -->
                    <v-btn
                      color="#CE5542"
                      x-small
                      height="24"
                      min-width="24"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleDeleteAccount(item)"
                      :disabled="listPermission[2] === false"
                    >
                      <v-icon color="white" size="14">mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      color="#E89F3C"
                      x-small
                      height="24"
                      min-width="24"
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleEditAccount(item)"
                      :disabled="listPermission[1] === false"
                    >
                      <v-icon color="white" size="14">mdi-pencil</v-icon>
                    </v-btn>
                    <!-- <v-btn
                      color="#6BC282"
                      x-small
                      height="24"
                      min-width="24"
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleAddAccountChild(item)"
                      :disabled="listPermission[0] === false"
                    >
                      <v-icon color="white" size="14">mdi-plus-circle</v-icon>
                    </v-btn> -->
                  </template>
                </v-menu>
              </template>
              <template v-slot:[`item.isActive`]="{ item }">
                <span v-if="item.isActive === true">Aktif</span>
                <span v-else>Tidak Aktif</span>
              </template>
              <template
                v-slot:[`footer.page-text`]="{
                  pageStart,
                  pageStop,
                  itemsLength,
                }"
              >
                Showing {{ pageStart }} to {{ pageStop }} of {{ itemsLength }}
              </template>
            </v-data-table>
            <v-row class="mt-n10">
              <v-spacer></v-spacer>
              <v-col md="auto" class="mr-10">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="7"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- Detail Dialog -->
    <v-dialog
      v-model="detailAccountDialog"
      transition="dialog-top-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Detail Akun</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="detailAccountDialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-row class="mt-6 mr-6">
              <v-col md="3" class="mt-3 text-right">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="account.code"
                  :readonly="true"
                  placeholder="Masukkan Kode"
                  class="mt-2 account-input"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="account.name"
                  :readonly="true"
                  placeholder="Masukkan Nama"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="account.accountTypeId"
                  :items="getAccountType"
                  item-value="id"
                  item-text="name"
                  :readonly="true"
                  placeholder="Pilih"
                  append-icon=""
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Saldo Normal</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="account.normalBalance"
                  :items="normalBalanceItems"
                  :readonly="true"
                  append-icon=""
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row> -->
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Laporan</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="account.reportType"
                  :items="getReportType"
                  item-value="name"
                  item-text="name"
                  :rules="reportTypeRules"
                  :readonly="true"
                  placeholder="Pilih"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  append-icon=""
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Status Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="account.isActive"
                  :items="isActiveItems"
                  item-value="isActive"
                  item-text="name"
                  :readonly="true"
                  placeholder="Pilih"
                  append-icon=""
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Arus Kas</span>
              </v-col>
              <v-col md="9">
                <!-- <v-select
                  v-model="accountChild.arusKasCategoryId"
                  :items="getArusKasCategory"
                  item-value="id"
                  item-text="name"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select> -->
                <v-select
                  v-model="account.arusKasCategoryId"
                  :items="getArusKasCategory"
                  item-value="name"
                  item-text="name"
                  placeholder="Pilih"
                  :readonly="true"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  append-icon=""
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Link Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="accountParent.linkAccount"
                  :items="getAccount"
                  :readonly="true"
                  item-value="id"
                  item-text="name"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon=""
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row
              class="mt-n4 mr-6"
              v-for="(val, index) in account.accountCategoryId"
              :key="index"
            >
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black"
                  >Kategori Akun {{ index + 1 }}</span
                >
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="account.accountCategoryId[index]"
                  :items="getAccountCategory"
                  item-value="id"
                  item-text="name"
                  :readonly="true"
                  placeholder="Pilih"
                  append-icon=""
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Deskripsi</span>
              </v-col>
              <v-col md="9">
                <v-textarea
                  v-model="account.description"
                  placeholder="Masukkan deskripsi"
                  :readonly="true"
                  height="80"
                  no-resize
                  outlined
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end mr-5">
              <v-btn
                @click="detailAccountDialog = false"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Tutup
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Detail Dialog -->
    <!-- Add Dialog -->
    <v-dialog
      v-model="addAccountParentDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Tambah Akun</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeAccountParentDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="addForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSaveAddAccount"
          >
            <v-row class="mt-6 mr-6">
              <v-col md="3" class="mt-3 text-right">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="accountParent.code"
                  :rules="accountCodeRules.concat(checkAddCodeRules)"
                  placeholder="Masukkan Kode"
                  class="mt-2"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="accountParent.name"
                  :rules="accountNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="accountParent.accountTypeId"
                  :items="getAccountType"
                  item-value="id"
                  item-text="name"
                  :rules="accountTypeRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Saldo Normal</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="accountParent.normalBalance"
                  :items="normalBalanceItems"
                  :rules="normalBalanceRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row> -->
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Laporan</span>
              </v-col>
              <v-col md="9">
                <!-- <v-select
                  v-model="accountParent.reportType"
                  :items="getReportType"
                  item-value="name"
                  item-text="name"
                  :rules="reportTypeRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select> -->
                <v-combobox
                  v-model="accountParent.reportType"
                  :items="getReportType"
                  item-value="name"
                  item-text="name"
                  :rules="reportTypeRules"
                  placeholder="Pilih"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Status Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="accountParent.isActive"
                  :items="isActiveItems"
                  item-value="isActive"
                  item-text="name"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Arus Kas</span>
              </v-col>
              <v-col md="9">
                <!-- <v-select
                  v-model="accountChild.arusKasCategoryId"
                  :items="getArusKasCategory"
                  item-value="id"
                  item-text="name"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select> -->
                <v-select
                  v-model="accountParent.arusKasCategoryId"
                  :items="getArusKasCategory"
                  item-value="name"
                  item-text="name"
                  placeholder="Pilih"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Link Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="accountParent.linkAccount"
                  :items="listLinkAkun"
                  item-value="id"
                  item-text="name"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                  <template v-slot:prepend-item>
                    <v-list-item class="mt-n5 mb-n5">
                      <v-list-item-content>
                        <v-text-field
                          v-model="dataSearch"
                          placeholder="Search"
                          @input="actionSearchTcodeAdd"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row
              class="mt-n4 mr-6"
              v-for="(val, index) in accountParent.accountCategoryId"
              :key="index"
            >
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black"
                  >Kategori Akun {{ index + 1 }}</span
                >
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="accountParent.accountCategoryId[index]"
                  :items="getAccountCategory"
                  item-value="id"
                  item-text="name"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col md="1">
                <v-btn @click="addDropdownCategory" icon v-if="index + 1 === 1">
                  <v-icon color="#3E9FF9">mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn @click="removeDropdownCategory(index)" icon v-else>
                  <v-icon color="#EB7765">mdi-minus-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Deskripsi</span>
              </v-col>
              <v-col md="9">
                <v-textarea
                  v-model="accountParent.description"
                  :rules="descriptionRules"
                  placeholder="Masukkan deskripsi"
                  height="100"
                  background-color="#FAFAFA"
                  no-resize
                  outlined
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end mr-7">
              <v-btn
                @click="closeAccountParentDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Add Dialog -->
    <!-- Add Child Dialog -->
    <v-dialog
      v-model="addAccountChildDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Tambah Anak Akun</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeAccountChildDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="addAccountChildForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSaveAddAccountChild"
          >
            <v-row class="mt-6 mr-6">
              <v-col md="3" class="mt-9 text-right">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="9">
                <span class="font-weight-black"
                  >Kode Parent : {{ accountChild.parent }}</span
                >
                <v-text-field
                  v-model="accountChild.code"
                  :rules="accountCodeRules"
                  placeholder="Masukkan Kode"
                  class="mt-2 account-code-input rounded-tr-0 rounded-br-0"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="accountChild.name"
                  :rules="accountNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="accountChild.accountTypeId"
                  :items="getAccountType"
                  item-value="id"
                  item-text="name"
                  :rules="accountTypeRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Saldo Normal</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="accountChild.normalBalance"
                  :items="normalBalanceItems"
                  item-value="id"
                  item-text="name"
                  :rules="normalBalanceRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row> -->
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Laporan</span>
              </v-col>
              <v-col md="9">
                <!-- <v-select
                  v-model="accountChild.reportType"
                  :items="getReportType"
                  item-value="name"
                  item-text="name"
                  :rules="reportTypeRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select> -->
                <v-combobox
                  v-model="accountChild.reportType"
                  :items="getReportType"
                  item-value="name"
                  item-text="name"
                  :rules="reportTypeRules"
                  placeholder="Pilih"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Arus Kas</span>
              </v-col>
              <v-col md="9">
                <!-- <v-select
                  v-model="accountChild.arusKasCategoryId"
                  :items="getArusKasCategory"
                  item-value="id"
                  item-text="name"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select> -->
                <v-select
                  v-model="accountChild.arusKasCategoryId"
                  :items="getArusKasCategory"
                  item-value="name"
                  item-text="name"
                  placeholder="Pilih"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Link Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="accountChild.linkAccount"
                  :items="listLinkAkun"
                  item-value="id"
                  item-text="name"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row
              class="mt-n4 mr-6"
              v-for="(val, index) in accountChild.accountCategoryId"
              :key="index"
            >
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black"
                  >Kategori Akun {{ index + 1 }}</span
                >
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="accountChild.accountCategoryId[index]"
                  :items="getAccountCategory"
                  item-value="id"
                  item-text="name"
                  :rules="accountCategoryRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col md="1">
                <v-btn @click="addDropdownCategory" icon v-if="index + 1 === 1">
                  <v-icon color="#3E9FF9">mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn @click="removeDropdownCategory(index)" icon v-else>
                  <v-icon color="#EB7765">mdi-minus-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Deskripsi</span>
              </v-col>
              <v-col md="9">
                <v-textarea
                  v-model="accountChild.description"
                  :rules="descriptionRules"
                  placeholder="Masukkan deskripsi"
                  height="100"
                  background-color="#FAFAFA"
                  no-resize
                  outlined
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end mr-7">
              <v-btn
                @click="closeAccountChildDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Add Child Dialog -->
    <!-- Edit Dialog -->
    <v-dialog
      v-model="editAccountChildDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Ubah Akun</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeEditDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="editForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSaveEditAccount"
          >
            <v-row class="mt-6 mr-6">
              <v-col md="3" class="mt-3 text-right">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="account.code"
                  :rules="accountCodeRules.concat(checkEditCodeRules)"
                  class="mt-2 account-input"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="account.name"
                  :rules="accountNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="account.accountTypeId"
                  :items="getAccountType"
                  item-value="id"
                  item-text="name"
                  :rules="accountTypeRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Saldo Normal</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="account.normalBalance"
                  :items="normalBalanceItems"
                  item-value="id"
                  item-text="name"
                  :rules="normalBalanceRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row> -->
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe Laporan</span>
              </v-col>
              <v-col md="9">
                <!-- <v-select
                  v-model="account.reportType"
                  :items="getReportType"
                  item-value="name"
                  item-text="name"
                  :rules="reportTypeRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select> -->
                <v-select
                  v-model="account.reportType"
                  :items="getReportType"
                  item-value="name"
                  item-text="name"
                  :rules="reportTypeRules"
                  placeholder="Pilih"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Arus Kas</span>
              </v-col>
              <v-col md="9">
                <!-- <v-select
                  v-model="account.arusKasCategoryId"
                  :items="getArusKasCategory"
                  item-value="id"
                  item-text="name"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select> -->
                <v-select
                  v-model="account.arusKasCategoryId"
                  :items="getArusKasCategory"
                  item-value="name"
                  item-text="name"
                  placeholder="Pilih"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Status Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="account.isActive"
                  :items="isActiveItems"
                  item-value="isActive"
                  item-text="name"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Link Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="account.linkAccount"
                  :items="listLinkAkun"
                  item-value="id"
                  item-text="name"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                  <template v-slot:prepend-item>
                    <v-list-item class="mt-n5 mb-n5">
                      <v-list-item-content>
                        <v-text-field
                          v-model="dataSearchEdit"
                          placeholder="Search"
                          @input="actionSearchTcodeEdit"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row
              class="mt-n4 mr-6"
              v-for="(val, index) in account.accountCategoryId"
              :key="index"
            >
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black"
                  >Kategori Akun {{ index + 1 }}</span
                >
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="account.accountCategoryId[index]"
                  :items="getAccountCategory"
                  item-value="id"
                  item-text="name"
                  :rules="accountCategoryRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col md="1">
                <v-btn @click="addDropdownCategory" icon v-if="index + 1 === 1">
                  <v-icon color="#3E9FF9">mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn @click="removeDropdownCategory(index)" icon v-else>
                  <v-icon color="#EB7765">mdi-minus-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row class="mt-n4 mr-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Deskripsi</span>
              </v-col>
              <v-col md="9">
                <v-textarea
                  v-model="account.description"
                  :rules="descriptionRules"
                  placeholder="Masukkan deskripsi"
                  height="80"
                  background-color="#FAFAFA"
                  no-resize
                  outlined
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end mr-7">
              <v-btn
                @click="closeEditDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Edit Dialog -->
    <!-- Delete Dialog -->
    <v-dialog
      v-model="dialogDelete"
      transition="dialog-top-transition"
      max-width="350"
    >
      <v-card color="pt-8">
        <BodyCardDelete />
        <v-card-actions class="justify-center">
          <v-btn
            class="btn-close-dialog mr-4"
            tile
            depressed
            @click="dialogDelete = false"
            >Batal</v-btn
          >
          <v-btn class="btn-confirm" tile depressed @click="confirmDeleteItem"
            >Hapus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Delete Dialog -->
    <v-snackbar
      v-model="getSnackbarAdd"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAddText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAdd = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarEditText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarDelete"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarDeleteText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarDelete = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarWarning"
      :timeout="timeout"
      color="warning"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarWarningText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarWarning = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarAddAlert"
      :timeout="timeout"
      color="warning"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAddAlertText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAddAlert = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarEditAlert"
      :timeout="timeout"
      color="warning"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarEditAlertText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarEditAlert = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import BaseButton from "./base/BaseButton.vue";
import BodyCardDelete from "./AppCardBodyDelete.vue";
const debounce = require("debounce");
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AccountCode",
  components: { BaseButton, BodyCardDelete },
  data: () => ({
    page: 1,
    btnAddDisable: false,
    pageCount: 0,
    maxAccountCategoryPerPage: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    search: "",
    addAccountParentDialog: false,
    addAccountChildDialog: false,
    detailAccountDialog: false,
    editAccountChildDialog: false,
    valid: true,
    accountTypeId: 0,
    accountCategoryId: 0,
    accountStatusId: 0,
    arusKasCategoryId: 0,
    accountTypeItems: [
      {
        id: 0,
        name: "Semua Tipe Akun",
      },
    ],
    accountCategoryItems: [
      {
        id: 0,
        name: "Semua Kategori Akun",
      },
    ],
    accountStatusItems: [
      {
        id: 0,
        name: "Semua Status Akun",
      },
    ],
    accountTypeLoading: true,
    accountCategoryLoading: true,
    arusKasCategoryLoading: true,
    // normalBalanceItems: ["D", "C"],
    listPermission: [false, false, false, false],
    isLoading: true,
    loadingText: "Sedang memuat data...",
    noDataText: "Tidak ada data yang tersedia",
    accountId: null,
    isActiveItems: [
      {
        name: "Aktif",
        isActive: true,
      },
      {
        name: "Tidak Aktif",
        isActive: false,
      },
    ],
    accountCode: "",
    account: {
      id: null,
      parentId: 0,
      isHeader: 0,
      isActive: null,
      parentCode: "N/A",
      code: "",
      name: "",
      accountCategoryId: [""],
      accountTypeId: null,
      arusKasCategoryId: [],
      reportType: [],
      linkAccount: 0,
      // normalBalance: "",
      description: "",
      reportTypeList: "",
    },
    accountParent: {
      parentId: 0,
      isHeader: 0,
      isActive: true,
      parentCode: "N/A",
      code: "",
      name: "",
      accountCategoryId: [""],
      accountTypeId: null,
      arusKasCategoryId: [],
      reportType: [],
      linkAccount: 0,
      // normalBalance: "",
      description: "",
    },
    accountChild: {
      parent: "",
      parentId: 0,
      isHeader: 0,
      isActive: true,
      parentCode: "N/A",
      code: "",
      name: "",
      accountCategoryId: [""],
      accountTypeId: null,
      arusKasCategoryId: [],
      reportType: [],
      linkAccount: 0,
      // normalBalance: "",
      description: "",
    },
    dialogDelete: false,
    // Snackbar
    snackbarAddText: "Akun berhasil ditambahkan!",
    snackbarEditText: "Akun berhasil diubah!",
    snackbarDeleteText: "Akun berhasil dihapus!",
    snackbarWarningText: "Akun tidak bisa dihapus!",
    snackbarAddAlertText: "Akun gagal ditambahkan!",
    snackbarEditAlertText: "Akun gagal diubah!",
    timeout: 2500,
    // Validation
    accountCodeRules: [(v) => !!v || "Kode akun harus diisi"],
    accountNameRules: [(v) => !!v || "Nama akun harus diisi"],
    accountCategoryRules: [(v) => !!v || "Kategori akun harus dipilih"],
    accountParentCategoryRules: [(v) => !!v || "Kategori akun harus dipilih"],
    accountTypeRules: [(v) => !!v || "Tipe akun harus dipilih"],
    reportTypeRules: [(v) => !!v || "Tipe laporan harus dipilih"],
    // normalBalanceRules: [(v) => !!v || "Saldo normal harus dipilih"],
    descriptionRules: [(v) => !!v || "Deskripsi harus diisi"],

    listLinkAkun: [],
    dataSearch: "",
    dataSearchEdit: "",
  }),
  computed: {
    ...mapGetters([
      "getAccount",
      "getAccountType",
      "getAccountCategory",
      "getArusKasCategory",
      "getListPermissionById",
      "getReportType",
    ]),
    computedHeaders() {
      const accountCategory = [];
      for (let i = 0; i < this.maxAccountCategoryPerPage; i++) {
        accountCategory.push({
          text: "Kategori Akun " + (i + 1),
          value: "accountCategoryId-" + (i + 1),
          sortable: true,
          divider: true,
          width: "236px",
        });
      }
      return [
        {
          text: "Kode",
          align: "start",
          sortable: true,
          value: "code",
          divider: true,
          width: "113.27px",
        },
        {
          text: "Nama",
          align: "start",
          sortable: true,
          value: "name",
          divider: true,
          width: "264.31px",
        },
        {
          text: "Tipe Akun",
          align: "center",
          value: "accountTypeId",
          sortable: true,
          divider: true,
          width: "141.59px",
        },
        ...accountCategory,
        {
          text: "Deskripsi Akun",
          value: "description",
          sortable: true,
          divider: true,
          width: "200px",
        },
        {
          text: "Tipe Laporan",
          value: "reportType",
          sortable: true,
          divider: true,
          width: "220px",
        },
        {
          text: "Arus Kas",
          align: "start",
          value: "arusKasCategoryId",
          sortable: true,
          divider: true,
          width: "200px",
        },
        {
          text: "Status Akun",
          align: "center",
          value: "isActive",
          sortable: true,
          divider: true,
          width: "150px",
        },
        {
          text: "Aksi",
          value: "actions",
          align: "center",
          sortable: false,
          divider: true,
          width: "188.83px",
        },
      ];
    },
    filteredAccounts() {
      var accountAwal = this.getAccount
        .filter((item) => {
          if (!this.accountTypeId) {
            return item;
          } else if (
            !this.accountTypeId &&
            !this.accountCategoryId &&
            !this.accountStatusId
          ) {
            return item;
          } else {
            return item.accountTypeId === this.accountTypeId;
          }
        })
        .filter((item) => {
          if (!this.accountCategoryId) {
            return item;
          } else if (
            !this.accountTypeId &&
            !this.accountCategoryId &&
            !this.accountStatusId
          ) {
            return item;
          } else {
            return item.accountCategoryId === this.accountCategoryId;
          }
        })
        .filter((item) => {
          if (!this.accountStatusId) {
            return item;
          } else if (
            !this.accountTypeId &&
            !this.accountCategoryId &&
            !this.accountStatusId
          ) {
            return item;
          } else {
            if (item.isActive) {
              return this.accountStatusId === 1;
            } else {
              return this.accountStatusId === 2;
            }
          }
        });

      for (var i = 0; i < accountAwal.length; i++) {
        if (accountAwal[i].reportType !== null) {
          var strReportType = this.setReportTypeList(accountAwal[i].reportType);
          accountAwal[i].reportType = strReportType;
        }

        if (accountAwal[i].arusKasCategoryId !== null) {
          var strArusKas = this.setArusKasList(
            accountAwal[i].arusKasCategoryId
          );
          accountAwal[i].arusKasCategoryId = strArusKas;
        }
      }

      return accountAwal;
    },
    getAccountCodes() {
      return this.getAccount.map((item) => item.code);
    },
    getSomeAccountTypeCodes() {
      let codes = this.getAccountCodes;
      let index = codes.indexOf(this.accountCode);
      if (index !== -1) {
        codes.splice(index, 1);
      }
      return codes;
    },
    checkAddCodeRules() {
      return () =>
        this.getAccountCodes.includes(this.accountParent.code) === false ||
        "Kode akun sudah ada";
    },
    checkEditCodeRules() {
      return () =>
        this.getSomeAccountTypeCodes.includes(this.account.code) === false ||
        "Kode akun sudah ada";
    },
    getSnackbarAdd: {
      get() {
        return this.$store.state.snackbarAdd;
      },
      set(v) {
        this.$store.commit("showSnackbarAdd", v);
      },
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
    getSnackbarDelete: {
      get() {
        return this.$store.state.snackbarDelete;
      },
      set(v) {
        this.$store.commit("showSnackbarDelete", v);
      },
    },
    getSnackbarWarning: {
      get() {
        return this.$store.state.snackbarWarning;
      },
      set(v) {
        this.$store.commit("showSnackbarWarning", v);
      },
    },
    getSnackbarAddAlert: {
      get() {
        return this.$store.state.snackbarAddAlert;
      },
      set(v) {
        this.$store.commit("showSnackbarAddAlert", v);
      },
    },
    getSnackbarEditAlert: {
      get() {
        return this.$store.state.snackbarEditAlert;
      },
      set(v) {
        this.$store.commit("showSnackbarEditAlert", v);
      },
    },
  },
  watch: {
    getAccount() {
      this.isLoading = false;
    },
    getAccountType() {
      this.accountTypeLoading = false;
    },
    getAccountCategory() {
      this.accountCategoryLoading = false;
    },
    getArusKasCategory() {
      this.arusKasCategoryLoading = false;
    },
  },
  methods: {
    ...mapActions([
      "getAccountData",
      "getAccountTypeData",
      "getAccountCategoryData",
      "getReportTypeData",
      "getArusKasCategoryData",
    ]),
    actionSearchTcodeAdd() {
      if (this.dataSearch === "" || this.dataSearch === null) {
        this.listLinkAkun = this.getAccount;
      } else {
        this.getAccountData();
        this.listLinkAkun = this.getAccount.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.dataSearch.toLowerCase()) > -1
          );
        });
      }
    },
    actionSearchTcodeEdit() {
      if (this.dataSearchEdit === "" || this.dataSearchEdit === null) {
        this.listLinkAkun = this.getAccount;
      } else {
        this.getAccountData();
        this.listLinkAkun = this.getAccount.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.dataSearchEdit.toLowerCase()) >
            -1
          );
        });
      }
    },
    closeEditDialog() {
      this.editAccountChildDialog = false;
      this.dataSearchEdit = "";
      this.getAccountData();
      this.listLinkAkun = this.getAccount;
    },
    currentItems(value) {
      this.maxAccountCategoryPerPage = Math.max(
        ...value.map((item) => {
          if (!item.accountCategoryId) {
            return 0;
          } else {
            const id = JSON.parse(item.accountCategoryId);
            if (typeof id === "number" || !id) {
              return 1;
            } else {
              return id.length;
            }
          }
        })
      );
    },
    selectItem(value) {
      this.itemPerPage = value;
    },
    addDropdownCategory() {
      if (this.addAccountParentDialog) {
        this.accountParent.accountCategoryId.push("");
      }
      if (this.addAccountChildDialog) {
        this.accountChild.accountCategoryId.push("");
      }
      if (this.editAccountChildDialog) {
        this.account.accountCategoryId.push("");
      }
    },
    removeDropdownCategory(index) {
      if (this.addAccountParentDialog) {
        this.accountParent.accountCategoryId.splice(index, 1);
      }
      if (this.addAccountChildDialog) {
        this.accountChild.accountCategoryId.splice(index, 1);
      }
      if (this.editAccountChildDialog) {
        this.account.accountCategoryId.splice(index, 1);
      }
    },
    showAddDialog() {
      this.addAccountParentDialog = true;
    },
    showDetailItem(item) {
      this.account.code = item.code;
      this.account.name = item.name;
      if (typeof JSON.parse(item.accountCategoryId) === "number") {
        this.account.accountCategoryId = [JSON.parse(item.accountCategoryId)];
      } else {
        this.account.accountCategoryId = JSON.parse(item.accountCategoryId);
      }
      this.account.accountTypeId = item.accountTypeId;

      this.account.reportType = [];
      var arrReportType = item.reportType.split(",");
      for (var i = 0; i < arrReportType.length; i++) {
        if (arrReportType[i].substring(0, 1) === " ") {
          arrReportType[i] = arrReportType[i].substring(1);
        }
        this.account.reportType.push(arrReportType[i]);
      }

      this.account.arusKasCategoryId = [];
      var arrArusKasCategory = item.arusKasCategoryId.split(",");
      for (var k = 0; k < arrArusKasCategory.length; k++) {
        if (arrArusKasCategory[k].substring(0, 1) === " ") {
          arrArusKasCategory[k] = arrArusKasCategory[k].substring(1);
        }
        this.account.arusKasCategoryId.push(arrArusKasCategory[k]);
      }

      // this.account.reportType = JSON.parse(item.reportType);
      // this.account.normalBalance = item.normalBalance;
      this.account.description = item.description;
      this.account.isActive = item.isActive;
      this.detailAccountDialog = true;
    },
    handleSaveAddAccount() {
      if (this.$refs.addForm.validate()) {
        let data = {
          parentCode: this.accountParent.parentCode,
          parentId: this.accountParent.parentId,
          isHeader: this.accountParent.isHeader,
          code: this.accountParent.code,
          name: this.accountParent.name,
          accountCategoryId: JSON.stringify(
            this.accountParent.accountCategoryId
          ),
          accountTypeId: this.accountParent.accountTypeId,
          arusKasCategoryId: JSON.stringify(
            this.accountParent.arusKasCategoryId
          ),
          linkAccount: this.accountParent.linkAccount,
          reportType: JSON.stringify(this.accountParent.reportType),
          // normalBalance: this.accountParent.normalBalance,
          description: this.accountParent.description,
          isActive: this.accountParent.isActive,
        };
        this.$store.dispatch("addAccountData", data);
        this.addAccountParentDialog = false;
        this.$refs.addForm.reset();
        this.accountParent.accountCategoryId = [""];
      }
    },
    closeAccountParentDialog() {
      this.addAccountParentDialog = false;
      this.$refs.addForm.reset();
      this.$refs.addForm.resetValidation();
      this.accountParent.accountCategoryId = [""];
    },
    handleAddAccountChild(item) {
      this.accountChild.parentId = item.id;
      this.accountChild.parent = item.code;
      this.addAccountChildDialog = true;
      this.$refs.addAccountChildForm.resetValidation();
    },
    handleSaveAddAccountChild() {
      if (this.$refs.addAccountChildForm.validate()) {
        let data = {
          parentCode: this.accountChild.parentCode,
          parentId: this.accountChild.parentId,
          isHeader: this.accountChild.isHeader,
          code: this.accountChild.code,
          name: this.accountChild.name,
          accountCategoryId: JSON.stringify(
            this.accountChild.accountCategoryId
          ),
          accountTypeId: this.accountChild.accountTypeId,
          arusKasCategoryId: JSON.stringify(
            this.accountChild.arusKasCategoryId
          ),
          linkAccount: this.accountChild.linkAccount,
          reportType: JSON.stringify(this.accountChild.reportType),
          // normalBalance: this.accountChild.normalBalance,
          description: this.accountChild.description,
          isActive: this.accountChild.isActive,
        };
        this.$store.dispatch("addAccountData", data);
        this.addAccountChildDialog = false;
        this.$refs.addAccountChildForm.reset();
        this.accountChild.accountCategoryId = [""];
      }
    },
    closeAccountChildDialog() {
      this.addAccountChildDialog = false;
      this.$refs.addAccountChildForm.reset();
      this.$refs.addAccountChildForm.resetValidation();
      this.accountChild.accountCategoryId = [""];
    },
    handleEditAccount(item) {
      this.account.id = item.id;
      this.account.code = item.code;
      this.account.parentId = item.parentId;
      this.account.isHeader = item.isHeader;
      this.account.name = item.name;
      if (
        typeof JSON.parse(item.accountCategoryId) === "number" ||
        !item.accountCategoryId
      ) {
        this.account.accountCategoryId = [JSON.parse(item.accountCategoryId)];
      } else {
        this.account.accountCategoryId = JSON.parse(item.accountCategoryId);
      }
      this.account.accountTypeId = item.accountTypeId;
      this.account.linkAccount = item.linkAccount;
      this.account.arusKasCategoryId = [];
      this.account.reportType = [];

      if (item.arusKasCategoryId !== "") {
        var arrArusKasCategory = item.arusKasCategoryId.split(",");
        for (var k = 0; k < arrArusKasCategory.length; k++) {
          if (arrArusKasCategory[k].substring(0, 1) === " ") {
            arrArusKasCategory[k] = arrArusKasCategory[k].substring(1);
          }
          this.account.arusKasCategoryId.push(arrArusKasCategory[k]);
        }
      }

      if (item.reportType !== "") {
        var arrReportType = item.reportType.split(",");
        for (var i = 0; i < arrReportType.length; i++) {
          if (arrReportType[i].substring(0, 1) === " ") {
            arrReportType[i] = arrReportType[i].substring(1);
          }
          this.account.reportType.push(arrReportType[i]);
        }
      }

      // this.account.reportType = JSON.parse(item.reportType);
      // this.account.normalBalance = item.normalBalance;
      this.account.description = item.description;
      this.account.isActive = item.isActive;
      this.accountCode = item.code;
      this.editAccountChildDialog = true;
    },
    handleSaveEditAccount() {
      if (this.$refs.editForm.validate()) {
        let data = {
          id: this.account.id,
          parentCode: this.account.parentCode,
          parentId: this.account.parentId,
          isHeader: this.account.isHeader,
          code: this.account.code,
          name: this.account.name,
          accountCategoryId: JSON.stringify(this.account.accountCategoryId),
          accountTypeId: this.account.accountTypeId,
          arusKasCategoryId: JSON.stringify(this.account.arusKasCategoryId),
          linkAccount: this.account.linkAccount,
          reportType: JSON.stringify(this.account.reportType),
          // normalBalance: this.account.normalBalance,
          description: this.account.description,
          isActive: this.account.isActive,
        };
        this.$store.dispatch("editAccountData", data);
        this.editAccountChildDialog = false;
      }
    },
    handleDeleteAccount(item) {
      this.accountId = item.id;
      this.dialogDelete = true;
    },
    confirmDeleteItem() {
      this.$store.dispatch("deleteAccount", this.accountId);
      this.dialogDelete = false;
    },
    accountType(id) {
      return this.getAccountType
        .filter((e) => e.id === id)
        .map((item) => item.name)
        .toString();
    },
    arusKasCategoryDetail(id) {
      return this.getArusKasCategory
        .filter((e) => e.id === id)
        .map((item) => item.name)
        .toString();
    },
    reportType(id) {
      return this.getReportType
        .filter((e) => e.id === id)
        .map((item) => item.name)
        .toString();
    },
    setReportTypeList(item) {
      var strReportType = "";

      if (item.substring(0, 1) === "[") {
        var arrItem = JSON.parse(item);
        for (var j = 0; j < arrItem.length; j++) {
          if (j === arrItem.length - 1) {
            strReportType += this.reportType(arrItem[j]);
          } else {
            strReportType += this.reportType(arrItem[j]) + ", ";
          }
        }
      } else {
        strReportType = item;
      }

      return strReportType;
    },
    setArusKasList(item) {
      var strArusKas = "";

      if (item.substring(0, 1) === "[") {
        var arrItem = JSON.parse(item);
        if (arrItem.length > 0) {
          for (var j = 0; j < arrItem.length; j++) {
            if (j === arrItem.length - 1) {
              strArusKas += this.arusKasCategoryDetail(arrItem[j]);
            } else {
              strArusKas += this.arusKasCategoryDetail(arrItem[j]) + ", ";
            }
          }
        }
      } else {
        strArusKas = item;
      }

      return strArusKas;
    },
    accountCategory(accountCategory, id) {
      if (!accountCategory) {
        return "";
      } else {
        const accountCategoryId = JSON.parse(accountCategory);
        if (typeof accountCategoryId === "number") {
          if (id > 0) {
            return "";
          }
          return this.getAccountCategory
            .filter((e) => e.id === accountCategoryId)
            .map((item) => item.name)
            .toString();
        } else {
          return this.getAccountCategory
            .filter((e) => e.id === accountCategoryId[id])
            .map((item) => item.name)
            .toString();
        }
      }
    },
    searchAccount: debounce(function () {
      this.$store.dispatch("searchAccount", this.search);
    }, 500),
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 20;
      });

      this.listPermission[1] = permissions.some((item) => {
        return item === 21;
      });

      this.listPermission[2] = permissions.some((item) => {
        return item === 22;
      });

      this.listPermission[3] = permissions.some((item) => {
        return item === 23;
      });
    },
    setListTable() {
      this.getAccountData();
    },
  },
  async created() {
    await this.setListPermission();
    if (this.listPermission[3] === false) {
      this.isLoading = false;
      this.accountTypeLoading = false;
      this.accountCategoryLoading = false;
      this.noDataText = "Anda tidak memiliki izin untuk mengakses data";
    } else {
      await this.getAccountTypeData();
      await this.getAccountCategoryData();
      await this.getReportTypeData();
      await this.getArusKasCategoryData();
      await this.getAccountData();
      this.listLinkAkun = this.getAccount;
      this.accountTypeItems.push(...this.getAccountType);
      this.accountCategoryItems.push(...this.getAccountCategory);
      this.accountStatusItems.push(
        ...[
          {
            id: 1,
            name: "Aktif",
          },
          {
            id: 2,
            name: "Tidak Aktif",
          },
        ]
      );
    }
  },
};
</script>

<style src="../styles/account-code.scss" lang="scss" />
