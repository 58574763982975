<template>
  <v-container>
    <v-snackbar
      v-model="snackbarAlert"
      :timeout="timeout"
      :color="alertColor"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAlertText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbarAlert = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="net-asset-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="net-asset-card-title"
              >Konfigurasi Laporan Statements of Income</span
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="7">
            <v-btn
              color="#E89F3C"
              class="rounded white--text"
              :to="{ name: 'Statements of Income Edit' }"
              tile
              depressed
            >
              <v-icon class="mr-1" size="20"> mdi-pencil </v-icon>
              EDIT
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="4">
            <v-row>
              <v-col md="4" class="mr-n6">
                <span class="body-1">Tampilkan</span>
              </v-col>
              <v-col md="4">
                <v-select
                  v-model="itemPerPage"
                  :items="itemsPerPage"
                  @change="selectItem"
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="4" class="ml-n2">
                <span class="body-1">Entri</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" class="mr-3">
            <v-text-field
              v-model="search"
              placeholder="Cari akun"
              prepend-inner-icon="mdi-magnify"
              background-color="#FAFAFA"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-7 pr-7 pb-7">
        <v-col>
          <v-card class="account-table pb-4" elevation="0">
            <v-data-table
              id="account-parent-table"
              :headers="headers"
              :items="getAccountParent"
              item-key="key"
              :options="{
                itemsPerPage: itemPerPage,
              }"
              :page.sync="page"
              @page-count="pageCount = $event"
              @item-expanded="setColumnWidth"
              :search="search"
              :no-results-text="noResultsText"
              :no-data-text="noDataText"
              :expanded.sync="expanded"
              :single-expand="true"
              show-expand
              :footer-props="{
                'items-per-page-text': '',
                'disable-items-per-page': true,
                'items-per-page-options': [],
                'prev-icon': null,
                'next-icon': null,
              }"
            >
              <template v-slot:[`item.index`]="{ item }">
                {{ item.index + 1 }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  :key="item.index"
                  class="pl-0 pr-0"
                >
                  <v-data-table
                    id="account-child-table"
                    :headers="headers"
                    :items="getAccountChildItems(item.name, item.index)"
                    item-key="key"
                    hide-default-header
                    hide-default-footer
                  >
                    <template v-slot:[`item.index`]="{ item }">
                      {{ item.parentId + 1 }}.{{ item.index + 1 }}
                    </template>
                    <template v-slot:[`item.isCalculate`]="{ item }">
                      <v-checkbox
                        v-model="item.account.isCalculate"
                        :ripple="false"
                        color="primary"
                        :readonly="true"
                      ></v-checkbox>
                    </template>
                    <template v-slot:[`item.isSubGroup`]="{ item }">
                      <v-checkbox
                        v-model="item.account.isSubGroup"
                        :ripple="false"
                        color="primary"
                        :readonly="true"
                      ></v-checkbox>
                    </template>
                    <template v-slot:[`item.fontBold`]="{ item }">
                      <v-checkbox
                        v-model="item.account.style.fontBold"
                        :ripple="false"
                        color="primary"
                        :readonly="true"
                      ></v-checkbox>
                    </template>
                    <template v-slot:[`item.borderTop`]="{ item }">
                      <v-checkbox
                        v-model="item.account.style.borderTop"
                        :ripple="false"
                        color="primary"
                        :readonly="true"
                      ></v-checkbox>
                    </template>
                    <template v-slot:[`item.borderBottom`]="{ item }">
                      <v-checkbox
                        v-model="item.account.style.borderBottom"
                        :ripple="false"
                        color="primary"
                        :readonly="true"
                      ></v-checkbox>
                    </template>
                  </v-data-table>
                </td>
              </template>
              <template
                v-slot:[`footer.page-text`]="{
                  pageStart,
                  pageStop,
                  itemsLength,
                }"
              >
                Showing {{ pageStart }} to {{ pageStop }} of {{ itemsLength }}
              </template>
            </v-data-table>
            <v-row class="mt-n10">
              <v-spacer></v-spacer>
              <v-col md="auto" class="mr-10">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="7"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ReportBalanceSheetsList",
  data: () => ({
    headers: [
      { text: "", value: "data-table-expand", width: "64px" },
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "index",
        divider: true,
        width: "92px",
      },
      {
        text: "Nama",
        value: "name",
        sortable: true,
        divider: true,
        width: "472px",
      },
      {
        text: "Calculate",
        value: "isCalculate",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Sub Group",
        value: "isSubGroup",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Font Bold",
        value: "fontBold",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Border Top",
        value: "borderTop",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Border Bottom",
        value: "borderBottom",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
    ],
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    expanded: [],
    expandedSecond: [],
    search: "",
    accountLoading: true,
    loadingText: "Sedang memuat data...",
    noResultsText: "Data tidak ditemukan",
    noDataText: "Tidak ada data yang tersedia",
    // Snackbar
    alertColor: "",
    snackbarAlert: false,
    snackbarAlertText: "",
    timeout: 2000,
  }),
  computed: {
    ...mapGetters(["getReportAccountIS"]),
    getAccountParent() {
      return Object.keys({ ...this.getReportAccountIS }).map((item, index) => {
        return {
          key: "parent_" + index,
          index: index,
          name: item,
        };
      });
    },
  },
  watch: {
    getAccountParent() {
      this.accountLoading = false;
    },
  },
  methods: {
    ...mapActions(["getReportDataIS"]),
    selectItem(value) {
      this.itemPerPage = value;
    },
    snackbarWarning() {
      this.alertColor = "warning";
      this.snackbarAlertText = "Akun sudah ditambahkan!";
      this.snackbarAlert = true;
    },
    snackbarSuccess(text) {
      this.alertColor = "success";
      this.snackbarAlertText = text;
      this.snackbarAlert = true;
    },
    getAccountChildItems(parentName, parentId) {
      const accountChild = { ...this.getReportAccountIS }[parentName];
      let child = [];
      for (let key in { ...accountChild }) {
        child.push({
          key: "childLiabilities_" + key,
          index: parseInt(key),
          account: { ...accountChild }[key],
          name: { ...accountChild }[key].name,
          parentId: parentId,
          parent: parentName,
        });
      }
      return child;
    },
    getAccountGrandChildItems(
      grandParentName,
      grandParentId,
      parentName,
      parentId
    ) {
      const accountGrandChild = { ...this.getReportAccountIS }[grandParentName][
        parentName
      ];
      let grandChild = [];
      for (let key in { ...accountGrandChild }) {
        grandChild.push({
          key: "grandChild_" + key,
          index: parseInt(key),
          account: { ...accountGrandChild }[key],
          name: { ...accountGrandChild }[key].name,
          parentId: parentId,
          parent: parentName,
          grandParentId: grandParentId,
          grandParent: grandParentName,
        });
      }
      return grandChild;
    },
    setColumnWidth() {
      setTimeout(() => {
        if (this.expanded.length === 1) {
          const rowCount = this.getAccountChildItems(
            this.expanded[0].name,
            this.expanded[0].index
          ).length;
          for (let i = 1; i <= rowCount; i++) {
            for (let j = 1; j <= 8; j++) {
              document
                .querySelector(
                  `#account-child-table > div > table > tbody > tr:nth-child(1) > td:nth-child(${j})`
                )
                .classList.add("no-border-top");
              document
                .querySelector(
                  `#account-child-table > div > table > tbody > tr:nth-child(${rowCount}) > td:nth-child(${j})`
                )
                .classList.add("no-border-bottom");
              if (j >= 4 && j < 8) {
                document.querySelector(
                  `#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(${j})`
                ).style.width = "156px";
              }
            }
            document.querySelector(
              `#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`
            ).style.width = "63px";
            document
              .querySelector(
                `#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`
              )
              .classList.add("no-border-left");
            document.querySelector(
              `#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(2)`
            ).style.width = "92px";
            document.querySelector(
              `#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(3)`
            ).style.width = "472px";
            document
              .querySelector(
                `#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(8)`
              )
              .classList.add("no-border-right");
            document.querySelector(
              `#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(8)`
            ).style.width = "155px";
          }
        } else {
          return;
        }
      }, 0);
    },
    setChildColumnWidth() {
      setTimeout(() => {
        if (this.expandedSecond.length === 1) {
          const rowCount = this.getAccountGrandChildItems(
            this.expandedSecond[0].parent,
            this.expandedSecond[0].parentId,
            this.expandedSecond[0].name,
            this.expandedSecond[0].index
          ).length;
          for (let i = 1; i <= rowCount; i++) {
            for (let j = 1; j <= 8; j++) {
              document
                .querySelector(
                  `#account-grand-child-table > div > table > tbody > tr:nth-child(1) > td:nth-child(${j})`
                )
                .classList.add("no-border-top");
              document
                .querySelector(
                  `#account-grand-child-table > div > table > tbody > tr:nth-child(${rowCount}) > td:nth-child(${j})`
                )
                .classList.add("no-border-bottom");
              if (j >= 4 && j < 8) {
                document.querySelector(
                  `#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(${j})`
                ).style.width = "156px";
              }
            }
            document.querySelector(
              `#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`
            ).style.width = "62px";
            document
              .querySelector(
                `#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`
              )
              .classList.add("no-border-left");
            document.querySelector(
              `#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(2)`
            ).style.width = "92px";
            document.querySelector(
              `#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(3)`
            ).style.width = "472px";
            document
              .querySelector(
                `#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(8)`
              )
              .classList.add("no-border-right");
            document.querySelector(
              `#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(8)`
            ).style.width = "154px";
          }
        } else {
          return;
        }
      }, 0);
    },
  },
  mounted() {
    this.getReportDataIS();
  },
};
</script>

<style src="../styles/statements-net-asset.scss" lang="scss" />
