<template>
  <section class="login-section">
    <v-container>
      <v-row justify="center">
        <span class="login-title">Geek Accounting</span>
      </v-row>
      <v-row justify="center">
        <v-card
          height="315px"
          width="464px"
          elevation="0"
          rounded="lg"
          class="pa-8 mt-4"
        >
          <v-row class="justify-center mt-1">
            <p class="card-title">Masuk ke Dashboard</p>
          </v-row>
          <v-snackbar
            v-model="getAlertLogin"
            color="warning"
            :timeout="timeout"
            top
            style="margin-top: 80px"
          >
            <v-icon color="white" class="mr-4">mdi-alert</v-icon>
            <span>{{ getMessage }}</span>
            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                icon
                v-bind="attrs"
                @click="getAlertLogin = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
          <v-snackbar
            v-model="getAlertLogout"
            color="success"
            :timeout="timeout"
            width="100px"
            max-height="100px"
            top
            right
          >
            <v-icon color="white" class="mr-4">mdi-information</v-icon>
            <span>Logout berhasil!</span>
            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                icon
                v-bind="attrs"
                @click="getAlertLogout = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
          <v-form
            ref="form"
            @submit.prevent="handleLogin"
            v-model="valid"
            lazy-validation
            class="mt-12"
          >
            <v-text-field
              v-model="user.email"
              :rules="emailRules"
              placeholder="Email"
              required
              outlined
              dense
              background-color="#FAFAFA"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#BBBBBB" class="mr-1">mdi-email-outline</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              v-model="user.password"
              :rules="passwordRules"
              placeholder="Kata Sandi"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="handleClick"
              required
              outlined
              dense
              background-color="#FAFAFA"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#BBBBBB" class="mr-1">mdi-lock-outline</v-icon>
              </template>
            </v-text-field>

            <v-row justify="center" class="mt-1">
              <v-btn
                :disabled="!valid"
                type="submit"
                class="mr-3 btn-login"
                tile
                depressed
              >
                Masuk
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    timeout: 2000,
    valid: true,
    user: {
      email: "",
      password: "",
    },
    emailRules: [
      (v) => !!v || "E-mail harus diisi",
      (v) => /.+@.+\..+/.test(v) || "E-mail harus valid",
    ],
    passwordRules: [(v) => !!v || "Password harus diisi"],
    showPassword: false,
  }),
  computed: {
    getAlertLogin: {
      get() {
        return this.$store.state.authModule.alertLogin;
      },
      set(v) {
        this.$store.commit("setAlertLogin", v);
      },
    },
    getAlertLogout: {
      get() {
        return this.$store.state.authModule.alertLogout;
      },
      set(v) {
        this.$store.commit("setAlertLogout", v);
      },
    },
    getMessage: {
      get() {
        return this.$store.state.authModule.loginErrorMessage;
      },
    },
  },
  methods: {
    handleClick() {
      this.showPassword = !this.showPassword;
    },
    handleLogin() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("loginUser", this.user);
      }
    },
  },
};
</script>

<style src="../../styles/auth.scss" lang="scss" scoped />
