import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../../router/index";

Vue.use(Vuex);

const transactionModule = {
  state: {
    transaction: [],
    nomorReferensi: "",
    errorMessagePosting: "",
  },
  mutations: {
    setTransaction(state, payload) {
      state.transaction = payload;
    },
    setNomorReferensi(state, payload) {
      state.nomorReferensi = payload;
    },
    setErrorMessagePosting(state, payload) {
      state.errorMessagePosting = payload;
    },
  },
  actions: {
    async getListTransactionData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/transaction");
        commit("setTransaction", res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getNomorReferensiData({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/transaction/nomorReferensi"
        );
        commit("setNomorReferensi", res.data);
      } catch (error) {
        console.log(error);
      }
    },
    async addTransaction({ dispatch, commit }, payload) {
      try {
        let res = await axios.post(
          process.env.VUE_APP_API_URL + "/transaction",
          payload
        );
        if (res.data.status === 200) {
          commit("showSnackbarAdd", true);
          router.push({ name: "List Jurnal" });
          dispatch("getListTransactionData");
        } else {
          commit("showSnackbarAlert", true);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async editTransaction({ dispatch, commit }, payload) {
      try {
        let res = await axios.put(
          process.env.VUE_APP_API_URL + "/transaction/" + payload.id,
          payload
        );
        if (res.data.status === 207) {
          commit("showSnackbarAlert", true);
        } else {
          commit("showSnackbarEdit", true);
          router.push({ name: "List Jurnal" });
          dispatch("getListTransactionData");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteTransaction({ dispatch, commit }, id) {
      try {
        await axios.delete(process.env.VUE_APP_API_URL + "/transaction/" + id);
        dispatch("getListTransactionData");
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
    async searchTransaction({ commit }, payload) {
      try {
        let res = await axios.post(
          process.env.VUE_APP_API_URL + "/transaction/search",
          {
            search: payload,
          }
        );
        commit("setTransaction", res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async postingJournal({ dispatch, commit }, payload) {
      try {
        commit("setBtnLoading", true);
        let res = await axios.post(process.env.VUE_APP_API_URL + "/posting", {
          userId: payload.userId,
          transaction: payload.transaction,
        });
        const message = res.data.data.message;
        const result = message.charAt(0).toUpperCase() + message.slice(1);
        if (res.data.status === 207) {
          commit("setErrorMessagePosting", result);
          commit("showSnackbarAlert", true);
        } else {
          commit("showSnackbarPost", true);
          dispatch("getListTransactionData");
        }
      } catch (error) {
        console.log(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },
  },
  getters: {
    getListTransaction(state) {
      return state.transaction.filter((items) => {
        return items.status !== "Posting";
      });
    },
    getNomorReferensi(state) {
      return state.nomorReferensi;
    },
    getErrorMessagePosting(state) {
      return state.errorMessagePosting;
    },
  },
};

export default transactionModule;
