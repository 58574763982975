<template>
  <v-container>
    <v-card class="t-code-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="t-card-title">Kode T</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="7">
            <base-button
              :disabled="listPermission[0] === false"
              @add="showAddDialog"
            >
              <template v-slot:btnText>
                <span>Tambah Kode T</span>
              </template></base-button
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pa-7">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="getTCode"
            :search="search"
            :loading="isLoading"
            loading-text="Sedang memuat data..."
            no-results-text="Data tidak ditemukan"
            :no-data-text="noDataText"
            class="t-code-table"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#3E9FF9"
                    x-small
                    height="24"
                    min-width="24"
                    v-bind="attrs"
                    v-on="on"
                    @click="showDetailItem(item)"
                  >
                    <v-icon color="white" size="14">mdi-eye</v-icon>
                  </v-btn>
                  <v-btn
                    color="#CE5542"
                    x-small
                    height="24"
                    min-width="24"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleDeleteItem(item)"
                    :disabled="listPermission[2] === false"
                  >
                    <v-icon color="white" size="14">mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    color="#E89F3C"
                    x-small
                    height="24"
                    min-width="24"
                    class="ml-1 mr-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleEditItem(item)"
                    :disabled="listPermission[1] === false"
                  >
                    <v-icon color="white" size="14">mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- Detail Dialog -->
    <v-dialog
      v-model="detailTCodeDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Detail Kode T</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="detailTCodeDialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-row class="mt-6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="tCode.code"
                  :readonly="true"
                  placeholder="Masukkan Kode"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="tCode.name"
                  :readonly="true"
                  placeholder="Masukkan Nama"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row
              class="mt-n4"
              v-for="(val, index) in tCode.detail"
              :key="index"
            >
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black"
                  >Tipe {{ index + 1 }}</span
                >
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="tCode.detail[index].name"
                  :readonly="true"
                  placeholder="Masukkan Tipe"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Keterangan</span>
              </v-col>
              <v-col md="10">
                <v-textarea
                  v-model="tCode.description"
                  :readonly="true"
                  placeholder="Masukkan Keterangan"
                  outlined
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="detailTCodeDialog = false"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Tutup
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Detail Dialog -->
    <!-- Add Dialog -->
    <v-dialog
      v-model="addTCodeDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Tambah Kode T</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeTCodeAddDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="addForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleAddTCode"
          >
            <v-row class="mt-6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="input.code"
                  :rules="tCodeRules.concat(checkAddCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="input.name"
                  :rules="tCodeNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row
              class="mt-n4"
              v-for="(val, index) in input.detail"
              :key="index"
            >
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black"
                  >Tipe {{ index + 1 }}</span
                >
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="input.detail[index]"
                  placeholder="Masukkan Tipe"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col md="1">
                <v-btn @click="addInputType" icon v-if="index + 1 === 1">
                  <v-icon color="#3E9FF9">mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn @click="removeInputType(index)" icon v-else>
                  <v-icon color="#EB7765">mdi-minus-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Keterangan</span>
              </v-col>
              <v-col md="10">
                <v-textarea
                  v-model="input.description"
                  :rules="tCodeDescriptionRules"
                  placeholder="Masukkan Keterangan"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeTCodeAddDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Add Dialog -->
    <!-- Edit Dialog -->
    <v-dialog
      v-model="editTCodeDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Ubah Kode T</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="editTCodeDialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="editForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleEditTCode"
          >
            <v-row class="mt-6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="tCodeEdit.code"
                  :rules="tCodeRules.concat(checkEditCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="tCodeEdit.name"
                  :rules="tCodeNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row
              class="mt-n4"
              v-for="(val, index) in tCodeEdit.detail"
              :key="index"
            >
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black"
                  >Tipe {{ index + 1 }}</span
                >
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="tCodeEdit.detail[index]"
                  placeholder="Masukkan Tipe"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col md="1">
                <v-btn @click="addInputType" icon v-if="index + 1 === 1">
                  <v-icon color="#3E9FF9">mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn @click="removeInputType(index)" icon v-else>
                  <v-icon color="#EB7765">mdi-minus-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mb-7"></v-divider>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Keterangan</span>
              </v-col>
              <v-col md="10">
                <v-textarea
                  v-model="tCodeEdit.description"
                  :rules="tCodeDescriptionRules"
                  placeholder="Masukkan Keterangan"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="editTCodeDialog = false"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Edit Dialog -->
    <!-- Delete Dialog -->
    <v-dialog
      v-model="dialogDelete"
      transition="dialog-top-transition"
      max-width="350"
    >
      <v-card color="pt-8">
        <BodyCardDelete />
        <v-card-actions class="justify-center">
          <v-btn
            class="btn-close-dialog mr-4"
            tile
            depressed
            @click="dialogDelete = false"
            >Batal</v-btn
          >
          <v-btn class="btn-confirm" tile depressed @click="confirmDeleteItem"
            >Hapus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Delete Dialog -->
    <v-snackbar
      v-model="getSnackbarAdd"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAddText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAdd = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarEditText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarDelete"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarDeleteText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarDelete = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import BaseButton from "./base/BaseButton.vue";
import BodyCardDelete from "./AppCardBodyDelete.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TCode",
  components: { BaseButton, BodyCardDelete },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    search: "",
    isLoading: true,
    noDataText: "Tidak ada data yang tersedia",
    listPermission: [false, false, false, false],
    headers: [
      {
        text: "Kode",
        align: "start",
        sortable: true,
        value: "code",
        divider: true,
        width: "15%",
      },
      { text: "Nama", value: "name", sortable: true, divider: true },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "20%",
      },
    ],
    valid: true,
    detailTCodeDialog: false,
    addTCodeDialog: false,
    editTCodeDialog: false,
    input: {
      code: "",
      name: "",
      detail: [""],
      description: "",
    },
    tCode: {
      id: null,
      code: "",
      name: "",
      detail: [
        {
          name: "",
        },
      ],
      description: "",
    },
    tCodeEdit: {
      id: null,
      code: "",
      name: "",
      detail: [""],
      description: "",
    },
    tCodeCode: "",
    // Validation
    tCodeRules: [
      (v) => !!v || "Kode T harus diisi",
      (v) => (v && v.length === 3) || "Kode T harus terdiri dari 3 karakter",
    ],
    tCodeNameRules: [(v) => !!v || "Nama Kode T harus diisi"],
    tCodeDescriptionRules: [(v) => !!v || "Keterangan Kode T harus diisi"],
    dialogDelete: false,
    // Snackbar
    snackbarAddText: "Data berhasil ditambahkan!",
    snackbarEditText: "Data berhasil diubah!",
    snackbarDeleteText: "Data berhasil dihapus!",
    timeout: 2500,
  }),
  computed: {
    ...mapGetters(["getTCode", "getListPermissionById"]),
    getAllTCodes() {
      return this.getTCode.map((item) => item.code);
    },
    getSomeTCodes() {
      let codes = this.getAllTCodes;
      let index = codes.indexOf(this.tCodeCode);
      if (index !== -1) {
        codes.splice(index, 1);
      }
      return codes;
    },
    checkAddCodeRules() {
      return () =>
        this.getAllTCodes.includes(this.input.code) === false ||
        "Kode T sudah ada";
    },
    checkEditCodeRules() {
      return () =>
        this.getSomeTCodes.includes(this.tCode.code) === false ||
        "Kode T sudah ada";
    },
    getSnackbarAdd: {
      get() {
        return this.$store.state.snackbarAdd;
      },
      set(v) {
        this.$store.commit("showSnackbarAdd", v);
      },
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
    getSnackbarDelete: {
      get() {
        return this.$store.state.snackbarDelete;
      },
      set(v) {
        this.$store.commit("showSnackbarDelete", v);
      },
    },
  },
  watch: {
    getTCode() {
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions(["getTCodeData"]),
    selectItem(value) {
      this.itemPerPage = value;
    },
    closeTCodeAddDialog() {
      this.addTCodeDialog = false;
      this.$refs.addForm.reset();
      this.input.detail = [""];
    },
    showAddDialog() {
      this.addTCodeDialog = true;
    },
    showDetailItem(item) {
      this.tCode.code = item.code;
      this.tCode.name = item.name;
      this.tCode.description = item.description;
      if (item.detail.length === 0) {
        this.tCode.detail = [""];
      } else {
        this.tCode.detail = item.detail;
      }
      // console.log(item);
      this.detailTCodeDialog = true;
    },
    handleAddTCode() {
      if (this.$refs.addForm.validate()) {
        for (let i = 0; i < this.input.detail.length; i++) {
          this.input.detail[i] = {
            name: this.input.detail[i],
          };
        }
        // console.log(this.input);
        let data = {
          code: this.input.code,
          name: this.input.name,
          description: this.input.description,
          detail: this.input.detail,
        };
        this.$store.dispatch("addTCodeData", data);
        this.addTCodeDialog = false;
        this.$refs.addForm.reset();
      }
    },
    handleEditItem(item) {
      this.tCodeEdit.detail.splice(0, this.tCodeEdit.detail.length);
      this.tCodeEdit.id = item.id;
      this.tCodeEdit.code = item.code;
      this.tCodeEdit.name = item.name;
      this.tCodeEdit.description = item.description;
      this.tCodeCode = item.code;
      this.editTCodeDialog = true;
      if (item.detail.length === 0) {
        this.tCodeEdit.detail = [""];
      } else {
        for (let i = 0; i < item.detail.length; i++) {
          this.tCodeEdit.detail[i] = item.detail[i].name;
        }
      }
    },
    handleEditTCode() {
      if (this.$refs.editForm.validate()) {
        for (let i = 0; i < this.tCodeEdit.detail.length; i++) {
          this.tCodeEdit.detail[i] = {
            name: this.tCodeEdit.detail[i],
          };
        }
        let data = {
          id: this.tCodeEdit.id,
          code: this.tCodeEdit.code,
          name: this.tCodeEdit.name,
          description: this.tCodeEdit.description,
          detail: this.tCodeEdit.detail,
        };
        this.$store.dispatch("editTCodeData", data);
        this.editTCodeDialog = false;
      }
    },
    handleDeleteItem(item) {
      this.tCode.id = item.id;
      this.dialogDelete = true;
    },
    confirmDeleteItem() {
      this.$store.dispatch("deleteTCode", this.tCode.id);
      this.dialogDelete = false;
    },
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 32;
      });

      this.listPermission[1] = permissions.some((item) => {
        return item === 33;
      });

      this.listPermission[2] = permissions.some((item) => {
        return item === 34;
      });

      this.listPermission[3] = permissions.some((item) => {
        return item === 35;
      });
    },
    addInputType() {
      if (this.addTCodeDialog) {
        this.input.detail.push("");
      }
      if (this.editTCodeDialog) {
        this.tCodeEdit.detail.push("");
      }
    },
    removeInputType(index) {
      if (this.addTCodeDialog) {
        this.input.detail.splice(index, 1);
      }
      if (this.editTCodeDialog) {
        this.tCodeEdit.detail.splice(index, 1);
      }
    },
  },
  async created() {
    await this.setListPermission();
    if (this.listPermission[3] === false) {
      this.isLoading = false;
      this.noDataText = "Anda tidak memiliki izin untuk mengakses data";
    } else {
      await this.getTCodeData();
    }
  },
};
</script>

<style src="../styles/t-code.scss" lang="scss" />
