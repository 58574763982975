<template>
  <v-container>
    <!-- <v-snackbar
      v-model="getSnackbarAlert"
      :timeout="timeout"
      color="warning"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAlertText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAlert = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->
    <pop-up-warning
      :isShow="isShowWarning"
      :text="textWarning"
      @close-modal="closeModalWarning"
    >
    </pop-up-warning>
    <v-card class="journal-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="card-title">Ubah Transaksi</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-form
        @submit.prevent="handleEditAccount"
        ref="editForm"
        v-model="valid"
        lazy-validation
      >
        <v-row class="ml-n16 pr-8">
          <v-col md="6">
            <v-row class="mt-4">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Referensi</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="journal.code"
                  :rules="transactionCodeRules"
                  placeholder="Masukkan nomor referensi"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Periode</span>
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="journal.period"
                  :items="periodItems"
                  :item-text="(item) => item.year + item.month"
                  return-object
                  :rules="periodRules"
                  placeholder="Pilih"
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                  @change="onChangeDate(journal.date)"
                >
                  <template v-slot:item="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ getMonth(item.month) || "Audit" }} {{ item.year }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n7">
              <v-col md="4" class="mt-3 text-right">
                <span class="input-title">Tanggal</span>
              </v-col>
              <v-col md="8">
                <date-picker
                  v-model="journal.date"
                  type="date"
                  format="YYYYMMDD"
                  @change="onChangeDate"
                  @clear="onClearDate"
                  :popup-style="{
                    top: '365px',
                    left: '420px',
                  }"
                  placeholder="Masukkan Tanggal"
                  class="journal-datepicker mt-2"
                >
                </date-picker>
                <v-alert
                  v-show="dateAlert"
                  transition="slide-y-transition"
                  class="date-alert"
                  >{{ dateAlertText }}</v-alert
                >
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Penginput</span>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="journal.user"
                  :readonly="true"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6">
            <v-row class="mt-4">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Tipe Transaksi</span>
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="journal.transactionType"
                  :items="getTransactionType"
                  item-text="name"
                  return-object
                  :rules="transactionTypeRules"
                  @input="limitSelectTransType"
                  :error-messages="transTypeErrMessage"
                  placeholder="Pilih"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Tipe Jurnal</span>
              </v-col>
              <v-col md="8">
                <v-select
                  v-model="journal.journalType"
                  :items="getJournalType"
                  item-text="name"
                  return-object
                  :rules="journalTypeRules"
                  placeholder="Pilih"
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-n6">
              <v-col md="4" class="mt-2 text-right">
                <span class="input-title">Uraian</span>
              </v-col>
              <v-col md="8">
                <v-textarea
                  v-model="journal.note"
                  :rules="descriptionRules"
                  placeholder="Masukkan Uraian"
                  background-color="#FAFAFA"
                  height="115"
                  no-resize
                  outlined
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row> -->
          </v-col>
        </v-row>
        <v-card-title class="mt-n4 pl-7">
          <span class="card-title">Detail Transaksi</span>
        </v-card-title>
        <v-card-text>
          <v-row class="transaction-details-table-outer">
            <v-col>
              <table class="transaction-details-table">
                <thead>
                  <th class="text-left" style="width: 160px">Kode Akun</th>
                  <th class="text-left" style="width: 210px">Nama</th>
                  <th class="text-left" style="width: 220px">Debit</th>
                  <th class="text-left" style="width: 220px">Kredit</th>
                  <th class="text-left" style="width: 230px">Deskripsi</th>
                  <th
                    v-for="(item, index) in TCode"
                    :key="index"
                    class="text-left"
                    style="width: 300px"
                  >
                    T Code {{ index + 1 }}
                    <!-- <v-btn v-if="index + 1 == 3" @click="addNewAccountRow" icon>
                      <v-icon color="#3E9FF9">mdi-plus-circle-outline</v-icon>
                    </v-btn> -->
                  </th>
                  <th class="text-center" style="width: 85px">
                    <v-btn @click="addNewAccountRow" icon>
                      <v-icon color="#3E9FF9">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="(input, index) in journal.transactionDetail"
                    :key="input.id"
                  >
                    <td class="text-center">
                      <v-row no-gutters>
                        <input v-model="input.account.id" type="text" hidden />
                        <v-col md="6">
                          <input
                            :value="getAccountCode(input.account.id)"
                            readonly
                            class="code-account-input"
                          />
                        </v-col>
                        <v-col md="4">
                          <v-btn
                            @click="showDialog(index)"
                            class="btn-add-account ml-7"
                            tile
                            depressed
                          >
                            <v-icon color="white" size="25"
                              >mdi-plus-circle</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </td>
                    <td class="text-center">
                      <input
                        :value="getAccountName(input.account.id)"
                        readonly
                        class="name-input"
                      />
                    </td>
                    <td class="text-center">
                      <input class="rupiah-input" placeholder="Rp" disabled />
                      <vue-numeric
                        v-model="input.debet"
                        :empty-value="0"
                        separator=","
                        v-bind:precision="2"
                        class="debit-input"
                      >
                      </vue-numeric>
                    </td>
                    <td class="text-center">
                      <input class="rupiah-input" placeholder="Rp" disabled />
                      <vue-numeric
                        v-model="input.kredit"
                        :empty-value="0"
                        separator=","
                        v-bind:precision="2"
                        class="debit-input"
                      >
                      </vue-numeric>
                    </td>
                    <td class="text-center">
                      <input v-model="input.memo" class="memo-input" />
                    </td>
                    <td v-for="(item, i) in input.tblTCode" :key="item.id">
                      <input v-model="item.id" hidden />
                      <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs"> -->
                      <v-select
                        v-model="item.value"
                        :items="dataTCode[index][i]['detail' + index + i]"
                        item-value="value"
                        item-text="name"
                        class="mt-6"
                        style="font-size: 14px"
                        append-icon="mdi-chevron-down"
                        :placeholder="item.value"
                        outlined
                        dense
                        @click="clickSearchTcode(i, index)"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item class="mt-n5 mb-n5">
                            <v-list-item-content>
                              <v-text-field
                                v-model="
                                  dataSearch[index][i][
                                    'searchTcode' + index + i
                                  ]
                                "
                                placeholder="Search"
                                @input="actionSearchTcode(i, index)"
                              ></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                      <!-- </div>
                        </template>
                        <span>{{ getTcodeDetailName(item.value) }}</span>
                      </v-tooltip> -->
                    </td>
                    <td v-if="index > 1" class="text-center">
                      <v-btn @click="removeAccountRow" icon>
                        <v-icon color="#3E9FF9"
                          >mdi-minus-circle-outline</v-icon
                        >
                      </v-btn>
                    </td>
                    <td v-else></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td class="body-2 text-right font-weight-black">Total</td>
                    <td class="text-right">
                      Rp. {{ formatRupiah(getDebetTotal) }}
                    </td>
                    <td class="text-right">
                      Rp. {{ formatRupiah(getKreditTotal) }}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td class="body-2 text-right font-weight-black">
                      Different
                    </td>
                    <td class="text-right">
                      Rp. {{ formatRupiah(getOutOfBalance) }}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
          <!-- <v-row justify="space-between" class="mr-n4">
            <v-col md="3" class="ml-4 mt-3">
              <span class="row-title-img">Gambar</span>
            </v-col>
            <v-col md="3" class="mr-1">
              <label>
                <v-btn
                  @click="selectFile"
                  class="btn-upload"
                  color="#F1FFEF"
                  tile
                  depressed
                >
                  <v-icon size="20" color="#6BC282">mdi-cloud-upload</v-icon>
                  <span class="text-capitalize ml-2">Lampirkan Gambar</span>
                </v-btn>
                <input
                  ref="file"
                  type="file"
                  accept="image/png, image/jpeg"
                  multiple
                  @change="onFileChange"
                  style="visibility: hidden"
                />
              </label>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-n6">
            <div
              v-if="isOnFileChange === false"
              class="box-img-default"
              justify="center"
            >
              <v-col md="2">
                <v-img
                  :src="imgDefault"
                  width="60"
                  height="50"
                  class="mt-2 rounded"
                ></v-img>
              </v-col>
            </div>
            <v-row v-else class="box-img ml-7 mr-7 mt-1" justify="center">
              <v-col v-for="(img, index) in journal.img" :key="index" md="3">
                <div class="container-img">
                  <v-img
                    :src="img"
                    width="246"
                    height="138"
                    class="rounded"
                  ></v-img>
                  <v-btn class="btn-download" icon>
                    <v-icon color="white">mdi-download</v-icon>
                  </v-btn>
                  <v-btn @click="zoomImage(img.src)" class="btn-view" icon>
                    <v-icon color="white">mdi-eye</v-icon>
                  </v-btn>
                  <v-btn @click="deleteImage(img.id)" class="btn-delete" icon>
                    <v-icon color="white">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-row> -->
        </v-card-text>
        <v-card-actions class="justify-end mt-4 mr-6">
          <v-btn @click="$router.go(-1)" class="btn-cancel mr-2" tile depressed>
            Batal
          </v-btn>
          <v-btn
            type="submit"
            :disabled="!valid"
            class="btn-save-account"
            tile
            depressed
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <!-- <v-dialog v-model="dialogZoomImage" max-width="850">
      <v-row>
        <v-col md="2">
          <v-btn
            @click="dialogZoomImage = false"
            color="white"
            min-width="20"
            width="20"
            height="30"
            class="rounded-xl"
          >
            <v-icon size="16" color="black" dark>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <img :src="imgZoom" width="800" height="auto" />
    </v-dialog> -->
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="subtitle-1">Browse Kode Akun</span>
          <v-spacer></v-spacer>
          <v-btn small text @click="dialog = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="mb-n8">
          <v-row justify="end">
            <v-col md="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                placeholder="search"
                outlined
                dense
                >Search</v-text-field
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-card class="mt-2" elevation="1">
            <v-data-table
              :headers="headers"
              :items="getAccount"
              :search="search"
              class="account-table"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="#E46D47" x-small height="24" min-width="20">
                  <v-icon color="white" size="16" @click="addAccountCode(item)">
                    mdi-plus-circle
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "axios";
import moment from "moment";
import VueNumeric from "vue-numeric";
import { mapGetters, mapActions } from "vuex";
import PopUpWarning from "./base/popup/PopUpWarning.vue";

export default {
  name: "JournalEdit",
  components: { DatePicker, VueNumeric, PopUpWarning },
  data: () => ({
    valid: true,
    periodItems: [],
    journal: {
      date: "",
      code: "",
      period: "",
      user: "",
      transactionType: [],
      journalType: "",
      // note: "",
      transactionDetail: [],
      // img: [],
    },
    // img: "",
    index: null,
    periodeId: null,
    // imgDefault: require("@/assets/images/default-2.png"),
    // imgZoom: null,
    dialog: false,
    dialogZoomImage: false,
    showNewAccountRow: false,
    isOnFileChange: false,
    dateAlert: false,
    dateAlertText: "",
    // Datatable
    headers: [
      {
        text: "Kode",
        align: "start",
        sortable: false,
        value: "code",
        divider: true,
        width: "10%",
      },
      { text: "Nama", value: "name", divider: true, sortable: false },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    search: "",
    // Validation
    transTypeErrMessage: "",
    transactionCodeRules: [(v) => !!v || "Nomor Referensi harus diisi"],
    periodRules: [(v) => !!v || "Periode harus dipilih"],
    transactionTypeRules: [
      (v) => (v && v.length > 0) || "Tipe transaksi harus dipilih",
    ],
    journalTypeRules: [(v) => !!v || "Tipe jurnal harus dipilih"],
    // descriptionRules: [(v) => !!v || "Uraian harus diisi"],
    // Snackbar
    timeout: 2500,
    snackbarAlertText: "Nomor Referensi sudah ada!",
    TCode: [],
    isShowWarning: false,
    textWarning: "",
    timer: null,
    isSubmit: false,
    dataSearch: [
      [
        {
          searchTcode: "",
        },
      ],
    ],
    dataTCode: [[{}]],
  }),
  computed: {
    ...mapGetters([
      "getPeriodItems",
      // "getOpenPeriod",
      "getPeriod",
      "getProfile",
      "getJournalType",
      "getTransactionType",
      "getAccount",
      "getTCode",
    ]),
    getSnackbarAlert: {
      get() {
        return this.$store.state.snackbarAlert;
      },
      set(v) {
        this.$store.commit("showSnackbarAlert", v);
      },
    },
    getDebetTotal() {
      let total;
      let accountDebet = [];
      const num = 0;
      let accountDebetTotal;
      for (let i = 0; i < this.journal.transactionDetail.length; i++) {
        if (this.journal.transactionDetail[i].debet !== "") {
          accountDebet.push(this.journal.transactionDetail[i].debet);
        } else {
          accountDebet.push(num);
        }
      }
      accountDebetTotal = accountDebet.reduce((acc, val) => {
        return acc + val;
      }, 0);
      total = accountDebetTotal;
      if (this.journal.transactionDetail[0] !== undefined) {
        if (
          this.journal.transactionDetail[0].debet === "" &&
          this.journal.transactionDetail[1].debet === "" &&
          this.journal.transactionDetail.length === 2
        ) {
          return 0;
        } else {
          return total;
        }
      } else {
        return total;
      }
    },
    getKreditTotal() {
      let total;
      let accountKredit = [];
      const num = 0;
      let accountKreditTotal;
      for (let i = 0; i < this.journal.transactionDetail.length; i++) {
        if (this.journal.transactionDetail[i].kredit !== "") {
          accountKredit.push(this.journal.transactionDetail[i].kredit);
        } else {
          accountKredit.push(num);
        }
      }
      accountKreditTotal = accountKredit.reduce((acc, val) => {
        return acc + val;
      }, 0);
      total = accountKreditTotal;
      if (this.journal.transactionDetail[0] !== undefined) {
        if (
          this.journal.transactionDetail[0].kredit === "" &&
          this.journal.transactionDetail[1].kredit === "" &&
          this.journal.transactionDetail.length === 2
        ) {
          return 0;
        } else {
          return total;
        }
      } else {
        return total;
      }
    },
    getOutOfBalance() {
      this.checkValidDebetKredit();

      var total = this.getDebetTotal - this.getKreditTotal;

      var check = total.toFixed(2) === "-0.00" ? true : false;

      return check ? total * -1 : total;
    },
  },
  watch: {
    getSnackbarAlert(val) {
      if (val === true) {
        this.isShowWarning = val;
        this.textWarning = this.snackbarAlertText;
        this.timer = setTimeout(() => {
          this.isShowWarning = false;
          this.getSnackbarAlert = false;
        }, 2500);
      }
    },
  },
  methods: {
    ...mapActions([
      "getPeriodData",
      "getProfileData",
      "getJournalTypeData",
      "getTransactionTypeData",
      "getAccountData",
      "getTCodeData",
    ]),
    actionSearchTcode(i, index) {
      if (this.dataSearch[index][i]["searchTcode" + index + i] == "") {
        this.dataTCode[index][i]["detail" + index + i] =
          this.getTCode[i].detail;
      } else {
        this.getTCodeData();
        this.dataTCode[index][i]["detail" + index + i] = this.getTCode[
          i
        ].detail.filter((item) => {
          return (
            item.name
              .toLowerCase()
              .indexOf(
                this.dataSearch[index][i][
                  "searchTcode" + index + i
                ].toLowerCase()
              ) > -1
          );
        });
      }
    },
    clickSearchTcode(i, index) {
      // // this.dataTCode.splice(0, 1);
      if (this.dataSearch[index][i]["searchTcode" + index + i] !== "") {
        this.getTCodeData();
        this.dataTCode[index][i]["detail" + index + i] = this.getTCode[
          i
        ].detail.filter((item) => {
          return (
            item.name
              .toLowerCase()
              .indexOf(
                this.dataSearch[index][i][
                  "searchTcode" + index + i
                ].toLowerCase()
              ) > -1
          );
        });
      } else {
        this.getTCodeData();
        this.dataTCode[index][i]["detail" + index + i] =
          this.getTCode[i].detail;
      }
    },
    limitSelectTransType(e) {
      if (e.length > 2) {
        this.transTypeErrMessage = "Maksimal pilih 2 tipe transaksi";
      } else {
        this.transTypeErrMessage = "";
      }
    },
    getMonth(value) {
      return this.getPeriodItems
        .filter((e) => e.value === value)
        .map((item) => item.name)
        .toString();
    },
    getAccountCode(id) {
      return this.getAccount
        .filter((e) => e.id === id)
        .map((item) => item.code)
        .toString();
    },
    getAccountName(id) {
      return this.getAccount
        .filter((e) => e.id === id)
        .map((item) => item.name)
        .toString();
    },
    getPeriode(id) {
      return this.getPeriod
        .filter((e) => e.periodeId === id)
        .slice(0, 1)
        .shift();
    },
    onChangeDate(date) {
      if (!date) {
        this.dateAlert = true;
        this.dateAlertText = "Tanggal harus dipilih";
        this.valid = false;
        return;
      }
      let year = date.getFullYear();
      let month = moment(date).locale("id").format("MMMM");
      let yearPeriod = this.journal.period.year;
      let monthPeriod = this.getMonth(this.journal.period.month);
      monthPeriod = monthPeriod === "Audit" ? "Desember" : monthPeriod;
      if (year != yearPeriod && month == monthPeriod) {
        this.dateAlert = true;
        this.dateAlertText = "Tahun harus sesuai dengan periode";
        this.valid = false;
      } else if (month != monthPeriod && year == yearPeriod) {
        this.dateAlert = true;
        this.dateAlertText = "Bulan harus sesuai dengan periode";
        this.valid = false;
      } else if (year != yearPeriod && month != monthPeriod) {
        this.dateAlert = true;
        this.dateAlertText = "Bulan dan tahun harus sesuai dengan periode";
        this.valid = false;
      } else {
        this.dateAlert = false;
        this.valid = true;
      }
    },
    onClearDate(date) {
      if (!date) {
        this.dateAlert = true;
        this.dateAlertText = "Tanggal harus dipilih";
        this.valid = false;
      } else {
        this.dateAlert = false;
        this.valid = true;
      }
    },
    async getTransactionDetailData() {
      try {
        let id = this.$route.params.id;
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/transaction/detail/" + id
        );
        let data = res.data.data;

        this.dataTCode.splice(0, 1);
        this.dataSearch.splice(0, 1);
        for (let i = 0; i < data.transactionDetail.length; i++) {
          // v-model search
          let search = [];
          // option select
          let select = [];
          for (let e = 0; e < this.getTCode.length; e++) {
            // search
            let newSearch = {
              id: i,
              ["searchTcode" + i + e]: "",
            };
            search.push(newSearch);
            // select
            let newSelect = {
              ["detail" + i + e]: this.getTCode[e].detail,
            };
            select.push(newSelect);
          }
          this.dataSearch.push(search);
          this.dataTCode.push(select);
        }

        this.journal.code = data.code;
        this.journal.date = new Date(data.date);
        this.journal.period = this.getPeriode(data.periode.id);
        this.journal.user = data.user.name;
        this.journal.transactionType.push(data.transactionType);
        if (data.transactionType2 !== null) {
          this.journal.transactionType.push(data.transactionType2);
        }
        this.journal.journalType = data.journalType;
        // this.journal.note = data.note;
        this.journal.transactionDetail = data.transactionDetail;
        this.TCode = data.transactionDetail[0].tblTCode;
      } catch (error) {
        console.log(error);
      }
    },
    checkValidDebetKredit() {
      for (let i = 0; i < this.journal.transactionDetail.length; i++) {
        if (
          (this.journal.transactionDetail[i].debet === 0 &&
            this.journal.transactionDetail[i].kredit === 0) ||
          (this.journal.transactionDetail[i].debet === 0 &&
            this.journal.transactionDetail[i].kredit === "") ||
          (this.journal.transactionDetail[i].debet === "" &&
            this.journal.transactionDetail[i].kredit === 0) ||
          (this.journal.transactionDetail[i].debet === "" &&
            this.journal.transactionDetail[i].kredit === "")
        ) {
          this.valid = false;
          break;
        } else {
          this.valid = true;
        }
      }
    },
    showDialog(index) {
      this.index = index;
      this.dialog = true;
    },
    addNewAccountRow() {
      let tblTCode = [];
      for (let i = 0; i < this.TCode.length; i++) {
        tblTCode.push({ id: i + 1, value: "" });
      }
      this.journal.transactionDetail.push({
        account: [],
        debet: "",
        kredit: "",
        // memo: "",
        tblTCode: tblTCode,
      });

      let lengthData = this.dataTCode.length;
      // search
      let search = [];
      // select
      let select = [];
      for (let i = 0; i < this.getTCode.length; i++) {
        // search
        let newSearch = {
          ["searchTcode" + lengthData + i]: "",
        };
        search.push(newSearch);
        // select
        let newSelect = {
          ["detail" + lengthData + i]: this.getTCode[i].detail,
        };
        select.push(newSelect);
      }
      this.dataSearch.push(search);
      this.dataTCode.push(select);
    },
    removeAccountRow() {
      this.journal.transactionDetail.pop();
      this.dataSearch.pop();
      this.dataTCode.pop();
    },
    formatRupiah(value) {
      var rupiah = Intl.NumberFormat("id-ID", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);

      return rupiah;
    },
    // getTcodeDetailName(id) {
    //   let name = [];
    //   this.getTCode.map((el) => {
    //     if (el.detail.length > 0) {
    //       for (let i = 0; i < el.detail.length; i++) {
    //         if (el.detail[i].id === id) {
    //           name.push(el.detail[i].name);
    //         }
    //       }
    //     }
    //   });
    //   return name[0];
    // },
    // selectFile() {
    //   let fileInputElement = this.$refs.file;
    //   fileInputElement.click();
    // },
    // onFileChange(e) {
    //   for (const i of Object.keys(e.target.files)) {
    //     this.journal.img.push({
    //       id: i,
    //       src: URL.createObjectURL(e.target.files[i]),
    //     });
    //   }
    //   this.isOnFileChange = true;
    // },
    // deleteImage(index) {
    //   let images = this.journal.img;
    //   images.splice(
    //     images.findIndex((item) => item.id === index),
    //     1
    //   );
    //   if (images.length === 0) {
    //     this.isOnFileChange = false;
    //   }
    // },
    // zoomImage(img) {
    //   this.imgZoom = img;
    //   this.dialogZoomImage = true;
    // },
    addAccountCode(item) {
      let index = this.index;
      for (let i = index; i < this.journal.transactionDetail.length; i++) {
        this.journal.transactionDetail[index].account.id = item.id;
      }
      this.dialog = false;
    },
    handleEditAccount() {
      if (this.$refs.editForm.validate()) {
        let transactionDetail = [];
        let account = {};
        for (let i = 0; i < this.journal.transactionDetail.length; i++) {
          account = {
            account: { id: this.journal.transactionDetail[i].account.id },
            debet:
              this.journal.transactionDetail[i].debet == ""
                ? 0
                : this.journal.transactionDetail[i].debet,
            kredit:
              this.journal.transactionDetail[i].kredit == ""
                ? 0
                : this.journal.transactionDetail[i].kredit,
            memo: this.journal.transactionDetail[i].memo,
            tblTCode: JSON.stringify(
              this.journal.transactionDetail[i].tblTCode
            ),
          };
          transactionDetail.push(account);
        }
        let date = moment(this.journal.date).format("YYYY-MM-DD HH:mm:ss");
        let transactionType2;
        if (
          this.journal.transactionType.length > 1 &&
          this.journal.transactionType[1]
        ) {
          transactionType2 = { id: this.journal.transactionType[1].id };
        } else {
          transactionType2 = null;
        }
        let data = {
          id: parseInt(this.$route.params.id),
          user: { id: this.getProfile.id },
          journalType: { id: parseInt(this.journal.journalType.id) },
          transactionType: { id: parseInt(this.journal.transactionType[0].id) },
          transactionType2: transactionType2,
          periode: { id: parseInt(this.journal.period.periodeId) },
          code: this.journal.code,
          date: date,
          // note: this.journal.note,
          // attachment: this.img,
          transactionDetail: transactionDetail,
        };
        this.$store.dispatch("editTransaction", data);
        this.isSubmit = true;
      }
    },
    closeModalWarning() {
      this.isShowWarning = false;
      clearTimeout(this.timer);
      this.getSnackbarAlert = false;
    },
    confirmLeave() {
      return window.confirm(
        "Do you really want to leave? Changes you made may not be saved."
      );
    },
    confirmStay() {
      return !this.confirmLeave();
    },
    beforeWindowUnload(e) {
      e.preventDefault();
      e.returnValue = "";
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSubmit == false) {
      if (this.confirmStay()) {
        next(false);
      } else {
        next();
      }
    } else {
      next();
    }
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  async created() {
    await this.getPeriodData();
    await this.getProfileData();
    await this.getJournalTypeData();
    await this.getTransactionTypeData();
    await this.getAccountData();
    await this.getTCodeData();
    await this.getTransactionDetailData();
    this.periodItems = await this.getPeriod;

    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
};
</script>

<style src="../styles/journal-add.scss" lang="scss" />
