<template>
  <v-btn
    @click="report"
    :loading="loading"
    :disabled="disabled"
    :color="color"
    class="btn-print"
    small
    tile
    depressed
  >
    <slot name="btnText"></slot>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButtonExport",
  data: () => ({
    // color: "#59B187",
  }),
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#59B187",
    },
  },
  methods: {
    report() {
      this.$emit("export");
    },
  },
};
</script>

<style scoped>
.btn-print {
  color: #ffffff;
  text-transform: capitalize;
  height: 33px !important;
  width: auto;
  border-radius: 3px;
}
</style>
