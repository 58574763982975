<template>
  <v-container>
    <v-card class="report-card mt-6 ml-6 mr-6 pb-14">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="report-card-title">Laporan Trial Balance</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-10 mt-7">
        <v-col>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-row class="mt-n1">
              <v-row>
                <v-col md="2" class="mt-4">
                  <label>
                    <span class="input-title">Tahun</span>
                  </label>
                </v-col>
                <v-col md="10" class="ml-n10">
                  <date-picker
                    v-model="report.year"
                    type="year"
                    format="YYYY"
                    value-type="format"
                    @change="onChangeYear"
                    @close="onClosePanelYear"
                    class="datepicker mt-2"
                    placeholder="Pilih"
                    :popup-style="{
                      top: '235px',
                      left: '455px',
                    }"
                  >
                  </date-picker>
                  <v-alert
                    v-show="yearAlert"
                    transition="slide-y-transition"
                    class="yearpicker-alert"
                    >Tahun harus dipilih</v-alert
                  >
                </v-col>
              </v-row>
            </v-row>
            <v-row class="mt-8">
              <v-row>
                <v-col md="2" class="mt-4">
                  <label>
                    <span class="input-title">Periode Awal</span>
                  </label>
                </v-col>
                <v-col md="10" class="ml-n10">
                  <v-select
                    v-model="report.periodAwal"
                    :items="firstPeriodFilter"
                    :item-text="(item) => item.month"
                    return-object
                    :rules="periodRules"
                    :loading="periodLoading"
                    no-data-text="Tidak ada data yang tersedia"
                    clearable
                    placeholder="Pilih"
                    class="mt-2"
                    background-color="#FAFAFA"
                    append-icon="mdi-chevron-down"
                    outlined
                    dense
                    @change="onChangePeriodAwal"
                  >
                    <template v-slot:item="{ item }">
                      <span class="d-flex justify-center" style="width: 100%">
                        {{ getMonth(item.month) || "Audit" }}
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-row>
            <v-row class="mt-n1">
              <v-row>
                <v-col md="2" class="mt-4">
                  <label>
                    <span class="input-title">Periode Akhir</span>
                  </label>
                </v-col>
                <v-col md="10" class="ml-n10">
                  <v-select
                    v-model="report.periodAkhir"
                    :items="periodFilter"
                    :item-text="(item) => item.month"
                    return-object
                    :rules="periodRules"
                    :loading="periodLoading"
                    no-data-text="Tidak ada data yang tersedia"
                    clearable
                    placeholder="Pilih"
                    class="mt-2"
                    background-color="#FAFAFA"
                    append-icon="mdi-chevron-down"
                    outlined
                    dense
                    @change="onChangePeriodAkhir"
                  >
                    <template v-slot:item="{ item }">
                      <span class="d-flex justify-center" style="width: 100%">
                        {{ getMonth(item.month) || "Audit" }}
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-row>
            <v-row class="mt-12" justify="center">
              <!-- <v-btn
                @click="handlePreview"
                class="btn-preview mr-4"
                small
                tile
                depressed
                >Pratinjau</v-btn
              > -->
              <!-- <v-btn class="btn-print-pdf" small tile depressed
                >Cetak PDF</v-btn
              > -->
              <base-button-export
                @export="previewAction"
                :color="'#3E9FF9'"
                :disabled="!valid"
                class="mr-2"
              >
                <template v-slot:btnText>
                  <span>Preview</span>
                </template>
              </base-button-export>
              <base-button-export
                @export="exportToExcel"
                :loading="getBtnLoading"
                :disabled="!valid || getBtnLoading"
                class="ml-2"
              >
                <template v-slot:btnText>
                  <span>Cetak Excel</span>
                </template>
              </base-button-export>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-show="previewTable" class="report-card mt-6 ml-6 mr-6 pb-14">
      <div v-if="loadingTable" class="d-flex justify-center align-center pt-14">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <div
          v-if="errorState"
          class="d-flex justify-center align-center pt-14 text-center"
        >
          <h3>Oops, something went wrong. Please try again later.</h3>
        </div>
        <div v-else>
          <div class="title-preview">
            <h5>DANA PENSIUN SAMUDERA INDONESIA UTAMA</h5>
            <h5>TRIAL BALANCE</h5>
            <h5>
              PERIOD :
              {{ periodFrom }}
              TO
              {{ periodTo }}
            </h5>
          </div>
          <div class="preview-table">
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <th rowspan="2">Account Code</th>
                    <th rowspan="2">Account Name</th>
                    <th rowspan="2">Beginning Balance</th>
                    <th colspan="2">Movement</th>
                    <th rowspan="2">Closing Balance</th>
                  </tr>
                  <tr>
                    <th>Debit</th>
                    <th>Kredit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, index) in dataTable"
                    :key="index"
                    style="height: 30px"
                  >
                    <td>{{ data.accountCode }}</td>
                    <td :style="data.fontWeight">{{ data.accountName }}</td>
                    <td
                      :class="{
                        'text-red': parseFloat(data.beginningBalance) < 0,
                      }"
                      :style="data.fontWeight"
                    >
                      {{ data.beginningBalance }}
                    </td>
                    <td
                      :class="{ 'text-red': parseFloat(data.debit) < 0 }"
                      :style="data.fontWeight"
                    >
                      {{ data.debit }}
                    </td>
                    <td
                      :class="{ 'text-red': parseFloat(data.kredit) < 0 }"
                      :style="data.fontWeight"
                    >
                      {{ data.kredit }}
                    </td>
                    <td
                      :class="{
                        'text-red': parseFloat(data.closingBalance) < 0,
                      }"
                      :style="data.fontWeight"
                    >
                      {{ data.closingBalance }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import BaseButtonExport from "./base/BaseButtonExport.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions, mapGetters } from "vuex";
import { setNumberFormat } from "../functions/NumberFormat.ts";

export default {
  name: "Report",
  components: { BaseButtonExport, DatePicker },
  data: () => ({
    valid: true,
    periods: [],
    months: [
      { value: "01", name: "Januari" },
      { value: "02", name: "Februari" },
      { value: "03", name: "Maret" },
      { value: "04", name: "April" },
      { value: "05", name: "Mei" },
      { value: "06", name: "Juni" },
      { value: "07", name: "Juli" },
      { value: "08", name: "Agustus" },
      { value: "09", name: "September" },
      { value: "10", name: "Oktober" },
      { value: "11", name: "November" },
      { value: "12", name: "Desember" },
    ],
    report: {
      periodAwal: "",
      periodAkhir: "",
      year: null,
    },
    periodLoading: true,
    reportTypeLoading: true,
    // Validation Rules
    periodRules: [(v) => !!v || "Periode harus dipilih"],
    yearAlert: false,
    previewTable: false,
    dataTable: [],
    statusPreviewButton: false,
    periodFrom: "",
    periodTo: "",
    loadingTable: true,
    errorState: false,
  }),
  computed: {
    ...mapGetters(["getPeriod", "getReportType"]),
    periodFilter() {
      return this.periods.filter((item) => item.year == this.report.year);
    },
    firstPeriodFilter() {
      return this.periods.filter((item) => item.year == this.report.year);
    },
    getBtnLoading: {
      get() {
        return this.$store.state.btnLoading;
      },
      set(v) {
        this.$store.commit("setBtnLoading", v);
      },
    },
  },
  watch: {
    getPeriod() {
      this.periodLoading = false;
    },
    statusPreviewButton(val) {
      if (val == true) {
        // this.periodFrom = this.report.periodAwal.month + this.report.year;
        // this.periodTo = this.report.periodAkhir.month + this.report.year;
        this.periodFrom =
          (this.report.periodAwal.month === "13"
            ? "01"
            : this.report.periodAwal.month) + this.report.year;
        this.periodTo =
          (this.report.periodAkhir.month === "13"
            ? "12"
            : this.report.periodAkhir.month) + this.report.year;
      } else {
        let periodFrom = this.periodFrom;
        this.periodFrom = periodFrom;
        let periodTo = this.periodTo;
        this.periodTo = periodTo;
      }
    },
  },
  methods: {
    ...mapActions(["getPeriodData"]),
    getMonth(value) {
      return this.months
        .filter((e) => e.value === value)
        .map((item) => item.name)
        .toString();
    },
    onChangeYear(value) {
      if (!value) {
        this.yearAlert = true;
        this.valid = false;
      } else if (value && this.report.period) {
        this.valid = true;
      } else {
        this.yearAlert = false;
      }
    },
    onClosePanelYear() {
      if (!this.report.year) {
        this.yearAlert = true;
        this.valid = false;
      } else {
        this.yearAlert = false;
      }
    },
    exportToExcel() {
      if (!this.report.year) {
        this.yearAlert = true;
      }
      if (this.$refs.form.validate()) {
        this.$store.commit("setBtnLoading", true);
        this.$store.dispatch("exportTBExcel", this.report);
      }
    },
    previewAction() {
      this.loadingTable = true;
      this.statusPreviewButton = true;
      if (!this.report.year) {
        this.yearAlert = true;
        this.statusPreviewButton = false;
      }
      if (this.$refs.form.validate()) {
        this.previewTable = true;
        this.dataTable.splice(0, this.dataTable.length);
        this.$store
          .dispatch("getListPreviewTB", this.report)
          .then((response) => {
            if (response.length !== 0) {
              response.map((res) => {
                let beginning = "";
                let debit = "";
                let kredit = "";
                let closing = "";
                if (res.name == "") {
                  beginning = "";
                  debit = "";
                  kredit = "";
                  closing = "";
                } else {
                  beginning = setNumberFormat(res.beginning);
                  debit = setNumberFormat(res.debet);
                  kredit = setNumberFormat(res.kredit);
                  closing = setNumberFormat(res.closing);
                }
                let fontWeight = "";
                if (res.code == "" && res.name !== "") {
                  fontWeight = "font-weight: bold";
                } else {
                  fontWeight = "font-weight: normal";
                }
                let data = {
                  accountCode: res.code,
                  accountName: res.name,
                  beginningBalance: beginning,
                  debit: debit,
                  kredit: kredit,
                  closingBalance: closing,
                  fontWeight: fontWeight,
                };
                this.dataTable.push(data);
                this.statusPreviewButton = false;
                this.errorState = false;
                this.loadingTable = false;
              });
            } else {
              this.statusPreviewButton = false;
              this.errorState = false;
              this.loadingTable = false;
            }
          })
          .catch(() => {
            this.loadingTable = false;
            this.errorState = true;
          });
      }
    },
    onChangePeriodAkhir(period) {
      if (period.month !== "13" && this.report.periodAwal.month === "13") {
        this.report.periodAwal = "";
      }
    },
    onChangePeriodAwal(period) {
      if (period.month === "13") {
        this.report.periodAkhir = period;
      }
    },
  },
  async created() {
    await this.getPeriodData();
    this.periods = await this.getPeriod;
  },
};
</script>

<style src="../styles/report.scss" lang="scss" />
