<template>
  <v-card class="delete-card pt-8">
    <BodyCard />
    <v-card-actions class="justify-center">
      <v-btn class="btn-cancel mr-4" tile depressed @click="closeDelete"
        >Batal</v-btn
      >
      <v-btn class="btn-delete" tile depressed @click="confirmDeleteItem"
        >Hapus</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import BodyCard from "./AppCardBodyDelete.vue";

export default {
  name: "UserDelete",
  components: { BodyCard },
  props: ["userId"],
  methods: {
    confirmDeleteItem() {
      this.$store.dispatch("deleteUser", this.userId);
    },
    closeDelete() {
      this.$store.commit("showDeleteDialog", false);
    },
  },
};
</script>

<style src="../styles/user-delete.scss" lang="scss" scoped />
