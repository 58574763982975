import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";

Vue.use(Vuex);

function geStartDate(year, month) {
  return moment(`${year}-${month}`, "YYYY-MM-DD")
    .startOf("month")
    .format("YYYY-MM-DD");
}

function getEndDate(year, month) {
  return moment(`${year}-${month}`, "YYYY-MM-DD")
    .endOf("month")
    .format("YYYY-MM-DD");
}

const reportModule = {
  state: {
    postingNum: [],
  },
  mutations: {
    setPostingNum(state, payload) {
      state.postingNum = payload;
    },
  },
  actions: {
    async getPostingNumData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/posting");
        commit("setPostingNum", res.data);
      } catch (error) {
        console.log(error);
      }
    },

    async exportTBExcel({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.periodAwal.month);
        let endDate = getEndDate(payload.year, payload.periodAkhir.month);
        if (payload.periodAkhir.month === "13") {
          endDate = getEndDate(payload.year, 12);
        }
        if (payload.periodAwal.month === "13") {
          startDate = geStartDate(payload.year, 1);
        }
        const isAudit =
          payload.periodAwal.month === "13" ||
          payload.periodAkhir.month === "13";
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printTB/excel/" +
            `${startDate}/${endDate}/${isAudit}`,
          {
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Report Trial Balance.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async getListPreviewTB({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.periodAwal.month);
        let endDate = getEndDate(payload.year, payload.periodAkhir.month);
        if (payload.periodAkhir.month === "13") {
          endDate = getEndDate(payload.year, 12);
        }
        if (payload.periodAwal.month === "13") {
          startDate = geStartDate(payload.year, 1);
        }
        const isAudit =
          payload.periodAwal.month === "13" ||
          payload.periodAkhir.month === "13";
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printTB/preview/" +
            `${startDate}/${endDate}/${isAudit}`
        );
        return Promise.resolve(res.data);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async exportNAExcel({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.period.month);
        let endDate = getEndDate(payload.year, payload.period.month);
        if (payload.period.month === "13") {
          endDate = getEndDate(payload.year, 12);
          startDate = geStartDate(payload.year, 12);
        }
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printNA/excel/" +
            `${startDate}/${endDate}`,
          {
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Report ${payload.reportType.name}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async getListPreviewNA({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.period.month);
        let endDate = getEndDate(payload.year, payload.period.month);
        if (payload.period.month === "13") {
          endDate = getEndDate(payload.year, 12);
          startDate = geStartDate(payload.year, 12);
        }
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printNA/preview/" +
            `${startDate}/${endDate}`
        );
        return Promise.resolve(res.data);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async exportBSExcel({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.period.month);
        let endDate = getEndDate(payload.year, payload.period.month);
        if (payload.period.month === "13") {
          endDate = getEndDate(payload.year, 12);
          startDate = geStartDate(payload.year, 12);
        }
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printBS/excel/" +
            `${startDate}/${endDate}`,
          {
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Report ${payload.reportType.name}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async getListPreviewBS({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.period.month);
        let endDate = getEndDate(payload.year, payload.period.month);
        if (payload.period.month === "13") {
          endDate = getEndDate(payload.year, 12);
          startDate = geStartDate(payload.year, 12);
        }
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printBS/preview/" +
            `${startDate}/${endDate}`
        );
        return Promise.resolve(res.data);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async exportCNAExcel({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.period.month);
        let endDate = getEndDate(payload.year, payload.period.month);
        if (payload.period.month === "13") {
          endDate = getEndDate(payload.year, 12);
          startDate = geStartDate(payload.year, 12);
        }
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printCNA/excel/" +
            `${startDate}/${endDate}/${payload.reportKind}`,
          {
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Report ${payload.reportType.name}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async getListPreviewCNA({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.period.month);
        let endDate = getEndDate(payload.year, payload.period.month);
        if (payload.period.month === "13") {
          endDate = getEndDate(payload.year, 12);
          startDate = geStartDate(payload.year, 12);
        }
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printCNA/preview/" +
            `${startDate}/${endDate}/${payload.reportKind}`
        );
        return Promise.resolve(res.data);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async exportISExcel({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.period.month);
        let endDate = getEndDate(payload.year, payload.period.month);
        if (payload.period.month === "13") {
          endDate = getEndDate(payload.year, 12);
          startDate = geStartDate(payload.year, 12);
        }
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printIS/excel/" +
            `${startDate}/${endDate}/${payload.reportKind}`,
          {
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Report ${payload.reportType.name}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async getListPreviewIS({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.period.month);
        let endDate = getEndDate(payload.year, payload.period.month);
        if (payload.period.month === "13") {
          endDate = getEndDate(payload.year, 12);
          startDate = geStartDate(payload.year, 12);
        }
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printIS/preview/" +
            `${startDate}/${endDate}/${payload.reportKind}`
        );
        return Promise.resolve(res.data);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async exportCFExcel({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.period.month);
        let endDate = getEndDate(payload.year, payload.period.month);
        if (payload.period.month === "13") {
          endDate = getEndDate(payload.year, 12);
          startDate = geStartDate(payload.year, 12);
        }
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printCF/excel/" +
            `${startDate}/${endDate}`,
          {
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Report ${payload.reportType.name}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async getListPreviewCF({ commit }, payload) {
      try {
        let startDate = geStartDate(payload.year, payload.period.month);
        let endDate = getEndDate(payload.year, payload.period.month);
        if (payload.period.month === "13") {
          endDate = getEndDate(payload.year, 12);
          startDate = geStartDate(payload.year, 12);
        }
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printCF/preview/" +
            `${startDate}/${endDate}`
        );
        return Promise.resolve(res.data);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async exportALExcel({ commit }, payload) {
      try {
        let startDate = moment(
          `${payload.year}-${payload.periodAwal.month}`,
          "YYYY-MM-DD"
        )
          .startOf("month")
          .format("YYYY-MM-DD");
        let endDate = moment(
          `${payload.year}-${payload.periodAkhir.month}`,
          "YYYY-MM-DD"
        )
          .endOf("month")
          .format("YYYY-MM-DD");
        if (payload.periodAkhir.month === "13") {
          endDate = moment(`${payload.year}-12`, "YYYY-MM-DD")
            .endOf("month")
            .format("YYYY-MM-DD");
        }
        if (payload.periodAwal.month === "13") {
          startDate = geStartDate(payload.year, 1);
        }
        const isAudit =
          payload.periodAwal.month === "13" ||
          payload.periodAkhir.month === "13";
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printAL/excel/" +
            `${payload.startAccount}/${payload.endAccount}/${startDate}/${endDate}/${payload.transactionType}/${payload.beginningBalance}/${payload.endingBalance}/${isAudit}`,
          {
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Report Account Listing.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async getListPreviewAL({ commit }, payload) {
      try {
        let startDate = moment(
          `${payload.year}-${payload.periodAwal.month}`,
          "YYYY-MM-DD"
        )
          .startOf("month")
          .format("YYYY-MM-DD");
        let endDate = moment(
          `${payload.year}-${payload.periodAkhir.month}`,
          "YYYY-MM-DD"
        )
          .endOf("month")
          .format("YYYY-MM-DD");
        if (payload.periodAkhir.month === "13") {
          endDate = moment(`${payload.year}-12`, "YYYY-MM-DD")
            .endOf("month")
            .format("YYYY-MM-DD");
        }
        if (payload.periodAwal.month === "13") {
          startDate = geStartDate(payload.year, 1);
        }
        const isAudit =
          payload.periodAwal.month === "13" ||
          payload.periodAkhir.month === "13";
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printAL/preview/" +
            `${payload.startAccount}/${payload.endAccount}/${startDate}/${endDate}/${payload.transactionType}/${payload.beginningBalance}/${payload.endingBalance}/${isAudit}`
        );
        return Promise.resolve(res.data);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async exportJLExcel({ commit }, payload) {
      try {
        // let startDate = moment(
        //   `${payload.year}-${payload.period.month}`,
        //   "YYYY-MM-DD"
        // )
        //   .startOf("month")
        //   .format("YYYYMMDD");
        // let endDate = moment(
        //   `${payload.year}-${payload.period.month}`,
        //   "YYYY-MM-DD"
        // )
        //   .endOf("month")
        //   .format("YYYYMMDD");
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printJL/excel/" +
            `${payload.startPostingNum}/${payload.endPostingNum}`,
          {
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Report Journal Listing.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },

    async getListPreviewJL({ commit }, payload) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL +
            "/printJL/preview/" +
            `${payload.startPostingNum}/${payload.endPostingNum}`
        );
        return Promise.resolve(res.data);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      } finally {
        commit("setBtnLoading", false);
      }
    },
  },

  getters: {
    getPostingNum(state) {
      return state.postingNum;
    },
  },
};

export default reportModule;
