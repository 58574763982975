<template>
  <v-container>
    <v-card class="report-card mt-6 ml-6 mr-6 pb-14">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="report-card-title">Account Listing</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-10 mt-7">
        <v-col>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-row>
              <v-col md="2" class="mt-4">
                <label>
                  <span class="input-title">Akun Awal</span>
                </label>
              </v-col>
              <v-col md="10" class="ml-n10">
                <v-select
                  v-model="report.startAccount"
                  :items="accountItems"
                  :item-value="(item) => item.code"
                  :item-text="(item) => `${item.code} - ${item.name}`"
                  :rules="startAccountRules"
                  :loading="accountLoading"
                  :no-data-text="noDataText"
                  clearable
                  placeholder="Pilih"
                  class="mt-2"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col md="2" class="mt-4">
                <label>
                  <span class="input-title">Akun Akhir</span>
                </label>
              </v-col>
              <v-col md="10" class="ml-n10">
                <v-select
                  v-model="report.endAccount"
                  :items="accountItems"
                  :item-value="(item) => item.code"
                  :item-text="(item) => `${item.code} - ${item.name}`"
                  :rules="endAccountRules"
                  :loading="accountLoading"
                  :no-data-text="noDataText"
                  clearable
                  placeholder="Pilih"
                  class="mt-2"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col md="2" class="mt-4">
                <label>
                  <span class="input-title">Tahun</span>
                </label>
              </v-col>
              <v-col md="10" class="ml-n10">
                <date-picker
                  v-model="report.year"
                  type="year"
                  format="YYYY"
                  value-type="format"
                  @change="onChangeYear"
                  @close="onClosePanelYear"
                  class="datepicker mt-2"
                  placeholder="Pilih"
                  :popup-style="{
                    top: '380px',
                    left: '460px',
                  }"
                >
                </date-picker>
                <v-alert
                  v-show="yearAlert"
                  transition="slide-y-transition"
                  class="yearpicker-alert"
                  >Tahun harus dipilih</v-alert
                >
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col md="2" class="mt-4">
                <label>
                  <span class="input-title">Periode Awal</span>
                </label>
              </v-col>
              <v-col md="10" class="ml-n10">
                <v-select
                  v-model="report.periodAwal"
                  :items="firstPeriodFilter"
                  :item-text="(item) => item.month"
                  return-object
                  :rules="periodRules"
                  :loading="periodLoading"
                  :no-data-text="noDataText"
                  clearable
                  placeholder="Pilih"
                  class="mt-2"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                  @change="onChangePeriodAwal"
                >
                  <template v-slot:item="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ getMonth(item.month) || "Audit" }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col md="2" class="mt-4">
                <label>
                  <span class="input-title">Periode Akhir</span>
                </label>
              </v-col>
              <v-col md="10" class="ml-n10">
                <v-select
                  v-model="report.periodAkhir"
                  :items="periodFilter"
                  :item-text="(item) => item.month"
                  return-object
                  :rules="periodRules"
                  :loading="periodLoading"
                  :no-data-text="noDataText"
                  clearable
                  placeholder="Pilih"
                  class="mt-2"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                  @change="onChangePeriodAkhir"
                >
                  <template v-slot:item="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ getMonth(item.month) || "Audit" }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col md="2" class="mt-4">
                <label>
                  <span class="input-title">Tipe Transaksi</span>
                </label>
              </v-col>
              <v-col md="10" class="ml-n10">
                <v-select
                  v-model="report.transactionType"
                  :items="transactionTypeItems"
                  :item-value="(item) => item.code"
                  :item-text="(item) => `${item.code} - ${item.name}`"
                  :rules="transactionTypeRules"
                  :loading="transactionTypeLoading"
                  :no-data-text="noDataText"
                  clearable
                  placeholder="Pilih"
                  class="mt-2"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col md="3" class="mt-4">
                <label>
                  <span class="input-title">Beginning Balance</span>
                </label>
              </v-col>
              <v-col md="9" class="ml-n10">
                <v-radio-group v-model="report.beginningBalance" row>
                  <v-radio label="Yes" value="yes"></v-radio>
                  <v-radio label="No" value="no"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col md="3" class="mt-4">
                <label>
                  <span class="input-title">Ending Balance</span>
                </label>
              </v-col>
              <v-col md="9" class="ml-n10">
                <v-radio-group v-model="report.endingBalance" row>
                  <v-radio label="Yes" value="yes"></v-radio>
                  <v-radio label="No" value="no"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-n8">
              <v-col md="2" class="mt-4">
                <label>
                  <span class="input-title">Tanggal</span>
                </label>
              </v-col>
              <v-col md="10" class="ml-n10">
                <date-picker
                  v-model="report.day"
                  type="date"
                  format="DD/MM/YYYY"
                  value-type="YYYYMMDD"
                  @change="onChangeDay"
                  @close="onClosePanelDay"
                  class="datepicker mt-2"
                  range
                  placeholder="Pilih"
                  :popup-style="{
                    top: '380px',
                    left: '460px',
                  }"
                >
                </date-picker>
                <v-alert
                  v-show="dayAlert"
                  transition="slide-y-transition"
                  class="yearpicker-alert"
                  >Tanggal harus dipilih</v-alert
                >
              </v-col>
            </v-row> -->
            <v-row class="mt-12" justify="center">
              <!-- <v-btn
                @click="handlePreview"
                class="btn-preview mr-4"
                small
                tile
                depressed
                >Pratinjau</v-btn
              > -->
              <!-- <v-btn class="btn-print-pdf" small tile depressed
                >Cetak PDF</v-btn
              > -->
              <base-button-export
                @export="previewAction"
                :color="'#3E9FF9'"
                :disabled="!valid || !yearValid"
                class="mr-2"
              >
                <template v-slot:btnText>
                  <span>Preview</span>
                </template>
              </base-button-export>
              <base-button-export
                @export="exportToExcel"
                :loading="getBtnLoading"
                :disabled="!valid || !yearValid || getBtnLoading"
                class="ml-2"
              >
                <template v-slot:btnText>
                  <span>Cetak Excel</span>
                </template>
              </base-button-export>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-show="previewTable" class="report-card mt-6 ml-6 mr-6 pb-14">
      <div v-if="loadingTable" class="d-flex justify-center align-center pt-14">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <div
          v-if="errorState"
          class="d-flex justify-center align-center pt-14 text-center"
        >
          <h3>Oops, something went wrong. Please try again later.</h3>
        </div>
        <div v-else>
          <div class="title-preview">
            <h5>DANA PENSIUN SAMUDERA INDONESIA UTAMA</h5>
            <h5>ACCOUNT LISTING/GENERAL LISTING</h5>
            <h5>
              ACCOUNT CODE : {{ startAccountCode }} TO {{ endAccountCode }}
            </h5>
            <h5>
              PERIOD :
              {{ periodFrom }}
              TO
              {{ periodTo }}
            </h5>
          </div>
          <div class="preview-table">
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <th rowspan="2">Line No</th>
                    <th rowspan="2">Trans Date</th>
                    <th rowspan="2">Periode Trans</th>
                    <th rowspan="2">Reff</th>
                    <th rowspan="2">Trans Type</th>
                    <th rowspan="2">JE Type</th>
                    <th rowspan="2">User ID</th>
                    <th rowspan="2">Posting No</th>
                    <th rowspan="2">Debit/Credit</th>
                    <th rowspan="2">Amount</th>
                    <th rowspan="2">Description</th>
                    <th :colspan="getTCode.length">Optional</th>
                  </tr>
                  <tr>
                    <th v-for="(data, i) in getTCode" :key="i">
                      {{ data.code }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in dataTable" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ data.transDate }}</td>
                    <td>{{ data.periodeTrans }}</td>
                    <td>{{ data.reff }}</td>
                    <td>{{ data.transType }}</td>
                    <td>{{ data.jeType }}</td>
                    <td>{{ data.userId }}</td>
                    <td>{{ data.postingNo }}</td>
                    <td>{{ data.debitCredit }}</td>
                    <td :class="{ 'text-red': parseFloat(data.amount) < 0 }">
                      {{ data.amount }}
                    </td>
                    <td>{{ data.description }}</td>
                    <td v-for="(item, i) in data.tc" :key="i">
                      {{ item.value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import BaseButtonExport from "./base/BaseButtonExport.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions, mapGetters } from "vuex";
import { setNumberFormat } from "../functions/NumberFormat.ts";

export default {
  name: "ListingAccount",
  components: { BaseButtonExport, DatePicker },
  data: () => ({
    valid: true,
    yearValid: true,
    // dayValid: true,
    accountItems: [],
    periodItems: [],
    transactionTypeItems: [],
    months: [
      { value: "01", name: "Januari" },
      { value: "02", name: "Februari" },
      { value: "03", name: "Maret" },
      { value: "04", name: "April" },
      { value: "05", name: "Mei" },
      { value: "06", name: "Juni" },
      { value: "07", name: "Juli" },
      { value: "08", name: "Agustus" },
      { value: "09", name: "September" },
      { value: "10", name: "Oktober" },
      { value: "11", name: "November" },
      { value: "12", name: "Desember" },
    ],
    report: {
      startAccount: "",
      endAccount: "",
      //day: "",
      year: "",
      periodAwal: "",
      periodAkhir: "",
      transactionType: "",
      beginningBalance: "yes",
      endingBalance: "yes",
    },
    accountLoading: true,
    periodLoading: true,
    transactionTypeLoading: true,
    noDataText: "Tidak ada data yang tersedia",
    yearAlert: false,
    // dayAlert: false,
    // Validation Rules
    startAccountRules: [(v) => !!v || "Akun awal harus dipilih"],
    endAccountRules: [(v) => !!v || "Akun akhir harus dipilih"],
    periodRules: [(v) => !!v || "Periode harus dipilih"],
    transactionTypeRules: [(v) => !!v || "Tipe transaksi harus dipilih"],
    previewTable: false,
    dataTable: [],
    statusPreviewButton: false,
    startAccountCode: "",
    endAccountCode: "",
    periodFrom: "",
    periodTo: "",
    loadingTable: true,
    errorState: false,
  }),
  computed: {
    ...mapGetters([
      "getPeriod",
      "getAccount",
      "getTransactionType",
      "getTCode",
    ]),
    periodFilter() {
      return this.periodItems.filter((item) => item.year == this.report.year);
    },
    firstPeriodFilter() {
      return this.periodItems.filter((item) => item.year == this.report.year);
    },
    getBtnLoading: {
      get() {
        return this.$store.state.btnLoading;
      },
      set(v) {
        this.$store.commit("setBtnLoading", v);
      },
    },
  },
  watch: {
    getAccount() {
      this.accountLoading = false;
    },
    getPeriod() {
      this.periodLoading = false;
    },
    getTransactionType() {
      this.transactionTypeLoading = false;
    },
    statusPreviewButton(val) {
      if (val == true) {
        // ACCOUNT CODE
        this.startAccountCode = this.report.startAccount;
        this.endAccountCode = this.report.endAccount;
        // PERIOD
        // this.periodFrom = this.report.periodAwal.month + this.report.year;
        // this.periodTo = this.report.periodAkhir.month + this.report.year;
        this.periodFrom =
          (this.report.periodAwal.month === "13"
            ? "01"
            : this.report.periodAwal.month) + this.report.year;
        this.periodTo =
          (this.report.periodAkhir.month === "13"
            ? "12"
            : this.report.periodAkhir.month) + this.report.year;
      } else {
        // ACCOUNT CODE
        let start = this.startAccountCode;
        this.startAccountCode = start;
        let end = this.endAccountCode;
        this.endAccountCode = end;
        // PERIOD
        let periodFrom = this.periodFrom;
        this.periodFrom = periodFrom;
        let periodTo = this.periodTo;
        this.periodTo = periodTo;
      }
    },
  },
  methods: {
    ...mapActions([
      "getPeriodData",
      "getAccountData",
      "getTransactionTypeData",
      "getTCodeData",
    ]),
    getMonth(value) {
      return this.months
        .filter((e) => e.value === value)
        .map((item) => item.name)
        .toString();
    },
    // onChangeDay(value) {
    //   if (!value) {
    //     this.dayAlert = true;
    //     this.dayValid = false;
    //   } else {
    //     this.dayAlert = false;
    //     this.dayValid = true;
    //   }
    // },
    // onClosePanelDay() {
    //   if (!this.report.day) {
    //     this.dayAlert = true;
    //     this.dayValid = false;
    //   } else {
    //     this.dayAlert = false;
    //   }
    // },
    onChangeYear(value) {
      if (!value) {
        this.yearAlert = true;
        this.yearValid = false;
      } else {
        this.yearAlert = false;
        this.yearValid = true;
      }
    },
    onClosePanelYear() {
      if (!this.report.year) {
        this.yearAlert = true;
        this.yearValid = false;
      } else {
        this.yearAlert = false;
      }
    },
    exportToExcel() {
      if (!this.report.year) {
        this.yearAlert = true;
      }
      if (this.$refs.form.validate()) {
        this.$store.commit("setBtnLoading", true);
        this.$store.dispatch("exportALExcel", this.report);
      }
    },
    previewAction() {
      this.loadingTable = true;
      this.statusPreviewButton = true;
      if (!this.report.year) {
        this.yearAlert = true;
        this.statusPreviewButton = false;
      }
      if (this.$refs.form.validate()) {
        this.previewTable = true;
        this.dataTable.splice(0, this.dataTable.length);
        this.$store
          .dispatch("getListPreviewAL", this.report)
          .then((response) => {
            if (response.length !== 0) {
              response.map((res) => {
                let tc = [];
                if (res.tcodeList === null) {
                  for (let i = 0; i < this.getTCode.length; i++) {
                    let data = {
                      value: "",
                    };
                    tc.push(data);
                  }
                } else {
                  if (res.tcodeList.length < this.getTCode.length) {
                    tc = res.tcodeList;
                    let sum = this.getTCode.length - res.tcodeList.length;
                    for (let e = 0; e < sum; e++) {
                      let data = {
                        value: "",
                      };
                      tc.push(data);
                    }
                  } else {
                    tc = res.tcodeList;
                  }
                }
                let data = {
                  transDate: res.transDate,
                  periodeTrans: res.transPeriod,
                  reff: res.reff,
                  transType: res.transType1,
                  jeType: res.jeType,
                  userId: res.userId,
                  postingNo: res.postingNo,
                  debitCredit: res.balance,
                  amount: setNumberFormat(res.amount),
                  description: res.description,
                  tc: tc,
                };
                this.dataTable.push(data);
                this.statusPreviewButton = false;
                this.errorState = false;
                this.loadingTable = false;
              });
            } else {
              this.statusPreviewButton = false;
              this.errorState = false;
              this.loadingTable = false;
            }
          })
          .catch(() => {
            this.loadingTable = false;
            this.errorState = true;
          });
      }
    },
    onChangePeriodAkhir(period) {
      if (period.month !== "13" && this.report.periodAwal.month === "13") {
        this.report.periodAwal = "";
      }
    },
    onChangePeriodAwal(period) {
      if (period.month === "13") {
        this.report.periodAkhir = period;
      }
    },
  },
  async created() {
    await this.getPeriodData();
    await this.getAccountData();
    await this.getTransactionTypeData();
    await this.getTCodeData();
    this.accountItems = await this.getAccount;
    this.periodItems = await this.getPeriod;
    this.transactionTypeItems = await this.getTransactionType;
  },
};
</script>

<style src="../styles/report.scss" lang="scss" />
