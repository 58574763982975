<template>
  <nav>
    <!-- Sidebar -->
    <Sidebar
      :mini="mini"
      :showAllMenu="showAllMenu"
      @showAllItemsMenu="showAllMenu = true"
      @hideAllMenu="showAllMenu = false"
    />
    <v-app-bar color="white" class="navbar pt-1" elevation="0" dense app>
      <v-app-bar-nav-icon
        class="burger-button"
        @click="expandSidebar"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn class="ml-1 mr-1" id="navbar-notification-button" icon>
        <v-icon size="28" color="#D1D1D1">mdi-bell</v-icon>
      </v-btn>
      <div class="profile" id="navbar-profile-button">
        <v-icon size="28">mdi-account-circle-outline</v-icon>
      </div>
    </v-app-bar>

    <!--Notification-->
    <Notification />

    <!--Profile-->
    <Profile :profile="profile" />
  </nav>
</template>

<script>
import Sidebar from "@/components/layout/TheSidebar.vue";
import Notification from "@/components/layout/TheNotification.vue";
import Profile from "@/components/layout/TheProfile.vue";

export default {
  name: "TheNavbar",
  components: {
    Sidebar,
    Notification,
    Profile,
  },
  data: () => ({
    mini: false,
    profile: false,
    showAllMenu: true,
  }),
  methods: {
    expandSidebar() {
      this.mini = !this.mini;
      this.showAllMenu = !this.showAllMenu;
    },
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (e.target.id !== "navbar-profile") {
        this.profile = false;
      }
      if (e.target.parentNode.id === "navbar-profile-button") {
        this.profile = true;
      }
      if (e.target.id !== "navbar-notification") {
        this.$store.commit("showNotification", false);
      }
      if (e.target.id === "navbar-notification-button") {
        this.$store.commit("showNotification", true);
      }
      if (e.target.parentNode.parentNode.id === "navbar-notification-button") {
        this.$store.commit("showNotification", true);
      }
      if (e.target.parentNode.id === "navbar-notification-button") {
        this.$store.commit("showNotification", true);
      }
    });
  },
};
</script>

<style src="../../styles/navbar.scss" lang="scss" scoped />
