<template>
  <v-container>
    <v-card class="journal-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="journal-card-title">Jurnal</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="mb-n11 pl-7 pr-7">
        <v-row>
          <v-col md="2" class="mr-12">
            <base-button
              :disabled="listPermission[1] === false"
              @add="addJournal"
            >
              <template v-slot:btnText>
                <span>Input Jurnal Baru</span>
              </template></base-button
            >
          </v-col>
          <v-col md="2" class="mr-7">
            <v-btn
              :disabled="listPermission[1] === false"
              @click="selectFile"
              class="btn-upload-txt"
              color="#F1FFEF"
              tile
              depressed
            >
              <v-icon class="mr-2" size="18">mdi-cloud-upload</v-icon>
              Unggah Jurnal
            </v-btn>
            <input
              id="upload"
              type="file"
              ref="file"
              accept=".txt"
              style="visibility: hidden"
              multiple
              @change="onFileChange"
            />
          </v-col>
          <v-col md="2">
            <v-btn
              @click="postingJournal"
              :loading="getBtnLoading"
              :disabled="listPermission[3] === false || getBtnLoading"
              class="btn-posting"
              tile
              depressed
            >
              <v-icon class="mr-1" size="18">mdi-plus</v-icon>
              Posting
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="4">
            <v-row>
              <v-col md="4" class="mr-n6">
                <span class="body-1">Tampilkan</span>
              </v-col>
              <v-col md="4">
                <v-select
                  :value="itemPerPage"
                  item-value="itemPerPage"
                  :items="itemsPerPage"
                  @change="selectItem"
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="4" class="ml-n2">
                <span class="body-1">Entri</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" class="mr-3">
            <v-text-field
              v-model="search"
              @input="searchTransaction"
              placeholder="Cari transaksi"
              prepend-inner-icon="mdi-magnify"
              background-color="#FAFAFA"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="mt-n8 pl-7 pr-7">
        <v-row>
          <v-col md="12">
            <v-row>
              <v-col md="3">
                <v-select
                  v-model="journalTypeId"
                  :items="journalTypeItems"
                  item-value="id"
                  item-text="name"
                  :loading="journalTypeLoading"
                  :no-data-text="noDataText"
                  placeholder="Tipe Jurnal"
                  append-icon=""
                  outlined
                  dense
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#CCCCCC">mdi-filter</v-icon>
                  </template>
                </v-select>
              </v-col>
              <v-col md="3">
                <v-select
                  v-model="transactionTypeId"
                  :items="transactionTypeItems"
                  item-value="id"
                  item-text="name"
                  :loading="transactionTypeLoading"
                  :no-data-text="noDataText"
                  placeholder="Tipe Transaksi"
                  append-icon=""
                  outlined
                  dense
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#CCCCCC">mdi-filter</v-icon>
                  </template>
                </v-select>
              </v-col>
              <v-col md="3">
                <v-select
                  v-model="periodId"
                  :items="periodItems"
                  item-value="id"
                  item-text="name"
                  :loading="periodLoading"
                  :no-data-text="noDataText"
                  placeholder="Periode"
                  append-icon=""
                  outlined
                  dense
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#CCCCCC">mdi-filter</v-icon>
                  </template>
                </v-select>
              </v-col>
              <v-col md="3">
                <date-picker
                  v-model="date"
                  type="date"
                  format="YYYYMMDD"
                  value-type="format"
                  @clear="onClearDate"
                  range
                  placeholder="Tanggal"
                  range-separator=" - "
                  class="datepicker"
                  :popup-style="{
                    left: '850px',
                  }"
                ></date-picker>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-7 pr-7 pb-7 mt-n8">
        <v-col>
          <v-card class="journal-table-card pb-4" elevation="0">
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="filteredTransactionLists"
              :options="{
                itemsPerPage: itemPerPage,
              }"
              :page.sync="page"
              @page-count="pageCount = $event"
              :loading="isLoading"
              :loading-text="loadingText"
              :no-data-text="noDataText"
              :footer-props="{
                'items-per-page-text': '',
                'disable-items-per-page': true,
                'items-per-page-options': [],
                'prev-icon': null,
                'next-icon': null,
              }"
              show-select
              class="journal-table"
            >
              <template v-slot:[`item.date`]="{ item }">{{
                dateFormat(item.date)
              }}</template>
              <template v-slot:[`item.periode.month`]="{ item }">
                {{ item.periode.year + item.periode.month }}
              </template>
              <!-- <template v-slot:[`item.note`]="{ item }">
                <span v-if="item.note.length > 15">
                  {{ item.note.slice(0, 15) + "..." }}
                </span>
                <span v-else>
                  {{ item.note }}
                </span>
              </template> -->
              <template v-slot:[`header.data-table-select`]="{ props, on }">
                <v-simple-checkbox
                  v-bind="props"
                  v-on="on"
                  :ripple="false"
                  color="success"
                ></v-simple-checkbox>
              </template>
              <template
                v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
              >
                <div v-if="item.status === 'Posting'"></div>
                <v-simple-checkbox
                  v-else
                  :value="isSelected"
                  :ripple="false"
                  color="primary"
                  @input="select($event)"
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#3E9FF9"
                      dark
                      x-small
                      height="24"
                      min-width="24"
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleDetailJournal(item)"
                    >
                      <v-icon size="14">mdi-eye</v-icon>
                    </v-btn>
                    <v-btn
                      color="#E46D47"
                      x-small
                      height="24"
                      min-width="24"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleDeleteAccount(item)"
                      :disabled="
                        listPermission[4] === false || item.status === 'Posting'
                      "
                    >
                      <v-icon color="white" size="14">mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      color="#E89F3C"
                      x-small
                      height="24"
                      min-width="24"
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleEditAccount(item)"
                      :disabled="
                        listPermission[2] === false || item.status === 'Posting'
                      "
                    >
                      <v-icon color="white" size="14">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </template>
              <template
                v-slot:[`footer.page-text`]="{
                  pageStart,
                  pageStop,
                  itemsLength,
                }"
              >
                Showing {{ pageStart }} to {{ pageStop }} of {{ itemsLength }}
              </template>
            </v-data-table>
            <v-row class="mt-n10">
              <v-spacer></v-spacer>
              <v-col md="auto" class="mr-10">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="7"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- Delete Dialog -->
    <v-dialog
      v-model="dialogDelete"
      transition="dialog-top-transition"
      max-width="350px"
    >
      <v-card color="pt-8">
        <BodyCardDelete />
        <v-card-actions class="justify-center">
          <v-btn
            @click="closeDelete"
            class="btn-close-dialog mr-4"
            tile
            depressed
            >Batal</v-btn
          >
          <v-btn @click="confirmDeleteItem" class="btn-confirm" tile depressed
            >Hapus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Delete Dialog -->
    <!-- <v-snackbar
      v-model="getSnackbarAdd"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAddText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAdd = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->
    <!-- <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarEditText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->
    <!-- <v-snackbar
      v-model="getSnackbarDelete"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarDeleteText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarDelete = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->
    <!-- <v-snackbar
      v-model="getSnackbarPost"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarPostText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarPost = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->
    <!-- <v-snackbar
      v-model="snackbarWarning"
      :timeout="timeout"
      color="warning"
      min-width="100"
      top
      right
      transition="slide-y-transition"
    >
      {{ snackbarWarningText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          x-small
          v-bind="attrs"
          @click="snackbarWarning = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->
    <!-- <v-snackbar
      v-model="getSnackbarAlert"
      :timeout="timeout"
      color="warning"
      min-width="100"
      top
      right
      transition="slide-y-transition"
    >
      {{ snackbarAlertText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          x-small
          v-bind="attrs"
          @click="getSnackbarAlert = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->

    <pop-up-success
      :isShow="isShowSuccess"
      :text="textSuccess"
      @close-modal="closeModalSuccess"
    >
    </pop-up-success>
    <pop-up-error
      :isShow="isShowError"
      :text="textError"
      :textArray="textArray"
      @close-modal="closeModalError"
    ></pop-up-error>

    <pop-up-warning
      :isShow="isShowWarning"
      :text="textWarning"
      @close-modal="closeModalWarning"
    >
    </pop-up-warning>
  </v-container>
</template>

<script>
import BaseButton from "./base/BaseButton.vue";
import BodyCardDelete from "./AppCardBodyDelete.vue";
import moment from "moment";
const debounce = require("debounce");
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import PopUpSuccess from "./base/popup/PopUpSuccess.vue";
import PopUpError from "./base/popup/PopUpError.vue";
import PopUpWarning from "./base/popup/PopUpWarning.vue";

export default {
  name: "JournalList",
  components: {
    BaseButton,
    BodyCardDelete,
    DatePicker,
    PopUpSuccess,
    PopUpError,
    PopUpWarning,
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    selected: [],
    headers: [
      {
        text: "Referensi",
        align: "start",
        sortable: true,
        value: "code",
        divider: true,
        width: "180px",
      },
      {
        text: "Tanggal",
        align: "start",
        sortable: true,
        value: "date",
        divider: true,
        width: "180px",
      },
      {
        text: "Tipe Transaksi",
        value: "transactionType.name",
        sortable: true,
        divider: true,
        width: "180px",
      },
      {
        text: "Tipe Jurnal",
        value: "journalType.name",
        sortable: true,
        divider: true,
        width: "150px",
      },
      {
        text: "Periode Transaksi",
        value: "periode.month",
        sortable: true,
        divider: true,
        width: "200px",
      },
      {
        text: "Penginput",
        value: "user.name",
        sortable: true,
        divider: true,
        width: "180px",
      },
      // {
      //   text: "Catatan",
      //   value: "note",
      //   sortable: false,
      //   divider: true,
      //   width: "150px",
      // },
      // {
      //   text: "Attachment",
      //   value: "attachment",
      //   sortable: false,
      //   divider: true,
      // },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        divider: true,
        width: "140px",
      },
      { text: "", value: "data-table-select" },
    ],
    valid: true,
    // fileUpload: null,
    loadingText: "Sedang memuat data...",
    noDataText: "Tidak ada data yang tersedia",
    listPermission: [false, false, false, false, false],
    journalTypeItems: [{ id: 0, name: "Semua Tipe Jurnal" }],
    transactionTypeItems: [{ id: 0, name: "Semua Tipe Transaksi" }],
    periodItems: [{ id: 0, name: "Semua Periode" }],
    journalTypeId: 0,
    transactionTypeId: 0,
    transactionTypeList: "",
    periodId: 0,
    date: "",
    periodLoading: true,
    journalTypeLoading: true,
    transactionTypeLoading: true,
    search: "",
    itemId: null,
    isLoading: true,
    dialogDelete: false,
    // Snackbar
    snackbarWarning: false,
    snackbarAddText: "Data berhasil ditambahkan!",
    snackbarEditText: "Data berhasil diubah!",
    snackbarDeleteText: "Data berhasil dihapus!",
    snackbarPostText: "Jurnal berhasil diposting!",
    snackbarAlertText:
      "Posting gagal, ada transaksi pada periode yang sedang ditutup!",
    snackbarWarningText: "Belum ada jurnal yang dipilih!",
    timeout: 2500,
    isShowSuccess: false,
    textSuccess: "Jurnal berhasil diposting!",
    isShowError: false,
    textError: "",
    isShowWarning: false,
    textWarning: "",
    timer: null,
    fileUpload: [],
    textArray: [],
  }),
  computed: {
    ...mapGetters([
      "getListTransaction",
      "getJournalType",
      "getTransactionType",
      "getPeriodItems",
      "getOpenPeriod",
      "getListPermissionById",
      "getErrorMessagePosting",
    ]),
    filteredTransactionLists() {
      var listTransaction = this.getListTransaction
        .filter((item) => {
          if (this.journalTypeId === 0) {
            return item;
          } else {
            return item.journalType.id === this.journalTypeId;
          }
        })
        .filter((item) => {
          if (this.transactionTypeId === 0) {
            return item;
          } else {
            return item.transactionType.id === this.transactionTypeId;
          }
        })
        .filter((item) => {
          if (this.periodId === 0) {
            return item;
          } else {
            return item.periode.id === this.periodId;
          }
        })
        .filter((item) => {
          if (!this.date) {
            return item;
          } else {
            const formatDate = "YYYY-MM-DD";
            var itemDate = moment(item.date).format(formatDate);
            var dateAwal = moment(this.date[0])
              .subtract(1, "days")
              .format(formatDate);
            var dateAkhir = moment(this.date[1]).format(formatDate);

            return moment(itemDate).isBetween(dateAwal, dateAkhir);
          }
        });

      for (var i = 0; i < listTransaction.length; i++) {
        if (listTransaction[i].transactionType !== null) {
          var transactionTypeName = listTransaction[i].transactionType.name;
          var newTransactionTypeName = transactionTypeName.split(",");
          var resultTransactionTypeName = newTransactionTypeName[0];

          if (listTransaction[i].transactionType2 !== null) {
            listTransaction[i].transactionType.name =
              resultTransactionTypeName +
              ", " +
              listTransaction[i].transactionType2.name;
          }
        } else {
          listTransaction[i].transactionType = [];
          listTransaction[i].transactionType.push({ name: "", id: 0 });
        }

        if (listTransaction[i].journalType === null) {
          listTransaction[i].journalType = [];
          listTransaction[i].journalType.push({ name: "", id: 0 });
        }
      }

      return listTransaction;
    },
    getSnackbarAdd: {
      get() {
        return this.$store.state.snackbarAdd;
      },
      set(v) {
        this.$store.commit("showSnackbarAdd", v);
      },
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
    getSnackbarDelete: {
      get() {
        return this.$store.state.snackbarDelete;
      },
      set(v) {
        this.$store.commit("showSnackbarDelete", v);
      },
    },
    getSnackbarAlert: {
      get() {
        return this.$store.state.snackbarAlert;
      },
      set(v) {
        this.$store.commit("showSnackbarAlert", v);
      },
    },
    getSnackbarPost: {
      get() {
        return this.$store.state.snackbarPost;
      },
      set(v) {
        this.$store.commit("showSnackbarPost", v);
      },
    },
    getBtnLoading: {
      get() {
        return this.$store.state.btnLoading;
      },
      set(v) {
        this.$store.commit("setBtnLoading", v);
      },
    },
    // getErrorMessagePosting: {
    //   get() {
    //     return this.$store.state.
    //   }
    // }
  },
  watch: {
    getSnackbarDelete(val) {
      this.isShowSuccess = val;
      this.textSuccess = this.snackbarDeleteText;
      // this.timer = setTimeout(() => {
      //   this.isShowSuccess = false;
      //   this.getSnackbarDelete = false;
      // }, 2500);
    },
    getSnackbarAlert(val) {
      if (val === true) {
        this.isShowWarning = val;
        this.textWarning = this.getErrorMessagePosting;
        // this.timer = setTimeout(() => {
        //   this.isShowWarning = false;
        //   this.getSnackbarAlert = false;
        // }, 2500);
      }
    },
    getSnackbarPost(val) {
      this.isShowSuccess = val;
      this.textSuccess = this.snackbarPostText;
      // this.timer = setTimeout(() => {
      //   this.isShowSuccess = false;
      //   this.getSnackbarPost = false;
      // }, 2500);
    },
    getSnackbarAdd(val) {
      if (val == true) {
        this.isShowSuccess = val;
        this.textSuccess = this.snackbarAddText;
        // this.timer = setTimeout(() => {
        //   this.isShowSuccess = false;
        //   this.getSnackbarAdd = false;
        // }, 2500);
      }
    },
    getListTransaction() {
      this.isLoading = false;
    },
    getOpenPeriod() {
      this.periodLoading = false;
    },
    getJournalType() {
      this.journalTypeLoading = false;
    },
    getTransactionType() {
      this.transactionTypeLoading = false;
    },
  },
  methods: {
    ...mapActions([
      "getListTransactionData",
      "getJournalTypeData",
      "getTransactionTypeData",
      "getPeriodData",
    ]),
    selectItem(value) {
      this.itemPerPage = value;
    },
    dateFormat(date) {
      moment.locale("id");
      return moment(date).format("YYYYMMDD");
    },
    onClearDate(val) {
      if (!val) {
        this.date = "";
      }
    },
    getMonth(value) {
      return this.getPeriodItems
        .filter((e) => e.value === value)
        .map((item) => item.name)
        .toString();
    },
    addJournal() {
      this.$router.push({ name: "Tambah Jurnal" });
    },
    postingJournal() {
      // console.log(this.selected[0]);
      let transaction = [];
      for (let i = 0; i < this.selected.length; i++) {
        transaction.push(this.selected[i].id);
      }
      let data = {
        userId: JSON.parse(localStorage.getItem("userId")),
        transaction: transaction,
      };
      if (this.selected.length !== 0) {
        this.$store.dispatch("postingJournal", data);
      } else {
        // this.snackbarWarning = true;
        this.isShowWarning = true;
        this.textWarning = this.snackbarWarningText;
        // this.timer = setTimeout(() => {
        //   this.isShowWarning = false;
        // }, 2500);
      }
    },
    handleDetailJournal(item) {
      this.$router.push({ name: "Detail Jurnal", params: { id: item.id } });
    },
    handleEditAccount(item) {
      this.$router.push({ name: "Ubah Jurnal", params: { id: item.id } });
    },
    handleDeleteAccount(item) {
      this.itemId = item.id;
      this.dialogDelete = true;
    },
    confirmDeleteItem() {
      this.dialogDelete = false;
      this.$store.dispatch("deleteTransaction", this.itemId);
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    searchTransaction: debounce(function () {
      this.$store.dispatch("searchTransaction", this.search);
    }, 500),
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 44;
      });

      this.listPermission[1] = permissions.some((item) => {
        return item === 45;
      });

      this.listPermission[2] = permissions.some((item) => {
        return item === 46;
      });

      this.listPermission[3] = permissions.some((item) => {
        return item === 47;
      });

      this.listPermission[4] = permissions.some((item) => {
        return item === 48;
      });
    },
    onFileChange(e) {
      const file = e.target.files;
      for (var i = 0; i < file.length; i++) {
        let files = file[i];
        if (files.type != "text/plain") {
          this.isShowError = true;
          this.textError = "The file must be a file of type txt.";
          // this.timer = setTimeout(() => {
          //   this.isShowError = false;
          // }, 2500);
        } else if (this.fileUpload.length > 0) {
          this.fileUpload.splice(0, 1);
          this.fileUpload.push(file[i]);
          this.actionUpload();
        } else {
          this.fileUpload.push(file[i]);
          this.actionUpload();
        }
      }

      // this.fileUpload = e.target.files[0];
      // let formData = new FormData();
      // formData.append("file", this.fileUpload);
      // axios
      //   .post(
      //     process.env.VUE_APP_API_URL + "/upload-api/upload-file",
      //     formData,
      //     {
      //       headers: { "Content-Type": "multipart/form-data" },
      //     }
      //   )
      //   .then((resp) => {
      //     console.log(resp);
      //     this.$store.commit("showSnackbarAdd", formData);
      //     this.$store.dispatch("getListTransactionData", formData);
      //   })
      //   .catch((err) => {
      //     console.log(err.response);
      //     this.isShowError = true;
      //     const errorMessage = err.response.data;
      //     if (typeof errorMessage == "object") {
      //       this.textError = errorMessage.message;
      //     } else {
      //       this.textError = "Something went wrong!";
      //     }

      //     setTimeout(() => {
      //       this.isShowError = false;
      //     }, 2500);
      //   });
    },
    actionUpload() {
      this.$refs.file.value = "";
      let formData = new FormData();
      formData.append("file", this.fileUpload[0]);
      axios
        .post(
          process.env.VUE_APP_API_URL + "/upload-api/upload-file",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then(() => {
          // console.log(resp);
          //this.$store.commit("showSnackbarAdd", formData);
          this.getSnackbarAdd = true;
          this.$store.dispatch("getListTransactionData", formData);
        })
        .catch((err) => {
          console.log(err.response);
          this.fileUpload.splice(0, this.fileUpload.length);
          this.isShowError = true;
          const errorMessage = err.response.data;
          if (typeof errorMessage == "object") {
            if (typeof errorMessage.mesagge !== "object") {
              this.textError = errorMessage.message;
            } else {
              this.textArray = errorMessage.mesagge;
            }
          } else {
            this.textError = "Something went wrong!";
          }

          // this.timer = setTimeout(() => {
          //   this.isShowError = false;
          //   this.textArray.splice(0, this.textArray.length);
          // }, 2500);
        });
    },
    selectFile() {
      document.getElementById("upload").click();
    },
    closeModalSuccess() {
      this.isShowSuccess = false;
      // clearTimeout(this.timer);
      this.getSnackbarAdd = false;
      this.getSnackbarEdit = false;
      this.getSnackbarPost = false;
      this.getSnackbarDelete = false;
    },
    closeModalError() {
      this.isShowError = false;
      // clearTimeout(this.timer);
      this.textArray.splice(0, this.textArray.length);
    },
    closeModalWarning() {
      this.isShowWarning = false;
      // clearTimeout(this.timer);
      this.getSnackbarAlert = false;
      this.snackbarWarning = false;
    },
  },
  async created() {
    await this.setListPermission();
    if (this.listPermission[0] === false) {
      this.isLoading = false;
      this.journalTypeLoading = false;
      this.transactionTypeLoading = false;
      this.periodLoading = false;
      this.noDataText = "Anda tidak memiliki izin untuk mengakses data";
    } else {
      await this.getListTransactionData();
      await this.getJournalTypeData();
      await this.getTransactionTypeData();
      await this.getPeriodData();
      this.journalTypeItems.push(...this.getJournalType);
      this.transactionTypeItems.push(...this.getTransactionType);
      for (let i = 0; i < this.getOpenPeriod.length; i++) {
        this.periodItems.push({
          id: this.getOpenPeriod[i].periodeId,
          name:
            this.getMonth(this.getOpenPeriod[i].month) +
            " " +
            this.getOpenPeriod[i].year,
        });
      }
    }

    if (this.getSnackbarAdd == true) {
      this.isShowSuccess = true;
      this.textSuccess = this.snackbarAddText;
      // setTimeout(() => {
      //   this.isShowSuccess = false;
      //   this.getSnackbarAdd = false;
      // }, 2500);
    }

    if (this.getSnackbarEdit == true) {
      this.isShowSuccess = true;
      this.textSuccess = this.snackbarEditText;
      // setTimeout(() => {
      //   this.isShowSuccess = false;
      //   this.getSnackbarEdit = false;
      // }, 2500);
    }
  },
};
</script>

<style src="../styles/journal.scss" lang="scss" />
