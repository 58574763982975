<template>
  <v-dialog
    v-model="isShow"
    transition="dialog-top-transition"
    persistent
    max-width="350"
  >
    <v-card>
      <div class="warning-dialog-content">
        <v-icon color="#fdd835">mdi-alert</v-icon>
        <h2>warning</h2>
        <span>{{ text }}</span>
        <v-card-actions class="action">
          <v-btn
            @click="closePopUp"
            class="action__button mr-2"
            tile
            depressed
            rounded
          >
            Ok
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PopUpWarning",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  emits: ["close-modal"],
  methods: {
    closePopUp() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.warning-dialog-content {
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .v-icon {
    font-size: 100px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  span {
    font-size: 16px;
    color: #333333;
    max-width: 300px;
    margin: 0 auto;
  }

  h2 {
    margin: 10px 0 5px 0;
  }

  .action {
    margin-top: 30px;
    width: 100%;

    &__button {
      width: 100%;
      background-color: #fdd835;
      border-radius: 5px;
    }
    ::v-deep &__button {
      span {
        color: #ffffff;
      }
    }
  }
}
</style>
