<template>
  <v-container>
    <v-card class="report-card mt-6 ml-6 mr-6 pb-14">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="report-card-title">Laporan</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-10 mt-7">
        <v-col>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-row class="mt-n1">
              <v-row>
                <v-col md="2" class="mt-4">
                  <label>
                    <span class="input-title">Tahun</span>
                  </label>
                </v-col>
                <v-col md="10" class="ml-n10">
                  <date-picker
                    v-model="report.year"
                    type="year"
                    format="YYYY"
                    value-type="format"
                    @change="onChangeYear"
                    @close="onClosePanelYear"
                    class="datepicker mt-2"
                    placeholder="Pilih"
                    :popup-style="{
                      top: '235px',
                      left: '455px',
                    }"
                  >
                  </date-picker>
                  <v-alert
                    v-show="yearAlert"
                    transition="slide-y-transition"
                    class="yearpicker-alert"
                    >Tahun harus dipilih</v-alert
                  >
                </v-col>
              </v-row>
            </v-row>
            <v-row class="mt-8">
              <v-row>
                <v-col md="2" class="mt-4">
                  <label>
                    <span class="input-title">Periode</span>
                  </label>
                </v-col>
                <v-col md="10" class="ml-n10">
                  <v-select
                    v-model="report.period"
                    :items="periodFilter"
                    :item-text="(item) => item.month"
                    return-object
                    :rules="periodRules"
                    :loading="periodLoading"
                    no-data-text="Tidak ada data yang tersedia"
                    clearable
                    placeholder="Pilih"
                    class="mt-2"
                    background-color="#FAFAFA"
                    append-icon="mdi-chevron-down"
                    outlined
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <span class="d-flex justify-center" style="width: 100%">
                        {{ getMonth(item.month) || "Audit" }}
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-row>
            <v-row class="mt-1">
              <v-row>
                <v-col md="2" class="mt-4">
                  <label>
                    <span class="input-title">Tipe Laporan</span>
                  </label>
                </v-col>
                <v-col md="10" class="ml-n10">
                  <v-select
                    v-model="report.reportType"
                    :items="reportTypeItems"
                    item-text="name"
                    return-object
                    :rules="reportTypeRules"
                    :loading="reportTypeLoading"
                    clearable
                    placeholder="Pilih"
                    no-data-text="Tidak ada data yang tersedia"
                    class="mt-2"
                    background-color="#FAFAFA"
                    append-icon="mdi-chevron-down"
                    outlined
                    dense
                    @change="selectItem"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-row>
            <v-row
              class="mt-n6 ml-n6"
              v-if="
                report.reportType.name == 'Laporan Perubahaan Aset Neto' ||
                report.reportType.name == 'Laporan Perhitungan Hasil Usaha'
              "
            >
              <v-col md="2" class="mt-4">
                <label>
                  <span class="input-title">Jenis Laporan</span>
                </label>
              </v-col>
              <v-col md="9" class="ml-n10">
                <v-radio-group v-model="report.reportKind" row>
                  <v-radio label="Internal" value="internal"></v-radio>
                  <v-radio label="OJK" value="ojk"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="mt-12 d-flex justify-center">
              <!-- <v-btn
                @click="handlePreview"
                class="btn-preview mr-4"
                small
                tile
                depressed
                >Pratinjau</v-btn
              > -->
              <!-- <v-btn class="btn-print-pdf" small tile depressed
                >Cetak PDF</v-btn
              > -->
              <base-button-export
                @export="previewAction"
                :color="'#3E9FF9'"
                :disabled="!valid || !yearValid"
                class="mr-2"
              >
                <template v-slot:btnText>
                  <span>Preview</span>
                </template>
              </base-button-export>
              <base-button-export
                @export="exportToExcel"
                :loading="getBtnLoading"
                :disabled="!valid || !yearValid || getBtnLoading"
                class="ml-2"
              >
                <template v-slot:btnText>
                  <span>Cetak Excel</span>
                </template>
              </base-button-export>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-show="previewTable" class="report-card mt-6 ml-6 mr-6 pb-14">
      <div v-if="loadingTable" class="d-flex justify-center align-center pt-14">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <div
          v-if="errorState"
          class="d-flex justify-center align-center pt-14 text-center"
        >
          <h3>Oops, something went wrong. Please try again later.</h3>
        </div>
        <div v-else>
          <div class="title-preview">
            <h5>DANA PENSIUN SAMUDERA INDONESIA UTAMA</h5>
            <h5>DEFINED CONTRIBUTION PENSIUN PLAN</h5>
            <h5>{{ titleTablePreview }}</h5>
            <h5>ACCUMULATED UP TO {{ date }}</h5>
          </div>
          <div class="preview-table">
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <th style="min-width: 20px"></th>
                    <th>{{ date }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, index) in dataTable"
                    :key="index"
                    style="height: 30px"
                  >
                    <td :style="data.fontWeightName">{{ data.name }}</td>
                    <td :style="data.fontWeightTotal">{{ data.total }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import BaseButtonExport from "./base/BaseButtonExport.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions, mapGetters } from "vuex";
import { setNumberFormat } from "../functions/NumberFormat.ts";

export default {
  name: "Report",
  components: { BaseButtonExport, DatePicker },
  data: () => ({
    valid: true,
    yearValid: true,
    periods: [],
    reportTypeItems: [],
    months: [
      { value: "01", name: "Januari" },
      { value: "02", name: "Februari" },
      { value: "03", name: "Maret" },
      { value: "04", name: "April" },
      { value: "05", name: "Mei" },
      { value: "06", name: "Juni" },
      { value: "07", name: "Juli" },
      { value: "08", name: "Agustus" },
      { value: "09", name: "September" },
      { value: "10", name: "Oktober" },
      { value: "11", name: "November" },
      { value: "12", name: "Desember" },
    ],
    report: {
      period: "",
      year: null,
      reportType: "",
      reportKind: "ojk",
    },
    periodLoading: true,
    reportTypeLoading: true,
    // Validation Rules
    periodRules: [(v) => !!v || "Periode harus dipilih"],
    reportTypeRules: [(v) => !!v || "Tipe laporan harus dipilih"],
    yearAlert: false,
    src: "",
    previewTable: false,
    dataTable: [],
    statusPreviewButton: false,
    titleTablePreview: "",
    loadingTable: true,
    errorState: false,
    date: "",
  }),
  computed: {
    ...mapGetters(["getPeriod", "getReportType"]),
    periodFilter() {
      return this.periods.filter((item) => item.year == this.report.year);
    },
    getBtnLoading: {
      get() {
        return this.$store.state.btnLoading;
      },
      set(v) {
        this.$store.commit("setBtnLoading", v);
      },
    },
    // type() {
    //   if (this.report.reportType.code == "LAN") {
    //     return "STATEMENTS OF NET ASSETS";
    //   } else if (this.report.reportType.code == "LPAN") {
    //     return "STATEMENTS OF CHANGES IN NET ASSETS";
    //   } else if (this.report.reportType.code == "NRC") {
    //     return "BALANCE SHEETS";
    //   } else if (this.report.reportType.code == "LPHU") {
    //     return "STATEMENTS OF INCOME";
    //   } else if (this.report.reportType.code == "LAK") {
    //     return "STATEMENTS OF CASH FLOW";
    //   } else {
    //     return "";
    //   }
    // },
  },
  watch: {
    getPeriod() {
      this.periodLoading = false;
    },
    getReportType() {
      this.reportTypeLoading = false;
    },
    statusPreviewButton(val) {
      if (val == true) {
        if (this.report.reportType.code == "LAN") {
          this.titleTablePreview = "STATEMENTS OF NET ASSETS";
        } else if (this.report.reportType.code == "LPAN") {
          this.titleTablePreview = "STATEMENTS OF CHANGES IN NET ASSETS";
        } else if (this.report.reportType.code == "NRC") {
          this.titleTablePreview = "BALANCE SHEETS";
        } else if (this.report.reportType.code == "LPHU") {
          this.titleTablePreview = "STATEMENTS OF INCOME";
        } else if (this.report.reportType.code == "LAK") {
          this.titleTablePreview = "STATEMENTS OF CASH FLOW";
        } else {
          this.titleTablePreview = "";
        }
      } else {
        let title = this.titleTablePreview;
        this.titleTablePreview = title;
      }
    },
  },
  methods: {
    ...mapActions(["getPeriodData", "getReportTypeData"]),
    selectItem(value) {
      if (value) {
        this.report.reportType = value;
      } else {
        this.report.reportType = { name: "" };
      }
    },
    getMonth(value) {
      return this.months
        .filter((e) => e.value === value)
        .map((item) => item.name)
        .toString();
    },
    onChangeYear(value) {
      if (!value) {
        this.yearAlert = true;
        this.yearValid = false;
      } else {
        this.yearAlert = false;
        this.yearValid = true;
      }
    },
    onClosePanelYear() {
      if (!this.report.year) {
        this.yearAlert = true;
        this.yearValid = false;
      } else {
        this.yearAlert = false;
      }
    },
    exportToExcel() {
      if (!this.report.year) {
        this.yearAlert = true;
      }
      if (this.$refs.form.validate()) {
        this.$store.commit("setBtnLoading", true);
        let reportTypeCode = this.report.reportType.code;
        if (reportTypeCode === "LAN") {
          this.$store.dispatch("exportNAExcel", this.report);
        } else if (reportTypeCode === "NRC") {
          this.$store.dispatch("exportBSExcel", this.report);
        } else if (reportTypeCode === "LPAN") {
          this.$store.dispatch("exportCNAExcel", this.report);
        } else if (reportTypeCode === "LPHU") {
          this.$store.dispatch("exportISExcel", this.report);
        } else {
          this.$store.dispatch("exportCFExcel", this.report);
        }
      }
    },
    previewAction() {
      let d = new Date(
        parseInt(this.report.year),
        parseInt(
          this.report.period.month === "13" ? "12" : this.report.period.month
        ),
        0
      );

      let month = d.toString().split(" ")[1];
      let day = d.toString().split(" ")[2];
      let year = d.toString().split(" ")[3];
      this.date = day + " " + month + " " + year;

      this.loadingTable = true;
      this.statusPreviewButton = true;
      if (!this.report.year) {
        this.yearAlert = true;
        this.statusPreviewButton = false;
      }
      if (this.$refs.form.validate()) {
        this.previewTable = true;
        this.dataTable.splice(0, this.dataTable.length);
        let reportTypeCode = this.report.reportType.code;
        if (reportTypeCode === "LAN") {
          this.$store
            .dispatch("getListPreviewNA", this.report)
            .then((response) => {
              if (response.length !== 0) {
                response.map((res) => {
                  let total = "";
                  if (
                    res.style.kind == "group" ||
                    res.style.kind == "headerchild"
                  ) {
                    total = "";
                  } else {
                    total = setNumberFormat(res.total);
                  }

                  let bold = "";
                  if (res.style.fontWeight == "bold") {
                    bold = "font-weight: bold;";
                  } else {
                    bold = "font-weight: normal;";
                  }

                  let data = {
                    name: res.name,
                    total: total,
                    fontWeightName: bold,
                    fontWeightTotal: bold,
                  };
                  this.dataTable.push(data);
                  this.statusPreviewButton = false;
                  this.errorState = false;
                  this.loadingTable = false;
                });
              } else {
                this.statusPreviewButton = false;
                this.errorState = false;
                this.loadingTable = false;
              }
            })
            .catch(() => {
              this.loadingTable = false;
              this.errorState = true;
            });
        } else if (reportTypeCode === "NRC") {
          this.$store
            .dispatch("getListPreviewBS", this.report)
            .then((response) => {
              if (response.length !== 0) {
                response.map((res) => {
                  let total = "";
                  if (
                    res.style.kind == "group" ||
                    res.style.kind == "headerchild"
                  ) {
                    total = "";
                  } else {
                    total = setNumberFormat(res.total);
                  }
                  let bold = "";
                  if (res.style.fontWeight == "bold") {
                    bold = "font-weight: bold";
                  } else {
                    bold = "font-weight: normal";
                  }
                  let data = {
                    name: res.name,
                    total: total,
                    fontWeightName: bold,
                    fontWeightTotal: bold,
                  };
                  this.dataTable.push(data);
                  this.statusPreviewButton = false;
                  this.errorState = false;
                  this.loadingTable = false;
                });
              } else {
                this.statusPreviewButton = false;
                this.errorState = false;
                this.loadingTable = false;
              }
            })
            .catch(() => {
              this.loadingTable = false;
              this.errorState = true;
            });
        } else if (reportTypeCode === "LPAN") {
          this.$store
            .dispatch("getListPreviewCNA", this.report)
            .then((response) => {
              if (response.length !== 0) {
                response.map((res) => {
                  let total = "";
                  if (res.style.isCalculate == false) {
                    total = "";
                  } else {
                    total = setNumberFormat(res.total);
                  }

                  let boldName = "";
                  let boldTotal = "";
                  if (res.style.fontWeight == "bold") {
                    boldName = "font-weight: bold;";
                    boldTotal = "font-weight: bold;";
                  } else {
                    boldName = "font-weight: normal;";
                    boldTotal = "font-weight: normal;";
                  }

                  if (res.style.isHeader == false) {
                    boldName += "padding-left: 60px;";
                  }

                  let data = {
                    name: res.name,
                    total: total,
                    fontWeightName: boldName,
                    fontWeightTotal: boldTotal,
                  };
                  this.dataTable.push(data);
                  this.statusPreviewButton = false;
                  this.errorState = false;
                  this.loadingTable = false;
                });
              } else {
                this.statusPreviewButton = false;
                this.errorState = false;
                this.loadingTable = false;
              }
            })
            .catch(() => {
              this.loadingTable = false;
              this.errorState = true;
            });
        } else if (reportTypeCode === "LPHU") {
          this.$store
            .dispatch("getListPreviewIS", this.report)
            .then((response) => {
              if (response.length !== 0) {
                response.map((res) => {
                  let total = "";
                  if (res.style.kind == "group") {
                    total = "";
                  } else {
                    total = setNumberFormat(res.total);
                  }
                  let bold = "";
                  if (res.style.fontWeight == "bold") {
                    bold = "font-weight: bold";
                  } else {
                    bold = "font-weight: normal";
                  }
                  let data = {
                    name: res.name,
                    total: total,
                    fontWeightName: bold,
                    fontWeightTotal: bold,
                  };
                  this.dataTable.push(data);
                  this.statusPreviewButton = false;
                  this.errorState = false;
                  this.loadingTable = false;
                });
              } else {
                this.statusPreviewButton = false;
                this.errorState = false;
                this.loadingTable = false;
              }
            })
            .catch(() => {
              this.loadingTable = false;
              this.errorState = true;
            });
        } else {
          this.$store
            .dispatch("getListPreviewCF", this.report)
            .then((response) => {
              if (response.length !== 0) {
                response.map((res) => {
                  let total = "";
                  if (res.style.kind == "group") {
                    total = "";
                  } else {
                    total = setNumberFormat(res.total);
                  }
                  let bold = "";
                  if (res.style.fontWeight == "bold") {
                    bold = "font-weight: bold";
                  } else {
                    bold = "font-weight: normal";
                  }
                  let data = {
                    name: res.name,
                    total: total,
                    fontWeightName: bold,
                    fontWeightTotal: bold,
                  };
                  this.dataTable.push(data);
                  this.statusPreviewButton = false;
                  this.errorState = false;
                  this.loadingTable = false;
                });
              } else {
                this.statusPreviewButton = false;
                this.errorState = false;
                this.loadingTable = false;
              }
            })
            .catch(() => {
              this.loadingTable = false;
              this.errorState = true;
            });
        }
      }
    },
  },
  async created() {
    await this.getPeriodData();
    await this.getReportTypeData();
    this.periods = await this.getPeriod;
    this.reportTypeItems = await this.getReportType;
  },
};
</script>

<style src="../styles/report.scss" lang="scss" />
