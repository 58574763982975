<template>
  <v-container>
    <!-- Snackbar -->
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
      transition="slide-y-transition"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          x-small
          v-bind="attrs"
          @click="closeSnackbarEdit"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End Snackbar -->
    <v-row class="pl-6 mt-1">
      <v-col>
        <span class="profile-title">Ubah Profil</span>
      </v-col>
    </v-row>
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
      @submit.prevent="handleSave"
    >
      <!-- <v-row class="pl-6 mt-7">
        <v-col>
          <span class="profile-card-title">Peran dan Departemen</span>
        </v-col>
      </v-row>
      <v-card class="profile-card mt-2 ml-6 mr-6 pb-4">
        <v-row class="pa-7">
          <v-col>
            <v-row>
              <v-col md="2" class="mt-2 text-right">
                <label for="">Unit</label>
              </v-col>
              <v-col md="10">
                <v-select
                  v-model="profile.unit"
                  :items="unitItems"
                  placeholder="Pilih"
                  class="text-capitalize"
                  background-color="#F5F5F5"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <label for="">Peran</label>
              </v-col>
              <v-col md="10">
                <v-select
                  v-model="profile.roleId"
                  :items="roleItems"
                  item-value="id"
                  item-text="name"
                  placeholder="Pilih"
                  class="text-capitalize"
                  background-color="#F5F5F5"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <label for="">Departemen</label>
              </v-col>
              <v-col md="10">
                <v-select
                  v-model="profile.departmentId"
                  :items="departemenItems"
                  item-value="id"
                  item-text="name"
                  placeholder="Pilih"
                  class="text-capitalize"
                  background-color="#F5F5F5"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card> -->

      <v-row class="pl-6 mt-7">
        <v-col>
          <span class="profile-card-title">Pribadi</span>
        </v-col>
      </v-row>
      <v-card class="profile-card mt-2 ml-6 mr-6 pb-4">
        <v-row class="pa-7">
          <v-col>
            <!-- <v-row>
              <v-col md="2" class="mt-2 text-right">
                <label for="">Kode</label>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="profile.code"
                  type="text"
                  placeholder="Masukkan Kode"
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row> -->
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <label for="">Nama</label>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="profile.name"
                  :rules="nameRules"
                  type="text"
                  placeholder="Masukkan Nama"
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <label for="">Email</label>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="profile.email"
                  :rules="emailRules"
                  type="email"
                  placeholder="Masukkan Email"
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <label for="">Telepon</label>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="profile.phone"
                  :rules="phoneRules"
                  type="text"
                  placeholder="Masukkan Nomor Telepon"
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <label for="">Alamat</label>
              </v-col>
              <v-col md="10">
                <v-textarea
                  v-model="profile.address"
                  type="text"
                  placeholder="Masukkan Alamat"
                  background-color="#F5F5F5"
                  height="100"
                  no-resize
                  outlined
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="pl-6 mt-7">
        <v-col>
          <span class="profile-card-title">Keamanan</span>
        </v-col>
      </v-row>
      <v-card class="profile-card mt-2 ml-6 mr-6 pb-4">
        <v-row class="pa-7">
          <v-col>
            <v-row>
              <v-col md="2" class="mt-2 text-right">
                <label for="">Kata Sandi</label>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="profile.password"
                  type="password"
                  placeholder="Masukkan Kata Sandi"
                  hint="*Kosongkan password jika tidak ada perubahan password"
                  persistent-hint
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col md="2" class="mt-2 text-right">
                <label for="">Ulangi Kata Sandi</label>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="profile.repeatPassword"
                  :rules="[passwordConfirmationRule]"
                  type="password"
                  placeholder="Ulangi Kata Sandi"
                  background-color="#F5F5F5"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="mt-4">
        <v-spacer></v-spacer>
        <v-col md="2" class="mr-n11">
          <v-btn type="submit" class="btn-save-profile" tile depressed>
            Simpan
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProfileEdit",
  data: () => ({
    valid: true,
    // roleItems: [],
    // departemenItems: [],
    profile: {
      id: "",
      // roleId: "",
      // departmentId: "",
      // code: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      password: "",
      repeatPassword: "",
    },
    // Validation Rules
    nameRules: [(v) => !!v || "Nama harus diisi"],
    emailRules: [
      (v) => !!v || "Email harus diisi",
      (v) => /.+@.+\..+/.test(v) || "E-mail harus valid",
    ],
    phoneRules: [
      (v) => !!v || "Nomor Telepon harus diisi",
      (v) => /^\d+$/.test(v) || "Nomor Telepon harus terdiri dari angka",
      (v) =>
        (v && v.length >= 10) || "Nomor Telepon harus lebih dari 10 karakter",
    ],
    snackbarText: "Data berhasil disimpan",
    timeout: 2000,
  }),
  computed: {
    ...mapGetters(["getProfile"]),
    passwordConfirmationRule() {
      return () =>
        this.profile.password === this.profile.repeatPassword ||
        "Password harus cocok";
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
  },
  methods: {
    ...mapActions(["getProfileData"]),
    handleSave() {
      if (this.$refs.form.validate()) {
        let data = {
          id: this.profile.id,
          name: this.profile.name,
          email: this.profile.email,
          phone: this.profile.phone,
          address: this.profile.address,
          password: this.profile.password,
        };
        this.$store.dispatch("editProfileData", data);
      }
    },
    closeSnackbarEdit() {
      this.$store.commit("showSnackbarEdit", false);
    },
  },
  async created() {
    await this.getProfileData();
    this.profile.id = this.getProfile.id;
    this.profile.name = this.getProfile.name;
    this.profile.email = this.getProfile.email;
    this.profile.phone = this.getProfile.phone;
    this.profile.address = this.getProfile.address;
  },
};
</script>

<style src="../styles/profile-edit.scss" lang="scss" />
