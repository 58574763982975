import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const periodModule = {
  state: {
    period: [],
    periodList: [],
    months: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
    ],
    periodItems: [
      { value: "01", name: "Januari" },
      { value: "02", name: "Februari" },
      { value: "03", name: "Maret" },
      { value: "04", name: "April" },
      { value: "05", name: "Mei" },
      { value: "06", name: "Juni" },
      { value: "07", name: "Juli" },
      { value: "08", name: "Agustus" },
      { value: "09", name: "September" },
      { value: "10", name: "Oktober" },
      { value: "11", name: "November" },
      { value: "12", name: "Desember" },
      { value: "13", name: "Audit" },
    ],
    periodTable: false,
    addPeriod: false,
    snackbarClose: false,
    snackbarOpen: false,
  },
  mutations: {
    setPeriodList(state, payload) {
      state.periodList = payload;
    },
    setPeriod(state, payload) {
      state.period = payload;
    },
    setPeriodTable(state, payload) {
      state.periodTable = payload;
    },
    setAddPeriod(state, paylaod) {
      state.addPeriod = paylaod;
    },
    showSnackbarClose(state, payload) {
      state.snackbarClose = payload;
    },
    showSnackbarOpen(state, payload) {
      state.snackbarOpen = payload;
    },
  },
  actions: {
    async getListPeriodData({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/periodeTrans"
        );
        let data = res.data.data;
        commit("setPeriodList", data);
      } catch (error) {
        console.log(error);
      }
    },
    async getPeriodData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/periodeLink");
        let data = res.data.data;
        commit("setPeriod", data);
        commit("setPeriodTable", true);
      } catch (error) {
        console.log(error);
      }
    },
    async addPeriodData({ dispatch, commit }, payload) {
      try {
        let res = await axios.post(
          process.env.VUE_APP_API_URL + "/periodeTrans",
          {
            month: payload.month,
            year: payload.year,
          }
        );
        if (res.data.status === 207) {
          commit("showSnackbarAlert", true);
        } else {
          dispatch("getPeriodData");
          commit("setAddPeriod", true);
          commit("showSnackbarAdd", true);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async openPeriod({ dispatch, commit }, id) {
      try {
        const data = {
          periode: { id: id },
          user: { id: localStorage.getItem("userId") },
        };
        await axios.post(process.env.VUE_APP_API_URL + "/periodeLink", data);
        dispatch("getPeriodData");
        commit("showSnackbarOpen", true);
      } catch (error) {
        console.log(error);
      }
    },
    async closePeriod({ dispatch, commit }, id) {
      try {
        await axios.put(process.env.VUE_APP_API_URL + "/periodeLink/" + id);
        dispatch("getPeriodData");
        commit("showSnackbarClose", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getPeriodItems(state) {
      return state.periodItems;
    },
    getListPeriod(state) {
      return state.periodList;
    },
    getPeriod(state) {
      let period = state.period.sort((a, b) => {
        if (a.year !== b.year) return a.year - b.year;
        return state.months.indexOf(a.month) - state.months.indexOf(b.month);
      });
      return period;
    },
    getOpenPeriod(state) {
      let period = state.period.filter((item) => item.isClosed === false);
      return period;
    },
    getLastPeriod(state) {
      let period = state.period.slice(-1);
      return period;
    },
    getShowPeriodTable(state) {
      return state.periodTable;
    },
    getAddPeriod(state) {
      return state.addPeriod;
    },
  },
};

export default periodModule;
