<template>
  <footer>
    <v-row>
      <v-col>
        <span class="title">{{ title }}</span>
        <span class="text-copyright">{{ textCopyright }}</span>
      </v-col>
    </v-row>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data: () => ({
    title: "Geekgarden",
    textCopyright: "All rights reserved",
  }),
};
</script>

<style src="../../styles/footer.scss" lang="scss" scoped />
