<template>
  <v-container>
    <!-- Snackbar -->
    <v-snackbar
      v-model="getSnackbarAdd"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
      transition="slide-y-transition"
    >
      {{ snackbarAddText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          x-small
          v-bind="attrs"
          @click="closeSnackbarAdd"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End Snackbar -->
    <!-- Snackbar -->
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
      transition="slide-y-transition"
    >
      {{ snackbarEditText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          x-small
          v-bind="attrs"
          @click="closeSnackbarEdit"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End Snackbar -->
    <!-- Snackbar -->
    <v-snackbar
      v-model="getSnackbarDelete"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
      transition="slide-y-transition"
    >
      {{ snackbarDeleteText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          x-small
          v-bind="attrs"
          @click="closeSnackbarDelete"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End Snackbar -->
    <v-card class="user-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="user-card-title">Pengguna</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row>
          <v-col md="3">
            <base-button :disabled="listPermission[0] === false" @add="addUser">
              <template v-slot:btnText>
                <span>Tambah Pengguna</span>
              </template></base-button
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="mt-n2 pl-7 pr-7">
        <v-row>
          <v-col md="9">
            <v-row>
              <v-col md="4">
                <v-select
                  v-model="roleId"
                  :items="roleItems"
                  item-value="id"
                  item-text="name"
                  :loading="roleLoading"
                  placeholder="Peran"
                  append-icon=""
                  outlined
                  dense
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#CCCCCC">mdi-filter</v-icon>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="4">
                <v-select
                  v-model="departmentId"
                  :items="departmentItems"
                  item-value="id"
                  item-text="name"
                  :loading="departmentLoading"
                  placeholder="Departemen"
                  append-icon=""
                  outlined
                  dense
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#CCCCCC">mdi-filter</v-icon>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="mt-n8 pl-7 pr-7">
        <v-row justify="space-between">
          <v-col md="4">
            <v-row>
              <v-col md="4" class="mr-n6">
                <span class="body-1">Tampilkan</span>
              </v-col>
              <v-col md="4">
                <v-select
                  :value="itemPerPage"
                  item-value="itemPerPage"
                  :items="itemsPerPage"
                  @change="selectItem"
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="4" class="ml-n2">
                <span class="body-1">Entri</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="search"
              placeholder="Cari"
              prepend-inner-icon="mdi-magnify"
              background-color="#FAFAFA"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="mt-n12 pa-7">
        <v-col>
          <v-card class="user-table-card pb-4" elevation="0">
            <v-data-table
              :headers="headers"
              :items="listPermission[3] === false ? userItems : filteredUsers"
              :search="search"
              :page.sync="page"
              :options="{
                itemsPerPage: itemPerPage,
              }"
              :loading="isLoading"
              no-results-text="Data tidak ditemukan"
              :no-data-text="noDataText"
              loading-text="Sedang memuat data..."
              @page-count="pageCount = $event"
              :footer-props="{
                'items-per-page-text': '',
                'disable-items-per-page': true,
                'items-per-page-options': [],
                'prev-icon': null,
                'next-icon': null,
              }"
              class="user-table"
            >
              <template v-slot:[`item.roleId`]="{ item }">
                <span>{{ setRole(item.roleId) }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#3E9FF9"
                      x-small
                      height="24"
                      min-width="24"
                      v-bind="attrs"
                      v-on="on"
                      @click="showDetailItem(item)"
                    >
                      <v-icon color="white" size="14">mdi-eye</v-icon>
                    </v-btn>
                    <v-btn
                      color="#CE5542"
                      x-small
                      height="24"
                      min-width="24"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleDeleteItem(item)"
                      :disabled="listPermission[2] === false"
                    >
                      <v-icon color="white" size="14">mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      color="#E89F3C"
                      x-small
                      height="24"
                      min-width="24"
                      class="ml-1 mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleEditItem(item)"
                      :disabled="listPermission[1] === false"
                    >
                      <v-icon color="white" size="14">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </template>
              <template
                v-slot:[`footer.page-text`]="{
                  pageStart,
                  pageStop,
                  itemsLength,
                }"
              >
                Showing {{ pageStart }} to {{ pageStop }} of {{ itemsLength }}
              </template>
            </v-data-table>
            <v-row class="mt-n10">
              <v-spacer></v-spacer>
              <v-col md="2" class="mr-16">
                <v-pagination v-model="page" :length="pageCount"></v-pagination>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- Delete Dialog -->
    <v-dialog
      v-model="getDeleteDialog"
      transition="dialog-top-transition"
      max-width="350"
    >
      <UserDelete :userId="userId" />
    </v-dialog>
    <!-- End Delete Dialog -->
    <!-- User Detail Modal -->
    <v-dialog
      v-model="getDetailDialog"
      transition="dialog-top-transition"
      width="800"
    >
      <UserDetail />
    </v-dialog>
  </v-container>
</template>

<script>
import BaseButton from "./base/BaseButton.vue";
import UserDetail from "./UserDetail.vue";
import UserDelete from "./UserDelete.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserList",
  components: { BaseButton, UserDetail, UserDelete },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    isLoading: true,
    roleLoading: true,
    departmentLoading: true,
    search: "",
    userItems: [],
    noDataText: "Tidak ada data yang tersedia",
    listPermission: [false, false, false, false],
    roleItems: [
      {
        id: 0,
        name: "Semua Peran",
      },
    ],
    departmentItems: [
      {
        id: 0,
        name: "Semua Departemen",
      },
    ],
    roleId: 0,
    departmentId: 0,
    headers: [
      {
        text: "Kode",
        align: "start",
        sortable: true,
        value: "code",
        divider: true,
      },
      { text: "Nama", value: "name", sortable: true, divider: true },
      { text: "Email", value: "email", sortable: true, divider: true },
      { text: "Peran", value: "roleId", sortable: true, divider: true },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "20%",
      },
    ],
    valid: true,
    userId: null,
    userCode: "",
    // Validation rules
    dialogDelete: false,
    snackbarAddText: "Data berhasil ditambahkan!",
    snackbarEditText: "Data berhasil disimpan!",
    snackbarDeleteText: "Data berhasil dihapus!",
    timeout: 2000,
  }),
  computed: {
    ...mapGetters([
      "getUsers",
      "getRole",
      "getDepartment",
      "getListPermissionById",
    ]),
    filteredUsers() {
      return this.getUsers
        .filter((item) => {
          if (!this.roleId) {
            return item;
          } else if (!this.roleId && !this.departmentId) {
            return item;
          } else {
            return item.roleId === this.roleId;
          }
        })
        .filter((item) => {
          if (!this.departmentId) {
            return item;
          } else if (!this.roleId && !this.departmentId) {
            return item;
          } else {
            return item.departmentId === this.departmentId;
          }
        });
    },
    getDetailDialog: {
      get() {
        return this.$store.state.detailDialog;
      },
      set(v) {
        this.$store.commit("showDetailDialog", v);
      },
    },
    getDeleteDialog: {
      get() {
        return this.$store.state.deleteDialog;
      },
      set(v) {
        this.$store.commit("showDeleteDialog", v);
      },
    },
    getSnackbarAdd: {
      get() {
        return this.$store.state.snackbarAdd;
      },
      set(v) {
        this.$store.commit("showSnackbarAdd", v);
      },
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
    getSnackbarDelete: {
      get() {
        return this.$store.state.snackbarDelete;
      },
      set(v) {
        this.$store.commit("showSnackbarDelete", v);
      },
    },
  },
  watch: {
    getUsers() {
      this.isLoading = false;
    },
    getRole() {
      this.roleLoading = false;
    },
    getDepartment() {
      this.departmentLoading = false;
    },
  },
  methods: {
    ...mapActions(["getUserData", "getRoleData", "getDepartmentData"]),
    selectItem(value) {
      this.itemPerPage = value;
    },
    addUser() {
      let code_user = this.getUsers[this.getUsers.length - 1].code;
      let strCode = code_user.substr(0, 1);
      let intCode = code_user.match(/\d+/g);
      let rxCode = parseInt(code_user.match(/\d+/g)) + 1;
      let maxLength = intCode.toString().length;
      let padCode = String(rxCode).padStart(maxLength, "0");
      let newCode = strCode + padCode;
      this.$router.push({ name: "Tambah Pengguna", params: { code: newCode } });
    },
    showDetailItem(item) {
      this.$store.dispatch("getUserDetail", item.id);
    },
    handleEditItem(item) {
      this.$router.push({ name: "Ubah Pengguna", params: { id: item.id } });
    },
    handleDeleteItem(item) {
      this.userId = item.id;
      this.$store.commit("showDeleteDialog", true);
    },
    closeSnackbarAdd() {
      this.$store.commit("showSnackbarAdd", false);
    },
    closeSnackbarEdit() {
      this.$store.commit("showSnackbarEdit", false);
    },
    closeSnackbarDelete() {
      this.$store.commit("showSnackbarDelete", false);
    },
    setRole(id) {
      return this.getRole
        .filter((e) => e.id === id)
        .map((item) => item.name)
        .toString();
    },
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 1;
      });

      this.listPermission[1] = permissions.some((item) => {
        return item === 2;
      });

      this.listPermission[2] = permissions.some((item) => {
        return item === 3;
      });

      this.listPermission[3] = permissions.some((item) => {
        return item === 4;
      });
    },
  },
  async created() {
    await this.setListPermission();
    if (this.listPermission[3] === false) {
      this.isLoading = false;
      this.roleLoading = false;
      this.departmentLoading = false;
      this.noDataText = "Anda tidak memiliki izin untuk mengakses data";
    } else {
      await this.getUserData();
      await this.getRoleData();
      await this.getDepartmentData();
      this.roleItems.push(...this.getRole);
      this.departmentItems.push(...this.getDepartment);
    }
  },
};
</script>

<style src="../styles/user.scss" lang="scss" />
