import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const transactionTypeModule = {
  state: {
    transactionType: [],
  },
  mutations: {
    setTransactionType(state, payload) {
      state.transactionType = payload;
    },
  },
  actions: {
    async getTransactionTypeData({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/transactionType"
        );
        let data = res.data.data;
        commit("setTransactionType", data);
      } catch (error) {
        console.log(error);
      }
    },
    async addTransactionTypeData({ dispatch, commit }, payload) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + "/transactionType", {
          code: payload.code,
          name: payload.name,
          type: payload.type,
        });
        dispatch("getTransactionTypeData");
        commit("showSnackbarAdd", true);
      } catch (error) {
        console.log(error);
      }
    },
    async editTransactionTypeData({ dispatch, commit }, payload) {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "/transactionType/" + payload.id,
          {
            code: payload.code,
            name: payload.name,
            type: payload.type,
          }
        );
        dispatch("getTransactionTypeData");
        commit("showSnackbarEdit", true);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteTransactionType({ dispatch, commit }, id) {
      try {
        await axios.delete(
          process.env.VUE_APP_API_URL + "/transactionType/" + id
        );
        dispatch("getTransactionTypeData");
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getTransactionType(state) {
      return state.transactionType;
    },
  },
};

export default transactionTypeModule;
