<template>
  <v-container class="container pt-10">
    <v-row>
      <span class="ml-3 welcome-text">Selamat Datang di Sistem Accounting</span>
    </v-row>
    <v-row class="mt-8">
      <v-col md="9">
        <span class="chart-title"
          >Perbandingan Pemasukan dan Pengeluaran Tahun {{ year }}</span
        >
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="3">
        <date-picker
          class="year-picker"
          v-model="year"
          format="YYYY"
          type="year"
          value-type="format"
          placeholder="Pilih Tahun"
        ></date-picker>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-card class="pa-1 ml-4 rounded-lg">
        <apexchart
          type="area"
          height="400"
          width="1040"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-card>
    </v-row>

    <v-dialog
      v-model="isReminder"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar elevation="0">
          <v-spacer></v-spacer>

          <v-btn @click="closePopUp" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="license-dialog-content">
          <v-icon color="yellow">mdi-alert</v-icon>
          <span
            >License Key anda akan habis pada tanggal
            <b>{{ expiredDate }}</b></span
          >
          <v-card-actions class="justify-end action">
            <v-btn
              @click="closePopUp"
              class="btn-close-dialog mr-2"
              tile
              depressed
            >
              Ok
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    apexchart: VueApexCharts,
    DatePicker,
  },
  data: () => ({
    year: new Date().getFullYear(),
    series: [
      {
        name: "Pemasukkan",
        data: [31, 40, 28, 51, 42, 56, 48, 37, 44, 42, 50, 51],
      },
      {
        name: "Pengeluaran",
        data: [30, 32, 45, 32, 34, 52, 41, 42, 44, 47, 49, 50],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "area",
        zoom: false,
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "category",
        max: 12,
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Ags",
          "Sep",
          "Okt",
          "Nov",
          "Des",
        ],
      },
      tooltip: {
        x: {
          format: "MM",
        },
      },
      legend: {
        horizontalAlign: "left",
      },
    },
    expiredDate: "",
    isReminder: false,
  }),
  async created() {
    this.expiredDate = await this.getExpiredDate;
    this.isReminder = await this.getIsReminder;
  },
  computed: {
    ...mapGetters(["getExpiredDate", "getIsReminder"]),
  },
  methods: {
    closePopUp() {
      this.isReminder = false;
      this.$store.commit("setReminder", "false");
      this.$store.commit("setExpiredDate", "");
    },
  },
};
</script>

<style src="../styles/dashboard.scss" lang="scss" />
