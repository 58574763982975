<template>
  <v-expand-transition>
    <v-card
      v-show="profile"
      class="profile-card rounded-lg"
      elevation="10"
      id="navbar-profile"
    >
      <v-card-text>
        <v-row class="mt-n2" justify="center">
          <img src="@/assets/images/default.png" class="img-profile" alt="" />
        </v-row>
        <v-row class="mt-6" justify="center">
          <span class="profile-name">{{ getProfile.name }}</span>
        </v-row>
      </v-card-text>
      <v-card-actions class="card-button mt-5">
        <v-btn @click="pushToProfile" class="btn-profile" small tile depressed>
          Profil
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="logout" class="btn-logout" small tile depressed>
          Logout
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-expand-transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TheProfile",
  props: {
    profile: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["getProfile"]),
  },
  methods: {
    ...mapActions(["getProfileData", "logout"]),
    pushToProfile() {
      if (this.$route.name !== "Ubah Profil") {
        this.$router.push({
          name: "Ubah Profil",
          params: { id: this.getProfile.id },
        });
      }
      this.profile = false;
    },
  },
  async created() {
    await this.getProfileData();
  },
};
</script>

<style src="../../styles/profile.scss" lang="scss" scoped />
