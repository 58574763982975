<template>
  <v-container>
    <v-card class="account-type-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="account-card-title">Tipe Akun</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="7">
            <base-button
              :disabled="listPermission[0] === false"
              @add="showAddDialog"
            >
              <template v-slot:btnText>
                <span>Tambah Akun</span>
              </template></base-button
            >
          </v-col>
          <v-col md="4" class="mr-3">
            <v-text-field
              v-model="search"
              placeholder="Cari"
              prepend-inner-icon="mdi-magnify"
              background-color="#FAFAFA"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-7 pr-7 pb-7 mt-n8">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="getAccountType"
            :search="search"
            class="account-type-table"
            :loading="isLoading"
            loading-text="Sedang memuat data..."
            no-results-text="Data tidak ditemukan"
            :no-data-text="noDataText"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#E89F3C"
                    x-small
                    height="24"
                    min-width="24"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleEditItem(item)"
                    :disabled="listPermission[1] === false"
                  >
                    <v-icon color="white" size="14">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    color="#CE5542"
                    x-small
                    height="24"
                    min-width="24"
                    class="ml-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleDeleteItem(item.id)"
                    :disabled="listPermission[2] === false"
                  >
                    <v-icon color="white" size="14">mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- Add Dialog -->
    <v-dialog
      v-model="addAccountTypeDialog"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Tambah Tipe Akun</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeAccountTypeAddDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="addForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleAddAccountType"
          >
            <v-row class="mt-6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="input.code"
                  :rules="accountTypeCodeRules.concat(checkAddCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="input.name"
                  :rules="accountTypeNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeAccountTypeAddDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Add Dialog -->
    <!-- Edit Dialog -->
    <v-dialog
      v-model="editAccountTypeDialog"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Ubah Tipe Akun</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeAccountTypeEditDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="editForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleEditAccountType"
          >
            <v-row class="mt-6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Kode</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="accountType.code"
                  :rules="accountTypeCodeRules.concat(checkEditCodeRules)"
                  placeholder="Masukkan Kode"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="2" class="mt-2">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="accountType.name"
                  :rules="accountTypeNameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeAccountTypeEditDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Edit Dialog -->
    <!-- Delete Dialog -->
    <v-dialog
      v-model="dialogDelete"
      transition="dialog-top-transition"
      max-width="350"
    >
      <v-card color="pt-8">
        <BodyCardDelete />
        <v-card-actions class="justify-center">
          <v-btn
            class="btn-close-dialog mr-4"
            tile
            depressed
            @click="dialogDelete = false"
            >Batal</v-btn
          >
          <v-btn class="btn-confirm" tile depressed @click="confirmDeleteItem"
            >Hapus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Delete Dialog -->
    <v-snackbar
      v-model="getSnackbarAdd"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAddText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAdd = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarEditText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarDelete"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarDeleteText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarDelete = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseButton from "./base/BaseButton.vue";
import BodyCardDelete from "./AppCardBodyDelete.vue";

export default {
  name: "AccountType",
  components: { BaseButton, BodyCardDelete },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    isLoading: true,
    search: "",
    listPermission: [false, false, false, false],
    noDataText: "Tidak ada data yang tersedia",
    headers: [
      {
        text: "Kode",
        align: "start",
        sortable: true,
        value: "code",
        divider: true,
        width: "15%",
      },
      { text: "Nama", value: "name", sortable: true, divider: true },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "20%",
      },
    ],
    valid: true,
    addAccountTypeDialog: false,
    editAccountTypeDialog: false,
    input: {
      code: "",
      name: "",
    },
    accountType: {
      id: null,
      code: "",
      name: "",
    },
    accountTypeCode: "",
    // Validation
    accountTypeCodeRules: [
      (v) => !!v || "Kode Tipe Akun harus diisi",
      (v) =>
        (v && v.length >= 3) ||
        "Kode Tipe Akun minimal harus terdiri dari 3 karakter",
      (v) =>
        (v && v.length <= 10) ||
        "Kode Tipe Akun maksimal terdiri dari 10 karakter",
    ],
    accountTypeNameRules: [(v) => !!v || "Nama Tipe Akun harus diisi"],
    dialogDelete: false,
    // Snackbar
    snackbarAddText: "Data berhasil ditambahkan!",
    snackbarEditText: "Data berhasil diubah!",
    snackbarDeleteText: "Data berhasil dihapus!",
    timeout: 2500,
  }),
  computed: {
    ...mapGetters(["getAccountType", "getListPermissionById"]),
    getAccountTypeCodes() {
      return this.getAccountType.map((item) => item.code);
    },
    getSomeAccountTypeCodes() {
      let codes = this.getAccountTypeCodes;
      let index = codes.indexOf(this.accountTypeCode);
      if (index !== -1) {
        codes.splice(index, 1);
      }
      return codes;
    },
    checkAddCodeRules() {
      return () =>
        this.getAccountTypeCodes.includes(this.input.code) === false ||
        "Kode tipe akun sudah ada";
    },
    checkEditCodeRules() {
      return () =>
        this.getSomeAccountTypeCodes.includes(this.accountType.code) ===
          false || "Kode tipe akun sudah ada";
    },
    getSnackbarAdd: {
      get() {
        return this.$store.state.snackbarAdd;
      },
      set(v) {
        this.$store.commit("showSnackbarAdd", v);
      },
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
    getSnackbarDelete: {
      get() {
        return this.$store.state.snackbarDelete;
      },
      set(v) {
        this.$store.commit("showSnackbarDelete", v);
      },
    },
  },
  watch: {
    getAccountType() {
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions(["getAccountTypeData"]),
    selectItem(value) {
      this.itemPerPage = value;
    },
    showAddDialog() {
      this.addAccountTypeDialog = true;
    },
    handleEditItem(item) {
      this.accountType.id = item.id;
      this.accountType.code = item.code;
      this.accountType.name = item.name;
      this.accountTypeCode = item.code;
      this.editAccountTypeDialog = true;
    },
    closeAccountTypeEditDialog() {
      this.editAccountTypeDialog = false;
    },
    closeAccountTypeAddDialog() {
      this.addAccountTypeDialog = false;
      this.$refs.addForm.reset();
    },
    handleAddAccountType() {
      if (this.$refs.addForm.validate()) {
        let data = {
          code: this.input.code,
          name: this.input.name,
        };
        this.$store.dispatch("addAccountTypeData", data);
        this.addAccountTypeDialog = false;
        this.$refs.addForm.reset();
      }
    },
    handleEditAccountType() {
      if (this.$refs.editForm.validate()) {
        let data = {
          id: this.accountType.id,
          code: this.accountType.code,
          name: this.accountType.name,
        };
        this.$store.dispatch("editAccountTypeData", data);
        this.editAccountTypeDialog = false;
      }
    },
    handleDeleteItem(id) {
      this.accountType.id = id;
      this.dialogDelete = true;
    },
    confirmDeleteItem() {
      this.$store.dispatch("deleteAccountType", this.accountType.id);
      this.dialogDelete = false;
    },
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 12;
      });

      this.listPermission[1] = permissions.some((item) => {
        return item === 13;
      });

      this.listPermission[2] = permissions.some((item) => {
        return item === 14;
      });

      this.listPermission[3] = permissions.some((item) => {
        return item === 15;
      });
    },
  },
  async created() {
    await this.setListPermission();
    if (this.listPermission[3] === false) {
      this.isLoading = false;
      this.noDataText = "Anda tidak memiliki izin untuk mengakses data";
    } else {
      await this.getAccountTypeData();
    }
  },
};
</script>

<style src="../styles/account-type.scss" lang="scss" />
