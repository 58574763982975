import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const roleModule = {
  state: {
    department: [],
  },
  mutations: {
    setDepartment(state, payload) {
      state.department = payload;
    },
  },
  actions: {
    async getDepartmentData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/department");
        let data = res.data.data;
        commit("setDepartment", data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getDepartment(state) {
      return state.department;
    },
  },
};

export default roleModule;
