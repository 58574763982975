<template>
  <v-container>
    <v-card class="neto-asset-change-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="account-card-title">Perubahan Asset Neto</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <base-button
              :disabled="listPermission[0] === false"
              @add="showAddDialog"
            >
              <template v-slot:btnText>
                <span>Tambah</span>
              </template></base-button
            >
          </v-col>
          <!-- <v-col md="4" class="mr-3">
            <v-text-field
              v-model="search"
              placeholder="Cari"
              prepend-inner-icon="mdi-magnify"
              background-color="#FAFAFA"
              outlined
              dense
            ></v-text-field>
          </v-col> -->
        </v-row>
      </v-card-title>

      <v-row class="pl-7 pr-7 pb-7 mt-n8 pa-7">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="
              listPermission[3] === false ? emptyItems : filteredNetoAssetChange
            "
            :search="search"
            class="neto-asset-change-table"
            :loading="isLoading"
            loading-text="Sedang memuat data..."
            no-results-text="Data tidak ditemukan"
            :no-data-text="noDataText"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.id`]="{ item }">
              {{
                filteredNetoAssetChange
                  .map(function (x) {
                    return x.id;
                  })
                  .indexOf(item.id) + 1
              }}
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <span v-if="item.type == true">Addition</span>
              <span v-else>Deduction</span>
            </template>
            <template v-slot:[`item.isHeader`]="{ item }">
              <v-icon v-if="item.isHeader == true" size="14">mdi-check</v-icon>
              <v-icon v-else size="14">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.isCalculate`]="{ item }">
              <v-icon v-if="item.isCalculate == true" size="14"
                >mdi-check</v-icon
              >
              <v-icon v-else size="14">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#3E9FF9"
                    dark
                    x-small
                    height="24"
                    min-width="24"
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleDetailItem(item)"
                  >
                    <v-icon size="14">mdi-eye</v-icon>
                  </v-btn>
                  <v-btn
                    color="#CE5542"
                    x-small
                    height="24"
                    min-width="24"
                    class="ml-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleDeleteItem(item.id)"
                    :disabled="listPermission[2] === false"
                  >
                    <v-icon color="white" size="14">mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    color="#E89F3C"
                    x-small
                    height="24"
                    min-width="24"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleEditItem(item)"
                    :disabled="listPermission[1] === false"
                  >
                    <v-icon color="white" size="14">mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- Add Dialog -->
    <v-dialog
      v-model="addDialog"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Tambah Data</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeAddDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="addForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleAddData"
          >
            <v-row class="mt-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="input.type"
                  :items="type"
                  item-value="id"
                  item-text="name"
                  :rules="typeRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="input.name"
                  :rules="nameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Urutan</span>
              </v-col>
              <v-col md="19">
                <v-text-field
                  v-model="input.order"
                  :rules="urutanRules"
                  placeholder="Masukkan Urutan"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Header</span>
              </v-col>
              <v-col md="19" class="wrapping-radio">
                <v-radio-group
                  v-model="input.header"
                  :rules="headerRules"
                  :column="false"
                  class="radio-button mt-1"
                  dense
                >
                  <v-row>
                    <v-col>
                      <v-radio label="Ya" value="1"> </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="Tidak" value="0"> </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Kalkulasi</span>
              </v-col>
              <v-col md="9">
                <v-radio-group
                  v-model="input.calculation"
                  :rules="kalkulasiRules"
                  :column="false"
                  class="mt-1"
                  dense
                >
                  <v-row>
                    <v-col>
                      <v-radio label="Ya" value="1"> </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="Tidak" value="0"> </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <div v-if="input.calculation == 1">
              <v-divider class="mb-5 mt-5"></v-divider>
              <div v-for="(val, index) in input.accountId" :key="index">
                <v-row class="mt-n6">
                  <v-col md="4" class="mt-2 mb-5" v-if="index + 1 === 1">
                    <span class="subtitle-1 font-weight-black">Kode Akun</span>
                  </v-col>
                  <v-col md="8" class="text-right mt-1">
                    <v-btn @click="addDropdown" icon v-if="index + 1 === 1">
                      <v-icon color="#3E9FF9">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-n6">
                  <v-col>
                    <v-select
                      v-model="input.accountId[index]"
                      :items="listCodeAccount"
                      item-value="id"
                      placeholder="Pilih"
                      background-color="#FAFAFA"
                      append-icon="mdi-chevron-down"
                      outlined
                      dense
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.code }} - {{ data.item.name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.code }} - {{ data.item.name }}
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item class="mt-n5 mb-n5">
                          <v-list-item-content>
                            <v-text-field
                              v-model="dataSearch"
                              placeholder="Search"
                              @input="actionSearchCodeAccountAdd"
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col md="2" class="text-right" v-if="index + 1 !== 1">
                    <v-btn @click="removeDropdown(index)" icon>
                      <v-icon color="#EB7765">mdi-minus-circle-outline</v-icon>
                    </v-btn></v-col
                  ></v-row
                >
              </div>
            </div>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeAddDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Add Dialog -->

    <!-- Edit Dialog -->
    <v-dialog
      v-model="editDialog"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Ubah Data</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeEditDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="editForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleEditData"
          >
            <v-row class="mt-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="inputValue.type"
                  :items="type"
                  item-value="id"
                  item-text="name"
                  :rules="typeRules"
                  placeholder="Pilih"
                  background-color="#FAFAFA"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="inputValue.name"
                  :rules="nameRules"
                  placeholder="Masukkan Nama"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Urutan</span>
              </v-col>
              <v-col md="19">
                <v-text-field
                  v-model="inputValue.order"
                  :rules="urutanRules"
                  placeholder="Masukkan Urutan"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Header</span>
              </v-col>
              <v-col md="19">
                <v-radio-group
                  v-model="inputValue.header"
                  :rules="headerRules"
                  :column="false"
                  class="mt-1"
                  dense
                >
                  <v-row>
                    <v-col>
                      <v-radio label="Ya" value="1"> </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="Tidak" value="0"> </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Kalkulasi</span>
              </v-col>
              <v-col md="9">
                <v-radio-group
                  v-model="inputValue.calculation"
                  :rules="kalkulasiRules"
                  :column="false"
                  class="mt-1"
                  dense
                >
                  <v-row>
                    <v-col>
                      <v-radio label="Ya" value="1"> </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="Tidak" value="0"> </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <div v-if="inputValue.calculation == 1">
              <v-divider class="mb-5 mt-5"></v-divider>
              <div v-for="(val, index) in inputValue.accountId" :key="index">
                <v-row class="mt-n6">
                  <v-col md="4" class="mt-2 mb-5" v-if="index + 1 === 1">
                    <span class="subtitle-1 font-weight-black">Kode Akun</span>
                  </v-col>
                  <v-col md="8" class="text-right mt-1">
                    <v-btn @click="addDropdownEdit" icon v-if="index + 1 === 1">
                      <v-icon color="#3E9FF9">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-n6">
                  <v-col>
                    <v-select
                      v-model="inputValue.accountId[index]"
                      :items="listCodeAccount"
                      item-value="id"
                      placeholder="Pilih"
                      background-color="#FAFAFA"
                      append-icon="mdi-chevron-down"
                      outlined
                      dense
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.code }} - {{ data.item.name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.code }} - {{ data.item.name }}
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item class="mt-n5 mb-n5">
                          <v-list-item-content>
                            <v-text-field
                              v-model="dataSearchEdit"
                              placeholder="Search"
                              @input="actionSearchCodeAccountEdit"
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col md="2" class="text-right" v-if="index + 1 !== 1">
                    <v-btn @click="removeDropdownEdit(index)" icon>
                      <v-icon color="#EB7765">mdi-minus-circle-outline</v-icon>
                    </v-btn></v-col
                  ></v-row
                >
              </div>
            </div>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeEditDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  End Edit Dialog -->

    <!-- Detail Dialog -->
    <v-dialog
      v-model="detailDialog"
      transition="dialog-top-transition"
      persistent
      max-width="525"
    >
      <v-card>
        <v-toolbar color="#F6F7FC" elevation="0">
          <v-toolbar-title>Detail Data</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeDetailDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-row class="mt-6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Tipe</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="inputValue.type"
                  :items="type"
                  item-value="id"
                  item-text="name"
                  :readonly="true"
                  placeholder="Pilih"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="9">
                <v-text-field
                  v-model="inputValue.name"
                  :readonly="true"
                  placeholder="Masukkan Nama"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Urutan</span>
              </v-col>
              <v-col md="19">
                <v-text-field
                  v-model="inputValue.order"
                  :readonly="true"
                  placeholder="Masukkan Urutan"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Header</span>
              </v-col>
              <v-col md="19">
                <v-radio-group
                  v-model="inputValue.header"
                  :readonly="true"
                  :column="false"
                  class="mt-1"
                  dense
                >
                  <v-row>
                    <v-col>
                      <v-radio label="Ya" value="1"> </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="Tidak" value="0"> </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col md="3" class="mt-2 text-right">
                <span class="subtitle-1 font-weight-black">Kalkulasi</span>
              </v-col>
              <v-col md="9">
                <v-radio-group
                  v-model="inputValue.calculation"
                  :readonly="true"
                  :column="false"
                  class="mt-1"
                  dense
                >
                  <v-row>
                    <v-col>
                      <v-radio label="Ya" value="1"> </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="Tidak" value="0"> </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <div v-if="inputValue.calculation == 1">
              <v-divider class="mb-5 mt-5"></v-divider>
              <div v-for="(val, index) in inputValue.accountId" :key="index">
                <v-row class="mt-n6">
                  <v-col md="4" class="mt-2 mb-5" v-if="index + 1 === 1">
                    <span class="subtitle-1 font-weight-black">Kode Akun</span>
                  </v-col>
                  <!-- <v-col md="8" class="text-right mt-1">
                    <v-btn
                      @click="addDropdownCategory"
                      icon
                      v-if="index + 1 === 1"
                    >
                      <v-icon color="#3E9FF9">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </v-col> -->
                </v-row>
                <v-row class="mt-n6">
                  <v-col class="mb-3">
                    <v-select
                      v-model="inputValue.accountId[index]"
                      :items="getAccount"
                      :readonly="true"
                      item-value="id"
                      placeholder="Pilih"
                      background-color="#FAFAFA"
                      append-icon=""
                      outlined
                      dense
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.code }} - {{ data.item.name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.code }} - {{ data.item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <!-- <v-col md="2" class="text-right" v-if="index + 1 !== 1">
                    <v-btn @click="removeDropdownCategory(index)" icon>
                      <v-icon color="#EB7765">mdi-minus-circle-outline</v-icon>
                    </v-btn>
                  </v-col> -->
                </v-row>
              </div>
            </div>
            <v-card-actions class="justify-end">
              <v-btn
                @click="closeDetailDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Tutup
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Detail Dialog -->

    <!-- Delete Dialog -->
    <v-dialog
      v-model="deleteDialog"
      transition="dialog-top-transition"
      max-width="350"
    >
      <v-card color="pt-8">
        <BodyCardDelete />
        <v-card-actions class="justify-center">
          <v-btn
            class="btn-close-dialog mr-4"
            tile
            depressed
            @click="deleteDialog = false"
            >Batal</v-btn
          >
          <v-btn class="btn-confirm" tile depressed @click="confirmDeleteItem"
            >Hapus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Delete Dialog -->

    <v-snackbar
      v-model="getSnackbarAdd"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAddText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarAdd = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarEdit"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarEditText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="getSnackbarDelete"
      :timeout="timeout"
      color="success"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarDeleteText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="getSnackbarDelete = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "./base/BaseButton.vue";
import BodyCardDelete from "./AppCardBodyDelete.vue";

export default {
  name: "NetoAssetChange",
  components: { BaseButton, BodyCardDelete },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    listPermission: [false, false, false, false],
    emptyItems: [],
    noDataText: "Tidak ada data yang tersedia",
    netoAssetChangeLoading: true,
    isLoading: true,
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "id",
        divider: true,
      },
      {
        text: "Nama",
        value: "name",
        sortable: true,
        divider: true,
        width: "30%",
      },
      { text: "Tipe", value: "type", sortable: true, divider: true },
      { text: "Urutan", value: "noUrut", sortable: true, divider: true },
      { text: "Header", value: "isHeader", sortable: true, divider: true },
      {
        text: "Kalkulasi",
        value: "isCalculate",
        sortable: true,
        divider: true,
      },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "15%",
      },
    ],
    valid: true,
    addDialog: false,
    editDialog: false,
    detailDialog: false,
    input: {
      type: null,
      name: "",
      order: "",
      header: "",
      calculation: "",
      accountId: [""],
    },
    inputValue: {
      id: null,
      type: null,
      name: "",
      order: "",
      header: "",
      calculation: "1",
      accountId: [""],
    },
    netoAssetChangeId: 0,
    // Validation
    typeRules: [(v) => !!v || "Tipe harus dipilih"],
    nameRules: [(v) => !!v || "Nama harus diisi"],
    urutanRules: [(v) => !!v || "Urutan harus diisi"],
    headerRules: [(v) => !!v || "Header harus diisi"],
    kalkulasiRules: [(v) => !!v || "Kalkulasi harus diisi"],
    deleteDialog: false,
    // Snackbar
    snackbarAddText: "Data berhasil ditambahkan!",
    snackbarEditText: "Data berhasil diubah!",
    snackbarDeleteText: "Data berhasil dihapus!",
    timeout: 2500,
    type: [
      { id: 1, name: "Deduction" },
      { id: 2, name: "Addition" },
    ],
    dataSearch: "",
    dataSearchEdit: "",
    listCodeAccount: [],
  }),
  computed: {
    ...mapGetters([
      "getNetoAssetChange",
      "getListPermissionById",
      "getAccount",
    ]),
    filteredNetoAssetChange() {
      return this.getNetoAssetChange.filter((items) => {
        if (!this.netoAssetChangeId) {
          return items;
        } else if (this.netoAssetChangeId === 0) {
          return items;
        }
      });
    },
    getSnackbarAdd: {
      get() {
        return this.$store.state.snackbarAdd;
      },
      set(v) {
        this.$store.commit("showSnackbarAdd", v);
      },
    },
    getSnackbarEdit: {
      get() {
        return this.$store.state.snackbarEdit;
      },
      set(v) {
        this.$store.commit("showSnackbarEdit", v);
      },
    },
    getSnackbarDelete: {
      get() {
        return this.$store.state.snackbarDelete;
      },
      set(v) {
        this.$store.commit("showSnackbarDelete", v);
      },
    },
  },
  watch: {
    getNetoAssetChange() {
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions(["getDataNetoAssetChange", "getAccountData"]),
    actionSearchCodeAccountAdd() {
      if (this.dataSearch === "" || this.dataSearch === null) {
        this.listCodeAccount = this.getAccount;
      } else {
        this.getAccountData();
        this.listCodeAccount = this.getAccount.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.dataSearch.toLowerCase()) > -1
          );
        });
      }
    },
    actionSearchCodeAccountEdit() {
      // console.log(this.dataSearchEdit);
      if (this.dataSearchEdit === "" || this.dataSearchEdit === null) {
        this.listCodeAccount = this.getAccount;
      } else {
        this.getAccountData();
        this.listCodeAccount = this.getAccount.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.dataSearchEdit.toLowerCase()) >
            -1
          );
        });
      }
    },
    selectItem(value) {
      this.itemPerPage = value;
    },
    closeEditDialog() {
      this.editDialog = false;
      this.inputValue.accountId = [""];
      this.dataSearchEdit = "";
      this.getAccountData();
      this.listCodeAccount = this.getAccount;
    },
    closeAddDialog() {
      this.addDialog = false;
      this.$refs.addForm.reset();
      this.input.accountId = [""];
    },
    closeDetailDialog() {
      this.detailDialog = false;
    },
    showAddDialog() {
      this.addDialog = true;
    },
    handleAddData() {
      if (this.$refs.addForm.validate()) {
        let data = {
          type: this.input.type - 1,
          name: this.input.name,
          noUrut: parseInt(this.input.order),
          isHeader: parseInt(this.input.header),
          isCalculate: parseInt(this.input.calculation),
          account: JSON.stringify(this.input.accountId),
        };
        this.$store.dispatch("addNetoAssetChange", data);
        this.addDialog = false;
        this.$refs.addForm.reset();
        this.input.accountId = [""];
      }
    },
    handleEditItem(item) {
      this.inputValue.id = item.id;
      if (item.type == true) {
        this.inputValue.type = 2;
      } else {
        this.inputValue.type = 1;
      }
      this.inputValue.name = item.name;
      this.inputValue.order = item.noUrut.toString();
      if (item.isHeader == true) {
        this.inputValue.header = "1";
      } else {
        this.inputValue.header = "0";
      }
      if (item.isCalculate == true) {
        this.inputValue.calculation = "1";
      } else {
        this.inputValue.calculation = "0";
      }
      this.inputValue.accountId = JSON.parse(item.account);
      this.editDialog = true;
    },
    handleEditData() {
      if (this.$refs.editForm.validate()) {
        let account = "";
        if (parseInt(this.inputValue.calculation) === 1) {
          account = JSON.stringify(this.inputValue.accountId);
        } else {
          account = '[""]';
        }
        let data = {
          id: this.inputValue.id,
          type: this.inputValue.type - 1,
          name: this.inputValue.name,
          noUrut: parseInt(this.inputValue.order),
          isHeader: parseInt(this.inputValue.header),
          isCalculate: parseInt(this.inputValue.calculation),
          account: account,
        };
        this.$store.dispatch("editNetoAssetChange", data);
        this.editDialog = false;
        this.inputValue.accountId = [""];
      }
    },
    handleDetailItem(item) {
      this.inputValue.id = item.id;
      if (item.type == true) {
        this.inputValue.type = 2;
      } else {
        this.inputValue.type = 1;
      }
      this.inputValue.name = item.name;
      this.inputValue.order = item.noUrut.toString();
      if (item.isHeader == true) {
        this.inputValue.header = "1";
      } else {
        this.inputValue.header = "0";
      }
      if (item.isCalculate == true) {
        this.inputValue.calculation = "1";
      } else {
        this.inputValue.calculation = "0";
      }
      this.inputValue.accountId = JSON.parse(item.account);
      this.detailDialog = true;
    },
    handleDeleteItem(id) {
      this.inputValue.id = id;
      this.deleteDialog = true;
    },
    confirmDeleteItem() {
      this.$store.dispatch("deleteNetoAssetChange", this.inputValue.id);
      this.deleteDialog = false;
    },
    setListPermission() {
      const permissions = this.getListPermissionById;
      this.listPermission[0] = permissions.some((item) => {
        return item === 16;
      });

      this.listPermission[1] = permissions.some((item) => {
        return item === 17;
      });

      this.listPermission[2] = permissions.some((item) => {
        return item === 18;
      });

      this.listPermission[3] = permissions.some((item) => {
        return item === 19;
      });
    },
    addDropdown() {
      if (this.addDialog) {
        this.input.accountId.push("");
      }
    },
    removeDropdown(index) {
      if (this.addDialog) {
        this.input.accountId.splice(index, 1);
      }
    },
    addDropdownEdit() {
      if (this.editDialog) {
        this.inputValue.accountId.push("");
      }
    },
    removeDropdownEdit(index) {
      if (this.editDialog) {
        this.inputValue.accountId.splice(index, 1);
      }
    },
  },
  async created() {
    await this.setListPermission();
    if (this.listPermission[3] === false) {
      this.isLoading = false;
      this.netoAssetChangeLoading = false;
      this.noDataText = "Anda tidak memiliki izin untuk mengakses data";
    } else {
      await this.getDataNetoAssetChange();
      await this.getAccountData();
      this.listCodeAccount = this.getAccount;
    }
  },
};
</script>

<style src="../styles/neto-asset-change.scss" lang="scss" />
