<template>
  <v-container>
    <v-snackbar
      v-model="snackbarAlert"
      :timeout="timeout"
      :color="alertColor"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAlertText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbarAlert = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="net-asset-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="net-asset-card-title"
              >Konfigurasi Laporan Statements of Net Asset</span
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="7">
            <!-- <v-btn
              
              color="#E89F3C"
              class="rounded white--text"
              :to="{ name: 'Statements Net Asset Edit' }"
              tile
              depressed
            >
              <v-icon class="mr-1" size="20">
                mdi-pencil
              </v-icon>
              EDIT
            </v-btn> -->
            <base-button @add="openAddParentDialog()">
              <template v-slot:btnText>
                <span>Tambah Komponen</span>
              </template></base-button
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="4">
            <v-row>
              <v-col md="4" class="mr-n6">
                <span class="body-1">Tampilkan</span>
              </v-col>
              <v-col md="4">
                <v-select
                  v-model="itemPerPage"
                  :items="itemsPerPage"
                  @change="selectItem"
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="4" class="ml-n2">
                <span class="body-1">Entri</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" class="mr-3">
            <v-text-field
              v-model="search"
              placeholder="Cari akun"
              prepend-inner-icon="mdi-magnify"
              background-color="#FAFAFA"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-7 pr-7 pb-7">
        <v-col>
          <v-card class="account-table pb-4" elevation="0">
            <v-data-table
              id="account-parent-table"
              ref="accountParentTable"
              :headers="headers"
              :items="formattedItems"
              item-key="numbering"
              :options="{
                itemsPerPage: itemPerPage,
              }"
              :page.sync="page"
              @page-count="pageCount = $event"
              :search="search"
              :no-results-text="noResultsText"
              :no-data-text="noDataText"
              :footer-props="{
                'items-per-page-text': '',
                'disable-items-per-page': true,
                'items-per-page-options': [],
                'prev-icon': null,
                'next-icon': null,
              }"
            >
              <template v-slot:[`item.key`]="{ item }">
                <span :class="item.role === 'component' ? 'text-bold' : ''">{{
                  item.key
                }}</span>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <span :class="item.role === 'component' ? 'text-bold' : ''">{{
                  item.name
                }}</span>
              </template>
              <template v-slot:[`item.isCalculate`]="{ item }">
                <v-checkbox
                  v-if="Object.keys(item).some((el) => el == 'isCalculate')"
                  v-model="item.isCalculate"
                  :ripple="false"
                  color="primary"
                  :readonly="true"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.isSubGroup`]="{ item }">
                <v-checkbox
                  v-if="Object.keys(item).some((el) => el == 'isSubGroup')"
                  v-model="item.isSubGroup"
                  :ripple="false"
                  color="primary"
                  :readonly="true"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.fontBold`]="{ item }">
                <v-checkbox
                  v-if="Object.keys(item).some((el) => el == 'fontBold')"
                  v-model="item.fontBold"
                  :ripple="false"
                  color="primary"
                  :readonly="true"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.borderTop`]="{ item }">
                <v-checkbox
                  v-if="Object.keys(item).some((el) => el == 'borderTop')"
                  v-model="item.borderTop"
                  :ripple="false"
                  color="primary"
                  :readonly="true"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.borderBottom`]="{ item }">
                <v-checkbox
                  v-if="Object.keys(item).some((el) => el == 'borderBottom')"
                  v-model="item.borderBottom"
                  :ripple="false"
                  color="primary"
                  :readonly="true"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#6BC282"
                      x-small
                      height="24"
                      min-width="24"
                      v-bind="attrs"
                      v-on="on"
                      @click="openAddChildDialog(item)"
                      v-if="item.role === 'header' || item.role === 'component'"
                    >
                      <v-icon color="white" size="14">mdi-plus-circle</v-icon>
                    </v-btn>
                    <v-btn
                      color="#CE5542"
                      x-small
                      height="24"
                      min-width="24"
                      v-bind="attrs"
                      v-on="on"
                      @click="openDeleteDialog(item)"
                    >
                      <v-icon color="white" size="14">mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      color="#E89F3C"
                      x-small
                      height="24"
                      min-width="24"
                      class="mr-1 ml-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="openEditDialog(item)"
                    >
                      <v-icon color="white" size="14">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
                <!-- <v-menu offset-y v-if="item.role === 'child'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#CE5542"
                      x-small
                      height="24"
                      min-width="24"
                      class="ml-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="openAddChildDialog(item)"
                    >
                      <v-icon color="white" size="14">mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      color="#E89F3C"
                      x-small
                      height="24"
                      min-width="24"
                      v-bind="attrs"
                      v-on="on"
                      @click="openEditDialog(item)"
                    >
                      <v-icon color="white" size="14">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-menu> -->
              </template>
              <template
                v-slot:[`footer.page-text`]="{
                  pageStart,
                  pageStop,
                  itemsLength,
                }"
              >
                Showing {{ pageStart }} to {{ pageStop }} of {{ itemsLength }}
              </template>
            </v-data-table>
            <v-row class="mt-n10">
              <v-spacer></v-spacer>
              <v-col md="auto" class="mr-10">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="7"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- Add Parent Dialog -->
    <v-dialog
      v-model="addAccountParentDialog"
      transition="dialog-top-transition"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="subtitle-1">{{ accountDialogTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn small text @click="closeDialog">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="mb-n8">
          <v-row justify="end">
            <v-col md="4">
              <v-text-field
                v-model="searchAccount"
                append-icon="mdi-magnify"
                placeholder="search"
                outlined
                dense
                >Search</v-text-field
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-card class="mt-2" elevation="0">
            <v-data-table
              :headers="accountHeaders"
              :items="getAccountParent"
              :search="searchAccount"
              :loading="accountLoading"
              :loading-text="loadingText"
              :no-results-text="noResultsText"
              :no-data-text="noDataText"
              class="account-table"
            >
              <template v-slot:[`item.index`]="{ item }">
                <span>{{ item.index + 1 }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="#E46D47"
                  @click="handleAddAccountParent(item)"
                  x-small
                  height="24"
                  min-width="20"
                >
                  <v-icon color="white" size="16"> mdi-plus-circle </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Add Dialog -->
    <!-- Add Child Dialog -->
    <!-- <v-dialog
      v-model="addAccountChildDialog"
      transition="dialog-top-transition"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="subtitle-1">Tambah Header</span>
          <v-spacer></v-spacer>
          <v-btn small text @click="closeDialog">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="addChildForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleAddAccountChild"
          >
            <v-row class="mt-2">
              <v-col>
                <v-radio-group
                  v-model="accountLevel"
                  :column="false"
                  class="mt-1"
                  dense
                >
                  <v-row>
                    <v-col>
                      <v-radio label="Header" value="1" :readonly="true"> </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="Anak Akun" value="2" :readonly="true"> </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3" class="mt-1">
                <span class="subtitle-1 font-weight-black">Nama Akun</span>
              </v-col>
              <v-col md="9">
                <v-select
                  v-model="selectedAccountChild"
                  :items="accountChildDialog"
                  item-value="index"
                  item-text="name"
                  placeholder="Nama Akun"
                  append-icon=""
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog mr-n2"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <!-- End Add Child Dialog -->
    <!-- Add Grandchild Dialog -->
    <v-dialog
      v-model="addAccountChildDialog"
      transition="dialog-top-transition"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="subtitle-1">{{ accountDialogTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn small text @click="closeDialog">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            v-if="editAccountDialog"
            ref="addChildForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleUpdateAccountChild"
          >
            <v-row class="mt-2">
              <v-col>
                <v-radio-group
                  v-model="accountLevel"
                  :column="false"
                  @change="changeAccountLevel"
                  :readonly="editAccountDialog"
                  class="mt-1"
                  dense
                >
                  <v-row>
                    <v-col>
                      <v-radio label="Header" value="1" :ripple="false">
                      </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="Anak Akun" value="2" :ripple="false">
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3" class="mt-1">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="9">
                <v-autocomplete
                  v-if="accountLevel === '2'"
                  v-model="selectedAccountChild"
                  :items="getAccount"
                  item-value="code"
                  item-text="name"
                  placeholder="Nama"
                  :rules="[
                    (v) => !!(v && v.length) || 'Nama Anak Akun Harus Dipilih',
                  ]"
                  append-icon=""
                  outlined
                  dense
                ></v-autocomplete>
                <v-text-field
                  v-else
                  v-model="headerName"
                  :rules="[(v) => !!v || 'Nama Header Harus Diisi']"
                  placeholder="Nama"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="accountLevel === '2'" class="mt-n6">
              <v-col md="3" class="mt-1">
                <span class="subtitle-1 font-weight-black">Jurnal</span>
              </v-col>
              <v-col md="9">
                <v-radio-group
                  v-model="accountJournal"
                  :column="false"
                  class="mt-1"
                  dense
                >
                  <v-row>
                    <v-col>
                      <v-radio label="in" value="in" :ripple="false"> </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="out" value="out" :ripple="false">
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="mt-n2" v-if="accountLevel == 2">
              <v-col>
                <v-checkbox
                  v-model="calculate"
                  label="Calculate"
                  dense
                ></v-checkbox>
                <v-checkbox
                  v-model="subGroup"
                  label="Sub Group"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="fontBold"
                  label="Font Bold"
                  dense
                ></v-checkbox>
                <v-checkbox
                  v-model="borderTop"
                  label="Border Top"
                  dense
                ></v-checkbox>
                <v-checkbox
                  v-model="borderBottom"
                  label="Border Bottom"
                  dense
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <!-- <v-btn
                @click="closeTransactionTypeDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn> -->
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog mr-n2"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
          <v-form
            v-else
            ref="addChildForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleAddAccountChild"
          >
            <v-row class="mt-2">
              <v-col>
                <v-radio-group
                  v-model="accountLevel"
                  :column="false"
                  @change="changeAccountLevel"
                  :readonly="editAccountDialog"
                  class="mt-1"
                  dense
                >
                  <v-row>
                    <v-col>
                      <v-radio label="Header" value="1" :ripple="false">
                      </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="Anak Akun" value="2" :ripple="false">
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3" class="mt-1">
                <span class="subtitle-1 font-weight-black">Nama</span>
              </v-col>
              <v-col md="9">
                <v-autocomplete
                  v-if="accountLevel === '2'"
                  v-model="selectedAccountChild"
                  :items="getAccount"
                  item-value="code"
                  item-text="name"
                  placeholder="Nama"
                  :rules="[
                    (v) => !!(v && v.length) || 'Nama Anak Akun Harus Dipilih',
                  ]"
                  append-icon=""
                  outlined
                  dense
                ></v-autocomplete>
                <v-text-field
                  v-else
                  v-model="headerName"
                  :rules="[(v) => !!v || 'Nama Header Harus Diisi']"
                  placeholder="Nama"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="accountLevel === '2'" class="mt-n6">
              <v-col md="3" class="mt-1">
                <span class="subtitle-1 font-weight-black">Jurnal</span>
              </v-col>
              <v-col md="9">
                <v-radio-group
                  v-model="accountJournal"
                  :column="false"
                  class="mt-1"
                  dense
                >
                  <v-row>
                    <v-col>
                      <v-radio label="in" value="in" :ripple="false"> </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="out" value="out" :ripple="false">
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="mt-n2" v-if="accountLevel == 2">
              <v-col>
                <v-checkbox
                  v-model="calculate"
                  label="Calculate"
                  dense
                ></v-checkbox>
                <v-checkbox
                  v-model="subGroup"
                  label="Sub Group"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="fontBold"
                  label="Font Bold"
                  dense
                ></v-checkbox>
                <v-checkbox
                  v-model="borderTop"
                  label="Border Top"
                  dense
                ></v-checkbox>
                <v-checkbox
                  v-model="borderBottom"
                  label="Border Bottom"
                  dense
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <!-- <v-btn
                @click="closeTransactionTypeDialog"
                class="btn-close-dialog mr-2"
                tile
                depressed
              >
                Batal
              </v-btn> -->
              <v-btn
                type="submit"
                :disabled="!valid"
                class="btn-save-dialog mr-n2"
                tile
                depressed
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Add Grandchild Dialog -->

    <!-- Delete Dialog -->
    <v-dialog
      v-model="deletAccountDialog"
      transition="dialog-top-transition"
      max-width="350"
    >
      <v-card color="pt-8">
        <BodyCardDelete />
        <v-card-actions class="justify-center">
          <v-btn
            class="btn-close-dialog mr-4"
            tile
            depressed
            @click="deletAccountDialog = false"
            >Batal</v-btn
          >
          <v-btn class="btn-confirm" tile depressed @click="handleDeleteAccount"
            >Hapus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Delete Dialog -->
  </v-container>
</template>

<script>
import BaseButton from "./base/BaseButton.vue";
import BodyCardDelete from "./AppCardBodyDelete.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ReportStatementsOfNetAssetEdit",
  components: { BaseButton, BodyCardDelete },
  data: () => ({
    valid: true,
    tableItems: [],
    tableNumbering: "",
    itemParents: "",
    selectedItem: {},
    deletedItem: {},
    headers: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "numbering",
        divider: true,
        width: "92px",
      },
      {
        text: "Nama",
        value: "name",
        sortable: true,
        divider: true,
        width: "500px",
      },
      {
        text: "Calculate",
        value: "isCalculate",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Sub Group",
        value: "isSubGroup",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Font Bold",
        value: "fontBold",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Border Top",
        value: "borderTop",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Border Bottom",
        value: "borderBottom",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "156px",
      },
    ],
    accountHeaders: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "index",
        divider: true,
        width: "15%",
      },
      { text: "Nama", value: "name", sortable: true, divider: true },
      {
        text: "Aksi",
        value: "actions",
        align: "center",
        sortable: false,
        width: "20%",
      },
    ],
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    accountId: "",
    expanded: [],
    expandedSecond: [],
    account: {},
    accountChild: [],
    accountGrandChild: [],
    oldAccountChild: {},
    accountDialog: [],
    accountChildDialog: [],
    accountCodeFirstChar: "",
    accountParentName: "",
    accountChildName: "",
    search: "",
    searchAccount: "",
    dialog: false,
    dialogDelete: false,
    addAccountParentDialog: false,
    addAccountChildDialog: false,
    editAccountDialog: false,
    deletAccountDialog: false,
    addAccountGrandChildDialog: false,
    editAccountChildDialog: false,
    accountLoading: true,
    accountDialogTitle: "",
    loadingText: "Sedang memuat data...",
    noResultsText: "Data tidak ditemukan",
    noDataText: "Tidak ada data yang tersedia",
    // Snackbar
    alertColor: "",
    snackbarAlert: false,
    snackbarAlertText: "",
    timeout: 2000,
    // form
    accountLevel: "1",
    headerName: "",
    selectedAccountChild: "",
    calculate: false,
    subGroup: false,
    fontBold: false,
    borderTop: false,
    borderBottom: false,
    accountJournal: "",
  }),
  computed: {
    ...mapGetters(["getAccount", "getReportAccountNA", "getAccountType"]),
    getAccountParent() {
      console.log(JSON.parse(JSON.stringify(this.getAccountType)));
      return JSON.parse(JSON.stringify(this.getAccountType)).map(
        (item, index) => {
          return {
            key: "parent_" + index,
            index: index,
            name: item.name.toUpperCase(),
          };
        }
      );
    },
    // getAccountParent() {
    //   return this.getAccount.filter((item) => item.parentId === 0);
    // },
    // getAccountChild() {
    //   const accountChild = { ...this.getReportAccountNA }[this.accountParentName];
    //   if (this.accountParentName === "ASSETS") {
    //     return Object.keys({ ...accountChild }).map((item, index) => {
    //       return {
    //         key: 'child_' + index,
    //         index: index,
    //         name: item,
    //         parent: this.accountParentName,
    //       };
    //     });
    //   } else {
    //     let liabilitiesChild = [];
    //     for (let key in {...accountChild}) {
    //       liabilitiesChild.push({
    //         key: 'child_' + key,
    //         index: parseInt(key),
    //         account: {...accountChild}[key],
    //         name: {...accountChild}[key].name,
    //         parent: this.accountParentName,
    //       });
    //     }
    //     return liabilitiesChild;
    //   }
    //   // return this.getAccount.filter(
    //   //   (item) =>
    //   //     item.parentId !== 0 &&
    //   //     item.code.charAt(0) === this.accountCodeFirstChar
    //   // );
    // },
    // getAccountGrandChild() {
    //   const accountChild = { ...this.getReportAccountNA }[this.accountParentName];
    //   const accountGrandChild = { ...accountChild }[this.accountChildName];
    //   let grandChildKeyList = [];
    //   for (let key in accountGrandChild) {
    //     grandChildKeyList.push({
    //       index: parseInt(key) + 1,
    //       name: accountGrandChild[key].name,
    //       ...accountGrandChild[key],
    //     });
    //   }
    //   return grandChildKeyList;
    // },
    // getAccountDialogItems() {
    //   if (this.addAccountParentDialog) {
    //     return this.getAccountParent;
    //   }
    //   if (this.addAccountChildDialog) {
    //     return this.getAccountChild;
    //   }
    //   return this.getAccountGrandChild;
    // },
    // accountItemsWithIndex() {
    //   return this.account.map((items, index) => ({
    //     ...items,
    //     index: index + 1,
    //   }));
    // },
    formattedItems() {
      const reportAccountNa = JSON.parse(
        JSON.stringify(this.getReportAccountNA)
      );
      const account = JSON.parse(JSON.stringify(this.getAccount));
      if (
        Object.keys(reportAccountNa).length > 0 &&
        Object.keys(account).length > 0
      ) {
        return this.formatItemsRecursively(
          reportAccountNa,
          this.tableNumbering,
          this.itemParents
        );
      } else return [];
    },
  },
  watch: {
    getAccountParent() {
      this.accountLoading = false;
    },
  },
  methods: {
    ...mapActions(["getAccountData", "getReportDataNA", "getAccountTypeData"]),
    selectItem(value) {
      this.itemPerPage = value;
    },
    getAccountChildItems(parentName, parentId) {
      const accountChild = { ...this.getReportAccountNA }[parentName];
      if (parentName === "ASSETS") {
        return Object.keys({ ...accountChild }).map((item, index) => {
          return {
            key: "childAssets_" + index,
            index: index,
            name: item,
            parentId: parentId,
            parent: parentName,
          };
        });
      } else {
        let liabilitiesChild = [];
        for (let key in { ...accountChild }) {
          liabilitiesChild.push({
            key: "childLiabilities_" + key,
            index: parseInt(key),
            account: { ...accountChild }[key],
            name: { ...accountChild }[key].name,
            parentId: parentId,
            parent: parentName,
          });
        }
        return liabilitiesChild;
      }
    },
    getAccountGrandChildItems(
      grandParentName,
      grandParentId,
      parentName,
      parentId
    ) {
      const accountGrandChild = { ...this.getReportAccountNA }[grandParentName][
        parentName
      ];
      let grandChild = [];
      for (let key in { ...accountGrandChild }) {
        grandChild.push({
          key: "grandChild_" + key,
          index: parseInt(key),
          account: { ...accountGrandChild }[key],
          name: { ...accountGrandChild }[key].name,
          parentId: parentId,
          parent: parentName,
          grandParentId: grandParentId,
          grandParent: grandParentName,
        });
      }
      return grandChild;
    },
    openAddParentDialog() {
      console.log({ ...this.getReportAccountNA });
      this.accountDialogTitle = "Tambah Komponen";
      this.addAccountParentDialog = true;
      this.accountDialog = this.getAccountParent;
      // console.table(this.getAccount.filter((item) => item.parentId === 91 || item.id === 91));
    },
    // handleAddAccountChild(item) {
    //   // const name = item.name;
    //   // console.log({...this.getReportAccountNA}[name]);
    //   this.accountDialogTitle = "Tambah Anak Akun";
    //   this.addAccountChildDialog = true;
    //   this.accountParentName = item.name;
    //   // this.accountCodeFirstChar = item.index.toString();
    //   // console.log(this.getAccountGrandChild);
    //   this.accountDialog = this.getAccountChild;
    //   this.dialog = true;
    // },
    openAddChildDialog(item) {
      this.accountDialogTitle = "Tambah Anak Akun";
      this.addAccountChildDialog = true;
      this.selectedItem = item;
      // this.accountChildDialog = this.getAccountChildItems(item.name, item.parentId);
    },
    openEditDialog(item) {
      if (item.role === "child") {
        this.accountLevel = "2";
        // TODO: get item name for v-autocomplete
        this.calculate = item.isCalculate;
        this.subGroup = item.isSubGroup;
        this.fontBold = item.fontBold;
        this.borderTop = item.borderTop;
        this.borderBottom = item.borderBottom;
      } else {
        this.accountLevel = "1";
        this.headerName = item.name;
      }
      this.editAccountDialog = true;
      if (item.role === "component") {
        this.addAccountParentDialog = true;
        this.accountDialogTitle = "Edit Komponen";
      }
      if (item.role === "child" || item.role === "header") {
        this.addAccountChildDialog = true;
        this.accountDialogTitle = "Edit Anak Akun";
      }
      this.selectedItem = item;
      // this.selectedItem = this.getAccount.find((account) => account.name === item.name);
      // console.log(item);
    },
    openDeleteDialog(item) {
      this.deletAccountDialog = true;
      this.deletedItem = item;
    },
    handleAddAccountParent(item) {
      let reportAccountNA = JSON.parse(JSON.stringify(this.getReportAccountNA));
      if (Object.keys(reportAccountNA).includes(item.name)) {
        this.snackbarWarning();
      } else {
        reportAccountNA[item.name] = {};
        console.log(reportAccountNA);
        //TODO: post reportAccountNA to database
        this.snackbarSuccess("Akun berhasil ditambahkan!");
      }
    },
    handleAddAccountChild() {
      if (this.$refs.addChildForm.validate()) {
        const reportAccountNA = JSON.parse(
          JSON.stringify(this.getReportAccountNA)
        );
        const parent = JSON.parse(JSON.stringify(this.selectedItem));
        const parents = parent.parents.split("~");
        let targetChildItem = parents.reduce(
          (previous, current) => previous[current],
          reportAccountNA
        );
        if (this.accountLevel === "1") {
          if (Object.keys(targetChildItem).includes(this.headerName)) {
            this.snackbarWarning();
          } else {
            targetChildItem[this.headerName] = {};
            // TODO: post reportAccountNA to database
            this.snackbarSuccess("Header berhasil ditambahkan!");
          }
        } else {
          const selectedChild = this.getAccount.find(
            (account) => account.code === this.selectedAccountChild
          );
          if (Object.keys(targetChildItem).includes(selectedChild.name)) {
            this.snackbarWarning();
          } else {
            targetChildItem[Object.keys(targetChildItem).length] = {
              name: selectedChild.name,
              isCalculate: this.calculate,
              isSubGroup: this.subGroup,
              account: {
                in: new Array(0),
                not: new Array(0),
              },
              style: {
                borderBottom: this.borderBottom,
                borderTop: this.borderTop,
                fontBold: this.fontBold,
              },
            };
            // TODO: post reportAccountNA to database
            this.snackbarSuccess("Akun berhasil ditambahkan!");
          }
        }
        this.closeDialog();
        console.log(reportAccountNA);
      }
    },
    handleUpdateAccountChild() {
      if (this.$refs.addChildForm.validate()) {
        const reportAccountNA = JSON.parse(
          JSON.stringify(this.getReportAccountNA)
        );
        const parent = JSON.parse(JSON.stringify(this.selectedItem));
        const parents = parent.parents.split("~");
        parents.pop();
        let targetChildItem = parents.reduce(
          (previous, current) => previous[current],
          reportAccountNA
        );
        if (this.accountLevel === "1") {
          // console.log(targetChildItem);
          targetChildItem = this.renameObjectKey(
            parent.name,
            this.headerName,
            targetChildItem
          );
          // console.log(targetChildItem);
          const header = parents.pop();
          let buildNewObject = parents.reduce(
            (previous, current) => previous[current],
            reportAccountNA
          );
          buildNewObject[header] = targetChildItem;
          // if (Object.keys(targetChildItem).includes(this.headerName)) {
          //   this.snackbarWarning();
          // } else {
          //   targetChildItem[this.headerName] = {};
          // TODO: post reportAccountNA to database
          this.snackbarSuccess("Header berhasil diubah!");
          // }
        } else {
          const selectedChild = this.getAccount.find(
            (account) => account.code === this.selectedAccountChild
          );
          if (Object.keys(targetChildItem).includes(selectedChild.name)) {
            this.snackbarWarning();
          } else {
            targetChildItem[this.selectedItem.key].name = selectedChild.name;
            targetChildItem[this.selectedItem.key].isCalculate = this.calculate;
            targetChildItem[this.selectedItem.key].isSubGroup = this.subGroup;
            targetChildItem[this.selectedItem.key].style.borderBottom =
              this.borderBottom;
            targetChildItem[this.selectedItem.key].style.borderTop =
              this.borderTop;
            targetChildItem[this.selectedItem.key].style.fontBold =
              this.fontBold;
            // targetChildItem[Object.keys(targetChildItem).length] = {
            //   name: selectedChild.name,
            //   isCalculate: this.calculate,
            //   isSubGroup: this.subGroup,
            //   account: {
            //     in: new Array(0),
            //     not: new Array(0),
            //   },
            //   style: {
            //     borderBottom: this.borderBottom,
            //     borderTop: this.borderTop,
            //     fontBold: this.fontBold,
            //   },
            // };
            // TODO: post reportAccountNA to database
            this.snackbarSuccess("Akun berhasil diubah!");
          }
        }
        this.closeDialog();
        console.log(reportAccountNA);
      }
    },
    handleDeleteAccount() {
      const reportAccountNA = JSON.parse(
        JSON.stringify(this.getReportAccountNA)
      );
      const parent = JSON.parse(JSON.stringify(this.deletedItem));
      const parents = parent.parents.split("~");
      parents.pop();
      let targetChildItem = parents.reduce(
        (previous, current) => previous[current],
        reportAccountNA
      );
      delete targetChildItem[this.deletedItem.key];
      console.log(reportAccountNA);
      // TODO: post reportAccountNA to database
      this.snackbarSuccess("Akun berhasil dihapus!");
      this.deletAccountDialog = false;
    },
    handleAddAccountGrandChild(item) {
      this.accountDialogTitle = "Tambah Cucu Akun";
      this.addAccountGrandChildDialog = true;
      this.accountParentName = item.parent;
      this.accountChildName = item.name;
      this.accountDialog = this.getAccountGrandChild;
      this.dialog = true;
    },
    snackbarWarning() {
      this.alertColor = "warning";
      this.snackbarAlertText = "Akun sudah ditambahkan!";
      this.snackbarAlert = true;
    },
    snackbarSuccess(text) {
      this.alertColor = "success";
      this.snackbarAlertText = text;
      this.snackbarAlert = true;
    },
    addAccount(item) {
      // console.log(item);
      let addSuccessText = "Akun berhasil ditambahkan!";
      if (this.addAccountParentDialog) {
        if (this.account.includes(item)) {
          this.snackbarWarning();
        } else {
          this.account.push(item);
          this.snackbarSuccess(addSuccessText);
        }
      } else if (this.addAccountChildDialog) {
        if (this.accountChild.includes(item)) {
          this.snackbarWarning();
        } else {
          this.accountChild.push(item);
          this.snackbarSuccess(addSuccessText);
        }
      } else if (this.addAccountGrandChildDialog) {
        if (this.accountGrandChild.includes(item)) {
          this.snackbarWarning();
        } else {
          console.log(item);
          this.accountGrandChild.push(item.account);
          this.snackbarSuccess(addSuccessText);
        }
      } else {
        if (this.accountChild.includes(item)) {
          this.snackbarWarning();
        } else {
          this.accountChild.splice(
            this.accountChild.indexOf(this.oldAccountChild),
            1,
            item
          );
          let text = "Akun berhasil diubah!";
          this.snackbarSuccess(text);
          this.dialog = false;
        }
      }
    },
    // handleEditAccountChild(account) {
    //   this.accountDialogTitle = "Edit Anak Akun";
    //   this.oldAccountChild = account;
    //   this.editAccountChildDialog = true;
    //   this.accountCodeFirstChar = account.code.charAt(0);
    //   this.accountDialog = this.getAccountChild;
    //   this.dialog = true;
    // },
    handleDeleteAccountChild(account) {
      this.accountId = account.id;
      this.dialogDelete = true;
    },
    confirmDeleteItem() {
      this.accountChild.splice(
        this.accountChild.findIndex((item) => item.id === this.accountId),
        1
      );
      let text = "Akun berhasil dihapus!";
      this.dialogDelete = false;
      this.snackbarSuccess(text);
    },
    closeDialog() {
      this.searchAccount = "";
      this.dialog = false;
      if (this.addAccountChildDialog) {
        this.$refs.addChildForm.reset();
      }
      this.addAccountParentDialog = false;
      this.addAccountChildDialog = false;
      this.editAccountDialog = false;
      setTimeout(() => {
        this.accountLevel = "1";
      }, 0);
    },
    changeAccountLevel() {
      this.headerName = "";
      this.selectedAccountChild = {};
      this.calculate = false;
      this.subGroup = false;
      this.fontBold = false;
      this.borderTop = false;
      this.borderBottom = false;
    },
    // setColumnWidth() {
    //   const isCollapsed = Object.entries({...this.$refs.accountParentTable.expanded}).length;
    //   if (isCollapsed) {
    //     this.expandedSecond = [];
    //   }
    //   setTimeout(() => {
    //     if (this.expanded.length === 1) {
    //       const rowCount = this.getAccountChildItems(this.expanded[0].name, this.expanded[0].index).length;
    //       for (let i = 1; i <= rowCount; i++) {
    //         for (let j = 1; j <= 9; j++) {
    //           document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(1) > td:nth-child(${j})`).classList.add('no-border-top');
    //           document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${rowCount}) > td:nth-child(${j})`).classList.add('no-border-bottom');
    //           if (j >= 4 && j < 9 ) {
    //             document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(${j})`).style.width = "156px";
    //           }
    //         }
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`).style.width = "63px";
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`).classList.add('no-border-left');
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(2)`).style.width = "92px";
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(3)`).style.width = "300px";
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(9)`).classList.add('no-border-right');
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(9)`).style.width = "155px";
    //       }
    //     } else {
    //       return;
    //     }
    //   }, 0);
    // },
    // setChildColumnWidth() {
    //   setTimeout(() => {
    //     if (this.expandedSecond.length === 1) {
    //       const rowCount = this.getAccountGrandChildItems(this.expandedSecond[0].parent, this.expandedSecond[0].parentId, this.expandedSecond[0].name, this.expandedSecond[0].index).length;
    //       for (let i = 1; i <= rowCount; i++) {
    //         for (let j = 1; j <= 9; j++) {
    //           document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(1) > td:nth-child(${j})`).classList.add('no-border-top');
    //           document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${rowCount}) > td:nth-child(${j})`).classList.add('no-border-bottom');
    //           if (j >= 4 && j < 9 ) {
    //             document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(${j})`).style.width = "156px";
    //           }
    //         }
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`).style.width = "62px";
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`).classList.add('no-border-left');
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(2)`).style.width = "92px";
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(3)`).style.width = "300px";
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(9)`).classList.add('no-border-right');
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(9)`).style.width = "154px";
    //       }
    //     } else {
    //       return;
    //     }
    //   }, 0);
    // },
    formatItemsRecursively(items, numbering, itemParents) {
      const keys = Object.keys(items);
      keys.forEach((key, index) => {
        const child = items[key];
        if (child.name) {
          this.tableItems.push({
            key: key,
            numbering: numbering + (index + 1),
            name: child.name,
            isCalculate: child.isCalculate,
            isSubGroup: child.isSubGroup,
            fontBold: child.style.fontBold,
            borderTop: child.style.borderTop,
            borderBottom: child.style.borderBottom,
            account: child,
            role: "child",
            parents: itemParents + key,
          });
          if (child.account.in) {
            child.account.in.forEach((code) => {
              const account = JSON.parse(JSON.stringify(this.getAccount)).find(
                (account) => account.code === code
              );
              this.tableItems.push({
                name: code + " — " + account.name,
                account: account,
              });
            });
          }
          if (child.account.out) {
            child.account.out.forEach((code) => {
              const account = JSON.parse(JSON.stringify(this.getAccount)).find(
                (account) => account.code === code
              );
              this.tableItems.push({
                name: code + " — " + account.name,
                account: account,
              });
            });
          }
        } else {
          this.tableItems.push({
            key: key,
            numbering: numbering + (index + 1),
            name: key,
            role: ["assets", "liabilities"].includes(key.toLowerCase())
              ? "component"
              : "header",
            parents: itemParents + key,
          });
          if (Object.keys(items[key]).length > 0) {
            this.formatItemsRecursively(
              items[key],
              numbering + (index + 1) + ".",
              itemParents + key + "~"
            );
          }
          // push key
          // this.formatItemsRecursively
        }
      });
      return this.tableItems;
    },
    renameObjectKey(oldKey, newKey, obj) {
      let entries = Object.entries(obj);
      let index = entries.findIndex((el) => el[0] === oldKey);
      let targetEntries = entries.find((el) => el[0] === oldKey);
      entries[index] = [newKey, targetEntries[1]];

      let newObj = {};
      for (const [key, value] of entries) {
        newObj[key] = value;
      }

      return newObj;
    },
  },
  mounted() {
    this.getAccountData();
    this.getReportDataNA();
    this.getAccountTypeData();
    this.tableItems = [];
    this.tableNumbering = "";
    this.itemParents = "";
  },
  destroyed() {
    this.tableItems = [];
    this.tableNumbering = "";
    this.itemParents = "";
  },
};
</script>

<style src="../styles/statements-net-asset.scss" lang="scss" />
