<template>
  <v-btn
    @click="add"
    :disabled="disabled"
    :color="color"
    class="btn-add"
    tile
    depressed
  >
    <v-icon class="mr-1" size="20">mdi-plus</v-icon>
    <slot name="btnText"></slot>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  data: () => ({
    color: "#EB7765",
  }),
  props: ["disabled"],
  methods: {
    add() {
      this.$emit("add");
    },
  },
};
</script>

<style scoped>
.btn-add {
  color: #ffffff;
  text-transform: capitalize;
  width: auto;
  border-radius: 3px;
}
</style>
