import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const reportTypeModule = {
  state: {
    reportType: [],
  },
  mutations: {
    setReportType(state, payload) {
      state.reportType = payload;
    },
  },
  actions: {
    async getReportTypeData({ commit }) {
      try {
        let res = await axios.get(process.env.VUE_APP_API_URL + "/reportType");
        let data = res.data.data;
        commit("setReportType", data);
      } catch (error) {
        console.log(error);
      }
    },
    async addReportTypeData({ dispatch, commit }, payload) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + "/reportType", {
          code: payload.code,
          name: payload.name,
        });
        dispatch("getReportTypeData");
        commit("showSnackbarAdd", true);
      } catch (error) {
        console.log(error);
      }
    },
    async editReportTypeData({ dispatch, commit }, payload) {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "/reportType/" + payload.id,
          {
            code: payload.code,
            name: payload.name,
          }
        );
        dispatch("getReportTypeData");
        commit("showSnackbarEdit", true);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteReportTypeData({ dispatch, commit }, id) {
      try {
        await axios.delete(process.env.VUE_APP_API_URL + "/reportType/" + id);
        dispatch("getReportTypeData");
        commit("showSnackbarDelete", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getReportType(state) {
      return state.reportType;
    },
  },
};

export default reportTypeModule;
