<template>
  <v-container>
    <v-snackbar
      v-model="snackbarAlert"
      :timeout="timeout"
      :color="alertColor"
      min-width="100"
      top
      right
    >
      <span>{{ snackbarAlertText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbarAlert = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="net-asset-card mt-6 ml-6 mr-6 pb-4">
      <v-card-title class="pl-7">
        <v-row>
          <v-col>
            <span class="net-asset-card-title"
              >Konfigurasi Laporan Statements of Net Asset</span
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="7">
            <v-btn
              color="#E89F3C"
              class="rounded white--text btn-edit"
              :to="{ name: 'Statements Net Asset Edit' }"
              tile
              depressed
            >
              <v-icon class="mr-1" size="20"> mdi-pencil </v-icon>
              Ubah
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pl-7">
        <v-row justify="space-between">
          <v-col md="4">
            <v-row>
              <v-col md="4" class="mr-n6">
                <span class="body-1">Tampilkan</span>
              </v-col>
              <v-col md="4">
                <v-select
                  v-model="itemPerPage"
                  :items="itemsPerPage"
                  @change="selectItem"
                  append-icon="mdi-chevron-down"
                  background-color="#FAFAFA"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="4" class="ml-n2">
                <span class="body-1">Entri</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" class="mr-3">
            <v-text-field
              v-model="search"
              placeholder="Cari akun"
              prepend-inner-icon="mdi-magnify"
              background-color="#FAFAFA"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pl-7 pr-7 pb-7">
        <v-col>
          <v-card class="account-table pb-4" elevation="0">
            <v-data-table
              id="account-parent-table"
              ref="accountParentTable"
              :headers="headers"
              :items="formattedItems"
              item-key="key"
              :options="{
                itemsPerPage: itemPerPage,
              }"
              :page.sync="page"
              @page-count="pageCount = $event"
              :search="search"
              :no-results-text="noResultsText"
              :no-data-text="noDataText"
              :footer-props="{
                'items-per-page-text': '',
                'disable-items-per-page': true,
                'items-per-page-options': [],
                'prev-icon': null,
                'next-icon': null,
              }"
            >
              <template v-slot:[`item.key`]="{ item }">
                <span
                  :class="
                    ['assets', 'liabilities'].includes(item.name.toLowerCase())
                      ? 'text-bold'
                      : ''
                  "
                  >{{ item.key }}</span
                >
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <span
                  :class="
                    ['assets', 'liabilities'].includes(item.name.toLowerCase())
                      ? 'text-bold'
                      : ''
                  "
                  >{{ item.name }}</span
                >
              </template>
              <template v-slot:[`item.isCalculate`]="{ item }">
                <v-checkbox
                  v-if="Object.keys(item).some((el) => el == 'isCalculate')"
                  v-model="item.isCalculate"
                  :ripple="false"
                  color="primary"
                  :readonly="true"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.isSubGroup`]="{ item }">
                <v-checkbox
                  v-if="Object.keys(item).some((el) => el == 'isSubGroup')"
                  v-model="item.isSubGroup"
                  :ripple="false"
                  color="primary"
                  :readonly="true"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.fontBold`]="{ item }">
                <v-checkbox
                  v-if="Object.keys(item).some((el) => el == 'fontBold')"
                  v-model="item.fontBold"
                  :ripple="false"
                  color="primary"
                  :readonly="true"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.borderTop`]="{ item }">
                <v-checkbox
                  v-if="Object.keys(item).some((el) => el == 'borderTop')"
                  v-model="item.borderTop"
                  :ripple="false"
                  color="primary"
                  :readonly="true"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.borderBottom`]="{ item }">
                <v-checkbox
                  v-if="Object.keys(item).some((el) => el == 'borderBottom')"
                  v-model="item.borderBottom"
                  :ripple="false"
                  color="primary"
                  :readonly="true"
                ></v-checkbox>
              </template>
              <!-- <v-data-table
              id="account-parent-table"
              ref="accountParentTable"
              :headers="headers"
              :items="getAccountParent"
              item-key="key"
              :options="{
                itemsPerPage: itemPerPage,
              }"
              :page.sync="page"
              @page-count="pageCount = $event"
              @item-expanded="setColumnWidth"
              :search="search"
              :no-results-text="noResultsText"
              :no-data-text="noDataText"
              :expanded.sync="expanded"
              :single-expand="true"
              show-expand
              :footer-props="{
                'items-per-page-text': '',
                'disable-items-per-page': true,
                'items-per-page-options': [],
                'prev-icon': null,
                'next-icon': null,
              }"
            > -->
              <!-- <template v-slot:[`item.index`]="{ item }">
                {{ item.index + 1 }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" :key="item.index" class="pl-0 pr-0">
                  <recursive-data-table
                    :headers="headers"
                    :items="getAccountChildItems(item.name)"
                  >
                  </recursive-data-table> -->
              <!-- <v-data-table
                    id="account-child-table"
                    :headers="headers"
                    :items="getAccountChildItems(item.name, item.index)"
                    item-key="key"
                    :single-expand="true"
                    @item-expanded="setChildColumnWidth"
                    :expanded.sync="expandedSecond"
                    :show-expand="item.name === 'ASSETS'"
                    hide-default-header
                    hide-default-footer
                  >
                    <template v-slot:[`item.index`]="{ item }">
                      {{item.parentId + 1}}.{{ item.index + 1 }}
                    </template>
                    <template v-slot:[`item.isCalculate`]="{ item }">
                      <v-checkbox
                        v-if="item.parent !== 'ASSETS'"
                        v-model="item.account.isCalculate"
                        :ripple="false"
                        color="primary"
                        :readonly="true"
                      ></v-checkbox>
                    </template>
                    <template v-slot:[`item.isSubGroup`]="{ item }">
                      <v-checkbox
                        v-if="item.parent !== 'ASSETS'"
                        v-model="item.account.isSubGroup"
                        :ripple="false"
                        color="primary"
                        :readonly="true"
                      ></v-checkbox>
                    </template>
                    <template v-slot:[`item.fontBold`]="{ item }">
                      <v-checkbox
                        v-if="item.parent !== 'ASSETS'"
                        v-model="item.account.style.fontBold"
                        :ripple="false"
                        color="primary"
                        :readonly="true"
                      ></v-checkbox>
                    </template>
                    <template v-slot:[`item.borderTop`]="{ item }">
                      <v-checkbox
                        v-if="item.parent !== 'ASSETS'"
                        v-model="item.account.style.borderTop"
                        :ripple="false"
                        color="primary"
                        :readonly="true"
                      ></v-checkbox>
                    </template>
                    <template v-slot:[`item.borderBottom`]="{ item }">
                      <v-checkbox
                        v-if="item.parent !== 'ASSETS'"
                        v-model="item.account.style.borderBottom"
                        :ripple="false"
                        color="primary"
                        :readonly="true"
                      ></v-checkbox>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length" :key="item.index" class="pl-0 pr-0">
                        <v-data-table
                          id="account-grand-child-table"
                          :headers="headers"
                          :items="getAccountGrandChildItems(item.parent, item.parentId, item.name, item.index)"
                          item-key="key"
                          hide-default-header
                          hide-default-footer
                        >
                          <template v-slot:[`item.index`]="{ item }">
                            {{item.grandParentId + 1}}.{{item.parentId + 1}}.{{ item.index + 1 }}
                          </template>
                          <template v-slot:[`item.isCalculate`]="{ item }">
                            <v-checkbox
                              v-model="item.account.isCalculate"
                              :ripple="false"
                              color="primary"
                              :readonly="true"
                            ></v-checkbox>
                          </template>
                          <template v-slot:[`item.isSubGroup`]="{ item }">
                            <v-checkbox
                              v-model="item.account.isSubGroup"
                              :ripple="false"
                              color="primary"
                              :readonly="true"
                            ></v-checkbox>
                          </template>
                          <template v-slot:[`item.fontBold`]="{ item }">
                            <v-checkbox
                              v-model="item.account.style.fontBold"
                              :ripple="false"
                              color="primary"
                              :readonly="true"
                            ></v-checkbox>
                          </template>
                          <template v-slot:[`item.borderTop`]="{ item }">
                            <v-checkbox
                              v-model="item.account.style.borderTop"
                              :ripple="false"
                              color="primary"
                              :readonly="true"
                            ></v-checkbox>
                          </template>
                          <template v-slot:[`item.borderBottom`]="{ item }">
                            <v-checkbox
                              v-model="item.account.style.borderBottom"
                              :ripple="false"
                              color="primary"
                              :readonly="true"
                            ></v-checkbox>
                          </template>
                        </v-data-table>
                      </td>
                    </template>
                  </v-data-table> -->
              <!-- </td>
              </template> -->
              <template
                v-slot:[`footer.page-text`]="{
                  pageStart,
                  pageStop,
                  itemsLength,
                }"
              >
                Showing {{ pageStart }} to {{ pageStop }} of {{ itemsLength }}
              </template>
            </v-data-table>
            <v-row class="mt-n10">
              <v-spacer></v-spacer>
              <v-col md="auto" class="mr-10">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="7"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import RecursiveDataTable from "./base/RecursiveDataTable.vue";

export default {
  name: "ReportStatementsOfNetAssetList",
  // components: {
  //   RecursiveDataTable,
  // },
  data: () => ({
    tableItems: [],
    tableNumbering: "",
    headers: [
      // { text: "", value: "data-table-expand", width: "64px" },
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "key",
        divider: true,
        width: "92px",
      },
      {
        text: "Nama",
        value: "name",
        sortable: true,
        divider: true,
        width: "500px",
      },
      {
        text: "Calculate",
        value: "isCalculate",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Sub Group",
        value: "isSubGroup",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Font Bold",
        value: "fontBold",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Border Top",
        value: "borderTop",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
      {
        text: "Border Bottom",
        value: "borderBottom",
        align: "center",
        sortable: false,
        divider: true,
        width: "156px",
      },
    ],
    page: 1,
    pageCount: 0,
    itemPerPage: 10,
    itemsPerPage: [10, 25, 50, 100],
    // expanded: [],
    // expandedSecond: [],
    search: "",
    accountLoading: true,
    loadingText: "Sedang memuat data...",
    noResultsText: "Data tidak ditemukan",
    noDataText: "Tidak ada data yang tersedia",
    // Snackbar
    alertColor: "",
    snackbarAlert: false,
    snackbarAlertText: "",
    timeout: 2000,
  }),
  computed: {
    ...mapGetters(["getAccount", "getReportAccountNA"]),
    // getAccountParent() {
    //   return Object.keys({...this.getReportAccountNA}).map((item, index) => {
    //     return {
    //       key: "parent_" + index,
    //       index: index,
    //       name: item,
    //     };
    //   });
    // },
    formattedItems() {
      const reportAccountNa = JSON.parse(
        JSON.stringify(this.getReportAccountNA)
      );
      const account = JSON.parse(JSON.stringify(this.getAccount));
      if (
        Object.keys(reportAccountNa).length > 0 &&
        Object.keys(account).length > 0
      ) {
        return this.formatItemsRecursively(
          reportAccountNa,
          this.tableNumbering
        );
      } else return [];
    },
  },
  watch: {
    getAccountParent() {
      this.accountLoading = false;
    },
  },
  methods: {
    ...mapActions(["getAccountData", "getReportDataNA"]),
    selectItem(value) {
      this.itemPerPage = value;
    },
    snackbarWarning() {
      this.alertColor = "warning";
      this.snackbarAlertText = "Akun sudah ditambahkan!";
      this.snackbarAlert = true;
    },
    snackbarSuccess(text) {
      this.alertColor = "success";
      this.snackbarAlertText = text;
      this.snackbarAlert = true;
    },
    // getAccountChildItems(parentName) {
    //   const accountChild = {...this.getReportAccountNA}[parentName];
    //   return {...accountChild};
    // },
    // getAccountChildItems(parentName, parentId) {
    //   const accountChild = { ...this.getReportAccountNA }[parentName];
    //   if (parentName === "ASSETS") {
    //     return Object.keys({ ...accountChild }).map((item, index) => {
    //       return {
    //         key: 'childAssets_' + index,
    //         index: index,
    //         name: item,
    //         parentId: parentId,
    //         parent: parentName,
    //       };
    //     });
    //   } else {
    //     let liabilitiesChild = [];
    //     for (let key in {...accountChild}) {
    //       liabilitiesChild.push({
    //         key: 'childLiabilities_' + key,
    //         index: parseInt(key),
    //         account: {...accountChild}[key],
    //         name: {...accountChild}[key].name,
    //         parentId: parentId,
    //         parent: parentName,
    //       });
    //     }
    //     return liabilitiesChild;
    //   }
    // },
    // getAccountGrandChildItems(grandParentName, grandParentId, parentName, parentId) {
    //   const accountGrandChild = { ...this.getReportAccountNA }[grandParentName][parentName];
    //   let grandChild = [];
    //   for (let key in {...accountGrandChild}) {
    //     grandChild.push({
    //       key: 'grandChild_' + key,
    //       index: parseInt(key),
    //       account: {...accountGrandChild}[key],
    //       name: {...accountGrandChild}[key].name,
    //       parentId: parentId,
    //       parent: parentName,
    //       grandParentId: grandParentId,
    //       grandParent: grandParentName,
    //     });
    //   }
    //   return grandChild;
    // },
    // setColumnWidth() {
    //   const isCollapsed = Object.entries({...this.$refs.accountParentTable.expanded}).length;
    //   if (isCollapsed) {
    //     this.expandedSecond = [];
    //   }
    //   setTimeout(() => {
    //     if (this.expanded.length === 1) {
    //       const rowCount = this.getAccountChildItems(this.expanded[0].name, this.expanded[0].index).length;
    //       for (let i = 1; i <= rowCount; i++) {
    //         for (let j = 1; j <= 8; j++) {
    //           document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(1) > td:nth-child(${j})`).classList.add('no-border-top');
    //           document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${rowCount}) > td:nth-child(${j})`).classList.add('no-border-bottom');
    //           if (j >= 4 && j < 8 ) {
    //             document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(${j})`).style.width = "156px";
    //           }
    //         }
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`).style.width = "63px";
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`).classList.add('no-border-left');
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(2)`).style.width = "92px";
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(3)`).style.width = "300px";
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(8)`).classList.add('no-border-right');
    //         document.querySelector(`#account-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(8)`).style.width = "155px";
    //       }
    //     } else {
    //       return;
    //     }
    //   }, 0);
    // },
    // setChildColumnWidth() {
    //   setTimeout(() => {
    //     if (this.expandedSecond.length === 1) {
    //       const rowCount = this.getAccountGrandChildItems(this.expandedSecond[0].parent, this.expandedSecond[0].parentId, this.expandedSecond[0].name, this.expandedSecond[0].index).length;
    //       for (let i = 1; i <= rowCount; i++) {
    //         for (let j = 1; j <= 8; j++) {
    //           document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(1) > td:nth-child(${j})`).classList.add('no-border-top');
    //           document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${rowCount}) > td:nth-child(${j})`).classList.add('no-border-bottom');
    //           if (j >= 4 && j < 8 ) {
    //             document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(${j})`).style.width = "156px";
    //           }
    //         }
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`).style.width = "62px";
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(1)`).classList.add('no-border-left');
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(2)`).style.width = "92px";
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(3)`).style.width = "300px";
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(8)`).classList.add('no-border-right');
    //         document.querySelector(`#account-grand-child-table > div > table > tbody > tr:nth-child(${i}) > td:nth-child(8)`).style.width = "154px";
    //       }
    //     } else {
    //       return;
    //     }
    //   }, 0);
    // },
    formatItemsRecursively(items, numbering) {
      const keys = Object.keys(items);
      keys.forEach((key, index) => {
        const child = items[key];
        if (child.name) {
          this.tableItems.push({
            key: numbering + (index + 1),
            name: child.name,
            isCalculate: child.isCalculate,
            isSubGroup: child.isSubGroup,
            fontBold: child.style.fontBold,
            borderTop: child.style.borderTop,
            borderBottom: child.style.borderBottom,
          });
          if (child.account.in) {
            child.account.in.forEach((code) => {
              const account = JSON.parse(JSON.stringify(this.getAccount)).find(
                (account) => account.code === code
              );
              this.tableItems.push({
                name: code + " — " + account.name,
                account: account,
              });
            });
          }
          if (child.account.out) {
            child.account.out.forEach((code) => {
              const account = JSON.parse(JSON.stringify(this.getAccount)).find(
                (account) => account.code === code
              );
              this.tableItems.push({
                name: code + " — " + account.name,
                account: account,
              });
            });
          }
        } else {
          this.tableItems.push({
            key: numbering + (index + 1),
            name: key,
          });
          if (Object.keys(items[key]).length > 0) {
            this.formatItemsRecursively(
              items[key],
              numbering + (index + 1) + "."
            );
          }
          // push key
          // this.formatItemsRecursively
        }
      });
      return this.tableItems;
    },
  },
  mounted() {
    this.getAccountData();
    this.getReportDataNA();
    this.tableItems = [];
    this.tableNumbering = "";
  },
  destroyed() {
    this.tableItems = [];
    this.tableNumbering = "";
  },
};
</script>

<style src="../styles/statements-net-asset.scss" lang="scss" />
