import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const configurationReportModule = {
  state: {
    ReportAccountNA: [],
    ReportAccountBS: [],
    ReportAccountCNA: [],
    ReportAccountIS: [],
    ReportAccountCF: [],
  },
  mutations: {
    setReportAccountNA(state, payload) {
      state.ReportAccountNA = payload;
    },
    setReportAccountBS(state, payload) {
      state.ReportAccountBS = payload;
    },
    setReportAccountCNA(state, payload) {
      state.ReportAccountCNA = payload;
    },
    setReportAccountIS(state, payload) {
      state.ReportAccountIS = payload;
    },
    setReportAccountCF(state, payload) {
      state.ReportAccountCF = payload;
    },
  },
  actions: {
    async getReportDataNA({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/configuration/detail?code=rpt_na"
        );
        let data = res.data.data;
        let dataArr = JSON.parse(data.value);
        // console.log(dataArr);
        commit("setReportAccountNA", dataArr);
      } catch (error) {
        console.log(error);
      }
    },
    async getReportDataBS({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/configuration/detail?code=rpt_bs"
        );
        let data = res.data.data;
        let dataArr = JSON.parse(data.value);
        // console.log(dataArr);
        commit("setReportAccountBS", dataArr);
      } catch (error) {
        console.log(error);
      }
    },
    async getReportDataCNA({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/configuration/detail?code=rpt_cna"
        );
        let data = res.data.data;
        let dataArr = JSON.parse(data.value);
        // console.log(dataArr);
        commit("setReportAccountCNA", dataArr);
      } catch (error) {
        console.log(error);
      }
    },
    async getReportDataIS({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/configuration/detail?code=rpt_is"
        );
        let data = res.data.data;
        let dataArr = JSON.parse(data.value);
        // console.log(dataArr);
        commit("setReportAccountIS", dataArr);
      } catch (error) {
        console.log(error);
      }
    },
    async getReportDataCF({ commit }) {
      try {
        let res = await axios.get(
          process.env.VUE_APP_API_URL + "/configuration/detail?code=rpt_cf"
        );
        let data = res.data.data;
        let dataArr = JSON.parse(data.value);
        // console.log(dataArr);
        commit("setReportAccountCF", dataArr);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getReportAccountNA(state) {
      return state.ReportAccountNA;
    },
    getReportAccountBS(state) {
      return state.ReportAccountBS;
    },
    getReportAccountCNA(state) {
      return state.ReportAccountCNA;
    },
    getReportAccountIS(state) {
      return state.ReportAccountIS;
    },
    getReportAccountCF(state) {
      return state.ReportAccountCF;
    },
  },
};

export default configurationReportModule;
